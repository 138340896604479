import { createSelector } from "reselect"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import { initialState } from "./reducer"
import { DOCUMENT_SET_STATUS } from "./constants"

const documentReducer = state => get(state.toJS(), "document", initialState)

export const selectDocument = createSelector(documentReducer, state => state)

export const selectShowDocumentUploadReminder = createSelector(
  documentReducer,
  state => {
    if (isEmpty(state) || state?.status === DOCUMENT_SET_STATUS.DRAFT) {
      return false
    }
    return state?.status !== DOCUMENT_SET_STATUS.SUBMITTED
  },
)

export const selectIsSubmitting = createSelector(
  documentReducer,
  state => state.isSubmitting,
)

export const selectIsReplacingDocument = createSelector(
  documentReducer,
  state => state.isReplacingDocument,
)

export const selectIsLoading = createSelector(
  documentReducer,
  state => state.isLoading,
)

export const selectIsRenderIdUpload = createSelector(
  documentReducer,
  state => state.isRenderIdUpload,
)
