import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const getInitialState = state =>
  state.get("income-verification", initialState)

export const selectConnectLink = () =>
  createSelector(getInitialState, state => state.incomeVerification.connectLink)

export const selectIncomeVerification = () =>
  createSelector(getInitialState, state => state.incomeVerification)

export const selectIsLoadingIncomeVerification = () =>
  createSelector(getInitialState, state => state.isLoadingIncomeVerification)

export const selectIsSubmittedIncomeVerification = () =>
  createSelector(getInitialState, state => state.isSubmitted)
