import React from "react"
import { tracker, withTracker, PAGE_NAME } from "tracker"
import { T36 } from "@rentspree/component-v2"
import { compose } from "redux"
import track from "react-tracking"
import { ErrorAlert } from "components/alerts/error"
import { withConnect, withSaga } from "./connect"

import VerifyExamForm from "./form"

/* eslint-disable react/prefer-stateless-function */

export class StepExam extends React.Component {
  componentDidMount() {
    this.props.actions.examRetrieve()
  }
  render() {
    const { error, actions } = this.props
    return (
      <div>
        <T36 margin="50px 0px 10px" mMargin="35px 0 10px">
          TransUnion Screening
        </T36>
        {error?.show && (
          <ErrorAlert
            onClick={actions.closeErrorAlert}
            error={error}
            margin="25px 0 5px"
          />
        )}
        <VerifyExamForm {...this.props} />
      </div>
    )
  }
}

export default compose(
  track(),
  withSaga,
  withConnect,
)(withTracker(StepExam, tracker, PAGE_NAME.TU.EXAM))
