/* eslint-disable camelcase */
import get from "lodash/get"
import { createSelector } from "reselect"
import { fromJS } from "immutable"
import { PAGE_NAME } from "tracker"

import { PROVIDER, TU_ERROR_CODE } from "./constants"

export const selectTuCreditReport = state =>
  state.get("tuScreening").get("creditReport", fromJS({}))

export const selectCodeError = state => {
  const tuScreening = selectTuCreditReport(state)
  const creditReport = tuScreening.toJS()
  const transunion = get(creditReport, "transunion_errors", [])
  let selectError = {
    timestamps: "1880-04-12T15:13:11.733Z", // initial timestamp
  }
  // eslint-disable-next-line array-callback-return
  transunion.map(error => {
    selectError =
      new Date(selectError.timestamps) > new Date(error.timestamps)
        ? selectError
        : error
  })
  return get(selectError, "code", "")
}

export const makeSelectCodeError = () =>
  createSelector(selectCodeError, code => code)

export const makePageNameMapper = () =>
  createSelector(selectCodeError, code => pageNameMapper(code))

export const pageNameMapper = code => {
  switch (code) {
    case TU_ERROR_CODE["200010"]:
      return PAGE_NAME.TU.ERROR_200010
    case TU_ERROR_CODE["200012"]:
      return PAGE_NAME.TU.ERROR_200012
    case TU_ERROR_CODE["200014"]:
      return PAGE_NAME.TU.ERROR_200014
    case TU_ERROR_CODE["200015"]:
      return PAGE_NAME.TU.ERROR_200015
    default:
  }
  return null
}

const selectIntegrationId = state => {
  const creditReport = selectTuCreditReport(state).toJS()
  if (!creditReport.transunion_application) return undefined
  const {
    provider,
    transunion_application: { externalScreeningApplicationId, integrationId },
  } = creditReport
  return provider === PROVIDER.SHAREABLE
    ? externalScreeningApplicationId
    : integrationId
}

export const makeSelectIntegrationId = () =>
  createSelector(selectIntegrationId, integrationId => integrationId)
