import React from "react"
import { compose } from "redux"
import { Statsig } from "statsig-react"
import track from "react-tracking"

import { tracker, withTracker, PAGE_NAME } from "tracker"
import { EXPERIMENT_NAMES } from "utils/experiment/constants"
import { ErrorAlert } from "components/alerts/error"
import { T36 } from "@rentspree/component-v2"

import { withConnect, withSaga } from "./connect"
import SSNForm from "./form"
import { TU_100211_ERROR_ID } from "./constants"

/* eslint-disable react/prefer-stateless-function */

export class StepApplicantInfo extends React.Component {
  render() {
    const { actions, error } = this.props
    const haveToAskSupportToUpdate = error?.message?.id === TU_100211_ERROR_ID
    const isDisplayTooltip = Statsig.getExperiment(
      EXPERIMENT_NAMES.TRANSUNION_SCREENING,
    ).get("display_tooltip", true)

    return (
      <div>
        {error?.show && (
          <ErrorAlert
            onClick={actions.closeErrorAlert}
            error={error}
            haveContactInfo
            haveToAskSupportToUpdate={haveToAskSupportToUpdate}
            margin="25px 0 5px"
          />
        )}
        <T36
          lineHeight="normal"
          margin={`${error?.show ? "30" : "50"}px 0 35px 0`}>
          TransUnion Screening
        </T36>
        <SSNForm {...this.props} isDisplayTooltip={isDisplayTooltip} />
      </div>
    )
  }
}

export default compose(
  track(),
  withSaga,
  withConnect,
)(withTracker(StepApplicantInfo, tracker, PAGE_NAME.TU.INFO_SSN))
