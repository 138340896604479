import { SET_IS_SHOW_REVIEW_PAGE, UNSET_IS_SHOW_REVIEW_PAGE } from "./constants"

export const setIsShowReviewFlag = payload => ({
  type: SET_IS_SHOW_REVIEW_PAGE,
  payload,
})

export const unsetIsShowReviewFlag = () => ({
  type: UNSET_IS_SHOW_REVIEW_PAGE,
})
