import { array, object, lazy } from "yup"
import {
  validateLength,
  validateDob,
  validatePhone,
  validateDate,
  validateEmail,
} from "constants/validations"
import { addressValidationSchema } from "../validation-schema"

export const stepPersonalValidation = props => {
  const { personal } = props
  return {
    mapper: {
      firstName: personal.firstName,
      middleName: personal.middleName,
      lastName: personal.lastName,
      dob: personal.dob,
      phoneNumber: personal.phoneNumber,
    },
    schema: object({
      firstName: validateLength(),
      middleName: validateLength(false, 1, 35),
      lastName: validateLength(),
      dob: validateDob(),
      phoneNumber: validatePhone(),
    }),
  }
}

export const stepGovIdValidation = props => {
  const { governmentId } = props
  return {
    mapper: {
      photoType: governmentId.photoType,
      idNum: governmentId.idNum,
      issueBy: governmentId.issueBy,
      expiredDate: governmentId.expiredDate,
    },
    schema: object({
      photoType: validateLength(true, 0, 35),
      idNum: validateLength(true, 0, 35),
      issueBy: validateLength(true, 0, 255),
      expiredDate: validateDate(),
    }),
  }
}

export const stepEmergencyContactValidation = props => {
  const { emergencyContacts, isUsAddressOnly, isRequireFullAddress } = props
  return {
    mapper: {
      contacts: emergencyContacts,
    },
    schema: object({
      contacts: array(
        lazy(values => {
          const { isSkipEmail, streetAddress, zipcode, region } = values
          return object({
            firstName: validateLength(),
            lastName: validateLength(),
            email: isSkipEmail ? validateEmail(false) : validateEmail(),
            relationship: validateLength(true, 0, 35),
            phoneNumber: validatePhone(),
            ...addressValidationSchema({
              address: {
                streetAddress,
                zipCode: zipcode,
                region,
              },
              isRequireFullAddress,
              isUsAddressOnly,
            }),
          })
        }),
      ),
    }),
  }
}
