import { fromJS } from "immutable"
import {
  SET_IS_SELECT_MULTI_SHARE,
  SET_IS_SHOW_REQUIRE_MULTI_SHARE,
} from "./constants"

export const initialState = fromJS({
  isSelectMultiShareOffer: undefined,
  isShowRequireMultiShareOffer: undefined,
})

export default (state = initialState, action) => {
  let nextState = state
  switch (action.type) {
    case SET_IS_SELECT_MULTI_SHARE:
      nextState = state.set("isSelectMultiShareOffer", action.payload)
      return nextState
    case SET_IS_SHOW_REQUIRE_MULTI_SHARE:
      nextState = state.set("isShowRequireMultiShareOffer", action.payload)
      return nextState
    default:
      return state
  }
}
