import React from "react"
import {
  string,
  number,
  ref,
  mixed,
  object,
  array,
  addMethod,
  boolean,
} from "yup"
import moment from "moment/moment"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { FormattedMessage as Message } from "react-intl"
import date from "utils/yup-date-type"

import { SELECTED_RESIDENCE } from "containers/application/residence/constants"
import { WORK_STATUS } from "containers/application/occupation/constants"
import { BANK_ACCOUNT_TYPES } from "containers/application/reference/step-bank-information/const"
import message from "./error-messages"
import { INVALID_US_ZIP_CODE } from "./zipcode"
import { MILITARY_STATES } from "./us-state"

export const requiredYup = (
  required = false,
  yupType = string(),
  type = "normal",
) => {
  if (required) {
    let errorMessage = message.required
    if (type === "radio") {
      errorMessage = message.radioRequired
    }
    return yupType.required(<Message {...errorMessage} />)
  }
  return yupType
}

/**
 * The function will append the yup validation to the yup schema when it pass the condition in a function
 * default condition function will append required to yup schema
 *
 * The function can use for other yup validation for example If you want to append min length of array when radioValue === "yes"
 * then call this function with a customCondition
 *
 * whenCondition(someYupSchema, //yupType
 * "havePet", //conditionValueName
 * "Please input at lease 1 put information", //errorMessage
 * () => (conditionValue, schema) => conditionValue === "yes" ? schema.min(1) : schema) //customCondition
 *
 * @param {yup} yupType yup validation of input
 * @param {String} conditionValueName name of value that use for indicate the condition of required
 * @param {String} errorMessage error message that will display when required occur
 * @param {Function} customCondition custom function for determine the condition to append required yup validation
 */
export const whenCondition = (
  yupType = string(),
  conditionValueName,
  errorMessage = "Required",
  customCondition = null,
) => {
  // append required to yup schema when conditionValue === "yes" otherwise return only schema
  let condition = (conditionValue, schema) =>
    conditionValue === "yes" ? schema.required(errorMessage) : schema
  if (customCondition) {
    condition = customCondition
  }
  return yupType.when(conditionValueName, condition)
}

export const validateLength = (required = true, min = 2, max = 35) => {
  const validate = string()
    .min(min, <Message {...message.minChar} values={{ min }} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)

  return requiredYup(required, validate)
}

export const validateLengthSupportAllowEmpty = (
  val,
  min,
  max,
  allowEmpty = false,
  required = true,
) => {
  if (!val && allowEmpty) return requiredYup(required, string())
  return validateLength(required, min, max)
}

export const validateDate = (required = true) => {
  const validate = date().typeError(<Message {...message.invalidDate} />)
  return requiredYup(required, validate)
}

export const validateDob = (required = true, allowUnder18 = false) => {
  let validate = date()
    .typeError(<Message {...message.invalidDate} />)
    .min(
      moment()
        .subtract("125", "years")
        .format("MM/DD/YYYY"),
      <Message {...message.maxAge} values={{ max: "125" }} />,
    )
    .test({
      name: "dob",
      message: <Message {...message.dobNoFuture} />,
      test: value => moment().diff(moment(value)) >= 0,
    })
  if (!allowUnder18) {
    validate = validate.max(
      moment()
        .subtract("18", "years")
        .format("MM/DD/YYYY"),
      <Message {...message.minAge} values={{ min: "18" }} />,
    )
  }
  return requiredYup(required, validate)
}

export const validatePhone = (required = true, nullable = false) => {
  const validate = string()
    .min(10, <Message {...message.invalidPhone} />)
    .nullable(nullable)
  return requiredYup(required, validate)
}

export const validateZipCode = (required = true) => {
  const validate = string()
    .min(5, <Message {...message.invalidZipCode} />)
    .max(5, <Message {...message.invalidZipCode} />)
    .test(
      "not-invalid-us-zip-code",
      <Message {...message.invalidUSZipCode} />,
      zipCode => !INVALID_US_ZIP_CODE.includes(zipCode),
    )
  return requiredYup(required, validate)
}

export const validateAddressLineOne = (required = true, min = 2, max = 100) => {
  const validate = string()
    .min(min, <Message {...message.minChar} values={{ min }} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)
    .test({
      name: "NotMilitaryAddress",
      message: <Message {...message.notAllowedMilitaryAddress} />,
      test() {
        return !MILITARY_STATES.includes(this.parent.state)
      },
    })
    .matches(TU_REGEX.addressLine1, getInvalidAddressLineOneMessage)
  return requiredYup(required, validate)
}

export const validateState = (required = true) => {
  const validate = string()
    .min(2, <Message {...message.invalidState} />)
    .max(2, <Message {...message.invalidState} />)
  return requiredYup(required, validate)
}

export const validateRegion = (required = true) => {
  const validate = string()
    .min(2, <Message {...message.invalidRegion} />)
    .max(2, <Message {...message.invalidRegion} />)
  return requiredYup(required, validate)
}

export const validateEmail = (required = true) => {
  const validate = string()
    .email(<Message {...message.invalidEmail} />)
    .max(255, <Message {...message.maxChar} values={{ max: 255 }} />)
  return requiredYup(required, validate)
}

export const validateMandatoryCheckbox = (required = true) => {
  const validate = boolean().test("is check", value => value === true)
  return requiredYup(required, validate)
}

export const validateAcquaintanceLength = (required = false) => {
  const validate = number()
    .transform(
      (currentVal, originalVal) =>
        isEmpty(originalVal) ? undefined : currentVal,
    )
    .max(
      99,
      <Message {...message.invalidLengthOfAcquaintance} values={{ max: 2 }} />,
    )
  return requiredYup(required, validate)
}

export const validateMaxEndDate = (required = true, r, dateName = "Move-in") =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .maxEndDate(
      ref(r),
      <Message {...message.endLessThanStart} values={{ dateName }} />,
    )

export const validateNoFutureDate = (required = true, dateName) =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .min(
      moment().subtract("125", "years"),
      <Message {...message.mustLetterYear} year={125} />,
    )
    .max(moment(), <Message {...message.noFutureDate} values={{ dateName }} />)

export const validateFutureDate = (required = true, dateName) =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .min(
      moment().startOf("day"),
      <Message {...message.noPastDate} values={{ dateName }} />,
    )

export const getInvalidPersonNameMessage = () => (
  <Message {...message.invalidNameCharacter} />
)
export const getInvalidAlphaNumericMessage = () => (
  <Message {...message.invalidAlphaNumericCharacter} />
)
export const getInvalidTUNameMessage = () => (
  <Message {...message.invalidTUNameCharacter} />
)
export const getInvalidAddressLineOneMessage = () => (
  <Message {...message.invalidAddressLineOneCharacter} />
)
export const getInvalidAddressLineTwoMessage = () => (
  <Message {...message.invalidAddressLineTwoCharacter} />
)
export const getInvalidCityMessage = () => (
  <Message {...message.invalidCityCharacter} />
)
export const getInvalidStartingSSN = () => (
  <Message {...message.invalidStartingSSN} />
)
export const getHasNumberErrorMessage = () => <Message {...message.hasNumber} />
export const getHasLetterErrorMessage = () => <Message {...message.hasLetter} />
export const getSSNMismatchErrorMessage = () => (
  <Message {...message.ssnMismatch} />
)

export const validateSSN = (required = true) => {
  const validate = string().length(9, <Message {...message.invalidSSN} />)
  return requiredYup(required, validate)
}

export const validateIncome = (required = true, min, max) =>
  requiredYup(
    required,
    number()
      .min(
        min,
        <Message {...message.invalidIncomeRange} values={{ min, max }} />,
      )
      .max(
        max,
        <Message {...message.invalidIncomeRange} values={{ min, max }} />,
      ),
  )

export const validateWorkStatus = (required = true) =>
  requiredYup(
    required,
    string().oneOf(
      Object.values([
        WORK_STATUS.EMPLOYED,
        WORK_STATUS.RETIRED,
        WORK_STATUS.STUDENT,
        WORK_STATUS.UNEMPLOYED,
      ]),
      <Message {...message.invalidWorkStatus} />,
    ),
  )

export const validateMismatch = fieldToMatch =>
  function checkEqual(value) {
    const valueToMatch = get(this, `parent.${fieldToMatch}`)
    return valueToMatch ? value === valueToMatch : true
  }

export const validatePersonName = (required, min, max) =>
  validateLength(required, min, max).matches(PERSON_NAME_REGEX, {
    message: getInvalidPersonNameMessage,
    excludeEmptyString: true,
  })

export const validateAlphaNumeric = (required = false, min = 0, max = 18) =>
  validateLength(required, min, max).matches(ALPHA_NUMERIC_REGEX, {
    message: getInvalidAlphaNumericMessage,
    excludeEmptyString: true,
  })

export const validateGlobalZipCode = (required = false, min = 2, max = 12) =>
  validateLength(required, min, max).matches(GLOBAL_ZIP_CODE, {
    message: <Message {...message.invalidGlobalZipCode} />,
    excludeEmptyString: true,
  })

export const selectValidateZipCode = (isUsAddressOnly, required, min, max) => {
  if (isUsAddressOnly) return validateZipCode()
  return validateGlobalZipCode(required, min, max)
}

export const validateZipCodeSupportAllowEmpty = (
  val,
  isUsAddressOnly,
  allowEmpty = false,
  required = true,
  min,
  max,
) => {
  if (!val && allowEmpty) return requiredYup(required, string())
  return selectValidateZipCode(isUsAddressOnly, required, min, max)
}

export const validatePrice = (required = true, min = 0, max = 1000000) =>
  requiredYup(
    required,
    number()
      .min(
        min,
        <Message {...message.invalidPriceRange} values={{ min, max }} />,
      )
      .max(
        max,
        <Message {...message.invalidPriceRange} values={{ min, max }} />,
      ),
  )

export const validateAccountType = () =>
  mixed().oneOf(
    Object.values(BANK_ACCOUNT_TYPES),
    <Message {...message.invalidAccountType} />,
  )

export const validateOther = () =>
  mixed().when("accountType", {
    is: BANK_ACCOUNT_TYPES.OTHER,
    then: validateAlphaNumeric(true, undefined, 20),
  })

export const TU_REGEX = {
  name: /^[a-zA-Z\s'-]+$/,
  addressLine1: /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+]+$/,
  addressLine2: /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+]+$/,
  city: /^[a-zA-Z\s'-]+$/,
  ssn: /^(?!000)/,
  hasNumber: /[0-9]/,
  hasLetter: /[a-zA-Z]/,
}

export const ADDRESS_REGEX = {
  addressLine1: /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+]+$/,
}

export const PERSON_NAME_REGEX = /^[a-z\s']+$/i
export const ALPHA_NUMERIC_REGEX = /^[a-z0-9\s]+$/i
export const GLOBAL_ZIP_CODE = /^[a-zA-Z0-9]+((-| )[a-zA-Z0-9]+)*$/

function uniquePropertyTest(value, propertyName, displayMessage) {
  if (
    this.parent
      .filter(v => v !== value)
      .some(
        v =>
          get(v, propertyName, "").toLowerCase() ===
          get(value, propertyName, "").toLowerCase(),
      )
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      displayMessage,
    })
  }

  return true
}

function uniqueProperty(propertyName, displayMessage) {
  return this.test("unique", displayMessage, function call(value) {
    return uniquePropertyTest.call(this, value, propertyName, displayMessage)
  })
}

addMethod(object, "uniqueProperty", uniqueProperty)

export const eSignRecipientListValidation = () => {
  const recipient = array().of(
    object()
      .shape({
        fullName: validateLength(true, 2, 60),
        email: validateEmail(),
      })
      .uniqueProperty("email", <Message {...message.duplicateEmails} />),
  )
  return recipient
}

export const validateSelectedResidence = () =>
  requiredYup(require, string().oneOf(Object.values(SELECTED_RESIDENCE)))
