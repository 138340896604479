import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { B16 } from "@rentspree/component-v2"

import Modal from "components/modal/default-modal"
import useMobile from "hooks/use-mobile"
import { COLOR } from "styles/settings"

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: 10px;

  @media (max-width: 767px) {
    cursor: pointer;
  }

  &:hover {
    span {
      visibility: visible;
      @media (max-width: 767px) {
        visibility: hidden;
      }
    }
  }
`

export const TooltipMobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export const TooltipDesktopWrapper = styled.span`
  visibility: hidden;
  width: 170px;
  background-color: ${COLOR.textBlack};
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -85px;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${COLOR.textBlack} transparent transparent transparent;
  }
`

export const TooltipDescription = styled(B16)`
  color: ${COLOR.white};
  font-size: 12px;
  @media (max-width: 767px) {
    color: initial;
    font-size: 16px;
  }
  font-family: Source Sans Pro, sans-serif !important;
  margin-bottom: 0px;
`

const TooltipWithCustomImgIcon = ({
  imgIcon,
  desktopContent,
  mobileContent,
  mobileWidth = 767,
}) => {
  const [isShowTooltipMobile, setShowTooltipMobile] = React.useState(false)
  const isMobile = useMobile(mobileWidth)

  return (
    <Tooltip
      data-testid="tooltip-custom-img-container"
      onClick={() => isMobile && setShowTooltipMobile(true)}>
      <img src={imgIcon} alt="tooltip-custom-img-icon" />
      <TooltipDesktopWrapper data-testid="tooltip-custom-img-desktop-wrapper">
        <TooltipDescription data-testid="tooltip-custom-img-desktop-content">
          {desktopContent}
        </TooltipDescription>
      </TooltipDesktopWrapper>
      {isShowTooltipMobile && (
        <TooltipMobileWrapper data-testid="tooltip-custom-img-mobile-wrapper">
          <Modal
            data-testid="tooltip-custom-img-mobile-content"
            mDialogMaxWidth={`${mobileWidth}px`}
            showModal
            closeButton
            closeModal={event => {
              event.stopPropagation()
              setShowTooltipMobile(false)
            }}>
            {mobileContent}
          </Modal>
        </TooltipMobileWrapper>
      )}
    </Tooltip>
  )
}

TooltipWithCustomImgIcon.propTypes = {
  imgIcon: PropTypes.string.isRequired,
  desktopContent: PropTypes.string.isRequired,
  mobileContent: PropTypes.string.isRequired,
  mobileWidth: PropTypes.string,
}

export default TooltipWithCustomImgIcon
