import isEmpty from "lodash/isEmpty"
import { DRAFT_ACTION } from "../application/constants"

/**
 * getCleanDraftAction returns `clean` for the draft action if the pathname
 * is the same as the current pathname. Otherwise it returns an empty string.
 *
 * @param {{data: object, pathname: string, parser: string}} draft the draft object
 * @param {string} currentPathname
 *
 * @return {string}
 */
export const getCleanDraftAction = (draft, currentPathname) => {
  if (
    !isEmpty(draft) &&
    !isEmpty(currentPathname) &&
    draft.pathname === currentPathname
  ) {
    return DRAFT_ACTION.CLEAN
  }

  return ""
}
