import { object, array, string } from "yup"
import {
  validateLength,
  requiredYup,
  whenCondition,
  validateState,
} from "constants/validations"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"

export const stepAdditionalInformationValidation = props => {
  const {
    pets,
    vehicles,
    additionalInfo,
    application,
    isRequiredFelonyQuestion,
    isDisableEvictionQuestion,
  } = props

  const { type, version } = application
  const shouldChangeMaximumCharacter =
    type === APPLICATION_TYPE.CAR_LRA ||
    (type === APPLICATION_TYPE.RENTSPREE && parseFloat(version) >= 3.1)
  const liquidMaximumCharacter = shouldChangeMaximumCharacter ? 30 : 500
  const additionalMaximumCharacters = shouldChangeMaximumCharacter ? 100 : 500

  return {
    mapper: {
      havePet: pets.havePet,
      pets: pets.pets,
      haveVehicle: vehicles.haveVehicle,
      vehicles: vehicles.vehicles,
      liquidFurniture: additionalInfo.liquidFurniture,
      typeLiquidFurniture: additionalInfo.typeLiquidFurniture,
      ...(!isDisableEvictionQuestion && {
        unlawful: additionalInfo.unlawful,
        explainUnlawful: additionalInfo.explainUnlawful,
        moveOut: additionalInfo.moveOut,
        explainMoveOut: additionalInfo.explainMoveOut,
      }),
      ...(isDisableEvictionQuestion && {
        bankruptcy: additionalInfo.bankruptcy,
        explainBankruptcy: additionalInfo.explainBankruptcy,
      }),
      ...(isRequiredFelonyQuestion && {
        felony: additionalInfo.felony,
        explainFelony: additionalInfo.explainFelony,
      }),
    },
    schema: object().shape({
      havePet: requiredYup(),
      pets: array()
        .of(
          object().shape({
            name: validateLength(false, 0),
            breed: validateLength(true, 0, 35),
            weight: validateLength(false, 0, 5),
          }),
        )
        .when(
          "havePet",
          (havePet, schema) => (havePet === "yes" ? schema.min(1) : schema),
        ),
      haveVehicle: requiredYup(),
      vehicles: array()
        .of(
          object().shape({
            make: validateLength(true, 0, 35),
            model: validateLength(true, 0, 35),
            year: validateLength(true, 0, 4),
            color: validateLength(true, 0, 35),
            licensePlate: validateLength(true, 0, 35),
            state: validateState(),
          }),
        )
        .when(
          "haveVehicle",
          (haveVehicle, schema) =>
            haveVehicle === "yes" ? schema.min(1) : schema,
        ),
      liquidFurniture: requiredYup(true, string(), "radio"),
      typeLiquidFurniture: whenCondition(
        validateLength(false, 0, liquidMaximumCharacter),
        "liquidFurniture",
      ),
      ...(!isDisableEvictionQuestion && {
        unlawful: requiredYup(true, string(), "radio"),
        explainUnlawful: whenCondition(
          validateLength(false, 0, additionalMaximumCharacters),
          "unlawful",
        ),
        moveOut: requiredYup(true, string(), "radio"),
        explainMoveOut: whenCondition(
          validateLength(false, 0, additionalMaximumCharacters),
          "moveOut",
        ),
      }),
      ...(isDisableEvictionQuestion && {
        bankruptcy: requiredYup(true, string(), "radio"),
        explainBankruptcy: whenCondition(
          validateLength(false, 0, additionalMaximumCharacters),
          "bankruptcy",
        ),
      }),
      ...(isRequiredFelonyQuestion && {
        felony: requiredYup(true, string(), "radio"),
        explainFelony: whenCondition(
          validateLength(false, 0, additionalMaximumCharacters),
          "felony",
        ),
      }),
    }),
  }
}
