/*
 *
 * Price reducer
 *
 */
import { fromJS } from "immutable"
import {
  GET_SCREENING_REQUEST_SUCCESS,
  GET_SCREENING_REQUEST_FAILED,
} from "containers/wrapper/constants"

export const initialState = fromJS({})

function priceReducer(state = initialState, action) {
  let nextState = state
  const { payload } = action
  switch (action.type) {
    case GET_SCREENING_REQUEST_SUCCESS:
      nextState = initialState.mergeDeep(payload.price)
      return nextState
    case GET_SCREENING_REQUEST_FAILED:
      return initialState
    default:
      return state
  }
}

export default priceReducer
