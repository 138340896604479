import { fromJS, List, Map } from "immutable"
import { STATUS } from "components/guide/constant"
import _ from "lodash"
import {
  GET_RENTAL_SUBMISSION_SUCCESS,
  GET_RENTAL_WITH_CO_APPLICANT_SUCCESS,
  SET_RENTAL_APP_CONTINUE_PATH,
  SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE,
} from "./constants"
import { SUBMIT_RENTAL_SUCCESS } from "./rental-guide/constants"
import { START_NEW_RENTAL_SUCCESS } from "./rental-instruction/constants"
import {
  SAVE_APPLICANT_INFO_SUCCESS,
  SAVE_APPLICANT_INFO_REQUEST,
  CO_APPLICANT_TYPE,
} from "../applicant-info/constants"
import { SAVE_APPLICATION_SUCCESS } from "../application/constants"
import {
  START_NEW_SCREENING_SUCCESS,
  EXAM_EVALUATE_SUCCESS,
  ATTACH_REPORT_SUCCESS,
  REPEATED_EXAM_RETRIEVE_REQUEST,
} from "../tu-screening/constants"
import { parseFromAPI } from "./map-from-api"
import { UPDATE_ENVELOPE_SIGN_SUCCESS } from "../envelope/constants"
import { getParser } from "../applicant-info/map-to-api"
import { RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS } from "../autosave-form/constants"

export const initialState = fromJS({})

function rentalReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case GET_RENTAL_SUBMISSION_SUCCESS:
    case START_NEW_RENTAL_SUCCESS:
      nextState = state.merge(parseFromAPI(action.payload))
      return nextState
    case SAVE_APPLICANT_INFO_REQUEST:
      nextState = state.mergeWith((previousValue, newValue) => {
        const { haveTenants, haveCosigners } = action.payload
        // check if previous value is immutable list type
        if (List.isList(previousValue)) {
          // parse to plain js
          const previousList = previousValue.toJS()
          const newList = newValue.toJS()
          // get all types
          const updateTypes = _.uniq(_.map(newList, it => it.type))

          // lnr-3458 regroup application new form will sent final result of coApplicants
          if (updateTypes.length >= 2) {
            return List(newList)
          }

          // lnr-3458 regroup application old form still need to handle merging coApplicants
          const updateType = _.get(newList, "0.type")
          // take array of co-applicant that are not equal to updatedType
          // then concat updated co-applicant with filtered co-applicant array
          let updateList = _.concat(
            _.filter(previousList, list => list.type !== updateType),
            newList,
          )
          // ada-7147 filter out all selected "no" co-applicants type from updateList for state accuracy
          if (haveTenants === "no") {
            updateList = updateList.filter(
              item => item.type !== CO_APPLICANT_TYPE.OCCUPANT,
            )
          }
          if (haveCosigners === "no") {
            updateList = updateList.filter(
              item => item.type !== CO_APPLICANT_TYPE.COSIGNER,
            )
          }
          // parse to immutable list and return
          return List(updateList)
        }
        // check if previous value is immutable map type
        if (Map.isMap(previousValue)) {
          // parse new value to plain js then merge with previous value
          const newObj = newValue.toJS()
          return previousValue.mergeDeep(newObj)
        }
        // return new value if previous value is not map and list
        return newValue
      }, action.payload)
      return nextState
    case SAVE_APPLICANT_INFO_SUCCESS: {
      const { draft } = action.payload
      if (draft) {
        const parsedDraftPayload = getParser(draft.parser)(draft.data)
        // NOTE: need to merge co-applicant and draft
        if (
          parsedDraftPayload.haveCosigners === "yes" ||
          parsedDraftPayload.haveTenants === "yes"
        ) {
          const mergedCoApplicants = mergeCoApplicants(
            nextState,
            parsedDraftPayload,
          )

          nextState = nextState.merge({
            coApplicants: mergedCoApplicants,
          })
        } else {
          nextState = nextState.merge(parsedDraftPayload)
        }
      } else {
        nextState = nextState.merge({
          draft,
        })
      }

      nextState = nextState.merge(_.pick(action.payload, ["status"]))

      return nextState
    }
    case GET_RENTAL_WITH_CO_APPLICANT_SUCCESS:
      nextState = state.merge(
        _.omit(parseFromAPI(action.payload, action.isResumeAppEnabled), [
          "application",
          "credit_report",
        ]),
      )
      return nextState
    case SAVE_APPLICATION_SUCCESS:
      nextState = state.mergeDeep({
        application: _.pick(action.payload, [
          "_id",
          "id",
          "status",
          "version",
          "firstName",
          "lastName",
          "middleName",
          "lraEnvelopeId",
          "residence",
          "dateOfBirth",
          "homePhoneNumber",
          "phoneNumber",
          "occupation",
          "type",
        ]),
      })
      return nextState
    case UPDATE_ENVELOPE_SIGN_SUCCESS: {
      return state.mergeDeep({
        application: { status: STATUS.RENTER_SIGNED },
      })
    }
    case START_NEW_SCREENING_SUCCESS:
    case ATTACH_REPORT_SUCCESS:
    case EXAM_EVALUATE_SUCCESS:
    case REPEATED_EXAM_RETRIEVE_REQUEST:
      nextState = state.mergeDeep({
        credit_report: _.pick(action.payload, ["status"]),
      })
      return nextState
    case SUBMIT_RENTAL_SUCCESS:
      nextState = state.merge({
        status: _.get(action, "payload.status"),
      })
      return nextState
    case SET_RENTAL_APP_CONTINUE_PATH:
      nextState = state.mergeDeep({
        application: {
          continuePath: _.get(action, "payload"),
        },
      })
      return nextState
    case SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE:
      nextState = state.merge({
        continuePath: _.get(action, "payload"),
      })
      return nextState
    case RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS: {
      const { originalValue, draft } = action.payload
      let toBeMergedValue = originalValue
      if (
        originalValue.haveCosigners === "yes" ||
        originalValue.haveTenants === "yes"
      ) {
        const mergedCoApplicants = mergeCoApplicants(nextState, originalValue)
        toBeMergedValue = {
          ...originalValue,
          coApplicants: mergedCoApplicants,
        }
      }
      nextState = nextState.merge({
        draft,
        ...toBeMergedValue,
      })

      return nextState
    }

    default:
      return state
  }
}

export const mergeCoApplicants = (nextState, newValue) => {
  const filterType = newValue?.coApplicants[0]?.type
  // NOTE: make sure not to merge a duplicate co-applicant
  const uniqueCoAppFromStateAndDraft = [
    ...nextState.toJS().coApplicants.filter(coApp => coApp.type !== filterType),
    ...newValue?.coApplicants,
  ].reduce((uniqueObject, coApp) => {
    if (!coApp.firstName && !coApp.lastName && !coApp.email) {
      return uniqueObject
    }

    const key = `${coApp.firstName}${coApp.lastName}${coApp.email}`
    if (!uniqueObject[key]) {
      return { ...uniqueObject, [key]: coApp }
    }
    return uniqueObject
  }, {})
  return Object.values(uniqueCoAppFromStateAndDraft)
}

export default rentalReducer
