import React from "react"
import { BASE_DIGIDOC_URL } from "containers/envelope/api-constants"
import classnames from "classnames"
import { getInitialName } from "containers/envelope/helpers/get-recipient-name"
import { Box, Text, SignIcon, SignatureText, Underline } from "../sign-box"
import { SIGNATURE_TYPE } from "../constants"
import TooltipPopper from "../tooltip"
import { makeMapCursor } from "../cursor"

const mapCursor = makeMapCursor("default", "pointer")

export const InitialBox = ({
  targetRef,
  isDragging,
  left,
  top,
  value,
  active,
  colorIndex,
  onClick,
  onChangeData,
  signatureType,
  viewMode,
  fieldId,
  showError,
  recipient,
  canEdit,
  unmovable,
  small = false,
  ratio = 1,
}) => {
  const handleChange = event => {
    onChangeData(event.target.value)
  }

  if (isDragging) {
    return <div ref={targetRef} />
  }
  const isSigned = !!value
  const cursor = mapCursor(viewMode, canEdit, active, unmovable)
  const boxHeightSmall =
    isSigned && signatureType === SIGNATURE_TYPE.DRAW ? "32" : "22"
  return (
    <Box
      ref={targetRef}
      ratio={ratio}
      className={classnames({
        active,
        error: showError,
        "signed-initial": isSigned,
        "draw-initial": signatureType === SIGNATURE_TYPE.DRAW,
      })}
      id={fieldId}
      key={fieldId}
      cursor={cursor}
      left={left * ratio}
      top={top * ratio}
      value={value}
      colorIndex={colorIndex}
      padding={small || isSigned ? "0 10px 0 10px" : "8px"}
      height={small ? boxHeightSmall : "60"}
      flexD={small ? "row" : "column"}
      minHeight="22"
      minWidth="50"
      small={small}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      onChange={handleChange}>
      {showError && <TooltipPopper refNode={targetRef} />}
      {!isSigned && (
        <>
          {small && <SignIcon colorIndex={colorIndex} />}
          <Text
            style={small ? { paddingLeft: "5px" } : {}}
            weight={600}
            colorIndex={colorIndex}
            msg={getInitialName(recipient)}
            size={small ? "9pt" : "10pt"}
          />
          {!small && <SignIcon colorIndex={colorIndex} />}
          <Underline colorIndex={colorIndex} small={small} />
        </>
      )}
      {isSigned &&
        (signatureType === SIGNATURE_TYPE.DRAW ? (
          <div>
            <img src={`${BASE_DIGIDOC_URL}${value}`} alt="initial" />
          </div>
        ) : (
          <div>
            <SignatureText
              size="24px"
              signature={value}
              lineHeight={small ? "0px" : "40px"}
            />
          </div>
        ))}
    </Box>
  )
}
