import styled, { css } from "styled-components"
import { FLEX_CENTER } from "components/constants"

export const loading = styled.div`
  ${props =>
    props.center &&
    css`
      width: 100%;
      ${FLEX_CENTER};
      height: 320px;
      background: white;
      z-index: 4;
    `} ${props =>
    props.overlay &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.85);
      z-index: 3;
      @media screen and (max-width: 767px) {
        border-radius: 0px;
      }
    `};
`
