/*
 *
 * Residence constants
 *
 */

export const SELECTED_RESIDENCE = {
  OWNER: "owner",
  RENTAL: "rental",
  NONE: "none",
}

export const RESIDENCE_QUESTIONS = {
  PREVIOUS: "Did you rent or own your previous residence?",
  CURRENT: "Do you rent or own your current residence?",
}

export const currentResidenceChoices = [
  {
    label: "Rent",
    id: "rental",
    value: "rental",
  },
  { label: "Own", id: "owner", value: "owner" },
]

export const previousResidenceChoices = [
  {
    label: "Rent",
    id: "rental",
    value: "rental",
  },
  { label: "Own", id: "owner", value: "owner" },
  { label: "Other", id: "none", value: "none" },
]
