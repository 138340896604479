import React from "react"
import { Route } from "react-router-dom"
import urlJoin from "url-join"

export function renderRoute(route, outerProps = {}) {
  const path =
    route.path && outerProps.match
      ? urlJoin(outerProps.match.path, route.path)
      : undefined

  return (
    <Route
      key={route.key}
      path={path}
      exact={route.exact}
      strict={route.strict}
      render={props => {
        if (route.render) {
          return route.render({ ...props, route })
        }
        return <route.component {...props} routes={route.routes} />
      }}
    />
  )
}
