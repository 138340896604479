import React from "react"
import styled from "styled-components"

import DrawInput from "components/envelope/modal/draw-input"

import { Wrapper, SignButtonGroup, InfoText } from "./text-signature"

export const DrawForm = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DrawSignatureForm = ({
  nextButtonText,
  closeModal,
  uploadSaveSignSignature,
  uploadEditSignSignature,
  mode,
  envelopeId,
  userToken,
  isLoading,
}) => {
  let signature = React.useRef(null)
  let initials = React.useRef(null)
  const [signatureErr, setSignatureErr] = React.useState(false)
  const [initialsErr, setInitialsErr] = React.useState(false)

  const validate = () => {
    setSignatureErr(signature.isEmpty())
    setInitialsErr(initials.isEmpty())
    return !signature.isEmpty() && !initials.isEmpty()
  }

  const handleSubmit = () => {
    if (!validate()) return

    if (mode === "newSign") {
      uploadSaveSignSignature({
        type: "draw",
        fullName: signature.getCanvas().toDataURL(),
        initials: initials.getCanvas().toDataURL(),
        envelopeId,
        userToken,
      })
    } else {
      uploadEditSignSignature({
        type: "draw",
        fullName: signature.getCanvas().toDataURL(),
        initials: initials.getCanvas().toDataURL(),
        envelopeId,
        userToken,
      })
    }
  }

  return (
    <Wrapper>
      <DrawForm>
        <DrawInput
          labelText="Draw your signature"
          setRef={ref => {
            signature = ref
          }}
          onClear={() => {
            signature.clear()
          }}
          dWidth="291px"
          dHeight="100px"
          isRequired
          isError={signatureErr}
        />
        <DrawInput
          labelText="Draw your initials"
          setRef={ref => {
            initials = ref
          }}
          onClear={() => {
            initials.clear()
          }}
          dWidth="129px"
          dHeight="100px"
          isRequired
          isError={initialsErr}
        />
      </DrawForm>
      <InfoText />
      <SignButtonGroup
        nextButtonText={nextButtonText}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Wrapper>
  )
}

export default DrawSignatureForm
