import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import injectSaga from "utils/inject-saga"
import { redirect } from "containers/wrapper/actions"
import selectLoading from "containers/loading/selectors"
import errorSelector from "containers/error/selectors"
import saga from "./saga"
import { sendAttachReport } from "./actions"

export const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  error: errorSelector(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ redirect, sendAttachReport }, dispatch),
  }
}

export const withSaga = injectSaga({ key: "attachReport", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
