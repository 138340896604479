import { object, lazy, mixed } from "yup"
import moment from "moment"
import _ from "lodash"
import {
  validateMaxEndDate,
  validateLength,
  validatePhone,
  validateEmail,
  validateNoFutureDate,
  validateSelectedResidence,
  validateLengthSupportAllowEmpty,
  ADDRESS_REGEX,
  getInvalidAddressLineOneMessage,
  validateZipCodeSupportAllowEmpty,
  validateRegion,
  validateState,
} from "constants/validations"
import { OCCURRING } from "containers/router/constants"
import { SELECTED_RESIDENCE } from "./constants"

const rentalSchema = {
  current: lazy(values => {
    const { isSkipEmailLL, isSkipPhoneLL } = values
    return object().shape({
      monthlyRent: validateLength(true, 0, 10),
      landlordName: validateLength(true, 2, 70),
      landlordEmail: validateEmail(!isSkipEmailLL), // If skip =  true ! to parse to false to require false
      landlordPhone: validatePhone(!isSkipPhoneLL), // If skip  = true ! to parse to false to require false
    })
  }),
  previous: lazy(values => {
    const { isSkipEmailLL, isSkipPhoneLL } = values
    return object().shape({
      monthlyRent: validateLength(true, 0, 10),
      landlordName: validateLength(true, 2, 70),
      landlordEmail: validateEmail(!isSkipEmailLL), // If skip =  true ! to parse to false to require false
      landlordPhone: validatePhone(!isSkipPhoneLL), // If skip  = true ! to parse to false to require false
      reason: validateLength(false, 0, 500),
    })
  }),
}

export const stepRental = props => {
  const { appResidence, occurring } = props
  return {
    mapper: {
      monthlyRent: appResidence[occurring].monthlyRent,
      landlordName: appResidence[occurring].landlordName,
      landlordEmail: appResidence[occurring].landlordEmail,
      landlordPhone: appResidence[occurring].landlordPhone,
      isSkipEmailLL: appResidence[occurring].isSkipEmailLL || false,
      isSkipPhoneLL: appResidence[occurring].isSkipPhoneLL || false,
    },
    schema: rentalSchema[occurring],
  }
}

export const stepResidence = props => {
  const { appResidence, occurring, isUsAddressOnly } = props
  const isCurrent = occurring === OCCURRING.CURRENT
  return {
    mapper: {
      selected: appResidence[occurring].selected,
      streetAddress: appResidence[occurring].street,
      city: appResidence[occurring].city,
      state: appResidence[occurring].state,
      zipcode: appResidence[occurring].zipCode,
      region: appResidence[occurring].region,
      dateIn: appResidence[occurring].dateIn,
      dateOut:
        _.get(appResidence, `${occurring}.dateOut`, "") ||
        (isCurrent ? "Present" : moment().format("YYYY-MM")),
      reason: appResidence[occurring].reason,
    },
    schema: lazy(values => {
      const isRequireFullAddress = values.selected !== SELECTED_RESIDENCE.NONE
      const isRequiredReason =
        occurring !== OCCURRING.PREVIOUS ||
        values.selected === SELECTED_RESIDENCE.OWNER
      return isRequireFullAddress
        ? object().shape({
            selected: validateSelectedResidence(),
            streetAddress: validateLengthSupportAllowEmpty(
              values.streetAddress,
              0,
              255,
              !isRequireFullAddress,
              isRequireFullAddress,
            ).matches(ADDRESS_REGEX.addressLine1, {
              message: getInvalidAddressLineOneMessage,
              excludeEmptyString: true,
            }),
            city: validateLength(isRequireFullAddress, 0, 35),
            state: isUsAddressOnly
              ? validateState()
              : validateLength(isRequireFullAddress, 2, 50),
            zipcode: validateZipCodeSupportAllowEmpty(
              values.zipCode,
              isUsAddressOnly || values.region === "US",
              !isRequireFullAddress,
              isRequireFullAddress,
            ),
            region: isUsAddressOnly ? mixed() : validateRegion(),
            dateIn: validateNoFutureDate(isRequireFullAddress, "Move-in"),
            dateOut: lazy(value => {
              if (value === "Present") {
                return validateLength()
              }
              return validateMaxEndDate(
                isRequireFullAddress,
                "dateIn",
                "move-in",
              )
            }),
            reason: validateLength(isRequiredReason, 0, 500),
          })
        : object().shape({ selected: validateSelectedResidence() })
    }),
  }
}
