import loadable from "@loadable/component"

const ApplicationLoadable = loadable(() => import("containers/application"))
const ApplicationV2Loadable = loadable(() =>
  import("containers/application-v2"),
)
const SubmittedPageLoadable = loadable(() =>
  import("containers/submitted-page"),
)
const ApplicantInfoLoadable = loadable(() =>
  import("containers/applicant-info"),
)

const ApplyLimitPageLoadable = loadable(() =>
  import("containers/apply-limit-page"),
)

const TuScreeningLoadable = loadable(() => import("containers/tu-screening"))

const DisabledScreeningPageLoadable = loadable(() =>
  import("containers/disabled-screening-page"),
)

const FinishPageLoadable = loadable(() =>
  import("containers/rental-submission/rental-finish"),
)

const RentalPaymentPageLoadable = loadable(() =>
  import("containers/rental-submission/rental-payment"),
)

const ApplyNowLandingPageLoadable = loadable(() =>
  import("containers/rental-submission/apply-now-landing"),
)

const PaymentProcessingPageLoadable = loadable(() =>
  import("containers/rental-submission/payment-processing"),
)
const DocumentUploadLoadable = loadable(() =>
  import("containers/document-upload"),
)
const IncomeVerificationLoadable = loadable(() =>
  import("containers/rental-submission/income-verification"),
)

export {
  ApplicationLoadable,
  SubmittedPageLoadable,
  ApplicantInfoLoadable,
  ApplyLimitPageLoadable,
  TuScreeningLoadable,
  DisabledScreeningPageLoadable,
  FinishPageLoadable,
  RentalPaymentPageLoadable,
  ApplyNowLandingPageLoadable,
  PaymentProcessingPageLoadable,
  DocumentUploadLoadable,
  ApplicationV2Loadable,
  IncomeVerificationLoadable,
}
