import Hr from "@rentspree/component-v2/dist/hr"
import styled from "styled-components"

export const MobileHr = styled(Hr)`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
