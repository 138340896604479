import { takeLatest, put, select, call } from "redux-saga/effects"
import { push, replace } from "connected-react-router"
import { buildPath, parseSubdomain } from "@rentspree/path"
import urlJoin from "url-join"
import { MAIN_URL, BASE_NAME } from "env"
import { locationAssign } from "utils/call-window"
import { removeAccessToken } from "constants/cookie"
import _ from "lodash"

import {
  REDIRECT_RENTAL_SUBMISSION_CALL,
  REDIRECT_AUTHWEB_CALL,
  LOCATION_REDIRECT_CALL,
  REDIRECT_RENTAL_APP_CALL,
} from "./constants"
import {
  getSearch,
  getScreeningRequestId,
  getPathname,
  makeSelectIsAllowNewQuestionUser,
} from "./selectors"

import { ROUTE } from "../router/constants"

export const redirectToAuthWeb = (screeningRequestId, search, action) => {
  const subdomain = _.get(action, "subdomain")
  // build path for continue url
  let continuePath = urlJoin(MAIN_URL, BASE_NAME, ROUTE.GUIDE.BASE)
  // build target url for redirect
  let authWebPath = urlJoin(MAIN_URL, _.get(action, "payload") || ROUTE.SIGNUP)
  // replace www with subdomain if subdomain is exist
  if (subdomain) {
    continuePath = parseSubdomain(subdomain, continuePath)
    authWebPath = parseSubdomain(subdomain, authWebPath)
  }
  // build path for continue query param
  const continueUrl = `${buildPath(continuePath, {
    screeningRequestId,
  })}${search}`
  // build path for redirect target
  const signupUrl = buildPath(
    authWebPath,
    null,
    _.merge({ continue: continueUrl }, _.get(action, "query") || {}),
  )
  locationAssign(signupUrl)
}

export function* redirectRentalApp(action) {
  const search = yield select(getSearch)
  const screeningRequestId = yield select(getScreeningRequestId)
  const pathName = yield select(getPathname)
  const isAllowNewQuestionUser = yield select(makeSelectIsAllowNewQuestionUser)
  const applicationPath = isAllowNewQuestionUser
    ? ROUTE.APPLICATION_V2.BASE
    : ROUTE.APPLICATION.BASE
  yield put(
    push({
      pathname: buildPath(
        urlJoin(ROUTE.GUIDE.BASE, applicationPath, action.payload),
        { screeningRequestId },
      ),
      search,
      previous: pathName,
    }),
  )
}

export function* redirect({ payload, search, isReplace = false }) {
  const existingSearch = yield select(getSearch)
  const screeningRequestId = yield select(getScreeningRequestId)
  const pathName = yield select(getPathname)
  const url = payload ? urlJoin(ROUTE.GUIDE.BASE, payload) : ROUTE.GUIDE.BASE
  const assignFunc = isReplace ? replace : push
  yield put(
    assignFunc({
      pathname: buildPath(url, { screeningRequestId }),
      search: _.isUndefined(search) ? existingSearch : search,
      previous: pathName,
    }),
  )
}

export function* redirectAuthWeb(action) {
  yield call(removeAccessToken)
  const search = yield select(getSearch)
  const screeningRequestId = yield select(getScreeningRequestId)
  yield call(redirectToAuthWeb, screeningRequestId, search, action)
}

export function* locationRedirect(action) {
  const search = yield select(getSearch)
  const screeningRequestId = yield select(getScreeningRequestId)
  const destinationPath = buildPath(
    urlJoin(BASE_NAME, ROUTE.GUIDE.BASE, _.get(action, "payload", "")),
    {
      screeningRequestId,
    },
    search,
  )
  yield call(locationAssign, destinationPath)
}

export function* watchRedirect() {
  yield takeLatest(REDIRECT_RENTAL_APP_CALL, redirectRentalApp)
  yield takeLatest(LOCATION_REDIRECT_CALL, locationRedirect)
  yield takeLatest(REDIRECT_RENTAL_SUBMISSION_CALL, redirect)
  yield takeLatest(REDIRECT_AUTHWEB_CALL, redirectAuthWeb)
}
