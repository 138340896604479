import React from "react"
import T36 from "@rentspree/component-v2/dist/typography/t36"

import { FormFooter } from "components/buttons/form-footer"

import Form from "./form"

const NewTuErrorPageTemplate = ({
  backToGuide,
  content: { body, footer, title, formContent } = {},
  bodyArgs,
  ...props
}) => {
  const { loading } = props
  return (
    <>
      <T36 lineHeight="normal" margin="50px 0 30px" mMargin="30px 0 10px">
        {title}
      </T36>
      {body?.(...bodyArgs)}
      <Form content={formContent} {...props} />
      {footer}
      <FormFooter
        cancelBtn={{
          id: "errorTuCancelBtn",
          onClick: backToGuide,
          disabled: loading?.components,
        }}
        margin="60px 0 0"
        mMargin="0"
      />
    </>
  )
}

export default NewTuErrorPageTemplate
