import _ from "lodash"
import { callScroll } from "utils/call-window"
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT"
export const CLOSE_ERROR_ALERT = "CLOSE_ERROR_ALERT"
export const CLEAR_ERROR_ALERT = "CLEAR_ERROR_ALERT"

export const showErrorAlertCall = payload => {
  if (_.get(payload, "isScrollTop")) {
    callScroll(0, 0)
  }
  return {
    type: SHOW_ERROR_ALERT,
    payload,
  }
}
export const closeErrorAlertCall = () => ({ type: CLOSE_ERROR_ALERT })

export const clearErrorAlertCall = () => ({ type: CLEAR_ERROR_ALERT })
