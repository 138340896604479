import React from "react"
import { BlueGradientButton, Button } from "@rentspree/component-v2"

import { COLOR } from "../../styles/settings"
import { StatusButton } from "./status-button"

export const STATUS = {
  CREATED: "created",
  STARTED: "started",
  EDIT: "edit",
  REVIEW: "review",
  READY: "ready",
  DISABLE: "disable",
  RENTER_SIGNED: "renter_signed",
}

export const RENTAL_STEP = {
  APPLICANT: "applicant",
  APPLICATION: "application",
  CREDIT_REPORT: "creditReport",
}

const baseButtonProps = {
  small: true,
  minWidth: "128",
  weight: "600",
}

export const TERMS_OF_USE_URL = "https://www.rentspree.com/terms-of-use"

export const PRIVACY_POLICY =
  "https://www.rentspree.com/rentspree-privacy-policy"

export const SubmitTUWording = ({ buttonName }) => (
  <p>
    {`By clicking ${buttonName}, you agree to RentSpree's `}
    <a href={TERMS_OF_USE_URL}>Terms of Use</a>
    {" and "}
    <a href={PRIVACY_POLICY}>Privacy Policy</a>
    {
      " and authorize the property representative to obtain reports and information to verify the information you have provided."
    }
  </p>
)

export const STATUS_DATA_CREATED = {
  button: ({ onClick, ...props }) => (
    <StatusButton
      text="Start"
      onClick={onClick}
      renderDesktopButton={buttonProps => (
        <BlueGradientButton shadow {...baseButtonProps} {...buttonProps} />
      )}
      {...props}
    />
  ),
  // style
  color: "#4a90e2",
  textColor: COLOR.newBlack,
  textColorMobile: COLOR.newBlack,
  shadow: "0 5px 8px 0 rgba(0, 0, 0, 0.2)",
}
export const STATUS_DATA_STARTED = {
  button: ({ onClick, ...props }) => (
    <StatusButton
      text="Continue"
      onClick={onClick}
      renderDesktopButton={buttonProps => (
        <BlueGradientButton shadow {...baseButtonProps} {...buttonProps} />
      )}
      {...props}
    />
  ),
  // style
  color: "#4a90e2",
  textColor: COLOR.newBlack,
  textColorMobile: "#4a90e2",
  shadow: "0 5px 8px 0 rgba(0, 0, 0, 0.2)",
}
export const STATUS_DATA_COMPLETED = {
  status: "Completed",
  button: ({ onClick, ...props }) => (
    <StatusButton
      text="Edit"
      onClick={onClick}
      renderDesktopButton={buttonProps => (
        <Button google {...baseButtonProps} {...buttonProps} />
      )}
      {...props}
    />
  ),
  // style
  color: COLOR.darkGreen,
  textColor: COLOR.darkGreen,
  textColorMobile: COLOR.newBlack,
  shadow: "0 5px 8px 0 rgba(0, 0, 0, 0.2)",
}
export const STATUS_DATA_DISABLE = {
  button: () => null,
  // style
  color: COLOR.white,
  textColor: COLOR.newBlack,
  textColorMobile: COLOR.newBlack,
  shadow: "0 5px 8px 0 rgba(0, 0, 0, 0.2)",
}
export const DISPLAY_DOCUMENT_TYPE = {
  paystubs: "Proof of Income",
  bank_statement: "Bank Statement",
  tax_return: "Tax Returns",
  w2s: "W-2s",
  passport: "Government-issued ID",
  other: "Other",
}

export const uploadDocumentWording =
  "Edit or upload more documents anytime from your RentSpree dashboard."
