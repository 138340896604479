import React from "react"
import { withFormik } from "formik"
import { Checkbox } from "@rentspree/component-v2"

import { FormFooter } from "components/buttons/form-footer"

export const InnerForm = props => {
  const {
    backToGuide,
    values,
    handleSubmit,
    setFieldValue,
    loading,
    content,
  } = props
  return (
    <form onSubmit={handleSubmit}>
      <Checkbox
        id="acceptErrorTuCheckBox"
        fontSize="16px"
        labelBold
        label={content?.checkboxLabel}
        isSelected={values.acceptTerms}
        onClick={() => setFieldValue("acceptTerms", !values.acceptTerms)}
      />

      <FormFooter
        cancelBtn={{
          id: "errorTuCancelBtn",
          onClick: backToGuide,
          disabled: loading.components,
        }}
        continueBtn={{
          id: "errorTuContinueBtn",
          disabled: !values.acceptTerms || loading.components,
          loading: loading.components,
          loaded: loading.success,
          text: "Continue",
        }}
        margin="30px 0"
      />
    </form>
  )
}

const Form = withFormik({
  enableReinitialize: true,
  displayName: "acceptTUTermsForm",
  mapPropsToValues: () => ({
    acceptError: false,
  }),
  handleSubmit: (values, { props }) => {
    props.startNewScreening()
  },
})(InnerForm)

export default Form
