import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { Alert, Span } from "@rentspree/component-v2"
import { FormattedMessage as Message } from "react-intl"
import message from "constants/error-messages"

const StyledAlert = styled(Alert)`
  justify-content: space-between;
  margin: ${props => (props.margin ? props.margin : "30px 0")};
`

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
`

export const selectErrorMessage = err =>
  _.isString(err.message)
    ? { id: "customMessage", defaultMessage: _.get(err, "message") }
    : _.get(err, "message")

export const getErrorMessage = (err, isSaveInfoRelated) => {
  const isValidationError = _.get(err, "validation") && _.get(err, "location")
  if (isSaveInfoRelated) {
    return isValidationError
      ? message.validationError
      : message.saveApplicationError
  }
  return selectErrorMessage(err)
}

export const ErrorAlert = ({
  onClick,
  children,
  error,
  haveContactInfo,
  haveToAskSupportToUpdate,
  closeable = true,
  ...rest
}) => {
  const isSaveInfoRelated =
    _.get(error, "validation") || _.get(error, "location")
  return (
    <StyledAlert error {...rest}>
      <StyledMessage>
        <Span weight="600">
          <Message {...getErrorMessage(error, isSaveInfoRelated)} />
        </Span>
        {haveContactInfo && (
          <Span mt={1} size="14px">
            {haveToAskSupportToUpdate ? (
              <Message {...message.errorAskSupportToUpdate} />
            ) : (
              <Message {...message.errorSubtitle} />
            )}
          </Span>
        )}
      </StyledMessage>
      {closeable && (
        <button onClick={onClick}>
          <i className="fal fa-times" />
        </button>
      )}
    </StyledAlert>
  )
}
