import { fromJS } from "immutable"
import { getSubdomain } from "utils/subdomain"

import {
  SAVE_ALL_FIELD,
  GET_PROFILE_SUCCESS,
  CLEAR_PROFILE,
  VERIFY_MULTI_SHARE_SUCCESS,
} from "./constants"

export const initialState = fromJS({
  screeningRequestId: "",
  subdomain: getSubdomain(),
  isIdentifiedFS: false,
})

export const profileInitState = fromJS({
  address: null,
  email: null,
  firstName: null,
  image: null,
  lastName: null,
  phone: null,
  hasPassword: false,
  hasRegisteredUserType: false,
  isTermsAccepted: false,
  registeredUserType: null,
  userType: null,
})

export const multiShareInitialState = fromJS({
  isMultiShareActive: false,
  multiShareExpiredAt: null,
  remainingReportPerDay: null,
})

function wrapperReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case SAVE_ALL_FIELD:
      nextState = state.merge(action.payload)
      return nextState
    default:
      return state
  }
}

export function profileReducer(state = profileInitState, action) {
  let nextState = state
  let selectedState = null
  let payload = action.payload || {}
  payload = payload.result ? payload.result : payload

  switch (action.type) {
    // get profile from GET /me
    case GET_PROFILE_SUCCESS:
      nextState = state.merge(payload)
      return nextState
    case CLEAR_PROFILE:
      selectedState = {
        email: null,
        image: null,
        firstName: null,
        lastName: null,
      }
      nextState = state.merge(selectedState)
      return nextState
    default:
      return state
  }
}

export function multiShareReducer(state = multiShareInitialState, action) {
  let nextState = state
  let payload = action.payload || {}
  payload = payload.result ? payload.result : payload

  switch (action.type) {
    case VERIFY_MULTI_SHARE_SUCCESS:
      nextState = state.merge(payload)
      return nextState
    default:
      return state
  }
}

export default wrapperReducer
