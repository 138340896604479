import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Span } from "@rentspree/component-v2"
import { Icon } from "@rentspree/component-v2/dist/icon"

import { COLOR } from "styles/settings"

export const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
  display: flex;
  align-items: center;
`

const Text = styled.text`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`
export const TextWithIcon = ({
  color,
  text,
  size,
  fontSize,
  fontWeight,
  mFontSize,
  icon,
  iconColor,
  iconHeight,
  className,
  margin,
  onClick,
}) => (
  <Wrapper className={className} margin={margin} onClick={onClick}>
    {fontSize || mFontSize ? (
      <Span size={fontSize} tSize={mFontSize} color={color} weight={fontWeight}>
        {text}
      </Span>
    ) : (
      <Text color={color} weight={fontWeight}>
        {text}
      </Text>
    )}
    <Icon
      className={className}
      style={{ height: iconHeight }}
      icon={icon}
      name={icon}
      color={iconColor}
      size={size}
    />
  </Wrapper>
)

TextWithIcon.defaultProps = {
  color: COLOR.textBlack,
  iconHeight: "16px",
  size: "md",
}

TextWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  iconHeight: PropTypes.string,
}
