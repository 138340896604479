import React from "react"
import T36 from "@rentspree/component-v2/dist/typography/t36"

import Form from "./form"

const TuErrorPageTemplate = props => {
  const { content } = props
  return (
    <>
      <T36 lineHeight="normal" margin="50px 0 35px 0">
        {content?.title}
      </T36>
      {content?.body}
      <Form {...props} />
    </>
  )
}

export default TuErrorPageTemplate
