import React from "react"
import styled from "styled-components"

import Col from "components/atoms/col"
import Row from "components/atoms/row"

const ColStyled = styled(Col)`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin-left: 10px;

  .row-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`

const ReviewInfoComponent = ({ contents = [] }) => (
  <ColStyled className="info-wrapper">
    {contents.map(row => (
      <Row className="row-wrapper">{row.map(item => item.render())}</Row>
    ))}
  </ColStyled>
)

export default ReviewInfoComponent
