import { useState, useEffect } from "react"

const useMobile = mobileThreshold => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileThreshold)

  useEffect(
    () => {
      const handleResize = () => {
        const currentIsMobile = window.innerWidth <= mobileThreshold

        if (currentIsMobile !== isMobile) {
          setIsMobile(currentIsMobile)
        }
      }

      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    },
    [isMobile, mobileThreshold],
  )

  return isMobile
}

export default useMobile
