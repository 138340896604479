import styled, { css } from "styled-components"

import { generateProperty } from "utils/styled-utils"

const ButtonGroup = styled.div`
  ${props =>
    props.fixedBottom &&
    css`
      position: fixed;
      left: 0;
      bottom: 0;
    `}
  padding: ${props => props.padding || "0"};
  width: 100%;
  height: ${props => props.height || "40px"};
  button {
    width: ${props => props.btnWidth || "110px"};
  }
  .left-btn {
    float: left;
    background-color: transparent;
  }
  .right-btn {
    float: right;
  }
  @media (max-width: 767px) {
    ${props => props.mPadding && generateProperty("padding", props.mPadding)};
  }
`

export default ButtonGroup
