import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectReducer from "utils/inject-reducer"
import reducer from "containers/wrapper/reducer"
import { makeSelectProperty } from "containers/property/selectors"
import makeSelectLoading from "containers/loading/selectors"
import injectSaga from "utils/inject-saga"
import {
  saveAllField,
  redirect,
  getScreeningRequest,
} from "containers/wrapper/actions"
import saga from "./../wrapper/saga"

export const mapStateToProps = createStructuredSelector({
  property: makeSelectProperty(),
  loading: makeSelectLoading(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        saveAllField,
        redirect,
        getScreeningRequest,
      },
      dispatch,
    ),
  }
}

export const withReducer = injectReducer({ key: "wrapper", reducer })
export const withSaga = injectSaga({ key: "wrapper", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
