import { get } from "lodash"
import { createSelector } from "reselect"

import { USER_TYPE } from "constants/user"
import { initialState } from "./reducer"
import { getMultiShareSelectedOptionKey } from "./helper"
import {
  makeSelectMultiShare,
  makeSelectPropertyId,
} from "../../wrapper/selectors"

export const selectRentalGuide = state => state.get("rentalGuide", initialState)

export const getDocuments = state =>
  get(state.get("document", {}), "documents", [])

export const selectPropertyId = makeSelectPropertyId()
export const makeSelectRentalGuide = () =>
  createSelector(selectRentalGuide, substate => substate.toJS())

export const makeSelectDocuments = () =>
  createSelector(getDocuments, substate => substate)

export const selectIsSelectMultiShare = createSelector(
  state => ({
    renterId: state.get("rentalSubmission").toJS().renter_id,
    rentalId: state.get("rentalSubmission").toJS().id,
    isSelectMultiShare: state.get("rentalGuide").toJS().isSelectMultiShareOffer,
  }),
  subState => {
    const stringToBooleanMapping = {
      true: true,
      false: false,
    }
    const { renterId, rentalId } = subState
    const multiShareSelectedOptionKey = getMultiShareSelectedOptionKey({
      renterId,
      rentalId,
    })
    const selectedOptionStringFromLocalStorage = window.localStorage.getItem(
      multiShareSelectedOptionKey,
    )
    const selectedOptionFromLocalStorage =
      stringToBooleanMapping[selectedOptionStringFromLocalStorage]

    if (subState.isSelectMultiShare !== undefined) {
      return subState.isSelectMultiShare
    }

    if (selectedOptionFromLocalStorage !== undefined) {
      return selectedOptionFromLocalStorage
    }

    if (subState.isSelectMultiShare === undefined) {
      return true
    }

    return undefined
  },
)

export const makeSelectIsSelectMultiShare = () => selectIsSelectMultiShare

const selectAllowMultiShareOffer = createSelector(
  state => state.get("rentalSubmission").toJS(),
  makeSelectMultiShare(),
  (rentalSubmission, multiShare) => {
    const { isMultiShareActive } = multiShare
    const screeningOption = rentalSubmission?.screeningOption
    const isCreditReportSelected = get(screeningOption, "credit_report")
    const isRenterPay = screeningOption?.payerType === USER_TYPE.RENTER
    return !isMultiShareActive && isCreditReportSelected && isRenterPay
  },
)
export const makeSelectAllowMultiShareOffer = () => selectAllowMultiShareOffer
