import isEmpty from "lodash/isEmpty"
import pick from "lodash/pick"
import defaultTo from "lodash/defaultTo"
import get from "lodash/get"
import { ALLOW_ATTRIBUTES, CAR_PARTNER } from "./constants"

export function parseUserProfileToInstanceUser(
  profile,
  subdomain,
  userIntegration = [],
) {
  // TODO: should use cookies instead of `default-id`
  // will implement it when there are anonymous cases
  const expPartial = {}
  // eslint-disable-next-line no-underscore-dangle
  const userProfile = isEmpty(profile?._id) ? { _id: "default-id" } : profile
  const isCARAccountLinked = userIntegration.includes(CAR_PARTNER) || false
  const { _id: id, email, ...rest } = userProfile
  const userMailingAddressState = get(rest, "address.state") || ""
  const attributes = pick(
    {
      ...rest,
      subdomain: defaultTo(subdomain, ""),
      isCARAccountLinked,
      userMailingAddressState,
    },
    ALLOW_ATTRIBUTES,
  )
  return {
    id,
    userID: id,
    email,
    attributes: { ...attributes, ...expPartial }, // for launchDarkly
    custom: {
      userId: id,
      ...attributes,
      ...expPartial,
    }, // for statsig
  }
}
