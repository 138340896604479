import React from "react"
import { withFormik } from "formik"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import Checkbox from "@rentspree/component-v2/dist/input/checkbox"
import CenterContent from "components/atoms/center-content"

export const Form = ({
  values,
  handleSubmit,
  setFieldValue,
  loading,
  content,
}) => (
  <>
    <CenterContent margin="50px 0">
      <form onSubmit={handleSubmit}>
        <Checkbox
          id="acceptErrorTuCheckBox"
          fontSize="16px"
          labelBold
          simple
          label={content?.checkboxLabel}
          isSelected={values.acceptError}
          margin="0 0 10px"
          mMargin="0 15px 10px"
          onClick={() => setFieldValue("acceptError", !values.acceptError)}
        />
        <BlueGradientButton
          id="errorTuContinueBtn"
          small
          semiBold
          text={content?.callToActionText}
          type="submit"
          disabled={!values.acceptError || loading?.components}
          loading={loading?.components}
          loaded={loading?.success}
          margin="auto"
          width="368px"
          mWidth="100%"
        />
      </form>
    </CenterContent>
  </>
)

const FormikForm = withFormik({
  enableReinitialize: true,
  displayName: "tuErrorForm",
  mapPropsToValues: () => ({
    acceptError: false,
  }),
  handleSubmit: (values, { props }) => {
    props.startNewScreening()
  },
})(Form)

export default FormikForm
