import React from "react"
import styled from "styled-components"
import InfoIcon from "images/icon/app-fee-info-icon.svg"
import { COLOR } from "../../styles/settings"

export const InfoMessagePanelWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: ${COLOR.deepBluePale};
  color: ${COLOR.brightBlue};
  font-size: 14px;
  margin-top: 20px;
`

export const InfoIconContainer = styled.img`
  width: 20px;
  height: 20px;
  filter: invert(52%) sepia(99%) saturate(2679%) hue-rotate(194deg)
    brightness(105%) contrast(101%);
`

export const InfoMessagePanel = ({ children, testId, ...props }) => (
  <React.Fragment>
    <InfoMessagePanelWrapper
      data-testid={`${testId}-info-message-panel-wrapper`}
      {...props}>
      <InfoIconContainer src={InfoIcon} alt="An icon with 'i' sign" />
      {children}
    </InfoMessagePanelWrapper>
  </React.Fragment>
)
