import { put, call, takeLatest, select, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"

import { apiInstance } from "utils/api-interceptor"
import * as wrapperAction from "containers/wrapper/actions"
import { tracker } from "tracker"
import { TU_EVENT } from "tracker/tracker-const"
import { selectRentalId } from "containers/rental-submission/selectors"
import { clearErrorAlertCall } from "containers/error/constants"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"

import * as constants from "../constants"
import { redirectToTuScreeningPages, getTURenterApi } from "../saga"
import { selectSsn } from "../selectors"
import { unsetIsShowReviewFlag } from "./actions"

export const startNewScreeningApi = (renter, rentalId) =>
  apiInstance.post(
    buildPath(constants.START_NEW_SCREENING_API_URL, { rentalId }),
    { renter },
  )

// TODO: adjust this saga to redirect to new page
export function* startNewScreening(action) {
  yield put(constants.startNewScreeningRequest())
  try {
    const rentalId = yield select(selectRentalId)
    const creditReport = yield call(
      startNewScreeningApi,
      action.payload,
      rentalId,
    )
    // get tuRenter to check status
    const tuRenter = yield call(getTURenterApi)

    yield put(wrapperAction.setSuccess())
    yield put(constants.startNewScreeningSuccess(creditReport))
    yield call([tracker, "trackEvent"], TU_EVENT.saveScreeningInfo)
    yield put(constants.updateTuRenterCall(tuRenter))
    yield put(unsetIsShowReviewFlag())

    const ssn = yield select(selectSsn)
    yield call(redirectToTuScreeningPages, creditReport, tuRenter, ssn)

    yield put(clearErrorAlertCall())
  } catch (e) {
    yield put(constants.startNewScreeningFailed(e?.data || {}))
  }
}

export function* handleStartNewScreeningError(action) {
  const { tuCode, statusCode } = action.payload || {}
  // Priority TU-Error First
  if (tuCode) {
    switch (tuCode) {
      case "200015":
        yield put(constants.getTUScreeningDataCall())
        break
      default:
        break
    }
  } else if (statusCode) {
    switch (statusCode) {
      case 422:
        yield call(
          SweetAlert,
          ALERT_PRESET.ERROR,
          API_ERRORS[422].option,
          API_ERRORS[422].callback,
        )
        break
      default:
        break
    }
  }
}

export function* watchStartNewScreeningFailed() {
  yield takeLatest(
    constants.START_NEW_SCREENING_FAILED,
    handleStartNewScreeningError,
  )
}

export function* watchStartNewScreening() {
  yield takeLatest(constants.START_NEW_SCREENING_CALL, startNewScreening)
}

export function* rootSaga() {
  yield all([watchStartNewScreening(), watchStartNewScreeningFailed()])
}

export default rootSaga
