import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { createStructuredSelector } from "reselect"

import { TopNavbar, NavbarRentals } from "@rentspree/navbar"

import { USER_API_URL, DOMAIN_NAME, RENTALS_DOMAIN, API_URL } from "env"
import {
  makeSelectSubdomain,
  makeSelectProfile,
} from "containers/wrapper/selectors"

import { handleApiErrors } from "utils/handle-api-errors"
import { LISTING_PARTNER } from "utils/partner-consts"
import { getPartnerLogo } from "utils/get-partner-logo"
import { resizeWithImageProxy } from "utils/image"
import { feedbackCollectionInstance } from "utils/feedback-collection/instance"
import { tracker } from "../../tracker/tracker"

export const Navbar = ({ subdomain, profile }) => {
  if (subdomain === LISTING_PARTNER.RENTALS) {
    const partnerLogo = getPartnerLogo(subdomain)
    const resizedPartnerLogo = resizeWithImageProxy(partnerLogo, {
      height: 64,
    })
    return (
      <NavbarRentals
        domain={RENTALS_DOMAIN}
        partnerLogoSrc={resizedPartnerLogo}
      />
    )
  }

  const showGenericSurvey = () => feedbackCollectionInstance.showGenericSurvey()

  return (
    <TopNavbar
      configApi={{
        baseURL: USER_API_URL,
        domain: DOMAIN_NAME,
        apiURL: API_URL,
      }}
      profile={profile}
      tracker={tracker}
      otherButton={{
        styles: "z-index:6",
      }}
      isShowRenterSupport
      isShowNotification
      domain={DOMAIN_NAME}
      notificationBaseURL={API_URL}
      authBaseURL={USER_API_URL}
      showGenericSurvey={showGenericSurvey}
      handleApiErrors={handleApiErrors}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  subdomain: makeSelectSubdomain(),
  profile: makeSelectProfile(),
})

export const withConnect = connect(mapStateToProps)

export default compose(withConnect)(Navbar)
