/* eslint-disable react/no-unescaped-entities */
import React from "react"
import S20 from "@rentspree/component-v2/dist/typography/s20"
import { AgentBadge } from "@rentspree/component-v2/dist/agent-badge"
import styled from "styled-components"
import { B14, B16, Hr, Span } from "@rentspree/component-v2"

import { COLOR } from "styles/settings"
import AppFeeInfoIcon from "images/icon/app-fee-info-icon.svg"
import TooltipWithCustomImgIcon from "components/molecules/tooltip-with-custom-img-icon"

import { FlexContainer } from "./rental-step"
import { Div } from "../layout"
import { pricePositionFormat } from "../../utils/price-render-format"

export const RentalDetail = ({
  address,
  hideMobile,
  isShowAgentBadge,
  agentProfile,
  margin,
  mMargin,
  isLandingPage,
  addressTextColor,
}) => (
  <FlexContainer
    hideOnMobile={hideMobile}
    alignItems="flex-start"
    margin={margin || "0 0 30px"}
    mMargin={mMargin || "30px 0"}>
    {address && (
      <S20
        color={addressTextColor || COLOR.darkerGray}
        mSize={isLandingPage ? "24px" : "18px"}
        margin="0 0 0">
        {address}
      </S20>
    )}
    {isShowAgentBadge && (
      <AgentBadgeWrapper>
        <B16 margin="0 0 10px 0" weight="600">
          Application Recipient
        </B16>
        <AgentBadge id="rentalDetail" {...agentProfile} />
        <Hr margin="10px 0 10px 0" />
        <B14 margin="0 0 0 0" weight="400">
          Contact the representative you've been working with if you have any
          questions.
        </B14>
      </AgentBadgeWrapper>
    )}
  </FlexContainer>
)

export const AgentBadgeWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`

export const SpecialOfferRemovalWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
`

export const ScreeningFeeWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 8px;
`

export const AppFeeWithTooltip = ({
  title,
  fee = 0,
  tooltipContent,
  specialOfferPrice = "15.00",
  isSpecialOfferSelected = false,
  handleSelectSpecialOfferOption = () => {},
}) => (
  <ScreeningFeeWrapper data-testid="screening-fee-wrapper" margin="30px 0 0 0">
    <Div>
      <TooltipWithCustomImgIcon
        data-testid="screening-fee-tooltip"
        desktopContent={tooltipContent}
        mobileContent={tooltipContent}
        imgIcon={AppFeeInfoIcon}
      />

      <Span
        data-testid="screening-fee-bundle-and-price"
        size="16px"
        weight="600">
        {title}: ${pricePositionFormat(fee)}
      </Span>
    </Div>
    {isSpecialOfferSelected && (
      <Div>
        <Span
          data-testid="screening-fee-special-offer-price"
          size="16px"
          weight="600">
          Special offer: ${specialOfferPrice}
        </Span>
        <SpecialOfferRemovalWrapper
          data-testid="screening-fee-special-offer-price-removal-text-wrapper"
          onClick={() => handleSelectSpecialOfferOption(false)}>
          <Span
            data-testid="screening-fee-special-offer-price-removal-text"
            size="16px"
            weight="600"
            color={COLOR.blueLV5}
            rightSpace="0">
            Remove
          </Span>
        </SpecialOfferRemovalWrapper>
      </Div>
    )}
    <Div>
      <Span data-testid="screening-fee-subtotal" size="20px" weight="600">
        Subtotal: ${isSpecialOfferSelected
          ? (parseFloat(fee) + parseFloat(specialOfferPrice)).toFixed(2)
          : pricePositionFormat(fee)}
      </Span>
    </Div>
  </ScreeningFeeWrapper>
)
