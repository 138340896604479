import get from "lodash/get"
import map from "lodash/map"
import { CO_APPLICANT_TYPE } from "../constants"

export const stepAdditionalApplicant = payload => ({
  haveTenants: get(payload, "haveTenants"),
  haveCosigners: get(payload, "haveGuarantors"),
  coApplicants: [
    ...mapCoApplicants(get(payload, "tenants"), CO_APPLICANT_TYPE.OCCUPANT),
    ...mapCoApplicants(get(payload, "guarantors"), CO_APPLICANT_TYPE.COSIGNER),
  ],
})

export const mapCoApplicants = (coApplicants, type) =>
  map(coApplicants, coApplicant => ({
    firstName: get(coApplicant, "firstName"),
    lastName: get(coApplicant, "lastName"),
    email: get(coApplicant, "email"),
    dateOfBirth: get(coApplicant, "dateOfBirth"),
    shareLeaseLink: get(coApplicant, "shareLeaseLink", false),
    type,
  }))
