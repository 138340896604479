import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import { setActive, resetActive } from "containers/drag-n-drop/actions"

import {
  makeSelectDoesEnvelopeExist,
  makeSelectEnvelopeName,
  makeSelectEnvelopeUpdatedAt,
  makeSelectActiveIdType,
  makeSelectActiveIdPage,
  makeSelectActiveIdField,
  makeSelectActiveIdStyles,
  makeSelectActiveIdAssignee,
  makeSelectActiveIdUndeletable,
  makeSelectToolbar,
  makeSelectRecipientList,
  selectLoadingUpdateEnvelope,
  makeSelectIsUsingTemplate,
  selectFieldValidationResult,
  selectLoadingPrepareEnvelope,
} from "./selectors"
import reducer from "./reducer"
import { removeActiveBox } from "./actions"
import saga from "./saga"

export const makeMapStateToProps = () =>
  createStructuredSelector({
    haveEnvelope: makeSelectDoesEnvelopeExist(),
    envelopeName: makeSelectEnvelopeName(),
    envelopeUpdatedAt: makeSelectEnvelopeUpdatedAt(),
    activeIdType: makeSelectActiveIdType(),
    activeIdPage: makeSelectActiveIdPage(),
    activeIdField: makeSelectActiveIdField(),
    activeIdStyles: makeSelectActiveIdStyles(),
    activeIdAssignee: makeSelectActiveIdAssignee(),
    activeIdUndeletable: makeSelectActiveIdUndeletable(),
    toolBarList: makeSelectToolbar(),
    recipientsList: makeSelectRecipientList(),
    isLoadingUpdateEnvelope: selectLoadingUpdateEnvelope,
    isLoadingPrepareEnvelope: selectLoadingPrepareEnvelope,
    isUsingTemplate: makeSelectIsUsingTemplate(),
    fieldValidation: selectFieldValidationResult(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        /* envelope actions */
        removeActiveBox,
        /* drag-n-drop actions */
        setActive,
        resetActive,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "envelope", reducer })
export const withSaga = injectSaga({ key: "envelope", saga })
