import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import injectSaga from "utils/inject-saga"
import { redirect } from "containers/wrapper/actions"
import selectLoading from "containers/loading/selectors"
import { selectApplication } from "containers/rental-submission/selectors"
import {
  makeTuRenterSelector,
  makeIsShowCheckBoxUpdateSSN,
  makeIsShowReviewPageSelector,
  makeTuRenterAddress,
  makeNationalUsNumber,
} from "../selectors"
import saga from "./saga"
import { startNewScreening } from "../actions"
import { setIsShowReviewFlag, unsetIsShowReviewFlag } from "./actions"

export const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  application: selectApplication(),
  tuRenter: makeTuRenterSelector(),
  address: makeTuRenterAddress(),
  usNationalNumber: makeNationalUsNumber(),
  isShowCheckBoxUpdateSSN: makeIsShowCheckBoxUpdateSSN(),
  isShowReviewPage: makeIsShowReviewPageSelector(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        redirect,
        startNewScreening,
        setIsShowReviewFlag,
        unsetIsShowReviewFlag,
      },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "tuScreeningInfo", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
