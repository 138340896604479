export const formatSSN = val => {
  // Extract the first 9 characters and remove any existing dashes
  const ssn = val.slice(0, 9).replace(/-/g, "")
  const ssnLength = ssn.length
  const firstPart = ssn.slice(0, 3)
  const middlePart = ssn.slice(3, 5)
  const lastPart = ssn.slice(5)

  switch (true) {
    case ssnLength <= 3:
      return ssn

    case ssnLength <= 5:
      return `${firstPart}-${middlePart}`

    case ssnLength > 5:
      return `${firstPart}-${middlePart}-${lastPart}`

    default:
      return ssn
  }
}

export const censorSSN = (val, format = "•") =>
  !val ? "" : val.replace(/\d/g, format)
