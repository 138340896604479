import React from "react"
import styled from "styled-components"

const ToolTipContentWrapper = styled.div`
  .tooltip-title {
    display: none;
  }

  @media (max-width: 992px) {
    .tooltip-title {
      display: block;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
`

const ToolTipContent = ({ title = "", body = "" }) => (
  <ToolTipContentWrapper>
    <span className="tooltip-title">{title}</span>
    <span className="tooltip-body">{body}</span>
  </ToolTipContentWrapper>
)

export default ToolTipContent
