import get from "lodash/get"

/*
 *
 * rental-instruction constants
 *
 */

import { APPLICATION_TYPE_MAPPER } from "../../application/constants"

export const SCREENING_OPTION_MAPPER = {
  application: applicationType => get(APPLICATION_TYPE_MAPPER, applicationType),
  credit_report: "TransUnion Credit Report & Score",
  criminal: "Criminal Background Check",
  eviction: "Eviction Related Proceedings",
}

export const START_NEW_RENTAL_CALL = "START_NEW_RENTAL_CALL"
export const START_NEW_RENTAL_REQUEST = "START_NEW_RENTAL_REQUEST"
export const START_NEW_RENTAL_SUCCESS = "START_NEW_RENTAL_SUCCESS"
export const START_NEW_RENTAL_FAILED = "START_NEW_RENTAL_FAILED"

export const startNewRentalCall = payload => ({
  type: START_NEW_RENTAL_CALL,
  payload,
})
export const startNewRentalRequest = () => ({
  type: START_NEW_RENTAL_REQUEST,
})
export const startNewRentalSuccess = payload => ({
  type: START_NEW_RENTAL_SUCCESS,
  payload,
})
export const startNewRentalFailed = payload => ({
  type: START_NEW_RENTAL_FAILED,
  payload,
})

// TODO: INIT-649 clean up
export const START_PRE_PAYMENT_RENTAL_CALL = "START_PRE_PAYMENT_RENTAL_CALL"
export const START_PRE_PAYMENT_RENTAL_REQUEST =
  "START_PRE_PAYMENT_RENTAL_REQUEST"
export const START_PRE_PAYMENT_RENTAL_SUCCESS =
  "START_PRE_PAYMENT_RENTAL_SUCCESS"
export const START_PRE_PAYMENT_RENTAL_FAILED = "START_PRE_PAYMENT_RENTAL_FAILED"

export const PRE_PAYMENT_API_URL =
  "/api/v2/rental-submissions/:rentalId/credit-report/pre-payment"

export const startPrePaymentRentalCall = payload => ({
  type: START_PRE_PAYMENT_RENTAL_CALL,
  payload,
})
export const startPrePaymentRentalRequest = () => ({
  type: START_PRE_PAYMENT_RENTAL_REQUEST,
})
export const startPrePaymentRentalSuccess = payload => ({
  type: START_PRE_PAYMENT_RENTAL_SUCCESS,
  payload,
})
export const startPrePaymentRentalFailed = payload => ({
  type: START_PRE_PAYMENT_RENTAL_FAILED,
  payload,
})
