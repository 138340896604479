import get from "lodash/get"
import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const getTuAgreementState = state =>
  get(state.toJS(), "tuAgreement", initialState)

export const selectTuAgreementResponse = () =>
  createSelector(getTuAgreementState, state => get(state, "agreement"))

export const selectTuAgreementFetching = () =>
  createSelector(getTuAgreementState, state => get(state, "isFetching"))

export const selectTuAgreementErrors = () =>
  createSelector(getTuAgreementState, state => get(state, "errors"))

export const selectIsTuAgreementReConsent = () =>
  createSelector(getTuAgreementState, state => get(state, "isReConsent"))
