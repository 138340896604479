import { put, takeLatest } from "redux-saga/effects"
import { ROUTE } from "containers/router/constants"
import urlJoin from "url-join"
import { redirect } from "containers/wrapper/actions"
import { clearErrorAlert } from "containers/error/actions"
import { CALL_UPDATE_SSN, setSSN } from "./constants"

export function* identitySaga({ ssn }) {
  yield put(setSSN(ssn))
  const examPage = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.EXAM)
  yield put(redirect(examPage, true))
  yield put(clearErrorAlert())
}

export default function*() {
  yield takeLatest(CALL_UPDATE_SSN, identitySaga)
}
