import { createStructuredSelector } from "reselect"
import injectReducer from "utils/inject-reducer"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectSaga from "utils/inject-saga"
import errorSelector from "containers/error/selectors"
import { makeSelectProperty } from "containers/property/selectors"
import { makeSelectAgentProfile } from "containers/landlord/selectors"
import makeSelectLoading from "containers/loading/selectors"
import { closeErrorAlert } from "containers/error/actions"
import { redirect, redirectRentalApp } from "containers/wrapper/actions"
import { getPayment } from "containers/rental-submission/rental-payment/actions"
import { makeSelectMultiShare } from "containers/wrapper/selectors"
import { selectCreditReportBundleId } from "containers/tu-screening/selectors"
import * as GuideActions from "./actions"
import { prepareEnvelope } from "../../envelope/actions"
import {
  makeSelectRental,
  makeStatusMapper,
  makeIsPayAndSubmit,
  makeIsSubmitDisable,
} from "../selectors"
import saga from "./saga"

import reducer from "./reducer"
import {
  makeSelectRentalGuide,
  makeSelectDocuments,
  makeSelectIsSelectMultiShare,
  makeSelectAllowMultiShareOffer,
} from "./selectors"
import { setIsSelectMultiShare, setIsShowRequireMultiShare } from "./constants"
import { selectIncomeVerification } from "../income-verification/selector"
import { getIncomeVerification } from "../income-verification/action"
import { getCreditReport } from "../../../containers/tu-screening/actions"

export const mapStateToProps = createStructuredSelector({
  error: errorSelector(),
  property: makeSelectProperty(),
  rentalSubmission: makeSelectRental(),
  agentProfile: makeSelectAgentProfile(),
  status: makeStatusMapper(),
  isPayAndSubmit: makeIsPayAndSubmit(),
  isSubmitDisable: makeIsSubmitDisable(),
  loading: makeSelectLoading(),
  rentalGuide: makeSelectRentalGuide(),
  multiShare: makeSelectMultiShare(),
  documents: makeSelectDocuments(),
  isSelectMultiShareOffer: makeSelectIsSelectMultiShare(),
  isAllowMultiShareOffer: makeSelectAllowMultiShareOffer(),
  incomeVerification: selectIncomeVerification(),
  bundleId: selectCreditReportBundleId(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        redirect,
        redirectRentalApp,
        closeErrorAlert,
        setIsSelectMultiShare,
        setIsShowRequireMultiShare,
        prepareEnvelope,
        getPayment,
        getIncomeVerification,
        getCreditReport,
        ...GuideActions,
      },
      dispatch,
    ),
  }
}
export const withReducer = injectReducer({ key: "rentalGuide", reducer })
export const withSaga = injectSaga({
  key: "guide",
  saga,
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
