import generateApiCall from "utils/generate-api-call"
import {
  GET_INCOME_VERIFICATION_CALL,
  GET_INCOME_VERIFICATION_REQUEST,
  GET_INCOME_VERIFICATION_SUCCESS,
  GET_INCOME_VERIFICATION_FAILED,
  UPDATE_INCOME_VERIFICATION_STATUS_CALL,
  UPDATE_INCOME_VERIFICATION_STATUS_REQUEST,
  UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS,
  UPDATE_INCOME_VERIFICATION_STATUS_FAILED,
  CREATE_FINICITY_CONNECT_LINK_CALL,
  CREATE_FINICITY_CONNECT_LINK_REQUEST,
  CREATE_FINICITY_CONNECT_LINK_SUCCESS,
  CREATE_FINICITY_CONNECT_LINK_FAILED,
  CLEAN_CONNECT_LINK,
  SUBMIT_INCOME_VERIFICATION,
  HANDLE_FINICITY_POPUP_DONE,
  HANDLE_FINICITY_POPUP_ERROR,
} from "./constants"

export const getIncomeVerification = payload => ({
  type: GET_INCOME_VERIFICATION_CALL,
  payload,
})

export const updateIncomeVerificationStatus = payload => ({
  type: UPDATE_INCOME_VERIFICATION_STATUS_CALL,
  payload,
})

export const createFinicityConnectLink = payload => ({
  type: CREATE_FINICITY_CONNECT_LINK_CALL,
  payload,
})

export const cleanConnectLink = payload => ({
  type: CLEAN_CONNECT_LINK,
  payload,
})

export const submitIncomeVerification = payload => ({
  type: SUBMIT_INCOME_VERIFICATION,
  payload,
})

export const handleFinicityPopupDone = payload => ({
  type: HANDLE_FINICITY_POPUP_DONE,
  payload,
})

export const handleFinicityPopupError = payload => ({
  type: HANDLE_FINICITY_POPUP_ERROR,
  payload,
})

export const getIncomeVerificationApiState = generateApiCall([
  GET_INCOME_VERIFICATION_REQUEST,
  GET_INCOME_VERIFICATION_SUCCESS,
  GET_INCOME_VERIFICATION_FAILED,
])

export const updateIncomeVerificationStatusApiState = generateApiCall([
  UPDATE_INCOME_VERIFICATION_STATUS_REQUEST,
  UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS,
  UPDATE_INCOME_VERIFICATION_STATUS_FAILED,
])

export const createFinicityConnectLinkApiState = generateApiCall([
  CREATE_FINICITY_CONNECT_LINK_REQUEST,
  CREATE_FINICITY_CONNECT_LINK_SUCCESS,
  CREATE_FINICITY_CONNECT_LINK_FAILED,
])
