/*
 *
 * AppMisc reducer
 *
 */
import { fromJS } from "immutable"

import { BANK_ACCOUNT_TYPES } from "./reference/step-bank-information/const"
import { SAVE_APPLICATION_SUCCESS, GET_APPLICATION_SUCCESS } from "./constants"
import { parseFromAPI } from "./map-from-api"

const baseResidence = {
  dateOut: "",
  street: "",
  reason: "",
  monthlyRent: "",
  city: "",
  zipCode: "",
  state: "",
  region: "US",
  dateIn: "",
  selected: null,
  landlordEmail: "",
  landlordName: "",
  landlordPhone: "",
  agentName: "",
  agentPhone: "",
}

export const baseEmergency = {
  firstName: "",
  lastName: "",
  email: "",
  relationship: "",
  phoneNumber: "",
  streetAddress: "",
  city: "",
  state: "",
  zipcode: "",
  isSkipEmail: false,
  region: "US",
}

export const baseVehicle = {
  make: "",
  model: "",
  year: "",
  color: "",
  licensePlate: "",
  state: "",
}

export const baseCreditorInformation = {
  name: "",
  accountNumber: "",
  monthlyPayment: "",
  balanceDue: "",
}

export const baseBankInformation = {
  name: "",
  accountNumber: "",
  accountBalance: "",
  accountType: BANK_ACCOUNT_TYPES.PERSONAL_SAVING,
  other: "",
}
export const baseRefRelative = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  relationship: "",
  streetAddress: "",
  city: "",
  state: "",
  zipcode: "",
  region: "US",
}
export const baseRefPersonal = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  acquaintanceLength: "",
  occupation: "",
  streetAddress: "",
  city: "",
  state: "",
  zipcode: "",
  region: "US",
}

export const initialState = fromJS({
  personal: {
    firstName: "",
    middleName: undefined,
    lastName: "",
    dob: "",
    phoneNumber: "",
    governmentId: {
      photoType: "",
      idNum: "",
      issueBy: "",
      expiredDate: "",
    },
    driverLicense: {
      expiredDate: "",
      licenseNum: "",
      state: "",
    },
    emergencyContacts: [baseEmergency],
  },
  residence: {
    current: {
      ...baseResidence,
    },
    previous: {
      ...baseResidence,
      dateOut: "",
    },
  },
  occupation: {
    current: {
      /** working status that user select */
      workStatus: "",
      data: {
        /** info, duration, supervisor and address in a data from work status = employed form section */
        info: {},
        duration: {},
        supervisor: {},
        address: {},
      },
    },
    /** the additional income data */
    additionalIncomes: [],
    previous: {
      /** working status that user select */
      workStatus: "",
      data: {
        /** info, duration, supervisor and address in a data from work status = employed form section */
        info: {},
        duration: {},
        supervisor: {},
        address: {},
      },
    },
  },
  reference: {
    bankInformation: [baseBankInformation],
    creditorInformation: [baseCreditorInformation],
    nearestRelatives: [baseRefRelative],
    personalReferences: [baseRefPersonal],
  },
  miscellaneous: {
    pets: {
      havePet: null,
      pets: [],
    },
    vehicles: {
      haveVehicle: null,
      vehicles: [],
    },
    additionalInfo: {
      liquidFurniture: "",
      typeLiquidFurniture: "",
      unlawful: "",
      explainUnlawful: "",
      moveOut: "",
      explainMoveOut: "",
      bankruptcy: "",
      explainBankruptcy: "",
      felony: "",
      explainFelony: "",
    },
  },
  draft: null,
  continuePath: "",
})

function applicationReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case SAVE_APPLICATION_SUCCESS:
    case GET_APPLICATION_SUCCESS:
      return initialState.mergeDeep({
        ...parseFromAPI(payload, action.isMergeDraft),
        isResumeAppEnabled: state.toJS().isResumeAppEnabled,
      })
    default:
      return state
  }
}

export default applicationReducer
