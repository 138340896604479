import React, { useState } from "react"
import styled from "styled-components"
import { S24 } from "@rentspree/component-v2"

import TextSignature from "containers/envelope/form/text-signature"
import DrawSignature from "containers/envelope/form/draw-signature"
import { TabWrapper, Tabs, TabItem } from "components/layout/tabs"
import ModalDefault from "components/modal/default-modal"
import { COLOR } from "styles/settings"

import { TABS_LABEL } from "../constants"

const ModalWrapper = styled(ModalDefault)`
  z-index: 100000;
  #signatureFormTabsWrapper {
    padding: 20px 30px 0px 30px;
  }
  #signatureFormTabs {
    margin: 10px -30px 0px -30px;
  }
  .close {
    padding: 10px 10px 0px 0px;
  }
  .modal-content {
    width: 500px;
  }
  .modal-body {
    padding: 15px 30px 20px 30px !important;

    .col-md-6 {
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
  li {
    padding: 11px 10px;
    margin: 0px;
    width: auto;
    :hover {
      color: ${COLOR.newDeepBlue};
      border-color: ${COLOR.newDeepBlue};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: Source Sans Pro, sans-serif;
  button {
    align-self: flex-end;
  }
`

export const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SignatureFormModal = props => {
  const [activeTab, setActiveTab] = useState(
    props.signatureData.type || TABS_LABEL.TYPE,
  )
  const { showSignModal } = props
  const nextText = showSignModal.mode === "newSign" ? "Adopt" : "Replace All"
  const titleText =
    showSignModal.mode === "newSign" ? "Signature" : "Edit Signature"
  return (
    <ModalWrapper
      header={
        <TabWrapper id="signatureFormTabsWrapper">
          <S24 margin="0">{titleText}</S24>
          <Tabs id="signatureFormTabs">
            {Object.keys(TABS_LABEL).map(tab => (
              <TabItem
                id="type"
                active={
                  activeTab.charAt(0).toUpperCase() + activeTab.substring(1) ===
                  TABS_LABEL[tab]
                }
                onClick={() => {
                  setActiveTab(TABS_LABEL[tab])
                }}>
                {TABS_LABEL[tab]}
              </TabItem>
            ))}
          </Tabs>
        </TabWrapper>
      }
      className="rentspreeModal"
      closeButton
      show={showSignModal.show}
      backdrop="static"
      {...props}>
      <Wrapper>
        {activeTab === TABS_LABEL.TYPE ? (
          <TextSignature
            nextButtonText={nextText}
            {...props}
            signatureData={props.signatureData}
            mode={showSignModal.mode}
          />
        ) : (
          <DrawSignature
            nextButtonText={nextText}
            {...props}
            signatureData={props.signatureData}
            mode={showSignModal.mode}
            isLoading={props.isUploadingSignature}
          />
        )}
      </Wrapper>
    </ModalWrapper>
  )
}

export default SignatureFormModal
