import { ADD_TOAST, CLOSE_TOAST } from "./constants"

export const addToast = toast => {
  const id = new Date().getTime().toString()
  return {
    type: ADD_TOAST,
    payload: { ...toast, id },
  }
}

export const closeToast = id => ({
  type: CLOSE_TOAST,
  payload: id,
})
