export const UPDATE_INCOME_VERIFICATION_STATUS_API =
  "/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income/:action"

export const CREATE_FINICITY_CONNECT_LINK_API =
  "/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/connect-link"

export const GENERATE_INCOME_VERIFICATION_REPORT_API =
  "/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income/report"

export const GET_INCOME_VERIFICATION_API =
  "/api/v2/rental-submissions/:rentalSubmissionId/financial-verification/income"

export const ACTIONS = {
  START: "start",
  SUBMIT: "submit",
}

export const TOAST_MESSAGES = {
  SUCCESS_MESSAGE: "Success! Income verification completed",
  FAIL_MESSAGE: "Something went wrong, please try again.",
}

export const CLEAN_CONNECT_LINK = "CLEAN_CONNECT_LINK"
export const SUBMIT_INCOME_VERIFICATION = "SUBMIT_INCOME_VERIFICATION"
export const HANDLE_FINICITY_POPUP_DONE = "HANDLE_FINICITY_POPUP_DONE"
export const HANDLE_FINICITY_POPUP_ERROR = "HANDLE_FINICITY_POPUP_ERROR"

export const GET_INCOME_VERIFICATION_CALL = "GET_INCOME_VERIFICATION_CALL"
export const GET_INCOME_VERIFICATION_REQUEST = "GET_INCOME_VERIFICATION_REQUEST"
export const GET_INCOME_VERIFICATION_SUCCESS = "GET_INCOME_VERIFICATION_SUCCESS"
export const GET_INCOME_VERIFICATION_FAILED = "GET_INCOME_VERIFICATION_FAILED"

export const UPDATE_INCOME_VERIFICATION_STATUS_CALL =
  "UPDATE_INCOME_VERIFICATION_STATUS_CALL"
export const UPDATE_INCOME_VERIFICATION_STATUS_REQUEST =
  "UPDATE_INCOME_VERIFICATION_STATUS_REQUEST"
export const UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS =
  "UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS"
export const UPDATE_INCOME_VERIFICATION_STATUS_FAILED =
  "UPDATE_INCOME_VERIFICATION_STATUS_FAILED"

export const CREATE_FINICITY_CONNECT_LINK_CALL =
  "CREATE_FINICITY_CONNECT_LINK_CALL"
export const CREATE_FINICITY_CONNECT_LINK_REQUEST =
  "CREATE_FINICITY_CONNECT_LINK_REQUEST"
export const CREATE_FINICITY_CONNECT_LINK_SUCCESS =
  "CREATE_FINICITY_CONNECT_LINK_SUCCESS"
export const CREATE_FINICITY_CONNECT_LINK_FAILED =
  "CREATE_FINICITY_CONNECT_LINK_FAILED"

export const INCOME_VERIFICATION_STATUS = {
  CREATED: "created",
  STARTED: "started",
  LINKED: "linked",
  SUBMITTED: "submitted",
  READY: "ready",
  GENERATING: "generating",
  SUCCESS: "success",
  FAILED: "failed",
  OPENED: "opened",
  EXPIRED: "expired",
}

export const FINICITY_CONNECT_LINK_MOBILE_BREAKPOINT = 577
export const RE_OPEN_MODAL_PARAM = "reOpenModal"
