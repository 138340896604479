import _ from "lodash"
import { buildPath } from "@rentspree/path"
import { put, call, select, takeLatest } from "redux-saga/effects"
import urlJoin from "url-join"

import { ROUTE } from "containers/router/constants"
import { redirect } from "containers/wrapper/actions"
import {
  selectRentalId,
  selectScreeningOption,
} from "containers/rental-submission/selectors"
import { apiInstance } from "utils/api-interceptor"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"

import {
  getTUScreeningDataRequest,
  getTUScreeningDataFailed,
  getTUScreeningDataSuccess,
  GET_CREDIT_REPORT_API_URL,
  GET_TU_RENTER_API_URL,
  STATUS,
  REDIRECT_TU_ERROR_CALL,
  TU_RENTER_STATUS,
  getCreditReportRequest,
  getCreditReportSuccess,
  getCreditReportFailed,
} from "./constants"
import { GET_TU_AGREEMENT_ENDPOINT } from "./terms/constants"
import { setIsReConsent } from "./terms/actions"

export const getCreditReportApi = rentalId =>
  apiInstance.get(
    buildPath(GET_CREDIT_REPORT_API_URL, {
      rentalId,
    }),
  )

export const getTuAgreementAPI = () =>
  apiInstance.get(buildPath(GET_TU_AGREEMENT_ENDPOINT))

export const getTURenterApi = () => apiInstance.get(GET_TU_RENTER_API_URL)

export function* redirectToTuScreeningPages(
  creditReport,
  tuRenter = {},
  ssn = "",
) {
  const tuRenterStatus = _.get(tuRenter, "status")
  const creditReportStatus = _.get(creditReport, "status")
  let redirectPath = ""

  switch (creditReportStatus) {
    case STATUS.CREATED: {
      yield put(getTUScreeningDataRequest())
      const { isAccepted: isAcceptTuTerm, isReConsent } = yield call(
        getTuAgreementAPI,
      )
      if (isReConsent) yield put(setIsReConsent(isReConsent))
      if (isAcceptTuTerm) {
        redirectPath =
          tuRenterStatus === TU_RENTER_STATUS.PASS_EXAM
            ? urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.ATTACH)
            : urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.INFO)
      } else
        redirectPath = urlJoin(
          ROUTE.TU_SCREENING.BASE,
          ROUTE.TU_SCREENING.TERMS,
        )
      break
    }
    case STATUS.EXAM:
      if (!_.isEmpty(ssn)) {
        redirectPath = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.EXAM)
      } else if (tuRenterStatus === TU_RENTER_STATUS.PASS_EXAM) {
        redirectPath = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.SSN)
      } else
        redirectPath = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.INFO)
      break
    case STATUS.ERROR:
      redirectPath = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.ERROR)
      break
    case STATUS.READY:
    default:
      break
  }

  yield put(redirect(redirectPath, true))
}

export function* getTUScreeningData() {
  const rentalId = yield select(selectRentalId)
  yield put(getTUScreeningDataRequest())
  try {
    const screeningOption = yield select(selectScreeningOption)
    if (!_.get(screeningOption, "credit_report", true)) {
      yield put(redirect())
      yield put(getTUScreeningDataFailed())
    } else {
      const creditReport = yield call(getCreditReportApi, rentalId)
      const tuRenter = yield call(getTURenterApi)
      yield put(
        getTUScreeningDataSuccess({ creditReport, tuRenter: tuRenter || {} }),
      )
      yield call(redirectToTuScreeningPages, creditReport, tuRenter)
    }
  } catch (e) {
    yield put(getTUScreeningDataFailed())
    yield call(
      SweetAlert,
      ALERT_PRESET.ERROR,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
  }
}

export function* redirectTuError() {
  const errorPage = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.ERROR)
  yield put(redirect(errorPage))
}

export function* getCreditReportSaga() {
  yield put(getCreditReportRequest())
  try {
    const rentalId = yield select(selectRentalId)
    const screeningOption = yield select(selectScreeningOption)
    if (_.get(screeningOption, "credit_report")) {
      const creditReport = yield call(getCreditReportApi, rentalId)
      yield put(getCreditReportSuccess(creditReport))
    }
  } catch (err) {
    yield put(getCreditReportFailed(err))
  }
}

export function* rootSaga() {
  yield takeLatest(REDIRECT_TU_ERROR_CALL, redirectTuError)
}

export default rootSaga
