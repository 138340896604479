import { TYPES } from "./constants"

const setActive = payload => ({
  type: TYPES.SET_ACTIVE_ID,
  payload,
})

const resetActive = () => ({
  type: TYPES.RESET_ACTIVE,
})

const setChildrenBox = payload => ({ type: TYPES.SET_CHILDREN_BOX, payload })

export { setActive, resetActive, setChildrenBox }
