import filter from "lodash/filter"
import get from "lodash/get"
import map from "lodash/map"
import isNil from "lodash/isNil"

export const filterCoApplicant = (coApplicants, type) =>
  filter(coApplicants, coApplicant => get(coApplicant, "type") === type)

// TODO: Should change to ApplyLink if app service v2 change schema or retrieved param
export const mapShareLeaseLink = (coApplicants, type) => {
  const filteredData = filterCoApplicant(coApplicants, type)
  return map(filteredData, data => {
    const returnData = { ...data }
    if (isNil(data.shareLeaseLink)) {
      returnData.shareLeaseLink = true
    }
    return returnData
  })
}
