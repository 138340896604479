import React from "react"
import styled from "styled-components"
import { S16 } from "@rentspree/component-v2"

import { SignatureText } from "components/drag-and-drop/sign-box"

import { COLOR } from "styles/settings"

export const InfoBoxV2 = styled.div`
  &.primary {
    height: 100%;
    overflow: auto;
    background: ${COLOR.bgGrey};
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
  }
  .preview {
    margin: 0px;
    color: ${COLOR.placeholderGrey};
  }
`

export const Wrapper = styled.div`
  padding: 0;
`

export const WrapperSignature = styled.div`
  display: block;
  height: 47px;
  width: 100%;
  margin-bottom: 10px;
  .sign-text {
    margin: 5px 0px;
    white-space: nowrap;
  }
`

const PreviewSignature = ({ signature, initial }) => (
  <Wrapper>
    <InfoBoxV2 className="primary">
      <WrapperSignature className="wrapper">
        <S16 className="preview" weight="400" size="12px">
          Preview Signature
        </S16>
        <SignatureText signature={signature} size="20px" />
      </WrapperSignature>
      <WrapperSignature className="wrapper" md={4}>
        <S16 className="preview" weight="400" size="12px">
          Preview Initials
        </S16>
        <SignatureText signature={initial} size="20px" />
      </WrapperSignature>
    </InfoBoxV2>
  </Wrapper>
)

export default PreviewSignature
