import _ from "lodash"
import { createSelector } from "reselect"
import { fromJS } from "immutable"

export const mapperChoices = (choices = []) =>
  choices.map(c => {
    const label = _.get(c, "Text")
    const id = _.get(c, "Id")
    const value = id
    return {
      label,
      id,
      value,
    }
  })

export const mapperQuestion = (questions = []) =>
  questions.map(q => {
    const title = _.get(q, "Text")
    const id = _.get(q, "Id")
    return {
      id,
      title,
      choices: mapperChoices(_.get(q, "Answers")),
      selected: null,
    }
  })

export const selectTuExam = state =>
  state
    .get("tuScreening")
    .get("creditReport")
    .get("exam", fromJS({}))

export const makeSelectQuestion = () =>
  createSelector(selectTuExam, substate => {
    const exam = substate.toJS()
    return mapperQuestion(_.get(exam, "Questions"))
  })
export const makeSelectExam = () =>
  createSelector(selectTuExam, substate => substate.toJS())
