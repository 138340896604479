import cloneDeep from "lodash/cloneDeep"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import isObject from "lodash/isObject"
import map from "lodash/map"
import merge from "lodash/merge"
import moment from "moment"
import { getParser } from "containers/applicant-info/map-to-api"
import { CO_APPLICANT_TYPE } from "containers/applicant-info/constants"

export const mapCoApplicantFromAPI = coApplicants =>
  map(coApplicants, coApplicant => ({
    ...coApplicant,
    ...(get(coApplicant, "dateOfBirth") && {
      dateOfBirth: moment
        .utc(get(coApplicant, "dateOfBirth"))
        .format("YYYY-MM-DD"),
    }),
  }))

const mergeDraftDataIntoRentalSubmission = rentalSubmission => {
  const parsedDraftData = getParser(rentalSubmission.draft.parser)(
    rentalSubmission.draft.data,
  )
  const mergedRental = merge(cloneDeep(rentalSubmission), parsedDraftData)

  // check if there is a persisted coApplicant by checking the object id
  const hasCompletedGuarantors = rentalSubmission.coApplicants.some(
    coApplicant =>
      // eslint-disable-next-line no-underscore-dangle
      coApplicant._id && coApplicant.type === CO_APPLICANT_TYPE.COSIGNER,
  )
  const hasCompletedTenants = rentalSubmission.coApplicants.some(
    coApplicant =>
      // eslint-disable-next-line no-underscore-dangle
      coApplicant._id && coApplicant.type === CO_APPLICANT_TYPE.OCCUPANT,
  )

  const shouldMergeCoApplicants =
    (!hasCompletedGuarantors &&
      rentalSubmission.draft?.data.haveGuarantors === "yes") ||
    (!hasCompletedTenants && rentalSubmission.draft?.data.haveTenants === "yes")

  mergedRental.coApplicants = [
    ...rentalSubmission.coApplicants,
    ...(shouldMergeCoApplicants ? parsedDraftData.coApplicants : []),
  ]

  return mergedRental
}

export const parseFromAPI = (rentalSubmission, isMergeDraft = false) => {
  if (!isObject(rentalSubmission)) {
    return { id: "-1" }
  }

  let mergedRental = { ...rentalSubmission }
  if (isMergeDraft && rentalSubmission.draft) {
    mergedRental = mergeDraftDataIntoRentalSubmission(rentalSubmission)
  }

  mergedRental = {
    ...mergedRental,
    moveDate: !isEmpty(mergedRental.moveDate)
      ? moment.utc(mergedRental.moveDate).format("YYYY-MM-DD")
      : "",
    monthlyRent: mergedRental?.monthlyRent?.toString() ?? "",
    coApplicants: mapCoApplicantFromAPI(get(mergedRental, "coApplicants")),
  }
  return mergedRental
}
