import React from "react"
import { compose } from "redux"
import _ from "lodash"
import { Statsig } from "statsig-react"

import InstructionPage from "./rental-instruction"
import GuidePage from "./rental-guide"
import { isInstruction } from "./constants"
import { withConnect } from "./connect"
import { ApplyLinkExpired } from "./apply-link-expired"
/* eslint-disable react/prefer-stateless-function */
export class RentalWrapper extends React.Component {
  render() {
    const { rentalSubmission, screeningOption } = this.props
    const isPremiumApplyLinkEnabled = Statsig.checkGate(
      "premium_screening_applylink",
    )
    const shouldShowExpiredPage =
      screeningOption?.premium && !isPremiumApplyLinkEnabled

    if (shouldShowExpiredPage) {
      return <ApplyLinkExpired />
    }

    return (
      <div>
        {!_.isEmpty(rentalSubmission) && (
          <div>
            {isInstruction(
              _.get(rentalSubmission, "status"),
              _.get(rentalSubmission, "oldVersion"),
              // TODO: INIT-649 cleanup
              _.get(rentalSubmission, "isPrePaymentRequired"),
              _.get(rentalSubmission, "payment.status"),
            ) ? (
              <InstructionPage />
            ) : (
              <GuidePage />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default compose(withConnect)(RentalWrapper)
