export const SET_SSN = "SET_SSN"
export const CALL_UPDATE_SSN = "CALL_UPDATE_SSN"
export const CLEAR_SSN = "CLEAR_SSN"
export const TU_100211_ERROR_ID = "tuExamWrongSSN"

export const setSSN = payload => ({
  type: SET_SSN,
  payload,
})

export const clearSSN = () => ({
  type: CLEAR_SSN,
})
