import produce from "immer"

import {
  GET_TU_AGREEMENT_CONTENT_REQUESTED,
  GET_TU_AGREEMENT_CONTENT_SUCCESS,
  GET_TU_AGREEMENT_CONTENT_FAILED,
  SET_IS_RE_CONSENT,
} from "./constants"

export const initialState = {
  agreement: null,
  isReConsent: false,
  isFetching: true,
  errors: null,
}

/* eslint-disable default-case, no-param-reassign */
export const agreementReducer = (
  state = initialState,
  { type, response = {}, errors = {} },
) =>
  produce(state, draft => {
    switch (type) {
      case GET_TU_AGREEMENT_CONTENT_REQUESTED:
        draft.isFetching = true
        draft.errors = null
        break
      case GET_TU_AGREEMENT_CONTENT_SUCCESS:
        draft.agreement = response
        draft.isFetching = false
        break
      case GET_TU_AGREEMENT_CONTENT_FAILED:
        draft.isFetching = false
        draft.errors = errors
        break
      case SET_IS_RE_CONSENT:
        draft.isReConsent = !!response
        break
      default:
        break
    }
    return draft
  })

export default agreementReducer
