import _ from "lodash"
import { getPaymentCall, submitPaymentCall } from "./constants"

// Get payment
export function getPayment({ provider, multishare }) {
  return getPaymentCall({ provider, multishare })
}

/**
 * Submit payment with payment object
 * @param payment the payment object to be submitted
 * @param stripe the stripe object
 * @param cardElement the stripe card html element that stripe rendered
 * @returns function to dispatch submit payment api call
 */
export function submitPayment(payment, stripe, cardElement) {
  if (!_.isEmpty(payment) && stripe && cardElement) {
    return submitPaymentCall({
      payment,
      stripe,
      cardElement,
    })
  }
  return false
}
