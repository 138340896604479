import React from "react"
import get from "lodash/get"
import styled from "styled-components"
import { compose } from "redux"
import track from "react-tracking"
import { trackPageView } from "tracker"

import NewTuErrorPageTemplate from "components/templates/new-tu-error-page"
import TuErrorPageTemplate from "components/templates/tu-error-page"
import selectContent from "./constants"
import { withConnect, withSaga } from "./connect"

const Wrapper = styled.div`
  .detail {
    margin: 0 0 4px;
    line-height: 1.5;
  }
`

export const ErrorPageTemplate = ({ isNewTemplate, integrationId, ...props }) =>
  isNewTemplate ? (
    <NewTuErrorPageTemplate bodyArgs={[integrationId]} {...props} />
  ) : (
    <TuErrorPageTemplate {...props} />
  )

export class ErrorPage extends React.Component {
  state = {
    content: selectContent(this.props.code),
  }

  componentDidMount() {
    this.handleTrackPageView(this.props)
  }

  componentDidUpdate(prevProps) {
    const { code: nextCode } = this.props
    const { code: prevCode } = prevProps
    if (nextCode !== prevCode) {
      this.handleTrackPageView(this.props)
      this.setContent(nextCode)
    }
  }

  handleTrackPageView(props) {
    if (props.pageName) {
      trackPageView(props.pageName, get(props, "match.path"))
    }
  }

  setContent = code => {
    this.setState({ content: selectContent(code) })
  }

  render() {
    const { content } = this.state
    return (
      <Wrapper>
        <ErrorPageTemplate
          isNewTemplate={content.newTemplate}
          content={content}
          backToGuide={() => this.props.actions.redirect()}
          startNewScreening={this.props.actions.startNewScreening}
          {...this.props}
        />
      </Wrapper>
    )
  }
}

export default compose(
  track(),
  withConnect,
  withSaga,
)(ErrorPage)
