import React from "react"
import styled from "styled-components"

import {
  MAIN_PANEL_HEIGHT,
  MAIN_PANEL_WIDTH,
  MAIN_PANEL_SHADOW,
  MAIN_PANEL_RADIUS,
  MAIN_PANEL_PADDING,
  MAIN_PANEL_PADDING_MOBILE,
} from "styles/settings"

export const StyledMainPanel = styled.div`
  background-color: white;
  box-shadow: ${MAIN_PANEL_SHADOW};
  min-height: ${MAIN_PANEL_HEIGHT};
  width: ${MAIN_PANEL_WIDTH};
  position: relative;
  border-radius: ${MAIN_PANEL_RADIUS};
  padding: ${MAIN_PANEL_PADDING};

  @media (max-width: 767px) {
    width: 100%;
    min-height: 0px;
    box-shadow: none;
    padding: ${MAIN_PANEL_PADDING_MOBILE};
    border-radius: 0px;
  }
`

export const MainPanel = ({ children }) => (
  <StyledMainPanel>{children}</StyledMainPanel>
)
