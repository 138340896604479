import { css } from "styled-components"

export const COLOR = {
  // facebook
  facebookBlue: "#4565a0",
  darkFacebookBlue: "#2a477c",
  // button , link to Facebook and Google sign up , Forget Password
  brightBlue: "#3b99fc",
  skyBlue: "#7cd5ff",
  // hover button and link
  waterBlue: "#146dca",
  // login with different account , tell us about your self page
  brightRoyalBLue: "#4b97f5",
  // user pic background
  veryLightSteelBlue: "#c3cbdf",
  // checkbox selected
  lightDeepBlue: "rgba(59, 153, 252, 0.1)",
  // google
  googleYellow: "#f29900",

  veryLightGray: "#f1f1f1",
  lineGray: "#e5e5e5",
  lightGray: "#aaaaaa",
  gray: "#888888",
  darkGray: "#4a4a4a",
  darkerGray: "#333333",
  grayStroke: "#d6d6d6",
  borderGray: "rgba(0, 0, 0, 0.23)",
  shadowGray: "rgba(0, 0, 0, 0.08)",

  black: "#000000",
  white: "#ffffff",
  whiteTwo: "#f9f9f9",

  softRed: "#f15555",
  lightWhiteRed: "#ffe9e9",
  lightSoftRed: "rgba(241, 85, 85, 0.1)",

  green: "#80c640",
  darkGreen: "#67A033",
  lightWhiteGreen: "#e6ffcf",
  springGreen: "#b6f67b",

  yellow: "#d3b84d",
  darkYellow: "#ac932d",
  lightSoftYellow: "#fef5d9",

  deepBluePale: "#e2f0ff",

  whiteStart: "rgba(255, 255, 255, 0)",
  whiteEnd: "rgba(255, 255, 255, 1)",

  greenLemon: "#89c43b",
  carrotOrange: "#ff8a23",

  borderGrey: " #e5e5e5",

  grayFade: "#eceeef",
  lightBlueHEX: "#E2F0FF",
  lightBlueRGB: "226, 240, 255",
  lightBlueRGBA: "226, 240, 255, 0.9",

  // new credit-report
  fontBlue: "#3b99fc",
  grayLight: "#f9f9f9",
  fontRed: "#f15555",
  fontOrange: "#ffa65e",
  fontYellowDeep: "#a7903f",
  fontRedDeep: "#a33434",

  selectionGrey: "#F8F8F8",
  placeholderGrey: "#AAAAAA",

  bgGreyRGB: "241, 241, 241",

  // new rentspree color
  newDeepBlue: "#007ADF",
  greenCyan: "#00ECBC",
  cyanBlue: "#546A84",
  lightHawaiiBlue: "#00C9E5",

  darkNegativeRed: "#a72f31",
  negativeRed: "#F15555",
  lightRed: "rgba(255, 233, 233, 0.9)",
  redPale: "#FFE9E9",
  highlightRed: "#D43737",

  darkBlue: "rgb(50, 120, 179)",
  blue: "rgb(108, 164, 210)",
  lightBlue: "rgb(153, 209, 255)",
  steelBlue: "#37495F",
  darkSteelBlue: "rgb(39, 50, 66)",
  darkSteelBlue50: "rgba(39, 50, 66, 0.5)",
  darkDeepBlue: "rgb(29, 102, 206)",
  deepBlue: "rgb(61, 138, 247)",
  whiteBlue: "#308DCE",
  activeBlue: " #3B99FC",
  blueLV2: "#004BB0",
  blueLV3: "#195DB8",
  blueLV5: "#3B99FC",
  blueLV6: "#E2F0FF",

  // CAA
  newBlack: "#4A4A4A",
  newGrey: "#E5E5E5",

  lineGrey: "#D8D8D8",
  bgGrey: "#F1F1F1",
  textBlack: "#333333",

  mintGreen: "#00d5a9",
  newOrange: "#FF8A23",
  textBlue: "#0062B3",
}

export const DARK_GRADIENT = {
  color1: "#748ba2",
  color2: "#738aa1 11%",
  color3: "#71889f 21%",
  color4: "#70869d 28%",
  color5: "#6e849b 34%",
  color6: "#6b8198 38%",
  color7: "#697e95 41%",
  color8: "#667b92 44%",
  color9: "#63788e 46%",
  color10: "#5f748a 47%",
  color11: "#5c7187 48%",
  color12: "#586d83 50%",
  color13: "#55697f 52%",
  color14: "#51657b 55%",
  color15: "#4e6177 59%",
  color16: "#4a5e74 63%",
  color17: "#475b70 70%",
  color18: "#44586d 78%",
  color19: "#42556b 88%",
  color20: "#405369",
}

export const ANIMATE = {
  FAST: "0.2s",
  NORMAL: "0.5s",
  SLOW: "1s",
}

export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const INLINE_FLEX_CENTER = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const MAIN_PANEL_SHADOW = "0 5px 20px 0 rgba(0, 0, 0, 0.23)"
export const MAIN_PANEL_WIDTH = "720px"
export const MAIN_PANEL_HEIGHT = "486px"
export const MAIN_PANEL_RADIUS = "10px"
export const MAIN_PANEL_PADDING = "40px 53px"
export const MAIN_PANEL_PADDING_MOBILE = "0 20px 20px 20px"

export const TabletAndMobileWidth = "991px"

export const BOX_SHADOW_1 = "0 2px 7px 0 rgba(0, 0, 0, 0.1)"
export const BOX_SHADOW_2 = "0 2px 12px 0 rgba(0, 0, 0, 0.2)"
export const BOX_SHADOW_3 = "0px 2px 15px rgba(0, 0, 0, 0.25)"
export const BOX_SHADOW_TOP_1 = "0 -2px 7px 0 rgba(0, 0, 0, 0.1)"
export const DROPDOWN_SHADOW = "0 0 20px 0 rgba(0, 0, 0, 0.15)"

export const DIAMOND_LINEAR_GRADIENT = `linear-gradient(rgba(249, 249, 249, 0.5) 0%, ${
  COLOR.grayLight
})`

export const TOPBAR_HEIGHT = "70px"
export const TOPBAR_HEIGHT_WO_PIXEL = 70

export const colorShades = [
  {
    bgRgb: "226, 240, 255",
    border: COLOR.fontBlue,
    content: COLOR.fontBlue,
  }, // 1
  {
    bgRgb: "254, 245, 217",
    border: COLOR.yellow,
    content: COLOR.yellow,
  }, // 2
  {
    bgRgb: "221, 255, 248",
    border: COLOR.mintGreen,
    content: COLOR.mintGreen,
  }, // 3
  {
    bgRgb: "255, 238, 223",
    border: COLOR.newOrange,
    content: COLOR.newOrange,
  }, // 4
  {
    bgRgb: "224, 251, 255",
    border: COLOR.lightHawaiiBlue,
    content: COLOR.lightHawaiiBlue,
  }, // 5
  {
    bgRgb: "255, 233, 233",
    border: COLOR.fontRed,
    content: COLOR.fontRed,
  }, // 6
  {
    bgRgb: "230, 255, 207",
    border: COLOR.green,
    content: COLOR.green,
  }, // 7
  {
    bgRgb: "241, 241, 241",
    border: COLOR.placeholderGrey,
    content: COLOR.placeholderGrey,
  }, // 8
]
export const SIDEBAR_WIDTH = "175px"
export const SIDEBAR_COLLAPSE_WIDTH = "46px"
export const SIDEBAR_IPAD_WIDTH = "56px"
export const RIGHT_PANEL_WIDTH = "320px"
export const BREADCRUMBS_HEIGHT = "45px"
export const REPORT_TABS_HEIGHT = "50px"
export const REPORT_PADDING = "60px"
export const FILTER_HEIGHT = "40px"
export const BORDER_RADIUS = "5px"
export const MOBILE_SIDEBAR_HEIGHT = "60px"
export const SMALL_HEADER_HEIGHT = "60px"
export const MOBILE_BREADCRUMBS_HEIGHT = "45px"
export const MOBILE_TAB_HEIGHT = "50px"
export const MOBILE_ACCEPT_DENY_HEIGHT = "45px"
export const GET_STARTED_WIDTH = "375px"

export const breakpoints = {
  mobile: styles => `
    @media (max-width: ${TabletAndMobileWidth}) {
      ${styles}
    }
  `,
  mobileContact: styles => `
  @media (max-width: 1223px) {
    ${styles}
  }
`,
  mobileApplication: styles => `
  @media (max-width: 676px) {
    ${styles}
  }
`,
  mobileApplicationWithoutKeyboard: styles => `
  @media (max-width: 676px) and (min-height: 700px) {
    ${styles}
  }
`,
}
