import React from "react"
import styled from "styled-components"
import { TOAST_POSITION } from "../../../containers/toast/constants"
import Toast from "../../atoms/toast"
import { breakpoints } from "../../../styles/settings"

const {
  TOP_LEFT,
  TOP_CENTER,
  TOP_CENTER_EDGE,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
} = TOAST_POSITION

const Floater = styled.div`
  position: fixed;
  z-index: 10001;
  display: flex;
  width: ${({ width }) => width || "350px"};
  flex-direction: column;
  ${({ position }) => toastFloatPositionStyle(position)} ${breakpoints.mobile(`
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    left: 0;
    transform: translateX(0);
  `)};
`

const ToastFloater = ({ position, toasts, onClose }) => (
  <Floater
    data-testid="toast-floater"
    position={position}
    width={toasts[0]?.width}>
    {toasts?.map(toast => (
      <Toast
        key={toast.id}
        position={position}
        onClose={() => onClose(toast.id)}
        {...toast}
      />
    ))}
  </Floater>
)

export default ToastFloater

const toastFloatPositionStyle = position => {
  switch (position) {
    case BOTTOM_LEFT:
      return `
        bottom: 20px;      
        left: 20px;
      `
    case BOTTOM_CENTER:
      return `
        bottom: 20px;      
        left: 50%;
        transform: translateX(-50%);
      `
    case BOTTOM_RIGHT:
      return `
        bottom: 20px;      
        right: 20px;
      `
    case TOP_LEFT:
      return `
        top: 10px;      
        left: 20px;
      `
    case TOP_RIGHT:
      return `
        top: 10px;      
        right: 20px;
      `
    case TOP_CENTER_EDGE:
      return `
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      `
    case TOP_CENTER:
    default:
      return `
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
      `
  }
}
