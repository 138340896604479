import React from "react"
import get from "lodash/get"
import { T36 } from "@rentspree/component-v2"
import { withTracker, PAGE_NAME, tracker } from "tracker"
import usePageViewedTracker from "tracker/hooks/use-page-viewed-tracker"

import AdditionalApplicantForm from "./form"
import { withConnect } from "../connect"
import { APPLICANT_TYPE } from "../constants"

export const AdditionalApplicantContainer = props => {
  const applicantType = get(props.rental, "applicant_type")

  const isGuarantor = applicantType === APPLICANT_TYPE.GUARANTOR

  usePageViewedTracker({
    routeMatch: props.match,
    routeLocation: props.location,
    windowLocation: window.location,
    domDocument: document,
  })

  return (
    <div>
      <T36 margin="50px 0 30px">Additional Applicant</T36>
      <AdditionalApplicantForm {...props} isGuarantor={isGuarantor} />
    </div>
  )
}

export default withConnect(
  withTracker(
    AdditionalApplicantContainer,
    tracker,
    PAGE_NAME.APPLICANT.TENANTS,
  ),
)
