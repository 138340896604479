import produce from "immer"
import forEach from "lodash/forEach"
import concat from "lodash/concat"
import isArray from "lodash/isArray"

import {
  CLEAR_FILE_REJECTED_MESSAGES,
  CLEAR_IN_PROGRESS_FILES,
  DELETE_DOCUMENT_FILE_FAILED,
  DELETE_DOCUMENT_FILE_REQUEST,
  DELETE_DOCUMENT_FILE_SUCCESS,
  GET_DOCUMENT_SET_SUCCESS,
  GET_DRAFT_DOCUMENT_SUCCESS,
  REJECT_SELECTED_FILES,
  SAVE_UPLOADED_DOCUMENT_FILE_FAILED,
  SAVE_UPLOADED_DOCUMENT_FILE_REQUEST,
  SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS,
  SET_UPLOADING_FILE_PROGRESS,
  UPLOAD_DOCUMENT_FILE_FAILED,
  UPLOAD_DOCUMENT_FILE_REQUEST,
  SUBMIT_RENTAL_SUBMISSION_REQUEST,
  SUBMIT_RENTAL_SUBMISSION_SUCCESS,
  SUBMIT_RENTAL_SUBMISSION_FAILED,
  REPLACE_DOCUMENT_REQUEST,
  REPLACE_DOCUMENT_SUCCESS,
  REPLACE_DOCUMENT_FAILED,
  LANDING_DOCUMENT_UPLOAD_REQUEST,
  LANDING_DOCUMENT_UPLOAD_SUCCESS,
  LANDING_DOCUMENT_UPLOAD_FAILED,
} from "./constants"

export const initialState = {
  isSubmitting: false,
  isReplacingDocument: false,
  isLoading: false,
  isRenderIdUpload: false,
  documents: [],
  id: "",
  rentalSubmissionId: "",
  status: "",
}

/* eslint-disable no-param-reassign */
const documentUploadReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload, ...requestArg } = action
    const requestPayload = requestArg?.[0] || {}
    switch (type) {
      case GET_DOCUMENT_SET_SUCCESS:
      case GET_DRAFT_DOCUMENT_SUCCESS:
        draft.id = payload.id
        draft.rentalSubmissionId = payload.rentalSubmissionId
        draft.status = payload.status
        draft.documents = payload.documents
        break
      case REPLACE_DOCUMENT_REQUEST:
        draft.isReplacingDocument = true
        break
      case REPLACE_DOCUMENT_SUCCESS:
        draft.id = payload.id
        draft.rentalSubmissionId = payload.rentalSubmissionId
        draft.status = payload.status
        draft.documents = payload.documents
        draft.isReplacingDocument = false
        break
      case REPLACE_DOCUMENT_FAILED:
        draft.isReplacingDocument = false
        break
      case UPLOAD_DOCUMENT_FILE_REQUEST:
        forEach(draft.documents, doc => {
          if (doc?.id === requestPayload?.documentId) {
            doc?.files?.push({
              tempId: requestPayload?.fileTempId,
              originalName: requestPayload?.file.name,
              isUploading: true,
              uploadPercent: 0,
            })
          }
        })
        break
      case SAVE_UPLOADED_DOCUMENT_FILE_REQUEST:
        forEach(draft.documents, doc => {
          if (doc?.id === requestPayload?.documentId) {
            forEach(doc.files, file => {
              const matchFile = requestPayload?.files.find(
                ({ tempId }) => tempId === file.tempId,
              )
              if (matchFile) {
                file.isSaving = true
              }
            })
          }
        })
        break
      case SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            forEach(doc.files, (file, i) => {
              const matchFile = payload?.files.find(
                ({ tempId }) => tempId === file.tempId,
              )
              if (matchFile) doc.files[i] = matchFile
            })
          }
        })
        break
      case SET_UPLOADING_FILE_PROGRESS:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            forEach(doc.files, file => {
              if (file.tempId === payload?.fileTempId) {
                file.uploadPercent = payload?.uploadPercent
              }
            })
          }
        })
        break
      case SAVE_UPLOADED_DOCUMENT_FILE_FAILED:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            doc.files = doc.files.filter(file => file.id)
          }
        })
        break
      case UPLOAD_DOCUMENT_FILE_FAILED:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            doc.files = doc.files.filter(
              file => file.tempId !== payload?.fileTempId,
            )
          }
        })
        break
      case DELETE_DOCUMENT_FILE_REQUEST:
        forEach(draft.documents, doc => {
          if (doc?.id === requestPayload?.documentId) {
            forEach(doc.files, file => {
              if (file.id === requestPayload?.fileId) {
                file.isDeleting = true
              }
            })
          }
        })
        break
      case DELETE_DOCUMENT_FILE_SUCCESS:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            doc.files = doc.files.filter(file => file.id !== payload?.fileId)
          }
        })
        break
      case DELETE_DOCUMENT_FILE_FAILED:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            forEach(doc.files, file => {
              if (file.id === payload?.fileId) {
                file.isDeleting = false
              }
            })
          }
        })
        break
      case REJECT_SELECTED_FILES:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            if (isArray(doc.errorMessages))
              doc.errorMessages = concat(
                doc.errorMessages,
                payload.errorMessages,
              )
            else doc.errorMessages = [...payload.errorMessages]
          }
        })
        break
      case CLEAR_FILE_REJECTED_MESSAGES:
        forEach(draft.documents, doc => {
          if (doc?.id === payload?.documentId) {
            doc.errorMessages = []
          }
        })
        break
      case CLEAR_IN_PROGRESS_FILES:
        forEach(draft.documents, doc => {
          // filter out all in progress file (status == false)
          doc.files = doc.files.filter(file =>
            [file.isUploading, file.isSaving, file.isDeleting].every(
              status => !status,
            ),
          )
        })
        break
      case SUBMIT_RENTAL_SUBMISSION_REQUEST:
        draft.isSubmitting = true
        break
      case SUBMIT_RENTAL_SUBMISSION_SUCCESS:
        draft.isSubmitting = false
        break
      case SUBMIT_RENTAL_SUBMISSION_FAILED:
        draft.isSubmitting = false
        break
      case LANDING_DOCUMENT_UPLOAD_REQUEST:
        draft.isLoading = true
        break
      case LANDING_DOCUMENT_UPLOAD_SUCCESS:
        draft.isLoading = false
        draft.isRenderIdUpload = payload.isRenderIdUpload
        break
      case LANDING_DOCUMENT_UPLOAD_FAILED:
        draft.isLoading = false
        break
      default:
        break
    }
    return draft
  })

export default documentUploadReducer
