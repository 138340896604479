import moment from "moment"

export const stepPersonal = data => ({
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName,
  dateOfBirth: data.dob ? moment(data.dob).format("YYYY-MM-DD") : "",
  phoneNumber: data.phoneNumber,
  renter_id: data.renter_id,
})

export const stepEmergency = (emergencies = []) => ({
  emergencyContacts: emergencies.map(v => ({
    firstName: v.firstName,
    lastName: v.lastName,
    email: v.email,
    relationship: v.relationship,
    phoneNumber: v.phoneNumber,
    streetAddress: v.streetAddress,
    city: v.city,
    state: v.state,
    zipCode: v.zipcode,
    isSkipEmail: v.isSkipEmail,
    region: v.region,
  })),
})

export const stepGovernment = government => ({
  governmentId: {
    photoType: government.photoType,
    idNum: government.idNum,
    issueBy: government.issueBy,
    expiredDate: government.expiredDate,
  },
})
