import React from "react"

import ripplesSVG from "images/gifs/ripple.svg"

import * as Styled from "./main-style"

export const Loading = ({ ...props }) => (
  <Styled.loading {...props}>
    <img src={ripplesSVG} alt="loading" />
  </Styled.loading>
)
