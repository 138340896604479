import { TU_CONTACT_KB_URL } from "env"

export const GET_TU_DATA_CALL = "GET_TU_DATA_CALL"
export const GET_TU_DATA_REQUEST = "GET_TU_DATA_REQUEST"
export const GET_TU_DATA_SUCCESS = "GET_TU_DATA_SUCCESS"
export const GET_TU_DATA_FAILED = "GET_TU_DATA_FAILED"

export const ACCEPT_TERMS_CALL = "ACCEPT_TERMS_CALL"
export const ACCEPT_TERMS_REQUEST = "ACCEPT_TERMS_REQUEST"
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS"
export const ACCEPT_TERMS_FAILED = "ACCEPT_TERMS_FAILED"

export const START_NEW_SCREENING_CALL = "START_NEW_SCREENING_CALL"
export const START_NEW_SCREENING_REQUEST = "START_NEW_SCREENING_REQUEST"
export const START_NEW_SCREENING_SUCCESS = "START_NEW_SCREENING_SUCCESS"
export const START_NEW_SCREENING_FAILED = "START_NEW_SCREENING_FAILED"

export const EXAM_RETRIEVE_CALL = "EXAM_RETRIEVE_CALL"
export const EXAM_RETRIEVE_REQUEST = "EXAM_RETRIEVE_REQUEST"
export const EXAM_RETRIEVE_SUCCESS = "EXAM_RETRIEVE_SUCCESS"
export const EXAM_RETRIEVE_FAILED = "EXAM_RETRIEVE_FAILED"
export const REPEATED_EXAM_RETRIEVE_REQUEST = "REPEATED_EXAM_RETRIEVE_REQUEST"

export const EXAM_EVALUATE_CALL = "EXAM_EVALUATE_CALL"
export const EXAM_EVALUATE_REQUEST = "EXAM_EVALUATE_REQUEST"
export const EXAM_EVALUATE_SUCCESS = "EXAM_EVALUATE_SUCCESS"
export const EXAM_EVALUATE_FAILED = "EXAM_EVALUATE_FAILED"

export const ATTACH_REPORT_CALL = "ATTACH_REPORT_CALL"
export const ATTACH_REPORT_REQUEST = "ATTACH_REPORT_REQUEST"
export const ATTACH_REPORT_SUCCESS = "ATTACH_REPORT_SUCCESS"
export const ATTACH_REPORT_FAILED = "ATTACH_REPORT_FAILED"

export const GET_CREDIT_REPORT_CALL = "GET_CREDIT_REPORT_CALL"
export const GET_CREDIT_REPORT_REQUEST = "GET_CREDIT_REPORT_REQUEST"
export const GET_CREDIT_REPORT_SUCCESS = "GET_CREDIT_REPORT_SUCCESS"
export const GET_CREDIT_REPORT_FAILED = "GET_CREDIT_REPORT_FAILED"

export const REDIRECT_TU_ERROR_CALL = "REDIRECT_TU_ERROR_CALL"
export const UPDATE_TU_RENTER_CALL = "UPDATE_TU_RENTER_CALL"

export const getTUScreeningDataCall = () => ({ type: GET_TU_DATA_CALL })
export const getTUScreeningDataRequest = () => ({ type: GET_TU_DATA_REQUEST })
export const getTUScreeningDataSuccess = payload => ({
  type: GET_TU_DATA_SUCCESS,
  payload,
})
export const getTUScreeningDataFailed = () => ({ type: GET_TU_DATA_FAILED })

export const acceptTermsCall = payload => ({ type: ACCEPT_TERMS_CALL, payload })
export const acceptTermsRequest = () => ({ type: ACCEPT_TERMS_REQUEST })
export const acceptTermsSuccess = payload => ({
  type: ACCEPT_TERMS_SUCCESS,
  payload,
})
export const acceptTermsFailed = () => ({ type: ACCEPT_TERMS_FAILED })

export const startNewScreeningCall = payload => ({
  type: START_NEW_SCREENING_CALL,
  payload,
})
export const startNewScreeningRequest = () => ({
  type: START_NEW_SCREENING_REQUEST,
})
export const startNewScreeningSuccess = payload => ({
  type: START_NEW_SCREENING_SUCCESS,
  payload,
})
export const startNewScreeningFailed = payload => ({
  type: START_NEW_SCREENING_FAILED,
  payload,
})

export const getCreditReportCall = () => ({
  type: GET_CREDIT_REPORT_CALL,
})

export const getCreditReportRequest = () => ({
  type: GET_CREDIT_REPORT_REQUEST,
})
export const getCreditReportSuccess = payload => ({
  type: GET_CREDIT_REPORT_SUCCESS,
  payload,
})
export const getCreditReportFailed = payload => ({
  type: GET_CREDIT_REPORT_FAILED,
  payload,
})

export const postExamCall = () => ({ type: EXAM_RETRIEVE_CALL })
export const examRetrieveRequest = () => ({ type: EXAM_RETRIEVE_REQUEST })
export const repeatedExamRetrieveCall = payload => ({
  type: REPEATED_EXAM_RETRIEVE_REQUEST,
  payload,
})
export const examRetrieveSuccess = payload => ({
  type: EXAM_RETRIEVE_SUCCESS,
  payload,
})
export const examRetrieveFailed = payload => ({
  type: EXAM_RETRIEVE_FAILED,
  payload,
})

export const examEvaluateCall = payload => ({
  type: EXAM_EVALUATE_CALL,
  payload,
})
export const examEvaluateRequest = () => ({ type: EXAM_EVALUATE_REQUEST })
export const examEvaluateSuccess = payload => ({
  type: EXAM_EVALUATE_SUCCESS,
  payload,
})
export const examEvaluateFailed = () => ({ type: EXAM_EVALUATE_FAILED })

export const attachReportCall = payload => ({
  type: ATTACH_REPORT_CALL,
  payload,
})
export const attachReportRequest = () => ({ type: ATTACH_REPORT_REQUEST })
export const attachReportSuccess = payload => ({
  type: ATTACH_REPORT_SUCCESS,
  payload,
})
export const attachReportFailed = () => ({ type: ATTACH_REPORT_FAILED })

export const redirectTuErrorCall = () => ({ type: REDIRECT_TU_ERROR_CALL })

export const updateTuRenterCall = payload => ({
  type: UPDATE_TU_RENTER_CALL,
  payload,
})

export const GET_CREDIT_REPORT_API_URL =
  "api/v2/rental-submissions/:rentalId/credit-report"
export const START_NEW_SCREENING_API_URL = `${GET_CREDIT_REPORT_API_URL}/start`
export const SKIP_PAYMENT_PROCESS_API_URL = `${GET_CREDIT_REPORT_API_URL}/skip-payment`
export const GET_TU_RENTER_API_URL = "api/v2/renters/tu"

export const POST_EXAM_RETRIEVE = `${GET_CREDIT_REPORT_API_URL}/exam/retrieve`
export const POST_EXAM_EVALUATE = `${GET_CREDIT_REPORT_API_URL}/exam/evaluate`
export const TU_STEP = [
  {
    title: "Service Agreement",
    key: "terms",
  },
  {
    title: "Screening Info",
    key: "info",
  },
  {
    title: "Verification",
    key: "verify",
  },
]

export const TU_STEP_MAP = {
  TERMS: "terms",
  INFO: "info",
  INSTRUCTION: "verify",
  IDENTITY: "info",
  ATTACH: "info",
  VERIFY: "verify",
}

export const TU_ERROR_CODE = {
  FAIL_EXAM: "fail_exam",
}

export const STATUS = {
  CREATED: "created",
  EXAM: "exam",
  READY: "ready",
  ERROR: "error",
}

export const TU_RENTER_STATUS = {
  CREATED: "created",
  SENT: "sent",
  PASS_EXAM: "passExam",
}

export const TU_ERROR_MAPPING = {
  NOT_SHOWN_SWEET_ALERT_CODES: ["200015"],
  NOT_REQUIRED_SSN_UPDATE_CODE: ["200015"],
}

export const TU_CONTACT = {
  PHONE: "(833) 458-6338",
  KB_URL:
    TU_CONTACT_KB_URL ||
    "https://support.rentspree.com/en/transunion-contact-info-and-hours",
}

export const REPEATED_EXAM = "repeated_exam"

export const TU_BUNDLE_ID = {
  CREDIT_PLUS_CRIMINAL: 5002,
  ALL_OPTIONS: 5003,
  CREDIT_PLUS_EVICTION: 5005,
  CREDIT_ONLY: 5009,
  ALL_PLUS_INCOME_ESTIMATE: 5013,
}
