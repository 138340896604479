import {
  GET_TU_AGREEMENT_CONTENT_CALL,
  GET_TU_AGREEMENT_CONTENT_REQUESTED,
  GET_TU_AGREEMENT_CONTENT_SUCCESS,
  GET_TU_AGREEMENT_CONTENT_FAILED,
  SET_IS_RE_CONSENT,
} from "./constants"

export const getTuAgreementContentCall = payload => ({
  type: GET_TU_AGREEMENT_CONTENT_CALL,
  payload,
})

export const getTuAgreementContentRequested = () => ({
  type: GET_TU_AGREEMENT_CONTENT_REQUESTED,
})

export const getTuAgreementContentSuccess = response => ({
  type: GET_TU_AGREEMENT_CONTENT_SUCCESS,
  response,
})

export const getTuAgreementContentFailed = errors => ({
  type: GET_TU_AGREEMENT_CONTENT_FAILED,
  errors,
})

export const setIsReConsent = response => ({
  type: SET_IS_RE_CONSENT,
  response,
})
