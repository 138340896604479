/*
 *
 * Landlord reducer
 *
 */
import { fromJS } from "immutable"
import pick from "lodash/pick"
import {
  GET_SCREENING_REQUEST_SUCCESS,
  GET_SCREENING_REQUEST_FAILED,
} from "containers/wrapper/constants"
import { GET_AGENT_REVIEW_BY_RENTAL_SUCCESS } from "../rental-submission/rental-guide/constants"

export const initialState = fromJS({
  agentProfile: {},
  landlord: {},
  agentReview: {},
})

function landlordReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case GET_SCREENING_REQUEST_SUCCESS:
      nextState = initialState.mergeDeep(
        pick(action.payload, ["agentProfile", "landlord"]),
      )
      return nextState
    case GET_SCREENING_REQUEST_FAILED:
      return initialState
    case GET_AGENT_REVIEW_BY_RENTAL_SUCCESS:
      return state.merge({ agentReview: action.payload })
    default:
      return state
  }
}

export default landlordReducer
