/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import produce from "immer"

import {
  CLEAN_CONNECT_LINK,
  CREATE_FINICITY_CONNECT_LINK_SUCCESS,
  GET_INCOME_VERIFICATION_REQUEST,
  GET_INCOME_VERIFICATION_SUCCESS,
  GET_INCOME_VERIFICATION_FAILED,
  UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS,
  SUBMIT_INCOME_VERIFICATION,
} from "./constants"

export const initialState = {
  incomeVerification: {
    id: "",
    externalAccountId: "",
    status: "",
    connectLink: "",
  },
  isLoadingIncomeVerification: false,
  isSubmitted: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action
  return produce(state, draft => {
    switch (type) {
      case CREATE_FINICITY_CONNECT_LINK_SUCCESS:
        draft.incomeVerification.connectLink = payload.link
        break
      case CLEAN_CONNECT_LINK:
        draft.incomeVerification.connectLink = ""
        break
      case GET_INCOME_VERIFICATION_REQUEST:
        draft.isLoadingIncomeVerification = true
        break
      case GET_INCOME_VERIFICATION_SUCCESS:
        draft.incomeVerification.id = payload._id
        draft.incomeVerification.externalAccountId = payload.externalAccountId
        draft.incomeVerification.status = payload.status
        draft.isLoadingIncomeVerification = false
        break
      case GET_INCOME_VERIFICATION_FAILED:
        draft.isLoadingIncomeVerification = false
        break
      case UPDATE_INCOME_VERIFICATION_STATUS_SUCCESS:
        draft.incomeVerification.status = payload.status
        break
      case SUBMIT_INCOME_VERIFICATION:
        draft.isSubmitted = true
        break
      default:
        break
    }
    return draft
  })
}
