import React from "react"
import { compose } from "redux"
import { tracker, withTracker, PAGE_NAME } from "tracker"
import styled from "styled-components"
import { Statsig } from "statsig-react"

import { EXPERIMENT_NAMES } from "utils/experiment/constants"
import { T36, B16 } from "@rentspree/component-v2"
import track from "react-tracking"

import Form from "./form"
import ReviewComponent from "./review"
import { withConnect, withSaga } from "./connect"

const OuterReviewWrapper = styled.div`
  .outer-review-wrapper {
    > .review-subtitle.p {
      line-height: 22px;
    }
  }
`

export class StepApplicantInfo extends React.Component {
  render() {
    const isDisplayTooltip = Statsig.getExperiment(
      EXPERIMENT_NAMES.TRANSUNION_SCREENING,
    ).get("display_tooltip", true)

    return (
      <>
        {!this.props.isShowReviewPage ? (
          <>
            <T36 lineHeight="normal" margin="50px 0 0 0 ">
              TransUnion Screening
            </T36>
            <Form
              className="form-wrapper"
              {...this.props}
              isDisplayTooltip={isDisplayTooltip}
            />
          </>
        ) : (
          <OuterReviewWrapper className="outer-review-wrapper">
            <T36
              lineHeight="normal"
              margin="50px 0 0 0 "
              mMargin="0"
              tMargin="0">
              Confirm Your Details
            </T36>
            <>
              <B16 className="review-subtitle">
                Please confirm your information below is accurate as these
                details will be used to validate your identity with TransUnion.
              </B16>
              <ReviewComponent {...this.props} />
            </>
          </OuterReviewWrapper>
        )}
      </>
    )
  }
}

export default compose(
  track(),
  withConnect,
  withSaga,
)(withTracker(StepApplicantInfo, tracker, PAGE_NAME.TU.INFO))
