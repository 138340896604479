import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import {
  makeSelectMultiShare,
  makeSelectScreeningRequestId,
} from "containers/wrapper/selectors"
import makeSelectLoading from "containers/loading/selectors"
import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"
import { makeSelectProperty } from "containers/property/selectors"
import { makeSelectAgentProfile } from "containers/landlord/selectors"
import { makeSelectPrice } from "containers/price/selectors"
// TODO: INIT-649 - Cleanup
import { startNewRental, startPrePaymentRental } from "./actions"
import reducer from "./reducer"
import {
  makeSelectScreeningOption,
  makeSelectPaymentUpfrontCriteria,
} from "./selectors"
import rootSaga from "./saga"

export const mapStateToProps = createStructuredSelector({
  screeningOption: makeSelectScreeningOption(),
  screeningRequestId: makeSelectScreeningRequestId(),
  price: makeSelectPrice(),
  property: makeSelectProperty(),
  agentProfile: makeSelectAgentProfile(),
  loading: makeSelectLoading(),
  multiShare: makeSelectMultiShare(),
  isMeetPaymentUpfrontCriteria: makeSelectPaymentUpfrontCriteria(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        startNewRental,
        startPrePaymentRental,
      },
      dispatch,
    ),
  }
}

export const withReducer = injectReducer({ key: "instruction", reducer })
export const withSaga = injectSaga({
  key: "instruction",
  saga: rootSaga,
})
export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
