import React from "react"
import { FieldArray } from "formik"
import { Clearfix } from "react-bootstrap"
import styled from "styled-components"
import {
  Hr,
  TextInput,
  RadioGroupWithConditionalFields,
  withFormikArrayField,
  Checkbox,
  S16,
} from "@rentspree/component-v2"

import { COLOR } from "styles/settings"
import { Col } from "components/layout/bootstrap"
import get from "lodash/get"

import { Div } from "components/layout"
import { choices, FooterNote, CO_APPLICANT_CHECKBOX } from "../constants"

export const OtherTenantsField = withFormikArrayField(FieldArray)

const ErrorMessage = styled.span`
  color: ${COLOR.highlightRed};
  font-weight: 400;
`

export const Form = props => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    setFieldValue,
    isGuarantor,
  } = props
  const filedTitle = isGuarantor ? "Tenant" : "Other Tenant"
  const CheckBoxErrorMessage = () => (
    <>
      <br />
      <ErrorMessage>Required</ErrorMessage>
    </>
  )
  return (
    <>
      <S16 margin="0 0 15px">
        {isGuarantor
          ? "I will guarantee/co-sign for the following tenants."
          : "Will you live with other tenants?"}
      </S16>
      <Clearfix>
        <Col md={12}>
          <RadioGroupWithConditionalFields
            choices={choices}
            handleChange={handleChange}
            handleBlur={handleBlur}
            name="haveTenants"
            hiddenFieldNames={["tenants"]}
            setFieldValue={setFieldValue}
            values={isGuarantor ? { haveTenants: "yes" } : values}
            hideChoices={isGuarantor}
            id="haveTenants"
            underRadioRender={() => <FooterNote page="tenant" />}>
            <Hr margin="20px 0" />
            <OtherTenantsField
              addBtnId="addTenantBtn"
              removeBtnId="removeTenantBtn"
              title={index => ` ${filedTitle} #`.concat(index + 1)}
              removeButtonText="Remove"
              addButtonText="Add additional tenant"
              fieldGroupName="tenants"
              minLength={1}
              maxLength={10}
              values={values}
              itemDefaultValue={{
                firstName: "",
                lastName: "",
                email: "",
                shareLeaseLink: true,
              }}
              renderFields={(item, index) => (
                <Div margin="10px 0 20px">
                  <Clearfix>
                    <Col sm={12} md={4}>
                      <TextInput
                        id={`tenants${index}firstName`}
                        type="text"
                        name={`tenants.${index}.firstName`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tenants[index].firstName}
                        error={
                          get(touched, ["tenants", index, "firstName"]) &&
                          get(errors, ["tenants", index, "firstName"])
                        }
                        label="First Name"
                        isRequired
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <TextInput
                        id={`tenants${index}lastName`}
                        type="text"
                        name={`tenants.${index}.lastName`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tenants[index].lastName}
                        error={
                          get(touched, ["tenants", index, "lastName"]) &&
                          get(errors, ["tenants", index, "lastName"])
                        }
                        label="Last Name"
                        isRequired
                      />
                    </Col>
                  </Clearfix>
                  <Clearfix>
                    <Col sm={12} md={8}>
                      <TextInput
                        id={`tenants${index}email`}
                        type="email"
                        name={`tenants.${index}.email`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tenants[index].email}
                        error={
                          get(touched, ["tenants", index, "email"]) &&
                          get(errors, ["tenants", index, "email"])
                        }
                        label="Email"
                        isRequired
                      />
                    </Col>
                  </Clearfix>
                  <Clearfix>
                    <Checkbox
                      id={`tenants${index}shareLeaseLink`}
                      name={`tenants.${index}.shareLeaseLink`}
                      onClick={() =>
                        setFieldValue(
                          `tenants.${index}.shareLeaseLink`,
                          !values.tenants[index].shareLeaseLink,
                        )
                      }
                      isSelected={values.tenants[index].shareLeaseLink}
                      label={
                        <>
                          {CO_APPLICANT_CHECKBOX.LABEL}
                          {get(touched, ["tenants", index, "shareLeaseLink"]) &&
                            get(errors, [
                              "tenants",
                              index,
                              "shareLeaseLink",
                            ]) && <CheckBoxErrorMessage />}
                        </>
                      }
                      labelDescription={CO_APPLICANT_CHECKBOX.DESCRIPTION}
                      fontSize="16px"
                      labelBold
                      display="block"
                      simple
                      error={
                        get(touched, ["tenants", index, "shareLeaseLink"]) &&
                        get(errors, ["tenants", index, "shareLeaseLink"])
                      }
                    />
                  </Clearfix>
                </Div>
              )}
            />
          </RadioGroupWithConditionalFields>
        </Col>
      </Clearfix>
    </>
  )
}

export default Form
