import { mixed } from "yup"
import {
  validateLengthSupportAllowEmpty,
  validateLength,
  validateZipCodeSupportAllowEmpty,
  validateState,
  ADDRESS_REGEX,
  getInvalidAddressLineOneMessage,
  validateRegion,
} from "constants/validations"

export const addressValidationSchema = ({
  address: { streetAddress, zipCode, region },
  isRequireFullAddress,
  isUsAddressOnly,
}) => ({
  streetAddress: validateLengthSupportAllowEmpty(
    streetAddress,
    0,
    255,
    !isRequireFullAddress,
    isRequireFullAddress,
  ).matches(ADDRESS_REGEX.addressLine1, {
    message: getInvalidAddressLineOneMessage,
    excludeEmptyString: true,
  }),
  city: validateLength(true, 0, 35),
  state: isUsAddressOnly ? validateState() : validateLength(true, 2, 50),
  zipcode: validateZipCodeSupportAllowEmpty(
    zipCode,
    isUsAddressOnly || region === "US",
    !isRequireFullAddress,
    isRequireFullAddress,
  ),
  region: isUsAddressOnly ? mixed() : validateRegion(),
})
