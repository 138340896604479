import omit from "lodash/omit"
import * as constants from "./constants"

export function getTUScreeningData() {
  return constants.getTUScreeningDataCall()
}

export function acceptTerms(payload) {
  return constants.acceptTermsCall(payload)
}

export function startNewScreening(renter) {
  const payload = omit(renter, "ssnConfirm")
  return constants.startNewScreeningCall(payload)
}

export function redirectTuError() {
  return constants.redirectTuErrorCall()
}

export function getCreditReport() {
  return constants.getCreditReportCall()
}
