import { generateAddress, resizeImgProxy } from "@rentspree/helper"
import { IMAGE_PROXY_HOST, IMAGE_PROXY_URL } from "env"
import { isEmpty } from "lodash"
import PlaceholderImage from "containers/rental-submission/apply-now-landing/ui/placeholder.svg"
import { createSelector } from "reselect"
import { GCS_PROTOCOL } from "utils/constants"

import { initialState } from "./reducer"

export const selectProperty = state => state.get("property", initialState)

export const toJs = substate => substate.toJS()

export const getProperty = state => selectProperty(state).toJS()

export const makeSelectProperty = () => createSelector(selectProperty, toJs)

export const propertyAddress = () =>
  createSelector(selectProperty, state => generateAddress(toJs(state)))

export const makeSelectPropertyAddressLine1and2 = () =>
  createSelector(selectProperty, property => {
    const { street, unitNumber, state, city, zip } = toJs(property)
    const addressLine1 = [street, unitNumber]
      .filter(v => !isEmpty(v))
      .join(", ")
    const addressLine2 = [city, state, zip].filter(v => !isEmpty(v)).join(", ")
    return { addressLine1, addressLine2 }
  })

export const makeSelectListingImage = () =>
  createSelector(selectProperty, state => {
    const { listing } = toJs(state)
    const image = listing?.publishedInformation?.photos?.[0]
    const result = image
      ? resizeImgProxy(
          image,
          600,
          400,
          IMAGE_PROXY_HOST,
          IMAGE_PROXY_URL,
          GCS_PROTOCOL,
        )
      : PlaceholderImage

    return result
  })
