export const SUBMIT_RENTAL_API_URL =
  "/api/v2/rental-submissions/:rentalId/submit"

export const CREATE_PUBLIC_CONTACT_API = `/api/v2/people/public/contacts`

export const ATTACH_PAYMENT_API_URL = `/api/v2/rental-submissions/:rentalId/credit-report/attach-payment`

export const SUBMIT_RENTAL_CALL = "SUBMIT_RENTAL_CALL"
export const SUBMIT_RENTAL_REQUEST = "SUBMIT_RENTAL_REQUEST"
export const SUBMIT_RENTAL_SUCCESS = "SUBMIT_RENTAL_SUCCESS"
export const SUBMIT_RENTAL_FAILED = "SUBMIT_RENTAL_FAILED"

export const ATTACH_PAYMENT_CALL = "ATTACH_PAYMENT_CALL"
export const ATTACH_PAYMENT_REQUEST = "ATTACH_PAYMENT_REQUEST"
export const ATTACH_PAYMENT_SUCCESS = "ATTACH_PAYMENT_SUCCESS"
export const ATTACH_PAYMENT_FAILED = "ATTACH_PAYMENT_FAILED"

export const CREATE_PUBLIC_CONTACT_CALL = "CREATE_PUBLIC_CONTACT_CALL"
export const CREATE_PUBLIC_CONTACT_REQUEST = "CREATE_PUBLIC_CONTACT_REQUEST"
export const CREATE_PUBLIC_CONTACT_SUCCESS = "CREATE_PUBLIC_CONTACT_SUCCESS"
export const CREATE_PUBLIC_CONTACT_FAILED = "CREATE_PUBLIC_CONTACT_FAILED"

export const GET_AGENT_REVIEW_BY_RENTAL_CALL = "GET_AGENT_REVIEW_BY_RENTAL_CALL"
export const GET_AGENT_REVIEW_BY_RENTAL_REQUEST =
  "GET_AGENT_REVIEW_BY_RENTAL_REQUEST"
export const GET_AGENT_REVIEW_BY_RENTAL_SUCCESS =
  "GET_AGENT_REVIEW_BY_RENTAL_SUCCESS"
export const GET_AGENT_REVIEW_BY_RENTAL_FAILED =
  "GET_AGENT_REVIEW_BY_RENTAL_FAILED"

export const SET_IS_SELECT_MULTI_SHARE = "SET_IS_SELECT_MULTI_SHARE"

export const SET_IS_SHOW_REQUIRE_MULTI_SHARE = "SET_IS_SHOW_REQUIRE_MULTI_SHARE"

export const PREPARE_ENVELOPE_TIMEOUT = 30000

export const TRACKING_EVENT_METHOD_NAME = "trackEvent"

export const setIsSelectMultiShare = payload => ({
  type: SET_IS_SELECT_MULTI_SHARE,
  payload,
})

export const setIsShowRequireMultiShare = payload => ({
  type: SET_IS_SHOW_REQUIRE_MULTI_SHARE,
  payload,
})

export const submitRentalCall = payload => ({
  type: SUBMIT_RENTAL_CALL,
  payload,
})

// TODO: INIT-649 - Cleanup
export const attachPaymentCall = payload => ({
  type: ATTACH_PAYMENT_CALL,
  payload,
})

export const postAttachPaymentRequest = () => ({
  type: ATTACH_PAYMENT_REQUEST,
})

export const postAttachPaymentSuccess = payload => ({
  type: ATTACH_PAYMENT_SUCCESS,
  payload,
})

export const postAttachPaymentFailed = payload => ({
  type: ATTACH_PAYMENT_FAILED,
  payload,
})

export const postRentalSubmissionRequest = () => ({
  type: SUBMIT_RENTAL_REQUEST,
})
export const postRentalSubmissionSuccess = payload => ({
  type: SUBMIT_RENTAL_SUCCESS,
  payload,
})
export const postRentalSubmissionFailed = payload => ({
  type: SUBMIT_RENTAL_FAILED,
  payload,
})

export const getAgentReviewByRentalCall = payload => ({
  type: GET_AGENT_REVIEW_BY_RENTAL_CALL,
  payload,
})
export const getAgentReviewByRentalRequest = () => ({
  type: GET_AGENT_REVIEW_BY_RENTAL_REQUEST,
})
export const getAgentReviewByRentalSuccess = payload => ({
  type: GET_AGENT_REVIEW_BY_RENTAL_SUCCESS,
  payload,
})
export const getAgentReviewByRentalFailed = payload => ({
  type: GET_AGENT_REVIEW_BY_RENTAL_FAILED,
  payload,
})

export const createPublicContactCall = () => ({
  type: CREATE_PUBLIC_CONTACT_CALL,
})

export const postPublicContactRequest = () => ({
  type: CREATE_PUBLIC_CONTACT_REQUEST,
})
export const postPublicContactSuccess = payload => ({
  type: CREATE_PUBLIC_CONTACT_SUCCESS,
  payload,
})
export const postPublicContactFailed = payload => ({
  type: CREATE_PUBLIC_CONTACT_FAILED,
  payload,
})

export const CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT = 10000

export const SCREENING_FEE_TITLE_WITH_TOOLTIP = {
  APPLICATION_ONLY: {
    TITLE: "Application only",
    TOOLTIP: "Includes application requested by the property representative.",
  },
  STANDARD_BUNDLE: {
    TITLE: "Screening Reports",
    TOOLTIP:
      "May include application and reports as requested by the property representative.",
  },
  PREMIUM_BUNDLE: {
    TITLE: "Screening Reports & Documents",
    TOOLTIP:
      "May include application, reports, income estimate, references, and documents as requested by the property representative.",
  },
}

export const MULTI_SHARE_COPIES = {
  questionTitle1: "Applying to more homes in the next 30 days?",
  questionSubtitle1: "Reuse your application to apply to more homes for free.",
  optionYes: "Yes! I want to reuse my application. (+$15)",
  optionNo: "No, I’m not applying anywhere else.",
}
