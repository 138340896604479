export const stepNearestRelative = (nearestRelative = []) => ({
  reference: {
    nearestRelatives: nearestRelative.map(v => ({
      firstName: v.firstName,
      lastName: v.lastName,
      phoneNumber: v.phone,
      email: v.email,
      streetAddress: v.streetAddress,
      city: v.city,
      state: v.state,
      zipCode: v.zipcode,
      relationship: v.relationship,
      region: v.region,
    })),
  },
})

export const stepPersonalRef = (personalRef = []) => ({
  reference: {
    personalReferences: personalRef.map(v => ({
      firstName: v.firstName,
      lastName: v.lastName,
      phoneNumber: v.phone,
      email: v.email,
      streetAddress: v.streetAddress,
      city: v.city,
      state: v.state,
      zipCode: v.zipcode,
      acquaintanceLength: v.acquaintanceLength,
      relationship: v.relationship,
      region: v.region,
    })),
  },
})
