import _ from "lodash"
import { fromJS } from "immutable"

import message from "constants/error-messages"

import {
  SAVE_APPLICATION_FAILED,
  VALIDATION_APPLICATION_ERROR_EXPECTED,
  VALIDATION_APPLICATION_ERROR_UNEXPECTED,
  VALIDATION_APPLICATION_ERROR_CLEAR,
} from "../application/constants"

import { SAVE_APPLICANT_INFO_FAILED } from "../applicant-info/constants"

import {
  CLOSE_ERROR_ALERT,
  SHOW_ERROR_ALERT,
  CLEAR_ERROR_ALERT,
} from "./constants"

export const initialState = fromJS({
  message: message.networkError,
  location: "",
  show: false,
  validation: false,
})

function errorReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case CLEAR_ERROR_ALERT:
      return initialState
    case VALIDATION_APPLICATION_ERROR_UNEXPECTED:
    case SAVE_APPLICATION_FAILED:
    case SAVE_APPLICANT_INFO_FAILED:
      if (_.isEmpty(state.get("location"))) {
        nextState = state.merge({
          location: _.get(action, "payload"),
        })
      }
      return nextState
    case VALIDATION_APPLICATION_ERROR_EXPECTED:
      nextState = state.set("validation", true)
      return nextState
    case VALIDATION_APPLICATION_ERROR_CLEAR:
      nextState = state.set("validation", false)
      return nextState
    case CLOSE_ERROR_ALERT:
      nextState = state.set("show", false)
      return nextState
    case SHOW_ERROR_ALERT:
      nextState = state.merge({
        message: _.get(action, "payload.message", message.networkError),
        show: true,
      })
      return nextState
    default:
      return state
  }
}

export default errorReducer
