import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import {
  selectLoadingGetEnvelope,
  selectLoadingPrepareEnvelope,
  selectEnvelopeData,
} from "./selectors"
import { getEnvelopeByIdCall } from "./actions"
import { resetActive } from "../drag-n-drop/actions"

export const makeMapStateToProps = () =>
  createStructuredSelector({
    isLoadingGetEnvelope: selectLoadingGetEnvelope,
    isLoadingPrepareEnvelope: selectLoadingPrepareEnvelope,
    envelopeData: selectEnvelopeData(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getEnvelopeByIdCall,
        resetActive,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)
