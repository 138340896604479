import React from "react"
import { GlobalStyle } from "@rentspree/component-v2"
import { css } from "styled-components"
import { SWEET_ALERT_STYLE } from "./sweetalert"

/* eslint no-unused-expressions: 0 */
export const customGlobal = css`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    @media (max-width: 767px) {
      height: 100%;
    }
  }

  body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
      sans-serif, simple-line-icons;
  }

  body.fontLoaded {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #app {
    height: 100%;
    width: 100%;
  }

  p,
  label,
  button {
    font-family: "Source Sans Pro", Georgia, Times, "Times New Roman", serif;
  }

  a {
    cursor: pointer;
  }

  svg {
    fill: none; /* This style is fixing sanitize.css that makes RentSpree Icon system not working */
  }

  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }
  ${SWEET_ALERT_STYLE};
`

export const GlobalStyles = () => <GlobalStyle customGlobal={customGlobal} />
