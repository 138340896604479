import React from "react"
import styled from "styled-components"
import {
  S24,
  TermBox,
  TermSection,
  Checkbox,
  BlueGradientButton,
  ErrorAlert,
} from "@rentspree/component-v2"

export const LayoutModalButtonRight = styled.div`
  font-family: Source Sans Pro, sans-serif;
  button {
    float: right;
  }
`

const TermsModalBody = ({
  title,
  error,
  selectedCheckBox,
  onCheckBoxClick,
  handleClickBtn,
  isLoading,
  onClearError,
  terms,
  isSuccess,
}) => (
  <LayoutModalButtonRight>
    <S24 margin="0px 0px 10px 0px" mMargin="0px 15px 10px 0px" mSize="24px">
      {title}
    </S24>
    {error && (
      <ErrorAlert
        errorMessage={error.errorMessage}
        secondaryMessage={error.secondaryMessage}
        onClose={onClearError}
        margin="10px 0"
      />
    )}
    <TermBox
      padding="15px 20px 0px 20px"
      mPadding="15px 20px 0px 20px"
      height="260"
      hideOverlay>
      <TermSection
        termsMargin="0px"
        termsSize="14px"
        termsAlign="left"
        terms={terms}
      />
    </TermBox>
    <Checkbox
      isSelected={selectedCheckBox}
      onClick={onCheckBoxClick}
      margin="15px 0px 30px 0"
      mMargin="15px 0px 30px 0"
      fontSize="16px"
      id="simpleBox"
      label="I accept the RentSpree E-Sign and 
E-Delivery Consent."
      simple
    />
    <BlueGradientButton
      small
      semiBold
      text="Continue"
      id="smBtn"
      fontSize="16px"
      mWidth="100%"
      onClick={handleClickBtn}
      loading={isLoading}
      loaded={isSuccess}
      disabled={!selectedCheckBox}
      tWidth="100%"
    />
  </LayoutModalButtonRight>
)
TermsModalBody.defaultProps = {
  title: "String",
  error: false,
  selectedCheckBox: false,
  onCheckBoxClick: () => {},
  handleClickBtn: () => {},
  isLoading: false,
  onClearError: () => {},
  terms: {},
}

export default TermsModalBody
