import styled, { css } from "styled-components"
import { generateProperty } from "utils/styled-utils"

export const InputGroup = styled.div`
  width: ${props => (props.width || props.half ? "50%" : "100%")};
  ${props =>
    props.right
      ? css`
          padding-left: 10px;
        `
      : css`
          padding-right: 10px;
        `};
  ${props =>
    props.full &&
    css`
      padding-left: 0px;
      padding-right: 0px;
    `};
  @media (max-width: 767px) {
    ${props =>
      props.mFull &&
      css`
        width: 100%;
        padding: 0;
      `} ${props =>
      props.mMarginBottom &&
      generateProperty("margin-bottom", props.mMarginBottom)};
  }
  ${props =>
    props.marginBottom && `margin-bottom: ${props.marginBottom};`} > div {
    background: white;
    ${props =>
      props.bgTransparent &&
      `
      background: transparent;
    `};
  }
`

// For the latest RentSpree UI kit
export const InputWrapper = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  ${props => props.mt && `margin-top: ${props.mt}px;`}
  ${props => props.mr && `margin-right: ${props.mr}px;`}
  ${props => props.mb && `margin-bottom: ${props.mb}px;`}
  ${props => props.ml && `margin-left: ${props.ml}px;`}
  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin: 0;
    ${props =>
      props.mMarginBottom && `margin-bottom: ${props.mMarginBottom}px;`}
  }
  > div {
    background: white;
    ${props =>
      props.bgTransparent &&
      `
      background: transparent;
    `}
  }
`
