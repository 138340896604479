import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import RentSpreeBaseModal from "@rentspree/component-v2/dist/modal"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import Button from "@rentspree/component-v2/dist/button"

export const TextWrapperStyled = styled.div`
  font-family: Source Sans Pro, sans-serif;
`

export const TitleStyled = styled(S24)`
  line-height: 32px;
  margin-top: 0;
`

export const InfoStyled = styled(B16)`
  line-height: 22px;
`

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  button {
    &:not(:last-child) {
      margin-left: 10px;
    }
  }
`

const ConfirmationModal = ({
  isOpen,
  modalSize,
  canBeClosedByClickOverlay,
  title,
  content,
  confirmBtnTxt,
  cancelBtnTxt,
  onClickConfirm,
  btnNextMinWidth,
  onClose,
  isLoading,
}) => (
  <RentSpreeBaseModal
    isOpen={isOpen}
    onClose={onClose}
    modalSize={modalSize}
    shouldCloseOnOverlayClick={canBeClosedByClickOverlay}>
    <TextWrapperStyled>
      <TitleStyled>{title}</TitleStyled>
      <InfoStyled>{content}</InfoStyled>
    </TextWrapperStyled>
    <ButtonsWrapperStyled>
      <BlueGradientButton
        small
        semiBold
        height="35px"
        minWidth={btnNextMinWidth}
        text={confirmBtnTxt}
        id="confirmBtn"
        fontSize="14px"
        sourceSansPro
        onClick={onClickConfirm}
        loading={isLoading}
      />
      <Button
        google
        small
        semiBold
        height="35px"
        fontSize="14px"
        id="cancelBtn"
        text={cancelBtnTxt}
        type="button"
        sourceSansPro
        disabled={isLoading}
        onClick={onClose}
      />
    </ButtonsWrapperStyled>
  </RentSpreeBaseModal>
)

ConfirmationModal.defaultProps = {
  title: "Confimation modal title",
  content: "Confimation modal content",
  confirmBtnTxt: "Confirm",
  cancelBtnTxt: "Cancel",
  modalSize: "small",
  canBeClosedByClickOverlay: true,
  btnNextMinWidth: "110",
  isLoading: false,
}

ConfirmationModal.propsType = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  confirmBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  modalSize: PropTypes.oneOf(["small", "medium", "large"]),
  canBeClosedByClickOverlay: PropTypes.bool,
  btnNextMinWidth: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onClickConfirm: PropTypes.func,
}

export default ConfirmationModal
