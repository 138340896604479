import React from "react"
import styled from "styled-components"
import { Span, S16, B16 } from "@rentspree/component-v2"

import Row from "components/atoms/row"
import { COLOR } from "styles/settings"

import { StyleCol } from "./text-with-label"

const SpanButton = styled(Span)`
  cursor: pointer;
`

const textOrSecret = (decision, value) =>
  decision === "Show" ? String(value).replace(/./g, "•") : value

const SensitiveTextWithLabel = ({ id, label, text, eventTracker, ...rest }) => {
  const [isShowSsn, setIsShowSsn] = React.useState(false)
  const ssnHideShowText = isShowSsn ? "Hide" : "Show"

  const sensitiveDisplayHandler = () => {
    setIsShowSsn(!isShowSsn)
    return !isShowSsn && eventTracker && eventTracker()
  }

  return (
    <StyleCol {...rest}>
      <S16 className="subtitle-label" size="14px" width="100%">
        {label}
      </S16>
      <Row>
        <B16 data-testid={id} className="review-text" width="32px">
          {textOrSecret(ssnHideShowText, text)}
        </B16>
        <SpanButton
          data-testid={`${id}-hide-show-btn`}
          color={COLOR.lightGray}
          bold={false}
          semiBold={false}
          light={false}
          weight="400"
          opacity="1"
          fontStyle="inherit"
          lineHeight="22px"
          leftSpace="10px"
          m0={false}
          align="left"
          size="14px"
          onClick={sensitiveDisplayHandler}>
          {ssnHideShowText}
        </SpanButton>
      </Row>
    </StyleCol>
  )
}

export default SensitiveTextWithLabel
