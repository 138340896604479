import React, { useMemo } from "react"
import { withFormik } from "formik"
import { Checkbox } from "@rentspree/component-v2"

import { FormFooter } from "components/buttons/form-footer"
import { TUTermFormWrapper } from "components/layout"

export const InnerForm = props => {
  const {
    actions,
    handleSubmit,
    loading,
    checkboxes,
    handleCheckboxesAccepted,
  } = props
  return (
    <TUTermFormWrapper onSubmit={handleSubmit}>
      {checkboxes.map((checkbox, index) => (
        <Checkbox
          id={`acceptTuAgreementCheckBoxRequired${index}`}
          secondaryLabel={checkbox.required ? false : "(Optional)"}
          fontSize="16px"
          labelBold
          label={checkbox.text}
          isSelected={checkbox.checked}
          onClick={() => handleCheckboxesAccepted(index)}
        />
      ))}

      <FormFooter
        cancelBtn={{
          id: "tuAgreementTermsCancelBtn",
          onClick: () => actions.redirect(),
          disabled: loading.components,
        }}
        continueBtn={{
          id: "tuAgreementTermsContinueBtn",
          disabled:
            useMemo(
              () =>
                !checkboxes.filter(r => !!r.required).every(r => !!r.checked),
              [checkboxes],
            ) || loading.components,
          loading: loading.components,
          loaded: loading.success,
          text: "Continue",
        }}
        margin="30px 0"
      />
    </TUTermFormWrapper>
  )
}

const Form = withFormik({
  enableReinitialize: true,
  displayName: "acceptTUTermsForm",
  handleSubmit: (_, { props }) => {
    const { actions, agreement: { version } = {} } = props
    actions.acceptTerms({ version })
  },
})(InnerForm)

export default Form
