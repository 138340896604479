/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable"
import { fromJS } from "immutable"
import { LOCATION_CHANGE } from "connected-react-router"
import { connectRouter } from "connected-react-router/immutable"

import languageProviderReducer from "containers/language-provider/reducer"
import LoadingReducer from "containers/loading/reducer"
import ErrorReducer from "containers/error/reducer"
import { profileReducer, multiShareReducer } from "containers/wrapper/reducer"
import propertyReducer from "containers/property/reducer"
import landlordReducer from "containers/landlord/reducer"
import priceReducer from "containers/price/reducer"
import screeningOptionReducer from "containers/rental-submission/rental-instruction/reducer"
import rentalReducer from "containers/rental-submission/reducer"
import documentUploadReducer from "containers/document-upload/reducer"
import envelopeReducer from "containers/envelope/reducer"
import featureFlagsReducer from "containers/feature-flags/reducer"

import history from "./utils/history"
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
})

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      })
    default:
      return state
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    router: connectRouter(history),
    language: languageProviderReducer,
    loading: LoadingReducer,
    error: ErrorReducer,
    profile: profileReducer,
    property: propertyReducer,
    screeningOption: screeningOptionReducer,
    landlord: landlordReducer,
    price: priceReducer,
    rentalSubmission: rentalReducer,
    envelope: envelopeReducer,
    multiShare: multiShareReducer,
    document: documentUploadReducer,
    featureFlags: featureFlagsReducer,
    ...injectedReducers,
  })
}
