import React from "react"
import styled from "styled-components"
import { S16, B14 } from "@rentspree/component-v2"
const TooltipBodyStyle = styled.div`
  font-family: Source Sans Pro, sans-serif;
  ${props =>
    props.customStyle ? props.customStyle : ""} @media (max-width: 767px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #4a4a4a;
    }
  }
`
const TooltipBody = props => {
  const { header, body, customStyle } = props
  return (
    <TooltipBodyStyle customStyle={customStyle}>
      {header && (
        <S16 color="black" size="14px">
          {header}
        </S16>
      )}
      <B14 color="black" size="12px">
        {body}
      </B14>
    </TooltipBodyStyle>
  )
}
export default TooltipBody
