import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledCenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.itemsDirection};
  margin: ${props => props.margin ?? "0"};
  padding: ${props => props.padding ?? "0"};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.centerText && "text-align: center"};
`

const CenterContent = ({
  children,
  margin,
  padding,
  width,
  height,
  itemsDirection,
  centerText,
}) => (
  <StyledCenterContent
    margin={margin}
    padding={padding}
    width={width}
    height={height}
    itemsDirection={itemsDirection}
    centerText={centerText}>
    {children}
  </StyledCenterContent>
)

CenterContent.propTypes = {
  margin: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  itemsDirection: PropTypes.string,
  centerText: PropTypes.bool,
}

CenterContent.defaultProps = {
  itemsDirection: "column",
  centerText: false,
}

export default CenterContent
