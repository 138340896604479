import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import track from "react-tracking"
import { Switch, Route } from "react-router-dom"
import Version from "@rentspree/version-check"
import { StatsigProvider } from "statsig-react"

import { DisabledScreeningPageLoadable } from "containers/router/loadable"
import Wrapper from "containers/wrapper"
import {
  makeSelectProfile,
  makeSelectSubdomain,
} from "containers/wrapper/selectors"
import WrapperWithoutNavbar from "containers/wrapper-without-navbar"
import { MainContent, StyledWrapper } from "components/layout"
import Navbar from "components/navbar"
import ScrollToTop from "components/scroll/scroll-to-top"
import { trackEvent } from "tracker"
import { GlobalStyles } from "styles"
import {
  IDLE_TIMEOUT_MS,
  STATSIG_ENVIRONMENT,
  STATSIG_SDK_KEY,
  STATSIG_API,
} from "env"

import { ROUTE, RedirectNotFound, RedirectNewApplyLink } from "./constants"
import { checkRequiredMainContentPaths } from "./helpers/check-required-main-content"
import { routes, routesWithoutNavbar } from "./config"
import { renderRoute } from "./render-route"
import { parseUserProfileToInstanceUser } from "../../utils/feature-flag/helpers"
import Toast from "../toast"

export const SubmitRentalRoutes = () => (
  <Route
    path={ROUTE.GUIDE.BASE}
    render={props => {
      const {
        location: { pathname },
      } = props
      const isNotRequiredMainContent = checkRequiredMainContentPaths(pathname)
      return (
        <Wrapper>
          {isNotRequiredMainContent ? (
            <Switch>{routes.map(route => renderRoute(route, props))}</Switch>
          ) : (
            <MainContent>
              <Switch>{routes.map(route => renderRoute(route, props))}</Switch>
            </MainContent>
          )}
        </Wrapper>
      )
    }}
  />
)

export const Router = ({ subdomain, profile }) => {
  // this line is broken if we don't passed the broken profile.profile into it
  const userInfo = parseUserProfileToInstanceUser(profile.profile, subdomain)

  return (
    <ScrollToTop>
      <GlobalStyles />
      <Version
        api={ROUTE.VERSION}
        idleTimeout={IDLE_TIMEOUT_MS}
        bottom={5}
        right={5}
      />
      <Switch>
        <Route path={ROUTE.GUIDE.OLD}>
          <RedirectNewApplyLink />
        </Route>
        <Route
          path={`${ROUTE.GUIDE.BASE}${ROUTE.GUIDE.LANDING}`}
          render={props => (
            <StatsigProvider
              sdkKey={STATSIG_SDK_KEY}
              waitForInitialization
              user={userInfo}
              options={{
                environment: {
                  tier: STATSIG_ENVIRONMENT,
                },
                api: STATSIG_API,
              }}>
              <WrapperWithoutNavbar>
                <Switch>
                  {routesWithoutNavbar.map(route => renderRoute(route, props))}
                </Switch>
              </WrapperWithoutNavbar>
            </StatsigProvider>
          )}
        />
        <Route
          path={`${ROUTE.GUIDE.BASE}${ROUTE.GUIDE.INACTIVE}`}
          exact
          render={props => (
            <div>
              <Navbar />
              <StyledWrapper>
                <MainContent>
                  <DisabledScreeningPageLoadable {...props} />
                </MainContent>
              </StyledWrapper>
            </div>
          )}
        />
        <div>
          <StatsigProvider
            sdkKey={STATSIG_SDK_KEY}
            waitForInitialization
            user={userInfo}
            options={{
              environment: {
                tier: STATSIG_ENVIRONMENT,
              },
              api: STATSIG_API,
            }}>
            <Navbar />
            <Toast />
            <SubmitRentalRoutes />
          </StatsigProvider>
        </div>
        <RedirectNotFound />
      </Switch>
    </ScrollToTop>
  )
}

const mapStateToProps = createStructuredSelector({
  subdomain: makeSelectSubdomain(),
  profile: makeSelectProfile(),
})

const withConnect = connect(mapStateToProps)
const withTracker = track({}, { dispatch: data => trackEvent(data) })

export default compose(
  withConnect,
  withTracker,
)(Router)
