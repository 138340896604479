import { stripeCardDeclineErrorMessage, stripeErrorMessage } from "../constants"

const getErrorStripeMessage = ({ code, errors }) => {
  // eslint-disable-next-line camelcase
  const declineCode = errors?.decline_code
  if (code === "card_declined" && declineCode) {
    return {
      id: "cardDeclineError",
      defaultMessage: stripeCardDeclineErrorMessage[declineCode],
    }
  }
  return { id: "stripeError", defaultMessage: stripeErrorMessage[code] }
}

export default getErrorStripeMessage
