import React from "react"
import { Popper } from "react-popper"
import styled from "styled-components"
import { S16 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"

export const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
  z-index: 2;
  @media (max-width: 991px) {
    display: none;
  }
`

const PopperArrow = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  &[data-placement*="bottom"] {
    top: 0;
    left: 4;
    margin-top: -0.95rem;
    width: 1em;
    height: 1em;
    &::before {
      border-width: 0 0.8em 1em 0.8em;
      border-color: transparent transparent ${COLOR.black} transparent;
    }
  }
  &[data-placement*="top"] {
    bottom: 0;
    margin-bottom: -1.1rem;
    width: 1em;
    height: 1em;
    &::before {
      border-width: 0.5em 0.5em 0 0.5em;
      border-color: ${COLOR.black} transparent transparent transparent;
    }
  }
  &[data-placement*="right"] {
    left: 0;
    margin-left: -0.95rem;
    height: 2em;
    width: 1em;
    &::before {
      border-width: 0.8rem 1rem 0.8rem 0;
      border-color: transparent ${COLOR.black} transparent transparent;
    }
  }
  &[data-placement*="left"] {
    right: 0;
    margin-right: -0.95rem;
    height: 1em;
    width: 1em;
    &::before {
      border-width: 0.8em 0 0.8em 1em;
      border-color: transparent transparent transparent ${COLOR.black};
    }
  }
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`

export const Container = styled.div`
  pointer-events: none;
  display: flex;
  top: -29px !important;
  flex-direction: column;
  padding: 5px;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  background-color: ${COLOR.black};
`
export const Item = styled(S16)`
  display: flex;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: normal;
  color: white;
`
const TooltipPopper = ({ refNode, message }) => {
  if (refNode !== "") {
    try {
      return (
        <Wrapper>
          <Popper referenceElement={refNode} placement="top">
            {({ ref, placement, style, arrowProps }) => (
              <Container
                ref={ref}
                style={{ ...style, whiteSpace: "nowrap" }}
                data-placement={placement}>
                <Item margin="0">{message || "Required"}</Item>
                <PopperArrow
                  ref={arrowProps.ref}
                  data-placement={placement}
                  style={arrowProps.style}
                />
              </Container>
            )}
          </Popper>
        </Wrapper>
      )
    } catch (e) {
      return null
    }
  }
  return null
}

export default TooltipPopper
