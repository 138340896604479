import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import selectLoading from "containers/loading/selectors"
import { redirect } from "containers/wrapper/actions"
import errorSelector from "containers/error/selectors"
import { closeErrorAlert, clearErrorAlert } from "containers/error/actions"
import saga from "./saga"
import { getRentalWithCoApplicant } from "../rental-submission/actions"
import { makeSelectRental, selectDraft } from "../rental-submission/selectors"
import {
  saveApplicantInfo,
  setIsResumeAppEnabled,
  autoSaveRentalSpecificsFromCTA,
} from "./actions"

const mapStateToProps = createStructuredSelector({
  draft: selectDraft(),
  rental: makeSelectRental(),
  loading: selectLoading(),
  error: errorSelector(),
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        redirect,
        saveApplicantInfo,
        closeErrorAlert,
        clearErrorAlert,
        getRentalWithCoApplicant,
        setIsResumeAppEnabled,
        autoSaveRentalSpecificsFromCTA,
      },
      dispatch,
    ),
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

const withSaga = injectSaga({ key: "applicantInfo", saga })

export { withConnect, withSaga, mapDispatchToProps }
