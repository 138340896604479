import _ from "lodash"
import { object, array, string } from "yup"
import { requiredYup } from "constants/validations"

export const examValidation = () => ({
  schema: object({
    questions: array().of(
      object({
        selected: requiredYup(true, string(), "radio").nullable(),
      }),
    ),
  }),
})

export const isSelectedAll = values =>
  _.findIndex(values.questions, { selected: null }) === -1
