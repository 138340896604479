import React from "react"
import { Clearfix } from "react-bootstrap"
import { withFormik } from "formik"
import get from "lodash/get"
import { generateFullName } from "@rentspree/helper"
import { S24, Hr, Alert, Icon, B14 } from "@rentspree/component-v2"
import styled from "styled-components"

import { Col } from "components/layout/bootstrap"
import { FormFooter } from "components/buttons/form-footer"
import { Tooltip } from "components/tooltip"
import { TUPersonalInfoSSN } from "components/tooltip/tooltip-body"
import ScrollToError from "components/scroll/scroll-to-error"
import Beforeunload from "components/alerts/beforeunload"
import SSNInput from "components/molecules/ssn-input"

import { ssnSchema } from "./validation-schema"
import { EmploymentInfo } from "../info/employment-info"

export const StyledAlert = styled(Alert)`
  display: flex;
  gap: 10px;
  padding: 10px;
  @media (max-width: 990px) {
    align-items: flex-start;
  }
`
export const InnerForm = props => {
  const {
    actions,
    dirty,
    errors,
    handleBlur,
    handleSubmit,
    loading,
    touched,
    values,
    setFieldValue,
    tuRenter,
    isDisplayTooltip,
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError {...props} />
      <Beforeunload isDirty={dirty} />
      <S24>Personal Info</S24>
      <Hr margin="0 0 20px 0" />
      <Clearfix>
        <Col md={12}>
          Full Name <br />
          <S24 color="#3b99fc" margin="2px 0 20px 0">
            {generateFullName(tuRenter)}
          </S24>
        </Col>
      </Clearfix>
      <Clearfix>
        <Col md={6}>
          <SSNInput
            id="tuSSNInput"
            name="ssn"
            value={values.ssn}
            label="SSN / ITIN"
            isRequired
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            labelChildren={
              isDisplayTooltip && (
                <Tooltip
                  mobileHeaderText="TransUnion Screening"
                  bubbleWidth="250px"
                  iconMargin="5px">
                  <TUPersonalInfoSSN />
                </Tooltip>
              )
            }
          />
        </Col>
        <Col md={6}>
          <SSNInput
            id="tuSSNConfirmInput"
            name="ssnConfirm"
            value={values.ssnConfirm}
            label="Confirm SSN / ITIN"
            isRequired
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Col>
        {!isDisplayTooltip && (
          <Col md={12}>
            <StyledAlert infoGray>
              <Icon name="MenuSuggest" size="lg" />
              <B14 m0>
                TransUnion requires an SSN/ITIN to verify your identity.
                RentSpree does not store this data.
              </B14>
            </StyledAlert>
          </Col>
        )}
      </Clearfix>
      <S24>Employment Info</S24>
      <Hr margin="0 0 20px 0" />
      <EmploymentInfo
        id="tuEmploymentInfo"
        values={values}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        disabled={loading.components}
        onWorkStatusChange={val => {
          setFieldValue("workStatus", val)
        }}
        onIncomeChange={val => {
          setFieldValue("income", val.value)
        }}
      />
      <FormFooter
        cancelBtn={{
          id: "tuSSNFormCancelBtn",
          onClick: () => actions.redirect(),
          disabled: loading.components,
        }}
        continueBtn={{
          id: "tuSSNFormContinueBtn",
          disabled: loading.components,
          loading: loading.components,
          loaded: loading.success,
          text: "Continue",
        }}
      />
    </form>
  )
}

const SSNForm = withFormik({
  enableReinitialize: true,
  displayName: "tuSSNForm",
  mapPropsToValues: props => ssnSchema(props).mapper,
  validationSchema: () => ssnSchema().schema,
  handleSubmit: (values, { props }) => {
    const updatedTuRenter = {
      ...props.tuRenter,
      income: parseInt(get(values, "income"), 10),
      workStatus: get(values, "workStatus"),
    }
    props.actions.updateTuRenterCall(updatedTuRenter)
    props.actions.saveSSN({ ssn: get(values, "ssn") })
  },
})(InnerForm)

export default SSNForm
