import { object, array, string, lazy } from "yup"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import {
  requiredYup,
  validateLength,
  validateFutureDate,
  validateEmail,
  validatePhone,
  validatePersonName,
  validatePrice,
  validateMandatoryCheckbox,
} from "constants/validations"
import {
  filterCoApplicant,
  mapShareLeaseLink,
} from "./map-from-api/co-applicant"
import { CO_APPLICANT_TYPE, APPLICANT_TYPE } from "./constants"
import { APPLICATION_TYPE } from "../rental-submission/constants"
import { checkIsMonthlyRentRequired } from "./helpers"

export const stepApplicantInformationValidation = props => {
  const haveRenterAgentData = !isEmpty(get(props, "rental.renterAgent", ""))
  const isSelectNoRenterAgent =
    get(props, "rental.haveRenterAgent", "") === "no"
  const renterAgentSelectedStatus = get(props, "rental.haveRenterAgent", "yes")
  const applicationType = get(props, "rental.application.type", "")
  const isCARApplication = applicationType === APPLICATION_TYPE.CAR_LRA

  const applicantType = get(props, "rental.applicant_type", "")
  const isRequireMoveInDate =
    isCARApplication || applicantType === APPLICANT_TYPE.TENANT
  const isMonthlyRentRequired = checkIsMonthlyRentRequired(
    isCARApplication,
    get(props, "rental"),
  )

  return {
    mapper: {
      applicantType,
      moveInDate: isRequireMoveInDate ? get(props, "rental.moveDate", "") : "",
      monthlyRent: get(props, "rental.monthlyRent"),
      haveRenterAgent:
        haveRenterAgentData || isSelectNoRenterAgent
          ? renterAgentSelectedStatus
          : "no",
      renterAgent: get(props, "rental.renterAgent", {
        fullName: "",
        phoneNumber: "",
        email: "",
      }),
    },
    schema: object().shape({
      applicantType: requiredYup(true),
      moveInDate: validateFutureDate(isRequireMoveInDate, "Move-in date"),
      monthlyRent: validateLength(isMonthlyRentRequired, 0, 20).test(
        "is_price_exceed",
        "Price must be between $100 and $1,000,000",
        async value => {
          const validationObj = object({
            monthlyRent: validatePrice(isMonthlyRentRequired, 100),
          })
          const isValid = await validationObj.isValid({
            monthlyRent: value,
          })
          return isValid
        },
      ),
      haveRenterAgent: requiredYup(true),
      renterAgent: object()
        .shape({
          fullName: validatePersonName(false, 0, 60),
          phoneNumber: lazy(
            values =>
              !isEmpty(values) ? validatePhone(false) : string().notRequired(),
          ),
          email: validateEmail(false),
        })
        .when(
          "haveRenterAgent",
          (haveRenterAgent, schema) =>
            haveRenterAgent === "yes" ? schema : object().shape({}),
        ),
    }),
  }
}

export const stepAdditionalApplicantValidation = props => ({
  mapper: {
    haveTenants:
      filterCoApplicant(
        get(props, "rental.coApplicants"),
        CO_APPLICANT_TYPE.OCCUPANT,
      ).length > 0
        ? "yes"
        : "no",
    tenants: mapShareLeaseLink(
      get(props, "rental.coApplicants"),
      CO_APPLICANT_TYPE.OCCUPANT,
    ),
    haveGuarantors:
      filterCoApplicant(
        get(props, "rental.coApplicants"),
        CO_APPLICANT_TYPE.COSIGNER,
      ).length > 0
        ? "yes"
        : "no",
    guarantors: mapShareLeaseLink(
      get(props, "rental.coApplicants"),
      CO_APPLICANT_TYPE.COSIGNER,
    ),
  },
  schema: object().shape({
    haveTenants: requiredYup(true),
    tenants: array()
      .of(
        object().shape({
          firstName: validatePersonName(true, 1, 15),
          lastName: validatePersonName(true, 2, 15),
          email: validateEmail(),
        }),
      )
      .when(
        "haveTenants",
        (haveTenants, schema) =>
          haveTenants === "yes" ? schema.min(1) : schema.min(0),
      ),
    haveGuarantors: requiredYup(true),
    guarantors: array()
      .of(
        object().shape({
          firstName: validatePersonName(true, 1, 15),
          lastName: validatePersonName(true, 2, 15),
          email: validateEmail(),
          shareLeaseLink: validateMandatoryCheckbox(true),
        }),
      )
      .when(
        "haveGuarantors",
        (haveGuarantors, schema) =>
          haveGuarantors === "yes" ? schema.min(1) : schema.min(0),
      ),
  }),
})
