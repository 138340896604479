/*
 *
 * AppMisc constants
 *
 */

export const choices = [
  { label: "Yes", id: "yes", value: "yes", showHidden: true },
  { label: "No", id: "no", value: "no" },
]

export const BANKRUPTCY_QUESTION =
  "Have you filed bankruptcy within the last seven years?"
export const UNLAWFUL_QUESTION =
  "Have you been a party to an unlawful detainer action or filed bankruptcy within the last seven years?"
export const LIQUID_FILLED_FURNITURE_QUESTION =
  "Do you or any of your co-applicants plan to use liquid-filled furniture?"
export const MOVE_OUT_QUESTION =
  "Have you or any of your co-applicants ever been asked to move out of a residence?"
export const FELONY_QUESTION =
  "Have you or any of your co-applicants ever been convicted of or pleaded no contest to a felony?"

export const MIAMI_DADE_STATE = [
  "33180",
  "33146",
  "33134",
  "33155",
  "33015",
  "33189",
  "33190",
  "33178",
  "33034",
  "33172",
  "33143",
  "33170",
  "33016",
  "33018",
  "33010",
  "33012",
  "33013",
  "33014",
  "33030",
  "33031",
  "33035",
  "33039",
  "33179",
  "33183",
  "33173",
  "33176",
  "33193",
  "33149",
  "33033",
  "33142",
  "33144",
  "33145",
  "33150",
  "33185",
  "33101",
  "33135",
  "33136",
  "33137",
  "33138",
  "33122",
  "33125",
  "33126",
  "33127",
  "33128",
  "33129",
  "33130",
  "33131",
  "33132",
  "33133",
  "33194",
  "33139",
  "33140",
  "33141",
  "33109",
  "33055",
  "33056",
  "33169",
  "33166",
  "33161",
  "33168",
  "33181",
  "33162",
  "33054",
  "33157",
  "33158",
  "33156",
  "33032",
  "33187",
  "33177",
  "33160",
  "33154",
  "33174",
  "33175",
  "33182",
  "33184",
  "33186",
  "33196",
  "33165",
  "33147",
  "33167",
]
