import React from "react"
import styled from "styled-components"
import { Span, B16 } from "@rentspree/component-v2"
import { isEmpty } from "lodash"
import { Div } from "../layout"
import { pricePositionFormat } from "../../utils/price-render-format"
import { generateMultiShareMessage } from "../../containers/rental-submission/helper"

export const Ul = styled.ul`
  padding-left: 35px;
  li {
    margin-bottom: 10px;
  }
`

export const AlertDetails = styled.div`
  line-height: 1.63;
`
export const ScreeningFeeWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`

export const InstructionList = () => (
  <Ul>
    <li>
      <B16 m0>
        Go at your own pace. You can pause and resume your application as you
        need.
      </B16>
    </li>
    <li>
      <B16 m0>
        When applying for the same household, each applicant needs to do this
        separately, with their own email address.
      </B16>
    </li>
  </Ul>
)

export const ApplicationFeeAndMessage = ({
  fee,
  isShowApplicationFee,
  multiShare,
  screeningOption,
  customLabel,
}) => {
  let priceLabel
  if (customLabel) {
    priceLabel = customLabel
  } else if (screeningOption?.premium || !isEmpty(screeningOption?.proOption)) {
    priceLabel = "Screening Reports & Documents fee"
  } else {
    priceLabel = "Screening Reports fee"
  }

  return (
    <ScreeningFeeWrapper margin="0 0 20px 0">
      {isShowApplicationFee && (
        <Div data-testid="instruction-page-application-fee-text">
          <Span size="20px" weight="600">
            {priceLabel}: ${pricePositionFormat(fee)}
          </Span>
        </Div>
      )}
      {multiShare.isMultiShareActive && (
        <Div data-testid="instruction-page-multi-share-text">
          <Span size="16px" weight="600">
            {generateMultiShareMessage(multiShare)}
          </Span>
        </Div>
      )}
    </ScreeningFeeWrapper>
  )
}
