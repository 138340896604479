import isEmpty from "lodash/isEmpty"
import { COLOR } from "styles/settings"
import React from "react"
import styled from "styled-components"
import { Button, Icon } from "@rentspree/component-v2"
import { DISPLAY_DOCUMENT_TYPE, uploadDocumentWording } from "./constant"
import { StatusButton } from "./status-button"

const Body = styled.div`
  color: ${COLOR.textBlack};
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

const Description = styled.p`
  margin-bottom: 5px;
`

const DocumentList = styled.ul`
  padding-left: 39px;
`

const DocumentListItem = styled.li`
  color: ${COLOR.lightGray};

  @media (max-width: 767px) {
    margin-left: 17px;
  }
`

const DocumentItemText = styled.span`
  color: ${COLOR.textBlack};
`

const CheckIcon = styled(Icon)`
  margin-left: 8px;
  color: ${COLOR.green};
  display: ${({ isUploaded }) => (isUploaded ? "inline" : "none")};
`

const atLeastOneFileUploadedSuccessfully = files =>
  files.some(file => !isEmpty(file.id))

export const DocumentStep = ({
  documents = [],
  onUploadDocument = () => {},
}) => {
  const totalUploadedDocuments = documents.filter(
    document =>
      !isEmpty(document.files) &&
      atLeastOneFileUploadedSuccessfully(document.files),
  ).length
  const totalDocuments = documents.length
  const isComplete = totalUploadedDocuments === totalDocuments

  return (
    <div>
      <DesktopDocumentStep
        documents={documents}
        totalUploadedDocuments={totalUploadedDocuments}
        totalDocuments={totalDocuments}
        isComplete={isComplete}
        onUploadDocument={onUploadDocument}
      />
      <MobileDocumentStep
        documents={documents}
        totalUploadedDocuments={totalUploadedDocuments}
        totalDocuments={totalDocuments}
        isComplete={isComplete}
        onUploadDocument={onUploadDocument}
      />
    </div>
  )
}

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLOR.newGrey};

  @media (max-width: 767px) {
    display: none;
  }
`

const DesktopTitle = styled.h3`
  color: ${({ isComplete }) => (isComplete ? COLOR.darkGreen : COLOR.newBlack)};
  font-family: Merriweather, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  > span {
    display: ${({ isComplete }) => (isComplete ? "none" : "inline")};
  }
`

const DesktopDocumentStep = ({
  documents,
  totalUploadedDocuments,
  totalDocuments,
  isComplete,
  onUploadDocument,
}) => (
  <DesktopContainer>
    <div>
      <DesktopTitle isComplete={isComplete}>
        Additional Documents{" "}
        <span>
          ({totalUploadedDocuments}/{totalDocuments})
        </span>
      </DesktopTitle>
      <Body>
        <Description>{uploadDocumentWording}</Description>
        <DocumentList>
          {documents.map(document => (
            <DocumentListItem key={document.type}>
              <DocumentItemText>
                {DISPLAY_DOCUMENT_TYPE[document.type]}
              </DocumentItemText>
              <CheckIcon
                name="success"
                isUploaded={atLeastOneFileUploadedSuccessfully(document.files)}
              />
            </DocumentListItem>
          ))}
        </DocumentList>
      </Body>
    </div>
    <div>
      <Button
        google
        text={isComplete ? "Edit" : "Upload"}
        small
        minWidth="128"
        weight="600"
        onClick={onUploadDocument}
      />
    </div>
  </DesktopContainer>
)

const MobileContainer = styled.div`
  display: none;
  padding: 20px;
  margin-bottom: 20px;
  border-left: solid
    ${({ isComplete }) => (isComplete ? COLOR.darkGreen : COLOR.lightGray)} 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  @media (max-width: 767px) {
    display: block;
  }
`

const MobileTitle = styled.h3`
  color: ${({ isComplete }) => (isComplete ? COLOR.darkGreen : COLOR.newBlack)};
  font-family: Merriweather, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px 0;

  > span {
    display: ${({ isComplete }) => (isComplete ? "none" : "inline")};
  }
`

const StatusButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const MobileDocumentStep = ({
  documents,
  totalUploadedDocuments,
  totalDocuments,
  isComplete,
  onUploadDocument,
}) => (
  <MobileContainer data-testId="document-step-mobile" isComplete={isComplete}>
    <React.Fragment>
      <MobileTitle isComplete={isComplete}>
        Additional Documents{" "}
        <span>
          ({totalUploadedDocuments}/{totalDocuments})
        </span>
      </MobileTitle>
      <Body>
        <Description>{uploadDocumentWording}</Description>
        <DocumentList>
          {documents.map(document => (
            <DocumentListItem key={document.type}>
              <DocumentItemText>
                {DISPLAY_DOCUMENT_TYPE[document.type]}
              </DocumentItemText>
              <CheckIcon
                name="success"
                isUploaded={atLeastOneFileUploadedSuccessfully(document.files)}
              />
            </DocumentListItem>
          ))}
        </DocumentList>
      </Body>
    </React.Fragment>
    <StatusButtonWrapper>
      <StatusButton
        text={isComplete ? "Edit" : "Upload"}
        onClick={onUploadDocument}
      />
    </StatusButtonWrapper>
  </MobileContainer>
)
