export const GET_TU_AGREEMENT_CONTENT_CALL = "GET_TU_AGREEMENT_CONTENT_CALL"
export const GET_TU_AGREEMENT_CONTENT_REQUESTED =
  "GET_TU_AGREEMENT_CONTENT_REQUESTED"
export const GET_TU_AGREEMENT_CONTENT_SUCCESS =
  "GET_TU_AGREEMENT_CONTENT_SUCCESS"
export const GET_TU_AGREEMENT_CONTENT_FAILED = "GET_TU_AGREEMENT_CONTENT_FAILED"

export const SET_IS_RE_CONSENT = "SET_IS_RE_CONSENT"

export const AGREEMENT_TYPE = {
  TU_RENTER: "tu-renter",
}

export const AGREEMENT_ENDPOINT = "/api/v2/agreements"
export const GET_TU_AGREEMENT_ENDPOINT = `${AGREEMENT_ENDPOINT}/${
  AGREEMENT_TYPE.TU_RENTER
}/is-accepted`
export const ACCEPT_TU_AGREEMENT_ENDPOINT = `${AGREEMENT_ENDPOINT}/${
  AGREEMENT_TYPE.TU_RENTER
}/accept`
export const GET_TU_AGREEMENT_CONTENT_ENDPOINT = `${AGREEMENT_ENDPOINT}/:type`

export const RE_CONSENT_PANEL = {
  TITLE: "The tenant screening service agreement has been updated.",
  BODY:
    "In order to access tenant screening, you must accept the latest agreement.",
}
