import { apiInstance } from "utils/api-interceptor"
import { call, put, takeLatest } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import {
  VERIFY_MULTI_SHARE_API_URL,
  VERIFY_MULTI_SHARE_CALL,
} from "./constants"
import { verifyMultiShareApiActions } from "./actions"

export const verifyMultiShareApi = () =>
  apiInstance.get(buildPath(VERIFY_MULTI_SHARE_API_URL))

export function* verifyMultiShare() {
  try {
    const verifyMultiShareResponse = yield call(verifyMultiShareApi)
    yield put(verifyMultiShareApiActions.success(verifyMultiShareResponse))
  } catch (error) {
    yield put(verifyMultiShareApiActions.failure(error))
  }
}

export function* watchMultiShare() {
  yield takeLatest(VERIFY_MULTI_SHARE_CALL, verifyMultiShare)
}
