import axios from "axios"
import { axiosAfterLoginRequest, axiosResponse } from "@rentspree/axios"
import {
  API_URL,
  USER_API_URL,
  AGENT_REVIEW_API_BASE_URL,
  FILES_URL,
} from "../env"
import {
  handleApiErrors,
  handleAuthorization,
  handleApiErrorsByPass404,
} from "./handle-api-errors"

axios.interceptors.response.use(axiosResponse.response, error => {
  throw error.response
})
const apiInstance = axios.create({
  baseURL: API_URL,
})

const financialVerificationApiInstance = axios.create({
  baseURL: API_URL,
})

const userInstance = axios.create({
  baseURL: USER_API_URL,
})

const filesInstance = axios.create({
  baseURL: FILES_URL,
})

const documentApiInstance = axios.create({
  baseURL: `${API_URL}/api/v2/other-documents`,
})

const agentReviewApiInstance = axios.create({
  baseURL: `${API_URL}${AGENT_REVIEW_API_BASE_URL}`,
})

const request = axiosAfterLoginRequest(
  { accessTokenKey: "access_token" },
  {
    headers: {
      post: {
        "Content-Type": "application/json",
      },
    },
  },
)

apiInstance.interceptors.request.use(request)

financialVerificationApiInstance.interceptors.request.use(request)

userInstance.interceptors.request.use(request)

filesInstance.interceptors.request.use(request)

documentApiInstance.interceptors.request.use(request)

apiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

apiInstance.interceptors.response.use(r => r, error => handleApiErrors(error))

financialVerificationApiInstance.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

financialVerificationApiInstance.interceptors.response.use(
  r => r,
  error => handleApiErrorsByPass404(error),
)

userInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

userInstance.interceptors.response.use(r => r, error => handleApiErrors(error))

agentReviewApiInstance.interceptors.request.use(request)

agentReviewApiInstance.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

filesInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

documentApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

export {
  apiInstance,
  userInstance,
  agentReviewApiInstance,
  filesInstance,
  documentApiInstance,
  financialVerificationApiInstance,
}
