import React from "react"
import _ from "lodash"
import { B16, Span, T20 } from "@rentspree/component-v2"
import styled from "styled-components"
import { COLOR } from "styles/settings"

import { Tooltip } from "components/tooltip"
import { TUScreeningBody } from "components/tooltip/tooltip-body"
import TransUnionLogo from "images/icon/transunion-logo.svg"

import {
  STATUS,
  RENTAL_STEP,
  STATUS_DATA_COMPLETED,
  STATUS_DATA_STARTED,
  STATUS_DATA_CREATED,
  STATUS_DATA_DISABLE,
} from "./constant"
import { Ul } from "../instruction"

export const RentalStep = ({
  step,
  status,
  index,
  onClick,
  screeningOption,
  isIncomeVerificationGateEnable,
}) => {
  const statusData = statusMapper(status, step)
  return (
    <Container
      borderColor={statusData.color}
      textColor={statusData.textColorMobile}
      shadow={statusData.shadow}
      status={status}
      index={index}>
      <FlexContainer
        height="100%"
        direction="row"
        minHeight="50px"
        justify="space-between"
        alignContent="center"
        alignItems="center"
        mDirection="column"
        mJustify="flex-start"
        mAlignItems="flex-start">
        <ContentContainer>
          <StepTitleComponent
            step={step}
            title={stepMapper(step)}
            color={statusData.textColor}
            index={index}
          />
          <StatusComponent
            step={step}
            screeningOption={screeningOption}
            status={statusData.status}
            isIncomeVerificationGateEnable={isIncomeVerificationGateEnable}
          />
        </ContentContainer>
        <ButtonContainer>
          <statusData.button
            onClick={onClick}
            id={`guidePage${_.upperFirst(step)}${_.upperFirst(status)}Btn`}
          />
        </ButtonContainer>
      </FlexContainer>
    </Container>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -5px;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: flex-end;
  }
`

export const stepMapper = step => {
  switch (step) {
    case RENTAL_STEP.APPLICANT:
      return "Rental Specifics"
    case RENTAL_STEP.APPLICATION:
      return "Rental Application"
    case RENTAL_STEP.CREDIT_REPORT:
      return "TransUnion Screening"
    default:
      return ""
  }
}
export const statusMapper = (status, step) => {
  switch (status) {
    case STATUS.CREATED:
      return STATUS_DATA_CREATED
    case STATUS.EDIT:
      return { ...STATUS_DATA_STARTED }
    case STATUS.READY:
    case STATUS.REVIEW:
      return step === RENTAL_STEP.CREDIT_REPORT
        ? { ...STATUS_DATA_COMPLETED, button: () => null }
        : STATUS_DATA_COMPLETED
    case STATUS.RENTER_SIGNED:
      return { ...STATUS_DATA_COMPLETED, button: () => null }
    case STATUS.DISABLE:
    default:
      return STATUS_DATA_DISABLE
  }
}

export const StepTitleComponent = ({ step, title, index, color }) => (
  <TitleContainer>
    <T20 margin="0 0 5px 0" mMargin="0px 0px 20px 0px" color={color}>
      {index}. {title}
    </T20>
    {step === RENTAL_STEP.CREDIT_REPORT && (
      <img
        style={{
          marginTop: "1px",
          marginLeft: "5px",
          width: "20px",
          height: "20px",
        }}
        src={TransUnionLogo}
        alt="TransUnion Logo"
      />
    )}
  </TitleContainer>
)

export const TitleContainer = styled.div`
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    margin-left: 20px;
    margin-bottom: 17px;
  }
`

export const ScreeningOptionWrapper = styled(Ul)`
  padding-left: 20px;
  @media (max-width: 767px) {
    padding-left: 0px;
  }
  li {
    margin-bottom: -2px;
    color: ${COLOR.placeholderGrey};
    @media (max-width: 767px) {
      margin-left: 17px;
    }
  }
`

const CreditReportWrapper = styled(Span)`
  @media (max-width: 375px) {
    display: inline;
    p {
      display: inline;
    }
  }
  display: flex;
  column-gap: -5px;
`

export const StatusComponent = ({
  step,
  screeningOption,
  status,
  isIncomeVerificationGateEnable,
}) => {
  const {
    credit_report: creditReport,
    criminal,
    eviction,
    premium,
  } = screeningOption
  return (
    <StatusWrapper>
      <B16 margin="0px" mColor="#4A4A4A">
        {status}
      </B16>
      {step === RENTAL_STEP.CREDIT_REPORT && (
        <ScreeningOptionWrapper>
          {creditReport && (
            <li>
              <CreditReportWrapper>
                <B16 m0>TransUnion Credit Report & Score</B16>
                <Tooltip
                  mobileHeaderText="TransUnion Screening"
                  color={COLOR.placeholderGrey}>
                  <TUScreeningBody />
                </Tooltip>
              </CreditReportWrapper>
            </li>
          )}
          {criminal && (
            <li>
              <B16 m0>TransUnion Criminal Background Check</B16>
            </li>
          )}
          {eviction && (
            <li>
              <B16 m0>TransUnion Eviction Related Proceedings</B16>
            </li>
          )}
          {premium &&
            !isIncomeVerificationGateEnable && (
              <li>
                <B16 m0>TransUnion Income Estimate</B16>
              </li>
            )}
        </ScreeningOptionWrapper>
      )}
    </StatusWrapper>
  )
}

export const Container = styled.div`
  ${props => props.index === 1 && `border-top: 1px solid ${COLOR.lineGray};`}
  border-bottom: 1px solid ${COLOR.lineGray};
  height: auto;
  min-height: 100px;
  padding: 25px 0px 10px 0;
  @media (max-width: 767px) {
    height: auto;
    min-height: 110px;
    border-top: none;
    border-bottom: none;
    border-left: 5px solid ${props => props.borderColor};
    background-color: white;
    margin-bottom: 20px;
    box-shadow: ${props => props.shadow};
    padding: 20px;
    ${props =>
      props.status === STATUS.DISABLE &&
      `
      background-color: ${props.borderColor};
      color: ${props.textColor};
    `};
  }
`

export const FlexContainer = styled.div`
  display: flex;
  ${props => props.minHeight && `min-height: ${props.minHeight}`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.flexWrap && "flex-wrap: wrap"};
  ${props => props.height && `height: ${props.height}`};
  flex-direction: ${props => (props.direction ? props.direction : `column`)};
  align-items: ${props => (props.alignItems ? props.alignItems : `center`)};
  justify-content: ${props => (props.justify ? props.justify : `center`)};
  align-content: ${props =>
    props.alignContent ? props.alignContent : `strech`};
  ${props => props.gap && `gap: ${props.gap}`};
  text-align: left;
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.hideOnDesktop && `display: none`};
  @media (max-width: 767px) {
    ${props => props.mMargin && `margin: ${props.mMargin}`};
    ${props => props.mDirection && `flex-direction: ${props.mDirection}`};
    ${props => props.mJustify && `justify-content: ${props.mJustify}`};
    ${props => props.mAlignItems && `align-items: ${props.mAlignItems}`};
    ${props => props.mFlex && `flex: ${props.mFlex}`};
    ${props => props.mGap && `gap: ${props.mGap}`};
    ${props => props.hideOnMobile && `display: none`};
    ${props => props.hideOnDesktop && `display: flex`};
  }
`
