import {
  RENTAL_SPECIFICS_AUTOSAVE_CALL,
  QUEUE_RENTAL_SPECIFICS_AUTOSAVE,
  CANCEL_RENTAL_SPECIFICS_AUTOSAVE,
  RENTAL_SPECIFICS_AUTOSAVE_ERROR,
  RENTAL_APPLICATION_AUTOSAVE_CALL,
  RENTAL_APPLICATION_AUTOSAVE_ERROR,
  CANCEL_RENTAL_APPLICATION_AUTOSAVE,
  QUEUE_RENTAL_APPLICATION_AUTOSAVE,
  RENTAL_APPLICATION_QUEUE_AUTOSAVE_SUCCESS,
  RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS,
} from "./constants"

export const autoSaveRentalSpecifics = payload => ({
  type: RENTAL_SPECIFICS_AUTOSAVE_CALL,
  payload,
})

export const queueAutoSaveRentalSpecifics = payload => ({
  type: QUEUE_RENTAL_SPECIFICS_AUTOSAVE,
  payload,
})

export const cancelAutoSaveRentalSpecifics = () => ({
  type: CANCEL_RENTAL_SPECIFICS_AUTOSAVE,
})

export const autoSaveRentalSpecificsError = error => ({
  type: RENTAL_SPECIFICS_AUTOSAVE_ERROR,
  error,
})

export const autoSaveRentalApplication = payload => ({
  type: RENTAL_APPLICATION_AUTOSAVE_CALL,
  payload,
})

export const queueAutoSaveRentalApplication = payload => ({
  type: QUEUE_RENTAL_APPLICATION_AUTOSAVE,
  payload,
})

export const cancelAutoSaveRentalApplication = () => ({
  type: CANCEL_RENTAL_APPLICATION_AUTOSAVE,
})

export const autoSaveRentalApplicationError = error => ({
  type: RENTAL_APPLICATION_AUTOSAVE_ERROR,
  error,
})

export const rentalSpecificsQueueAutosaveSuccess = payload => ({
  type: RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS,
  payload,
})

export const rentalApplicationQueueAutosaveSuccess = () => ({
  type: RENTAL_APPLICATION_QUEUE_AUTOSAVE_SUCCESS,
})
