import moment from "moment"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { LRA_RELEASE_DATE } from "env"

export const checkIsMonthlyRentRequired = (isCARApplication, rental) => {
  const createdAt = get(rental, "created_at")

  return (
    isCARApplication &&
    (isEmpty(createdAt) || moment(createdAt).isAfter(LRA_RELEASE_DATE))
  )
}
