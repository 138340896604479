import { useState, useLayoutEffect } from "react"

/**
 * @typedef {Object} ComponentDimensions
 * @property {number} height - component height
 * @property {number} width - component width
 * @property {number} left - component left value from parent DOM
 * @property {number} top - component top value from parent DOM
 */

/**
 * This custom hook will get a width, height, top, and left of a component when it is rendered
 *
 * @param {React.MutableRefObject<any>} ref - React ref
 * @returns {[ComponentDimensions, React.Dispatch<React.SetStateAction<ComponentDimensions>>]} An array of dimension values and a function to set the dimensions
 */
export function useDimensions(ref, ratio) {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  })

  useLayoutEffect(
    () => {
      if (ref?.current) {
        const {
          width,
          height,
          top: rectTop,
          left: rectLeft,
        } = ref.current.getBoundingClientRect()
        setDimensions({
          width,
          height,
          top: rectTop,
          left: rectLeft,
        })
      }
    },
    [ratio],
  )

  return [dimensions, setDimensions]
}
