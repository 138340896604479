import styled from "styled-components"

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 0 20px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    max-width: 720px;
  }

  @media (max-width: 767px) {
    min-height: 100%;
    max-width: 720px;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        padding-bottom: 20px;
      }
    }
  }
`

export const VersionTag = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 10px;
  @media (max-width: 767px) {
    display: none;
  }
`
