import React from "react"
import styled from "styled-components"
import { S16, B16 } from "@rentspree/component-v2"

import Col from "components/atoms/col"

export const StyleCol = styled(Col)`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  width: 250px;
  > h4,
  p {
    margin: 0;
  }

  .subtitle-label {
    line-height: 20px;
  }
  .review-text {
    line-height: 22px;
    overflow-wrap: break-word;
  }
`

const TextWithLabel = ({ id, label, text, ...rest }) => (
  <StyleCol {...rest}>
    <S16 className="subtitle-label" size="14px" width="100%">
      {label}
    </S16>
    <B16 data-testid={id} className="review-text" width="100%">
      {text}
    </B16>
  </StyleCol>
)
export default TextWithLabel
