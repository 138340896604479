import React from "react"
import { T36 } from "@rentspree/component-v2"
import { withTracker, PAGE_NAME, tracker } from "tracker"
import usePageViewedTracker from "tracker/hooks/use-page-viewed-tracker"

import ApplicantInformationForm from "./form"
import { withConnect } from "../connect"

export const ApplicantInformationContainer = props => {
  usePageViewedTracker({
    routeMatch: props.match,
    routeLocation: props.location,
    windowLocation: window.location,
    domDocument: document,
  })

  return (
    <div>
      <T36 margin="50px 0 30px">Applicant Information</T36>
      <ApplicantInformationForm {...props} />
    </div>
  )
}

export default withConnect(
  withTracker(ApplicantInformationContainer, tracker, PAGE_NAME.APPLICANT.INFO),
)
