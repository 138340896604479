import React from "react"
import { withRouter } from "react-router-dom"
import { compose } from "redux"

import { callScroll } from "utils/call-window"

export class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      callScroll(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default compose(withRouter)(ScrollToTop)
