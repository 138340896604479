import produce from "immer"

import { ADD_TOAST, CLOSE_TOAST } from "./constants"

export const initialState = []

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case ADD_TOAST:
        draft.push(payload)
        break
      case CLOSE_TOAST:
        draft = draft.filter(toast => toast.id !== payload)
        break
      default:
        break
    }
    return draft
  })

export default reducer
