/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from "redux"
import { fromJS } from "immutable"
import { routerMiddleware } from "connected-react-router/immutable"
import createSagaMiddleware from "redux-saga"
import createReducer from "./reducers"
import sweetAlertMiddleware from "./redux-middleware/sweet-alert"

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. sweetAlertMiddleware: Enable sweetAlert to triggers application-wide
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    sweetAlertMiddleware,
  ]

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    (process.env.NODE_ENV !== "production" ||
      process.env.DEV_SITE === "true") &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
