import React from "react"
import B14 from "@rentspree/component-v2/dist/typography/b14"
import B16 from "@rentspree/component-v2/dist/typography/b16"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import CenterContent from "components/atoms/center-content"
import { TU_CONTACT } from "../constants"

export const error12 = {
  title: "You have exceed the verification attempt limit",
  body: (
    <div>
      <B16 lineHeight="24px" margin="0 0 27px 0">
        Sorry, you have exceeded the maximum number of attempts to verify your
        identity online.
      </B16>
      <p className="detail">
        To finish your screening, you must follow these steps:
      </p>
      <p className="detail">
        1) Call TransUnion at {TU_CONTACT.PHONE}.
        <a href={TU_CONTACT.KB_URL} target="_blank">
          View TransUnion&apos;s support hours
        </a>
      </p>
      <p className="detail">
        2) Request to <b>manually verify your identity</b> and provide the email
        address you used for RentSpree.
      </p>
      <p className="detail">3) Follow TransUnion&apos;s instructions.</p>
      <p className="detail">
        4) Once you are verified, you must return to your RentSpree Dashboard to
        fill in your information and submit your screening.
      </p>
      <B16 lineHeight="24px" margin="27px 0 30px 0">
        Contact us at (323) 515-7757 or support@rentspree.com if you have any
        difficulty.
      </B16>
    </div>
  ),
  checkboxLabel:
    "I have called TransUnion and received confirmation that I am verified.",
}

const error14 = {
  title: "Please wait 5 minutes before trying again",
  body: (
    <B16 lineHeight="24px" margin="0 0 30px 0">
      Sorry, we&apos;re having trouble connecting with TransUnion. Please try
      again in 5 minutes. If the problem persists, please contact us at (323)
      515-7757 or support@rentspree.com.
    </B16>
  ),
  checkboxLabel:
    "I have waited 5 minutes and am ready to continue the verification process.",
}

const error10 = {
  title: "Next Step",
  body: TuApplicationId => (
    <div>
      <B16 lineHeight="24px" margin="0 0 30px 0">
        Additional verification is needed to process your screening. To
        continue, please call TransUnion, say <b>“verify my identity”</b>, and
        provide your <b>email address</b> and the <b>AppID#{TuApplicationId}</b>
      </B16>
      <CenterContent margin="50px 0" centerText>
        <S24 size="36px" mSize="28px" margin="0">
          {TU_CONTACT.PHONE}
        </S24>
        <B14 margin="0" mSize="12px">
          <a href={TU_CONTACT.KB_URL} target="_blank">
            View TransUnion&apos;s support hours
          </a>
        </B14>
      </CenterContent>
    </div>
  ),
  formContent: {
    checkboxLabel: "I have called TransUnion and am ready to proceed.",
    callToActionText: "Proceed",
  },
  newTemplate: true,
}

const error15 = {
  title: "Please wait 5 minutes before trying again",
  body: () => (
    <div>
      <B16 lineHeight="24px" margin="0 0 30px 0">
        Sorry, your identity verification is still in progress. Please try again
        in 5 minutes. If the problem persists, please contact us at (323)
        515-7757 or support@rentspree.com.
      </B16>
    </div>
  ),
  formContent: {
    checkboxLabel:
      "I have waited 5 minutes and am ready to continue the verification process.",
    callToActionText: "Continue",
  },
  newTemplate: true,
}

export default code => {
  switch (code) {
    case "200012":
      return error12
    case "200014":
      return error14
    case "200010":
      return error10
    case "200015":
      return error15
    default:
      return error14
  }
}

export const TU_ERROR_CODE = {
  "200010": "200010",
  "200012": "200012",
  "200014": "200014",
  "200015": "200015",
}

export const PROVIDER = {
  SHAREABLE: "shareable",
  SMARTMOVE: "smartmove",
}
