import get from "lodash/get"
import isEqual from "lodash/isEqual"
import isEmpty from "lodash/isEmpty"
import omitBy from "lodash/omitBy"
import last from "lodash/last"
import { ROUTE, OCCURRING } from "containers/router/constants"
import { USER_TYPE } from "constants/user"
import { APPLICANT_TYPE } from "../containers/applicant-info/constants"
import { SELECTED_RESIDENCE } from "../containers/application/residence/constants"

export const TRACKING_EVENT_METHOD_NAME = "trackEvent"

export const APPLICATION = {
  TU: "TransUnion",
}

export const MAP_PROP_OBJECT = {
  RESPONSE: "response",
  PARSED_PAYLOAD: "parsedPayload",
  RENTAL: "rental",
}

export const EVENT_RENTAL = {
  category: "Rental Submission",
  flow: {
    createNewAccount: {
      action: "Rental submission - Click create new account",
    },
    viewMySubmission: {
      action: "Rental submission - Click view my submission",
    },
  },
}

export const EVENT_RENTAL_V3 = {
  category: "Rental Submission 3.0",
  flow: {
    attach_report: {
      action: "Rental submission - Click attach report",
    },
    save_tu_success: {
      action: "Rental submission - Save TU screening success",
    },
  },
}

export const EVENT_RENTAL_V3_1 = {
  category: "Rental Submission 3.1",
  flow: {
    enterApplyNowPage: {
      action: "Enters apply now page",
    },
    proceedThoughApply: {
      action: "Proceed though apply",
    },
    skipInsertPhone: {
      action: "Click skip insert phone no",
    },
    skipInsertEmail: {
      action: "Click skip insert email",
    },
    proTipMessage: {
      action: "Hover Pro Tip",
    },
    proTipMessageMobile: {
      action: "Click Pro Tip",
    },
  },
}

export const EVENT_RENTAL_V3_2 = {
  category: "Rental Submission 3.2",
  flow: {
    revealSsn: {
      action: "reveal ssn",
    },
    backToEdit: {
      action: "incorrect information",
    },
    confirm: {
      action: "confirm information",
    },
  },
}

export const prepareTrackEvent = (category, event) => {
  const newEvent = {}
  Object.keys(event).forEach(key => {
    newEvent[key] = {
      action: get(event, [key, "action"]),
      properties: {
        event_category: category,
        category, // default category key for amplitude because amplitude provide analyzed data for this key
      },
    }
  })
  return newEvent
}

export const LOG_EVENT = {
  rental: prepareTrackEvent(EVENT_RENTAL.category, EVENT_RENTAL.flow),
  rental_v3: prepareTrackEvent(EVENT_RENTAL_V3.category, EVENT_RENTAL_V3.flow),
  rental_v3_1: prepareTrackEvent(
    EVENT_RENTAL_V3_1.category,
    EVENT_RENTAL_V3_1.flow,
  ),
  rental_v3_2: prepareTrackEvent(
    EVENT_RENTAL_V3_2.category,
    EVENT_RENTAL_V3_2.flow,
  ),
}

export const RENTAL_PAGE_NAME = {
  INSTRUCTION: "Rental submission-intro",
  GUIDE: "Rental submission-guide",
  PAYMENT: "Rental submission-payment",
  INACTIVE_PROPERTY: "Rental submission-inactive property",
  FINISHED: "Rental submission-submit form",
}
export const APPLICANT_INFO_PAGE_NAME = {
  MOVE_IN: "Applicant-move in date",
  INFO: "Applicant-applicant info",
  GUARANTORS: "Applicant-guarantors",
  TENANTS: "Applicant-other tenants",
}
export const TU_PAGE_NAME = {
  TERM: "TU-service agreement",
  INFO: "TU-screening-screening info",
  INFO_SSN: "TU-screening-screening info-ssn",
  ERROR_200010: "TU-processing-200010",
  ERROR_200012: "TU-processing-200012",
  ERROR_200014: "TU-processing-200014",
  ERROR_200015: "TU-processing-200015",
  ATTACH: "TU-attach report",
  EXAM: "TU-exam",
  TU_REVIEW: "TU information confirmation",
}

export const PAGE_NAME = {
  PERSONAL_INFO: "Application-personal info",
  PERSONAL_GOV_ID: "Application-government issued id",
  PERSONAL_EMERGENCY: "Application-emergency contact",
  CURRENT_RES_TYPE: "Application-current res type",
  CURRENT_RES_RENT_ADDRESS: "Application-current res-rental property-address",
  CURRENT_RES_RENT_DURATION:
    "Application-current res-rental property-duration of stay",
  CURRENT_RES_RENT_INFO: "Application-current res-rental property-rental info",
  CURRENT_RES_OWNER_ADDRESS: "Application-current res-owner occupied-address",
  CURRENT_RES_OWNER_DURATION:
    "Application-current res-owner occupied-duration of stay",
  PREVIOUS_RES_TYPE: "Application-previous res type",
  PREVIOUS_RES_RENT_ADDRESS: "Application-previous res-rental property-address",
  PREVIOUS_RES_RENT_DURATION:
    "Application-previous res-rental property-duration of stay",
  PREVIOUS_RES_RENT_INFO:
    "Application-previous res-rental property-rental info",
  PREVIOUS_RES_OWNER_ADDRESS: "Application-previous res-owner occupied-address",
  PREVIOUS_RES_OWNER_DURATION:
    "Application-previous res-owner occupied-duration of stay",
  CURRENT_OCC_TYPE: "Application-current occupation type",
  CURRENT_OCC_EMPLOYED_INFO:
    "Application-current occupation-employed-employment info",
  CURRENT_OCC_EMPLOYED_DURATION:
    "Application-current occupation-employed-duration of work",
  CURRENT_OCC_EMPLOYED_SUPERVISOR:
    "Application-current occupation-employed-supervisor details",
  CURRENT_OCC_EMPLOYED_ADDRESS:
    "Application-current occupation-employed-work address",
  CURRENT_OCC_EMPLOYED_ADDITIONAL:
    "Application-current occupation-employed-additional income",
  CURRENT_OCC_RETIED_ADDITIONAL:
    "Application-current occupation-retired-additional income",
  CURRENT_OCC_UNEMPLOYED_ADDITIONAL:
    "Application-current occupation-unemployed-additional income",
  CURRENT_OCC_STUDENT_ADDITIONAL:
    "Application-current occupation-student-additional income",
  PREVIOUS_OCC_TYPE: "Application-previous occupation type",
  PREVIOUS_OCC_EMPLOYED_INFO:
    "Application-previous occupation-employed-employment info",
  PREVIOUS_OCC_EMPLOYED_DURATION:
    "Application-previous occupation-employed-duration of work",
  PREVIOUS_OCC_EMPLOYED_SUPERVISOR:
    "Application-previous occupation-employed-supervisor details",
  PREVIOUS_OCC_EMPLOYED_ADDRESS:
    "Application-previous occupation-employed-work address",
  // TODO: Is This Correct
  REF_CREDIT_INFORMATION: "Application-credit information",
  REF_NEAREST_RELATIVE: "Application-nearest relative",
  REF_PERSONAL: "Application-personal ref",
  MISC_PET: "Application-pet",
  MISC_VEHICLE: "Application-vehicle",
  MISC_ADDITIONAL: "Application-additional info",
  SUBMITTED_PAGE: "Rental submission-prevent duplicate submission",
  TU: { ...TU_PAGE_NAME },
  APPLICANT: { ...APPLICANT_INFO_PAGE_NAME },
  RENTAL: { ...RENTAL_PAGE_NAME },
}

export const getPageName = (condition, value, firstPage, secondPage) =>
  condition === value ? firstPage : secondPage

export const getAdditionalPageName = selected => {
  switch (selected) {
    case "employed":
    case "retired":
      return PAGE_NAME.CURRENT_OCC_EMPLOYED_ADDITIONAL
    case "unemployed":
      return PAGE_NAME.CURRENT_OCC_UNEMPLOYED_ADDITIONAL
    case "student":
      return PAGE_NAME.CURRENT_OCC_STUDENT_ADDITIONAL
    default:
      return ""
  }
}

export const getResidentPageName = (
  occurring,
  selected,
  currentRentalPage,
  currentOwnerPage,
  previousRentalPage,
  previousOwnerPage,
) => {
  if (occurring === "current") {
    switch (selected) {
      case "rental":
        return currentRentalPage
      case "owner":
        return currentOwnerPage
      default:
        return ""
    }
  } else if (occurring === "previous") {
    switch (selected) {
      case "rental":
        return previousRentalPage
      case "owner":
        return previousOwnerPage
      default:
        return ""
    }
  } else {
    return ""
  }
}

export const generateAmplitudeProfileItem = (value, setOnce = false) => ({
  value,
  setOnce,
})

export const INSTRUCTION_EVENT = {
  clickApplyNow: response => {
    const {
      id: rentalSubmissionId,
      screeningOption: {
        application,
        credit_report: creditReport,
        criminal,
        eviction,
        payerType,
        premium,
      },
      isMultiShareActive = false,
      property,
    } = response

    const isAgentPay = isEqual(payerType, "landlord")

    const clickApplyNowEvent = {
      name: "Rental submission - Click Apply now",
      properties: {
        screening_option: "all",
        "screening_option.application": application || false,
        "screening_option.creditReport": creditReport || false,
        "screening_option.criminal": criminal || false,
        "screening_option.eviction": eviction || false,
        "screening_option.premium": premium || false,
        "screening_option.doc_upload": !!get(
          response,
          "proOption.documents",
          false,
        ),
        "screening_option.ref_check": !!get(
          response,
          "proOption.references",
          false,
        ),
        payer_type: !isAgentPay ? USER_TYPE.RENTER : USER_TYPE.AGENT,
        template: response?.application?.type,
        template_version: response?.application?.version,
        rental_submission_id: rentalSubmissionId,
        multi_share: isMultiShareActive && creditReport,
        screening_without_property: !property,
      },
    }

    if (application && !creditReport) {
      clickApplyNowEvent.properties.screening_option = "only_app"
    } else if (!application && creditReport) {
      clickApplyNowEvent.properties.screening_option = "only_tu"
    }

    return clickApplyNowEvent
  },
  RESUME_APPLICATION_FROM_REMINDER_EMAIL:
    "resume application from reminder email",
}

export const RENTAL_INSTRUCTION_EVENT = {
  ENTER_PAGE: {
    EVENT_NAME: "enter submit your application page",
    EVENT_PROPS: {
      SCREENING_OPTION_PREMIUM: "screening_option_premium",
    },
  },
}

export const RENTAL_GUIDE_EVENT = {
  accessApplicant: "Access Applicant Information",
  accessRental: "Access Rental Application",
  accessTransUnion: "Access TransUnion Screening",
  // New Convention
  COMPLETE_APPLICANT_INFORMATION: "Complete Applicant Information",
  COMPLETE_RENTAL_APPLICATION: "Complete Rental Application",
}

export const APPLICANT_PATH_CONST = {
  saveMoveInDate: "Save move-in date",
  saveApplyAs: "Save applying as",
  SaveTenant: "Save tenants",
  SaveGuarantorsCoSigners: "Save guarantors & co-signers",
}

export const APPLICANT_INFO_PATH_PROPERTY = [
  {
    key: "rental_submission_id",
    mapProp: "rentalId",
    mapPropObject: MAP_PROP_OBJECT.RENTAL,
  },
  {
    key: "applying_as",
    mapProp: "applicant_type",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mappingData => {
      if (mappingData === APPLICANT_TYPE.TENANT) {
        return "tenant"
      } else if (mappingData === APPLICANT_TYPE.GUARANTOR) {
        return "co-signer/guarantor"
      }
      return ""
    },
  },
  {
    key: "is_working_with_agent",
    mapProp: "haveRenterAgent",
    mapPropObject: MAP_PROP_OBJECT.PARSED_PAYLOAD,
    parser: mappingData => mappingData === "yes",
  },
]

export const ADDITIONAL_APPLICANT_PATH_PROPERTY = [
  {
    key: "rental_submission_id",
    mapProp: "rentalId",
    mapPropObject: MAP_PROP_OBJECT.RENTAL,
  },
  {
    key: "has_co_applicant",
    mapProp: "haveTenants",
    mapPropObject: MAP_PROP_OBJECT.PARSED_PAYLOAD,
    parser: mapProp => mapProp === "yes",
  },
  {
    key: "has_co_signer",
    mapProp: "haveCosigners",
    mapPropObject: MAP_PROP_OBJECT.PARSED_PAYLOAD,
    parser: mapProp => mapProp === "yes",
  },
]

export const APPLICATION_EVENT_PROPERTY_BASE = [
  {
    key: "rental_submission_id",
    mapProp: "rentalId",
    mapPropObject: MAP_PROP_OBJECT.RENTAL,
  },
]

export const APPLICATION_EVENT_PROPERTY_EMERGENCY_CONTACT = [
  {
    key: "number_of_emergency_contacts",
    mapProp: "emergencyContacts",
    mapPropObject: MAP_PROP_OBJECT.PARSED_PAYLOAD,
    parser: mapProp => mapProp.length,
  },
]

export const APPLICATION_EVENT_PROPERTY_CURRENT_RESIDENCE = [
  {
    key: "current_property_ownership",
    mapProp: "residence",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => {
      if (mapProp.current.selected === SELECTED_RESIDENCE.OWNER) {
        return "own"
      } else if (mapProp.current.selected === SELECTED_RESIDENCE.RENTAL) {
        return "rent"
      }
      return ""
    },
  },
  {
    key: "section",
    mapProp: "section",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: () => "property_details",
  },
]

export const APPLICATION_EVENT_PROPERTY_PREVIOUS_RESIDENCE = [
  {
    key: "property_ownership",
    mapProp: "residence",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => {
      if (mapProp.previous.selected === SELECTED_RESIDENCE.OWNER) {
        return "own"
      } else if (mapProp.previous.selected === SELECTED_RESIDENCE.RENTAL) {
        return "rent"
      } else if (mapProp.previous.selected === SELECTED_RESIDENCE.NONE) {
        return "none"
      }
      return ""
    },
  },
  {
    key: "section",
    mapProp: "section",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: () => "property_details",
  },
]

export const APPLICATION_EVENT_PROPERTY_RESIDENCE_RENTAL = [
  {
    key: "section",
    mapProp: "section",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: () => "rental_information",
  },
]

export const APPLICATION_EVENT_PROPERTY_CURRENT_OCCUPATION = [
  {
    key: "employment_status",
    mapProp: "occupation",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.current.workStatus,
  },
  {
    key: "has_additional_income",
    mapProp: "occupation",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.additionalIncomes.length > 0,
  },
  {
    key: "has_rent_subsidy",
    mapProp: "occupation",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => !isEmpty(omitBy(mapProp.rentSubsidy, isEmpty)),
  },
  {
    key: "has_rent_subsidy_as_rent_payment",
    mapProp: "occupation",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => !!mapProp.rentSubsidy?.inLieuOfCredit,
  },
]

export const APPLICATION_EVENT_PROPERTY_PREVIOUS_OCCUPATION = [
  {
    key: "employment_status",
    mapProp: "occupation",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.previous.workStatus,
  },
]

export const APPLICATION_EVENT_PROPERTY_REF_CREDITOR = [
  {
    key: "number_of_creditors",
    mapProp: "reference",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.creditorInformation.length,
  },
]

export const APPLICATION_EVENT_PROPERTY_REF_BANK = [
  {
    key: "number_of_banks",
    mapProp: "reference",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.bankInformation.length,
  },
]

export const APPLICATION_EVENT_PROPERTY_REF_RELATIVES = [
  {
    key: "number_of_references",
    mapProp: "reference",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.nearestRelatives.length,
  },
]

export const APPLICATION_EVENT_PROPERTY_REF_PERSONAL = [
  {
    key: "number_of_references",
    mapProp: "reference",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp.personalReferences.length,
  },
]

export const APPLICATION_EVENT_PROPERTY_ADDITIONAL_INFORMATION = [
  {
    key: "has_pets",
    mapProp: "miscellaneous",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp?.pets?.length > 0,
  },
  {
    key: "has_vehicles",
    mapProp: "miscellaneous",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp?.vehicles?.length > 0,
  },
  {
    key: "number_of_pets",
    mapProp: "miscellaneous",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp?.pets?.length,
  },
  {
    key: "number_of_vehicles",
    mapProp: "miscellaneous",
    mapPropObject: MAP_PROP_OBJECT.RESPONSE,
    parser: mapProp => mapProp?.vehicles?.length,
  },
]

export const TU_EVENT = {
  enterAgreementPage: "Enter agreement page",
  acceptTransUnionTerm: "Accept TU Agreement",
  saveScreeningInfo: "Save Screening Info",
  submitTransUnionExam: "Submit Exam",
  // New Convention
  COMPLETE_TU_SCREENING: "Complete Tranunion screening",
}

export const PAYMENT_EVENT = {
  clickSubmit: "Click Submit Reports",
  payAndSubmit: "Pay & Submit",
  submitSuccess: "Submit Report Success",
  submitFail: "Submit Report Fail",
  viewScreeningReport: "View Screening Report",
}

export const needPayment = buttonAction => {
  switch (buttonAction) {
    case "onSubmit":
      return { need_payment: false }
    case "onPayAndSubmit":
      return { need_payment: true }
    default:
      return ""
  }
}

export const selectEventNameApplicant = route => {
  const { eventName, properties } = applicantPathKey(route.current)
  return {
    ...(eventName && { eventName: `${eventName}` }),
    ...(properties ? { properties } : {}),
  }
}

export const selectEventNameApplication = route => {
  const select = route.current
  switch (select) {
    case ROUTE.APPLICATION.PERSONAL.BASE +
      ROUTE.APPLICATION.PERSONAL.PERSONAL_INFO:
      return {
        eventName: applicationEventType.personalInfo,
        properties: [...APPLICATION_EVENT_PROPERTY_BASE],
      }
    case ROUTE.APPLICATION.PERSONAL.BASE + ROUTE.APPLICATION.PERSONAL.GOV_ID:
      return {
        eventName: applicationEventType.personalGovernmentId,
        properties: [...APPLICATION_EVENT_PROPERTY_BASE],
      }
    case ROUTE.APPLICATION.PERSONAL.BASE + ROUTE.APPLICATION.PERSONAL.EMERGENCY:
      return {
        eventName: applicationEventType.personalEmergency,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_EMERGENCY_CONTACT,
        ],
      }
    case `${ROUTE.APPLICATION.RESIDENCE.BASE}/${OCCURRING.CURRENT}`:
      return {
        eventName: applicationEventType.residenceCurrent,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_CURRENT_RESIDENCE,
        ],
      }
    case `${ROUTE.APPLICATION.RESIDENCE.BASE}/${OCCURRING.CURRENT}/rental`:
      return {
        eventName: applicationEventType.residenceCurrent,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_RESIDENCE_RENTAL,
        ],
      }
    case `${ROUTE.APPLICATION.RESIDENCE.BASE}/${OCCURRING.PREVIOUS}`:
      return {
        eventName: applicationEventType.residencePrevious,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_PREVIOUS_RESIDENCE,
        ],
      }
    case `${ROUTE.APPLICATION.RESIDENCE.BASE}/${OCCURRING.PREVIOUS}/rental`:
      return {
        eventName: applicationEventType.residencePrevious,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_RESIDENCE_RENTAL,
        ],
      }
    case `${ROUTE.APPLICATION.OCCUPATION.BASE}/${OCCURRING.CURRENT}`:
      return {
        eventName: applicationEventType.occupationCurrent,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_CURRENT_OCCUPATION,
        ],
      }
    case `${ROUTE.APPLICATION.OCCUPATION.BASE}/${OCCURRING.PREVIOUS}`:
      return {
        eventName: applicationEventType.occupationPrevious,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_PREVIOUS_OCCUPATION,
        ],
      }
    case ROUTE.APPLICATION.REFERENCE.BASE + ROUTE.APPLICATION.REFERENCE.CREDIT:
      return {
        eventName: applicationEventType.refCreditorInfo,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_REF_CREDITOR,
        ],
      }
    case ROUTE.APPLICATION.REFERENCE.BASE + ROUTE.APPLICATION.REFERENCE.BANK:
      return {
        eventName: applicationEventType.refBankInfo,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_REF_BANK,
        ],
      }
    case ROUTE.APPLICATION.REFERENCE.BASE +
      ROUTE.APPLICATION.REFERENCE.RELATIVE:
      return {
        eventName: applicationEventType.refNearestRelative,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_REF_RELATIVES,
        ],
      }
    case ROUTE.APPLICATION.REFERENCE.BASE +
      ROUTE.APPLICATION.REFERENCE.PERSONAL:
      return {
        eventName: applicationEventType.refPersonal,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_REF_PERSONAL,
        ],
      }
    case ROUTE.APPLICATION.ADDITIONAL_INFORMATION.BASE:
      return {
        eventName: applicationEventType.additionalInformation,
        properties: [
          ...APPLICATION_EVENT_PROPERTY_BASE,
          ...APPLICATION_EVENT_PROPERTY_ADDITIONAL_INFORMATION,
        ],
      }
    default:
      return {}
  }
}

export const selectLastPath = path => {
  const split = path.split("/")
  return last(split)
}

export const shortcutPath = path => path.slice(1, path.length).split("/")

export const residenceType = {
  type: "type",
  address: "address",
  stay: "stay",
  rental: "rental info",
}

export const residencePathKey = current => {
  switch (selectLastPath(current)) {
    case "previous":
    case "current":
      return { eventName: residenceType.type, property: "type" }
    case "address":
      return { eventName: residenceType.address, property: "type" }
    case "duration":
    case "owner-info":
      return { eventName: residenceType.stay, property: "type" }
    case "rental-info":
      return { eventName: residenceType.rental }
    default:
      return ""
  }
}

export const occupationType = {
  workStatus: "work status",
  info: "employment info",
  duration: "duration of work",
  supervisor: "supervisor details",
  address: "work address",
  income: "additional income",
}

export function occupationPathKey(current) {
  switch (selectLastPath(current)) {
    case "previous":
    case "current":
      return { eventName: occupationType.workStatus, property: "work_status" }
    case "info":
      return { eventName: occupationType.info }
    case "duration":
      return { eventName: occupationType.duration }
    case "supervisor":
      return { eventName: occupationType.supervisor }
    case "address":
      return { eventName: occupationType.address }
    case "additional-income":
      return { eventName: occupationType.income }
    default:
      return ""
  }
}

// TODO: Need to adjust reference type for tracker
export const referenceType = {
  credit_information: "Creditor Information - Creditor Information",
  bank_information: "Creditor Information - Bank/Branch Information",
  relative: "nearest relatives",
  personal: "personal reference",
}

export function referencePathKey(current) {
  switch (selectLastPath(current)) {
    case "credit":
      return { eventName: referenceType.credit_information, useRawEvent: true }
    case "bank":
      return { eventName: referenceType.bank_information, useRawEvent: true }
    case "relative":
      return { eventName: referenceType.relative }
    case "personal":
      return { eventName: referenceType.personal }
    default:
      return ""
  }
}

export const miscType = {
  pet: "pet",
  vehicle: "vehicle",
  additional: "additional info",
}

export function miscPathKey(current) {
  switch (selectLastPath(current)) {
    case "pet":
      return { eventName: miscType.pet }
    case "vehicle":
      return { eventName: miscType.vehicle }
    case "additional":
      return { eventName: miscType.additional }
    default:
      return ""
  }
}

export const applicantEventType = {
  applicantInfo: "save applicant information",
  additionalApplicant: "save additional applicant",
}

export const applicationEventType = {
  personalInfo: "save personal information",
  personalGovernmentId: "save government issued id",
  personalEmergency: "save emergency contact",
  residenceCurrent: "save current residence",
  residencePrevious: "save previous residence",
  occupationCurrent: "save current occupation",
  occupationPrevious: "save previous occupation",
  refCreditorInfo: "save creditor information",
  refBankInfo: "save bank/branch information",
  refNearestRelative: "save reference information - nearest relative",
  refPersonal: "save reference information - personal reference",
  additionalInformation: "save additional information",
}

export function applicantPathKey(current) {
  switch (selectLastPath(current)) {
    case "applicant-info":
      return {
        eventName: applicantEventType.applicantInfo,
        properties: APPLICANT_INFO_PATH_PROPERTY,
      }
    case "additional-applicant":
      return {
        eventName: applicantEventType.additionalApplicant,
        properties: ADDITIONAL_APPLICANT_PATH_PROPERTY,
      }
    default:
      return ""
  }
}

// -- this function will map and return event-property using property mapping object.
// -- `propertyMap`: object contain property mapping - example. APPLICANT_INFO_PATH_PROPERTY
// -- `{ response, parsedPayload }`: objects contain required data for mapping process, other object can be added in the future if required.
export function mapEventProperty(
  propertyMap,
  { response, parsedPayload, rental },
) {
  const mappedProperties = {}
  if (!isEmpty(propertyMap)) {
    propertyMap.forEach(prop => {
      if (!isEmpty(prop)) {
        let mappingObject = {}
        if (prop.mapPropObject === MAP_PROP_OBJECT.RESPONSE) {
          mappingObject = response
        } else if (prop.mapPropObject === MAP_PROP_OBJECT.PARSED_PAYLOAD) {
          mappingObject = parsedPayload
        } else if (prop.mapPropObject === MAP_PROP_OBJECT.RENTAL) {
          mappingObject = rental
        }
        mappedProperties[prop.key] = prop.parser
          ? prop.parser(get(mappingObject, prop.mapProp))
          : get(mappingObject, prop.mapProp)
      }
    })
  }
  return mappedProperties
}

export const REVERSE_REQUEST_EVENT = {
  START: "Start reverse request",
}

export const REVERSE_REQUEST_PROPERTY = {
  SOURCE: { source: "submission_success" },
}

export const EVENT_RENTAL_APP_V3_PAGES = {
  SKIP: {
    EMERGENCY_CONTACT: "emergency_contact",
    CURRENT_RESIDENCE_RENTAL_INFO: "current_residence_rental_info",
    PREVIOUS_RESIDENCE_RENTAL_INFO: "previous_residence_rental_info",
    CURRENT_OCCUPATION_SUPERVISOR: "current_occupation_supervisor",
    PREVIOUS_OCCUPATION_SUPERVISOR: "previous_occupation_supervisor",
  },
  PRO_TIP: {
    GUIDE: "guide",
    MOVE_DATE: "move_in_date",
    CURRENT_OCCUPATION_SUPERVISOR: "current_occupation_supervisor",
    PREVIOUS_OCCUPATION_SUPERVISOR: "previous_occupation_supervisor",
    PERSONAL_REFERENCE: "personal_reference",
    SCREENING_INFO: "screening_info",
  },
  enterReferencePage: "enter references page",
}

export const DISABLED_PROPERTY = {
  enterSubmissionClosedPage: "Enter submission closed page",
}

export const LRA_REVIEW_AND_SIGN = {
  ENTER_APPLICATION_SIGN: "Enter application sign",
  SUCCESS_APPLICATION_SIGN: "Complete application sign",
  CLICK_CONFIRM_FINISH_SIGN: "Confirm application sign",
  CLICK_KEEP_EDITING: "Recheck application",
  ADOPT_SIGNATURE: "Adopt signature in application",
  REPLACE_SIGNATURE: "Replace signature in  application",
}

export const SIGN_FLAG_EVENTS = {
  CLICK_START_SIGN_FLAG: "Click start sign flag",
  CLICK_NEXT_SIGN_FLAG: "Click next sign flag",
}

export const SIGNATURE_TYPE_PROPERTIES = {
  TYPE: { signature_type: "type" },
  DRAW: { signature_type: "draw" },
}

export const CLICK_CHECKLIST_MENU_EVENT = "Click checklist menu"

export const CLICK_CHECKLIST_MENU_PROPERTY = {
  SOURCE: { source: "screening_submission_page" },
}

export const CHECK_ITEM_CHECKLIST_EVENT = "check an item"
export const CHECK_ITEM_PERFORMED_BY = {
  USER: "user",
  SYSTEM: "system",
}

export const AGENT_REVIEW_PAGE = {
  FEATURE_NAME: {
    TENANT_SCREENING: "tenant screening",
    ACCEPT_DENY: "accept/deny",
    ESIGN: "esign",
  },
}

export const CLICK_MOVING_SERVICES_EVENT =
  "click moving services landing page link"

export const SOURCE = {
  SUBMIT_PAGE: "application_submit_page",
}

export const CLICK_MOVING_SERVICES_PROPERTY = {
  SOURCE: { source: SOURCE.SUBMIT_PAGE },
}

export const DOCUMENT_UPLOAD_EVENT = {
  ACCESS_DOCUMENT_UPLOAD: "access documents upload",
  UPLOAD_DOCUMENT: "upload document",
  CLICK_UPLOAD_DOCUMENTS: "click upload documents",
  SUBMIT_DOCUMENT: "submit document",
  CLICK_UPLOAD_LATER: "click upload later",
}

export const DOCUMENT_TYPE_EVENT_MAPPER = {
  paystubs: "paystubs",
  bank_statement: "bank_statement",
  passport: "government_issued_id",
  w2s: "w-2s",
  tax_return: "tax_return",
  other: "other",
}

export const DOCUMENT_UPLOAD_PROPERTY = {
  PURPOSE: {
    START: "start",
    CONTINUE: "continue",
    EDIT: "edit",
  },
  SOURCE: {
    AUTO_INCOME_FLOW: "auto_income_flow",
    APPLICATION_FLOW: "application_flow",
  },
}

export const DAILY_APPLY_LIMIT_EVENT = {
  EVENT_NAME: {
    ENTER_MULTI_SHARE_APPLY_LIMIT: "enter multi-share apply limit",
  },
}
export const PAGE_VIEWED_EVENT_NAME = "[Amplitude] Page Viewed"
export const PAGE_VIEWED_EVENT_PROPERTY = {
  PAGE_DOMAIN: "[Amplitude] Page Domain",
  PAGE_LOCATION: "[Amplitude] Page Location",
  PAGE_PATH: "[Amplitude] Page Path",
  PAGE_TITLE: "[Amplitude] Page Title",
  PAGE_URL: "[Amplitude] Page URL",
}
export const DOCUMENT_TYPE = {
  PAYSTUBS: "paystubs",
  W2S: "w2s",
  BANK_STATEMENT: "bank_statement",
  TAX_RETURN: "tax_return",
  PET_APPLICATION: "pet_application",
  PASSPORT: "passport",
  PETS: "pets",
  OTHER: "other",
}
export const APPLICATION_EVENT = {
  SUBMIT_REPORT: "Submit report",
}
export const FINICITY_POPUP_EVENT = {
  ON_DONE: "finicity_popup_event_done",
  ON_CANCEL: "finicity_popup_event_cancel",
  ON_ERROR: "finicity_popup_event_error",
  ON_ROUTE: "finicity_popup_event_route",
  ON_USER: "finicity_popup_event_user_perform",
  ON_LOAD: "finicity_popup_event_load",
}
export const INCOME_VERIFICATION_EVENT_NAME = {
  CLICK_AUTO_INCOME_VERIFICATION: "click auto income verification",
}
export const INCOME_VERIFICATION_EVENT_PROPERTY = {
  SOURCE: {
    APPLICATION_FLOW: "application_flow",
  },
}
