export const stepAdditionalInformation = data => ({
  miscellaneous: {
    havePet: data.havePet,
    pets:
      data.pets &&
      data.pets.map(v => ({
        name: v.name,
        breed: v.breed,
        weight: v.weight,
      })),
    haveVehicle: data.haveVehicle,
    vehicles:
      data.vehicles &&
      data.vehicles.map(v => ({
        make: v.make,
        model: v.model,
        year: v.year,
        color: v.color,
        licensePlate: v.licensePlate,
        state: v.state,
      })),
    additionalInfo: {
      liquidFurniture: {
        choice: data.liquidFurniture,
        type: data.typeLiquidFurniture,
      },
      unlawful: {
        choice: data.unlawful,
        explain: data.explainUnlawful,
      },
      moveOut: {
        choice: data.moveOut,
        explain: data.explainMoveOut,
      },
      bankruptcy: {
        choice: data.bankruptcy,
        explain: data.explainBankruptcy,
      },
      felony: {
        choice: data.felony,
        explain: data.explainFelony,
      },
    },
  },
})
