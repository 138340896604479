import { createSelector } from "reselect"
import { parsePhoneNumber, isPossiblePhoneNumber } from "libphonenumber-js"
import _ from "lodash"
import moment from "moment/moment"
import { calculateYearDiffByDate } from "helpers/date"
import { initialState } from "./reducer"
import { getLatestTUError } from "./helpers/error"
import { TU_ERROR_MAPPING } from "./constants"
import { selectApplication } from "../rental-submission/selectors"

/**
 * Direct selector to the tuScreening state domain
 */

const selectTuScreeningDomain = state => state.get("tuScreening", initialState)

/**
 * Other specific selectors
 */
const selectCreditReport = () =>
  createSelector(selectTuScreeningDomain, substate =>
    substate.get("creditReport").toJS(),
  )

const selectCreditReportBundleId = () =>
  createSelector(selectTuScreeningDomain, substate =>
    substate.getIn(["creditReport", "bundleId"]),
  )

/**
 * We need to create this const for test
 * that expected and actual to referrentially equivalent.
 * otherwise test failed although expected == actual
 */
const tuRenterSelector = createSelector(selectTuScreeningDomain, substate =>
  substate.get("tuRenter").toJS(),
)

const makeTuRenterSelector = () => tuRenterSelector

const makeTuRenterAddress = () =>
  createSelector(selectApplication(), ({ residence }) => {
    let twoYearsDuration
    if (!residence) {
      return null
    }
    const filteredUsResidence = _.pickBy(
      residence,
      ({ region }) => region === "US",
    )
    if (_.isEmpty(filteredUsResidence)) return null
    const { current = null, previous = null } = filteredUsResidence
    if (_.size(filteredUsResidence) === 1) return current || previous
    twoYearsDuration = current
    const hasPrevious = _.get(previous, "selected", "none") !== "none"
    if (hasPrevious) {
      const currentDateOut =
        current.dateOut === "Present"
          ? moment().format("YYYY-MM")
          : current.dateOut
      const { dateIn: currentDateIn } = current
      const previousDateOut =
        previous.dateOut === "Present"
          ? moment().format("YYYY-MM")
          : previous.dateOut
      const { dateIn: previousDateIn } = previous
      const previousYearDuration = calculateYearDiffByDate(
        previousDateOut,
        previousDateIn,
      )
      const currentYearDuration = calculateYearDiffByDate(
        currentDateOut,
        currentDateIn,
      )
      if (previousYearDuration >= 2 && currentYearDuration < 2) {
        twoYearsDuration = previous
      }
    }
    return twoYearsDuration
  })

const selectSsn = state => {
  const tu = selectTuScreeningDomain(state).toJS()
  return _.get(tu, "ssn")
}

const selectStatusTURenter = state => {
  const tu = selectTuScreeningDomain(state).toJS()
  return _.get(tu, "tuRenter.status")
}

const selectTuScreening = state => selectTuScreeningDomain(state).toJS()
/**
 * Default selector used by tu-screening
 */

const makeSelectTuScreening = () =>
  createSelector(selectTuScreeningDomain, substate => substate.toJS())

const isShowCheckBoxUpdateSSN = tuScreening =>
  _.get(tuScreening, "tuRenter.isSent") === true &&
  !_.isEmpty(_.get(tuScreening, "creditReport.transunion_errors")) &&
  TU_ERROR_MAPPING.NOT_REQUIRED_SSN_UPDATE_CODE.indexOf(
    getLatestTUError(tuScreening.creditReport)?.code,
  ) === -1

const makeIsShowCheckBoxUpdateSSN = () =>
  createSelector(selectTuScreening, isShowCheckBoxUpdateSSN)

const makeIsShowReviewPageSelector = () =>
  createSelector(selectTuScreeningDomain, subState =>
    subState.get("isShowReviewPage"),
  )

const makeNationalUsNumber = () =>
  createSelector(selectApplication(), ({ phoneNumber }) => {
    if (!(phoneNumber && isPossiblePhoneNumber(`+${phoneNumber}`))) {
      return ""
    }
    const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`)
    if (parsedPhoneNumber.country !== "US") {
      return ""
    }
    return parsedPhoneNumber.nationalNumber
  })

export default makeSelectTuScreening
export {
  selectSsn,
  selectTuScreeningDomain,
  selectCreditReport,
  makeTuRenterSelector,
  selectTuScreening,
  makeIsShowCheckBoxUpdateSSN,
  isShowCheckBoxUpdateSSN,
  selectStatusTURenter,
  makeIsShowReviewPageSelector,
  makeTuRenterAddress,
  makeNationalUsNumber,
  selectCreditReportBundleId,
}
