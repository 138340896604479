/*
 *
 * AppOccupation constants
 *
 */
import _ from "lodash"

export const WORK_STATUS = {
  EMPLOYED: "employed",
  UNEMPLOYED: "unemployed",
  STUDENT: "student",
  RETIRED: "retired",
  NONE: "none",
}

export const WORK_STATUS_LABEL = {
  NONE: "None/Other",
}

export const WORK_STATUS_QUESTIONS = {
  PREVIOUS: "What was your previous work status?",
  CURRENT: "What is your current work status?",
}

export const CURRENT_WORK_STATUS = [
  {
    label: _.capitalize(WORK_STATUS.EMPLOYED),
    id: "employed",
    value: "employed",
  },
  {
    label: _.capitalize(WORK_STATUS.UNEMPLOYED),
    id: "unemployed",
    value: "unemployed",
  },
  {
    label: _.capitalize(WORK_STATUS.STUDENT),
    id: "student",
    value: "student",
  },
  {
    label: _.capitalize(WORK_STATUS.RETIRED),
    id: "retired",
    value: "retired",
  },
]

export const PREVIOUS_WORK_STATUS = [
  {
    label: _.capitalize(WORK_STATUS.EMPLOYED),
    id: "employed",
    value: "employed",
  },
  {
    label: WORK_STATUS_LABEL.NONE,
    id: "none",
    value: "none",
  },
]

export const switchOccurring = occurring => {
  switch (occurring) {
    case "current":
      return "previous"
    case "previous":
      return "current"
    default:
      return occurring
  }
}

export const OCCURRING_TITLE = {
  CURRENT: "Current Employment & Income",
  PREVIOUS: "Previous Employment & Income",
}
