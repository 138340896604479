import React from "react"
import styled from "styled-components"
import classnames from "classnames"
import moment from "moment"
import { COLOR } from "styles/settings"
import { Box } from "../sign-box"
import TooltipPopper from "../tooltip"
import { contentColor } from "../colors"
import { makeMapCursor } from "../cursor"

const mapCursor = makeMapCursor("default", "pointer")

export const StyledText = styled.p`
  font-family: ${props =>
    props.styles.fontFamily ? props.styles.fontFamily : "Source Sans Pro"};
  font-size: ${props =>
    props.styles.fontSize ? `${props.styles.fontSize}pt` : "10pt"};
  ${props => props.styles.italic && `font-style: italic`};
  ${props => props.styles.bold && `font-weight: bold`};
  ${props => props.styles.underline && `text-decoration: underline`};
  color: ${props => props.color};
  padding: 0 5px 0 5px;
  margin: 0;
`

export const Text = ({ color, msg, ...props }) => (
  <StyledText color={color} {...props}>
    {msg}
  </StyledText>
)

export const DateSignBox = ({
  targetRef,
  fieldId,
  isDragging,
  left,
  top,
  value,
  active,
  styles = {},
  colorIndex,
  onClick,
  onChangeData,
  viewMode,
  showError,
  canEdit,
  unmovable,
  ratio,
}) => {
  const handleChange = event => {
    onChangeData(event.target.value)
  }

  if (isDragging) {
    return <div ref={targetRef} />
  }
  const isSigned = !!value
  const msg = isSigned
    ? moment(parseInt(value, 10)).format("MM/DD/YYYY")
    : "Date Signed"
  const cursor = mapCursor(viewMode, canEdit, active, unmovable)

  const color = isSigned ? COLOR.textBlue : contentColor(colorIndex)
  return (
    <Box
      ratio={ratio}
      padding="0px"
      ref={targetRef}
      id={fieldId}
      key={fieldId}
      cursor={cursor}
      className={classnames({
        active,
        error: showError,
        "signed-date": isSigned,
      })}
      left={left * ratio}
      top={top * ratio}
      value={value}
      colorIndex={colorIndex}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      onChange={handleChange}>
      {showError && <TooltipPopper refNode={targetRef} />}
      <Text color={color} styles={styles} msg={msg} />
    </Box>
  )
}
