import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import includes from "lodash/includes"
import { createSelector } from "reselect"
import find from "lodash/find"
import isNil from "lodash/isNil"
import { makeSelectProfile } from "containers/wrapper/selectors"
import { E_SIGN_TERM } from "constants/terms"
import { ENVELOPE_STATUS, RECIPIENT_STATUS } from "./constants"
import {
  getEmptyOrInvalidAssignedFields,
  getValidationErrorObjectFrom,
} from "./helpers/get-validation-error-object"

export const getEnvelope = state => get(state.toJS(), "envelope")
const getProfile = state => get(state.toJS(), "user")

export const selectUserIntegration = createSelector(getProfile, state =>
  get(state, "userIntegration", []),
)

export const selectEnvelope = createSelector(getEnvelope, state => state)

export const makeSelectIsAcceptTermsLeaseAgreement = () =>
  createSelector(getProfile, state =>
    get(state, `profile.terms.${E_SIGN_TERM}`, false),
  )

// Envelope Data selector
// #region
export const selectEnvelopeData = () =>
  createSelector(selectEnvelope, state => get(state, "envelopeData", {}))

export const makeSelectEnvelopeFiles = () =>
  createSelector(selectEnvelopeData(), state => get(state, "files", []))

export const makeSelectDoesEnvelopeExist = () =>
  createSelector(selectEnvelopeData(), state => !isEmpty(state))

export const makeSelectEnvelopeName = () =>
  createSelector(selectEnvelopeData(), state => get(state, "name", ""))

export const makeSelectEnvelopeUpdatedAt = () =>
  createSelector(selectEnvelopeData(), state => get(state, "updatedAt", ""))

export const makeSelectIsUsingTemplate = () =>
  createSelector(selectEnvelopeData(), state => !!get(state, "templateId", ""))

export const makeSelectEnvelopePDFPath = () =>
  createSelector(selectEnvelopeData(), state => get(state, "pdfPath", ""))

export const selectIsAcceptedConsent = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, "envelopeData", {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .find(({ isAcceptedConsent }) => isAcceptedConsent)
    }
    return false
  })
export const selectIsSigned = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, "envelopeData", {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .every(({ status }) => status === "signed")
    }
    return false
  })
export const selectIsOwner = () =>
  createSelector(selectEnvelope, state => {
    const envelopeData = get(state, "envelopeData", {})
    if (!isEmpty(envelopeData)) {
      return !!envelopeData.recipients
        .filter(({ token }) => !!token)
        .find(({ userId }) => userId === envelopeData.owner)
    }
    return false
  })

export const selectSignatureData = () =>
  createSelector(selectEnvelope, state => get(state, "signature", {}))

export const selectLoadingGetEnvelope = createSelector(selectEnvelope, state =>
  get(state, "isLoadingGetEnvelope"),
)

export const selectLoadingPrepareEnvelope = createSelector(
  selectEnvelope,
  state => get(state, "isLoadingPrepareEnvelope"),
)
export const selectLoadingUpdateConsent = createSelector(
  selectEnvelope,
  state => get(state, "isLoadingUpdateConsent"),
)
export const selectUpdateConsentSuccess = createSelector(
  selectEnvelope,
  state => get(state, "updateConsentSuccess"),
)

export const selectLoadingGenerateEnvelopePDF = createSelector(
  selectEnvelope,
  state => get(state, "isLoadingGenerateEnvelopePDF"),
)
export const selectLoadingSavingFile = createSelector(selectEnvelope, state =>
  get(state, "isLoadingSavingFile"),
)

export const selectLoadingUpdateEnvelope = createSelector(
  selectEnvelope,
  state => get(state, "isLoadingUpdateEnvelope"),
)

export const selectIsUploadingSignature = createSelector(
  selectEnvelope,
  state => get(state, "isUploadingSignature"),
)

export const selectIsLoadingGeneratePdfFileURL = createSelector(
  selectEnvelope,
  state => get(state, "isLoadingGeneratePdfFileURL"),
)

export const makeSelectOwner = () =>
  createSelector(selectEnvelopeData(), state => get(state, "owner", []))

export const makeSelectRecipients = () =>
  createSelector(selectEnvelopeData(), state => get(state, "recipients", []))

export const makeSelectRecipientList = () =>
  createSelector(makeSelectRecipients(), state =>
    state.map(({ fullName, _id, roleId }) => ({
      value: _id,
      label: fullName,
      roleId,
    })),
  )

export const selectIsLoading = () =>
  createSelector(selectEnvelope, state => get(state, "isLoading"))

export const selectIsError = () =>
  createSelector(selectEnvelope, state => get(state, "isError"))

export const selectEnvelopeError = createSelector(getEnvelope, state =>
  get(state, "error"),
)

export const selectFieldValidationResult = () =>
  createSelector(selectEnvelopeData(), state => {
    if (!isEmpty(state)) {
      return getValidationErrorObjectFrom(state)
    }
    return {}
  })

export const makeSelectShowLeavePopup = () =>
  createSelector([selectEnvelope, makeSelectProfile()], (envelope, profile) => {
    const recipients = get(envelope, "envelopeData.recipients", [])
    const status = get(envelope, "envelopeData.status", "pending")
    const isSavedInformation = get(envelope, "isSavedInformation", false)
    const recipient =
      find(recipients, "token") ||
      find(recipients, { email: get(profile, "email") })
    if (isNil(recipient)) {
      return false
    }

    return (
      !includes([ENVELOPE_STATUS.COMPLETED, ENVELOPE_STATUS.VOID], status) &&
      recipient.status === RECIPIENT_STATUS.PENDING &&
      !isSavedInformation
    )
  })
// #endregion

// Drag n Drop selector
// #region
export const makeSelectActiveId = () =>
  createSelector(selectEnvelope, state => get(state, "activeId", {}))

export const makeSelectActiveIdType = () =>
  createSelector(makeSelectActiveId(), ({ type }) => type || "")

export const makeSelectActiveIdPage = () =>
  createSelector(makeSelectActiveId(), ({ page }) => page || "")

export const makeSelectActiveIdField = () =>
  createSelector(makeSelectActiveId(), ({ fieldId }) => fieldId || "")

export const makeSelectActiveIdStyles = () =>
  createSelector(makeSelectActiveId(), ({ styles }) => styles || {})

export const makeSelectActiveIdAssignee = () =>
  createSelector(makeSelectActiveId(), ({ assignee }) => assignee || "")

export const makeSelectActiveIdUndeletable = () =>
  createSelector(
    makeSelectActiveId(),
    ({ undeletable }) => undeletable || false,
  )

export const makeSelectToolbar = () =>
  createSelector(selectEnvelope, state => get(state, "toolbar", []))
// #endregion

export const makeSelectSavingFileLoadingList = () =>
  createSelector(selectEnvelope, state => get(state, "saveFileLoadings", []))

export const selectIsAccepting = () =>
  createSelector(selectEnvelope, state => get(state, "isAccepting"))
export const makeSelectIsConsentSuccess = () =>
  createSelector(selectEnvelope, state => get(state, "isConsentSuccess", false))
export const makeSelectIsShowModal = () =>
  createSelector(selectEnvelope, state =>
    get(state, "isShowConsentModal", false),
  )

export const makeSelectAllEmptyOrInvalidFields = () =>
  createSelector(selectEnvelopeData(), state =>
    getEmptyOrInvalidAssignedFields(state),
  )

export const makeSelectUploadSignatureSuccess = () =>
  createSelector(selectEnvelope, state => get(state, "uploadSignatureSuccess"))

export const makeSelectIsSignatureEdited = () =>
  createSelector(selectEnvelope, state => get(state, "isSignatureEdited"))
