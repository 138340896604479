import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"

import injectReducer from "utils/inject-reducer"
import reducer from "./reducer"
import { makeSelectRental } from "./selectors"
import errorSelector from "../error/selectors"
import { makeSelectScreeningOption } from "./rental-instruction/selectors"

export const withReducer = injectReducer({ key: "rentalWrapper", reducer })

export const mapStateToProps = createStructuredSelector({
  error: errorSelector(),
  rentalSubmission: makeSelectRental(),
  screeningOption: makeSelectScreeningOption(),
})

export const withConnect = connect(mapStateToProps)
