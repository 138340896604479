import {
  takeLatest,
  call,
  put,
  all,
  fork,
  delay,
  select,
} from "redux-saga/effects"
import { apiInstance } from "utils/api-interceptor"
import { DAILY_APPLY_LIMIT_ERROR } from "utils/constants"
import { buildPath } from "@rentspree/path"
import { tracker } from "tracker"
import { INSTRUCTION_EVENT } from "tracker/tracker-const"
import { ROUTE } from "containers/router/constants"
import _ from "lodash"
import isEmpty from "lodash/isEmpty"
import { setSuccess, redirect } from "containers/wrapper/actions"
import { getDraftDocumentApi } from "../../document-upload/saga"
import { getDraftDocumentApiState } from "../../document-upload/actions"
import { makeSelectMultiShare } from "../../wrapper/selectors"
import { makeSelectRental } from "../selectors"
import {
  START_NEW_RENTAL_CALL,
  startNewRentalRequest,
  startNewRentalSuccess,
  startNewRentalFailed,
  START_PRE_PAYMENT_RENTAL_CALL,
  PRE_PAYMENT_API_URL,
  startPrePaymentRentalRequest,
  startPrePaymentRentalFailed,
  startPrePaymentRentalSuccess,
} from "./constants"
import { GET_RENTAL_API_URL } from "../constants"
import { checkStatusRental } from "../saga"

export function trackApplyNowEvent(response, isMultiShareActive) {
  const { name, properties } = INSTRUCTION_EVENT.clickApplyNow({
    ...response,
    isMultiShareActive,
  })
  tracker.trackEvent(name, properties)
}

export const startNewRentalAppApi = ({ screeningRequestId }) =>
  apiInstance.post(
    buildPath(GET_RENTAL_API_URL, { screeningRequestId }),
    undefined,
  )

export function* startNewRentalApp(action) {
  yield put(startNewRentalRequest())
  try {
    const response = yield call(startNewRentalAppApi, _.get(action, "payload"))
    if (!isEmpty(response?.proOption?.documents)) {
      try {
        const docResponse = yield call(getDraftDocumentApi, {
          rentalSubmissionId: response?._id,
        })
        yield put(getDraftDocumentApiState.success(docResponse?.result?.[0]))
      } catch {
        // Ignore get draft document error because it is optional flow
      }
    }
    yield put(setSuccess())
    yield delay(200)
    const { isMultiShareActive } = yield select(makeSelectMultiShare())
    yield call(trackApplyNowEvent, response, isMultiShareActive)
    yield put(startNewRentalSuccess(response))
  } catch (err) {
    if (err?.data?.code === DAILY_APPLY_LIMIT_ERROR) {
      yield put(redirect(ROUTE.GUIDE.APPLY_LIMIT, true))
    } else {
      yield fork(checkStatusRental, err)
      yield put(startNewRentalFailed(err))
    }
  }
}

// TODO: INIT-649 clean up
export const startPrePaymentRentalAppApi = ({ screeningRequestId }) =>
  apiInstance.post(
    buildPath(
      GET_RENTAL_API_URL,
      { screeningRequestId },
      { isPrePaymentRequired: true },
    ),
    undefined,
  )
export const getPrePaymentApi = rentalId =>
  apiInstance.get(buildPath(PRE_PAYMENT_API_URL, { rentalId }, {}))

export const assignUrl = (...args) => window.location.assign(...args)

// TODO: INIT-649 clean up
export function* startPrePaymentRentalApp(action) {
  yield put(startPrePaymentRentalRequest())
  const rental = yield select(makeSelectRental())
  try {
    let rentalId
    if (rental?._id) rentalId = rental?._id
    else {
      const response = yield call(
        startPrePaymentRentalAppApi,
        _.get(action, "payload"),
      )
      yield call(trackApplyNowEvent, response)
      rentalId = response?._id
    }
    const payment = yield call(getPrePaymentApi, rentalId)
    if (payment?.paymentObject?.url) {
      yield call(assignUrl, payment.paymentObject.url)
    }
    yield put(startPrePaymentRentalSuccess(payment))
    yield put(setSuccess())
  } catch (err) {
    if (err?.data?.code === DAILY_APPLY_LIMIT_ERROR) {
      yield put(redirect(ROUTE.GUIDE.APPLY_LIMIT, true))
      yield put(startPrePaymentRentalFailed(err))
    } else {
      yield fork(checkStatusRental, err)
      yield put(startPrePaymentRentalFailed(err))
    }
  }
}

export function* watchStartNewRental() {
  yield takeLatest(START_NEW_RENTAL_CALL, startNewRentalApp)
}

export function* watchStartPrePaymentRental() {
  yield takeLatest(START_PRE_PAYMENT_RENTAL_CALL, startPrePaymentRentalApp)
}

export default function* rootSaga() {
  yield all([watchStartNewRental(), watchStartPrePaymentRental()])
}
