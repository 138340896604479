import { put, select, call, takeLatest, all } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import { setSuccess } from "containers/wrapper/actions"
import { tracker } from "tracker"
import { TU_EVENT } from "tracker/tracker-const"

import {
  GET_TU_AGREEMENT_CONTENT_ENDPOINT,
  GET_TU_AGREEMENT_CONTENT_CALL,
  ACCEPT_TU_AGREEMENT_ENDPOINT,
} from "./constants"
import {
  getTuAgreementContentRequested,
  getTuAgreementContentSuccess,
  getTuAgreementContentFailed,
} from "./actions"
import {
  acceptTermsRequest,
  acceptTermsSuccess,
  acceptTermsFailed,
  ACCEPT_TERMS_CALL,
} from "../constants"
import { redirectToTuScreeningPages, getTURenterApi } from "../saga"
import { selectTuScreening } from "../selectors"

export const getTuAgreementContentAPI = ({ type }) =>
  apiInstance.get(buildPath(GET_TU_AGREEMENT_CONTENT_ENDPOINT, { type }))

export function* getTuAgreementContent({ payload }) {
  yield put(getTuAgreementContentRequested())
  try {
    const response = yield call(getTuAgreementContentAPI, payload)
    yield put(getTuAgreementContentSuccess(response))
  } catch (error) {
    yield call(
      SweetAlert,
      ALERT_PRESET.ERROR,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
    yield put(getTuAgreementContentFailed(error))
  }
}

export const acceptTuAgreementAPI = payload =>
  apiInstance.post(ACCEPT_TU_AGREEMENT_ENDPOINT, payload)

export function* acceptTerms({ payload }) {
  yield put(acceptTermsRequest())
  try {
    yield call(acceptTuAgreementAPI, payload)
    const tuRenter = yield call(getTURenterApi)
    yield put(setSuccess())
    yield put(acceptTermsSuccess({ tuRenter: tuRenter || {} }))
    yield call([tracker, "trackEvent"], TU_EVENT.acceptTransUnionTerm)
    const { creditReport } = yield select(selectTuScreening)
    yield call(redirectToTuScreeningPages, creditReport, tuRenter)
  } catch (e) {
    yield call(
      SweetAlert,
      ALERT_PRESET.ERROR,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
    yield put(acceptTermsFailed())
  }
}

export function* watchGetTuAgreementContent() {
  yield takeLatest(GET_TU_AGREEMENT_CONTENT_CALL, getTuAgreementContent)
}

export function* watchAcceptAgreement() {
  yield takeLatest(ACCEPT_TERMS_CALL, acceptTerms)
}

export function* rootSaga() {
  yield all([watchGetTuAgreementContent(), watchAcceptAgreement()])
}

export default rootSaga
