import React from "react"
import { useSelector } from "react-redux"
import has from "lodash/has"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import {
  makeSelectActiveIdPage,
  makeSelectActiveIdField,
} from "containers/envelope/selectors"

import { MUST_ASSIGNED_COMPONENTS } from "containers/envelope/constants"
import { useDimensions } from "./helpers/use-dimensions"

export const DropBox = Component => ({
  page,
  dropBoxes,
  actions,
  width,
  height,
  recipientsList,
  viewMode,
  validationErrors = {},
  showSignError = false,
  signErrors = {},
  small,
  ratio,
  targetRef,
  ...props
}) => {
  const activeIdPage = useSelector(makeSelectActiveIdPage())
  const activeIdField = useSelector(makeSelectActiveIdField())
  const [dimensions] = useDimensions(targetRef, ratio)

  return (
    <Component
      ref={targetRef}
      onClick={actions.resetActive}
      {...props}
      style={{ width }}>
      {props.children}
      {dropBoxes.filter(({ visible = true }) => visible).map(box => {
        const findRecipient = recipientsList.find(
          ({ value: val, roleId }) =>
            val === box.assignee || roleId === box.assignee,
        )
        const recipient = get(findRecipient, "label", "")

        if (!recipient && MUST_ASSIGNED_COMPONENTS.includes(box.type)) {
          return <></>
        }

        const isActiveField = activeIdField === box.fieldId
        const isActivePage = activeIdPage === box.page
        const active = isActiveField && isActivePage

        const colorIndex = isEmpty(box.assignee) ? -1 : box.colorIndex
        const showError =
          has(validationErrors, box.fieldId) ||
          (showSignError && has(signErrors, box.fieldId))

        return (
          <Field
            ratio={ratio}
            key={box.fieldId}
            setChildrenBox={actions.setChildrenBox}
            setActive={actions.setActive}
            active={active}
            viewMode={viewMode}
            page={page}
            box={box}
            dimensions={dimensions}
            showError={showError}
            colorIndex={colorIndex}
            recipient={recipient}
            small={small}
          />
        )
      })}
    </Component>
  )
}

export const Field = React.memo(
  ({
    setChildrenBox,
    setActive,
    active,
    viewMode,
    page,
    box,
    dimensions,
    showError,
    colorIndex,
    recipient,
    small,
    ratio = 1,
  }) => {
    const handleClick = React.useCallback(
      () => {
        if (!active) {
          setActive(box)
        }
      },
      [active, box, setActive],
    )

    const handleChangeData = React.useCallback(
      data => {
        setChildrenBox({ page, box: { ...box, value: data } })
      },
      [page, box],
    )

    return (
      <box.element
        ratio={ratio}
        viewMode={viewMode}
        item={box}
        {...box}
        colorIndex={viewMode ? 0 : colorIndex}
        recipient={viewMode ? null : recipient}
        parentDimensions={dimensions}
        active={active}
        onClick={handleClick}
        onChangeData={handleChangeData}
        showError={showError}
        small={small}>
        {box.children}
      </box.element>
    )
  },
)
