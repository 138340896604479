import { createSelector } from "reselect"
import { parse } from "utils/query-parser"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"
import {
  initialState,
  multiShareInitialState,
  profileInitState,
} from "./reducer"
import { selectScreeningOption } from "../application-v2/selectors"

/**
 * Direct selector to the wrapper state domain
 */

const selectWrapper = state => state.get("wrapper", initialState)

export const selectProfile = state => state.get("profile", profileInitState)
export const selectMultiShare = state =>
  state.get("multiShare", multiShareInitialState)

export const getSearch = state =>
  state
    .get("router")
    .get("location")
    .get("search")

export const getPathname = state =>
  state
    .get("router")
    .get("location")
    .get("pathname")

export const getState = state => state

// TODO ACE-333 remove this
export const getPropertyId = state => state.get("wrapper").get("propertyId")

export const getScreeningOptionId = state =>
  state.get("wrapper").get("screeningOptionId")

export const getScreeningRequestId = state =>
  state.get("wrapper").get("screeningRequestId")
/**
 * Other specific selectors
 */

/**
 * Default selector used by wrapper
 */

const makeSelectMultiShare = () =>
  createSelector(selectMultiShare, state => state.toJS())

const makeSelectSearch = () => createSelector(getSearch, state => parse(state))

const makeSelectSubdomain = () =>
  createSelector(selectWrapper, state => state.get("subdomain"))

const makeSelectProfile = () =>
  createSelector(selectProfile, state => {
    if (state) {
      return state.toJS()
    }
    return null
  })

// TODO ACE-333 remove this
const makeSelectPropertyId = () =>
  createSelector(selectWrapper, state => state.get("propertyId"))

const makeSelectScreeningRequestId = () =>
  createSelector(selectWrapper, state => state.get("screeningRequestId"))

const makeSelectIsAllowNewQuestionUser = createSelector(
  selectScreeningOption,
  screeningOption => {
    const parsedSO = screeningOption.toJS()
    if (!parsedSO.applicationType) {
      return false
    }
    const isCarApp = parsedSO.applicationType === APPLICATION_TYPE.CAR_LRA
    if (isCarApp) {
      return false
    }
    return true
  },
)

export {
  selectWrapper,
  makeSelectSubdomain,
  makeSelectProfile,
  makeSelectPropertyId,
  makeSelectScreeningRequestId,
  makeSelectMultiShare,
  makeSelectSearch,
  makeSelectIsAllowNewQuestionUser,
}
