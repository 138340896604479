import { array, object, lazy, mixed, string, boolean } from "yup"
import get from "lodash/get"
import {
  validateLength,
  validatePhone,
  validateEmail,
  validateMaxEndDate,
  validateNoFutureDate,
  validateRegion,
  validateState,
  ADDRESS_REGEX,
  getInvalidAddressLineOneMessage,
  validateLengthSupportAllowEmpty,
  validateZipCodeSupportAllowEmpty,
  validateIncome,
} from "constants/validations"
import { OCCURRING } from "containers/router/constants"
import { addressValidationSchema } from "../validation-schema"
import { WORK_STATUS } from "./constants"

export const occupationFormValueMapper = ({ appOccupation, occurring }) => ({
  workStatus: get(appOccupation, `${occurring}.workStatus`, ""),
  employed: {
    employer: get(appOccupation, `${occurring}.data.info.employer`, ""),
    jobTitle: get(appOccupation, `${occurring}.data.info.jobTitle`, ""),
    salary: get(appOccupation, `${occurring}.data.info.salary`, ""),
    startDate: get(appOccupation, `${occurring}.data.duration.startDate`, ""),
    endDate:
      get(appOccupation, `${occurring}.data.duration.endDate`, "") ||
      (occurring === OCCURRING.CURRENT ? "Present" : ""),
    firstName: get(appOccupation, `${occurring}.data.supervisor.firstName`, ""),
    lastName: get(appOccupation, `${occurring}.data.supervisor.lastName`, ""),
    phoneNumber: get(appOccupation, `${occurring}.data.supervisor.phoneNumber`),
    email: get(appOccupation, `${occurring}.data.supervisor.email`, ""),
    isSkipEmailHR: get(
      appOccupation,
      `${occurring}.data.supervisor.isSkipEmailHR`,
      false,
    ),
    isSkipPhoneHR: get(
      appOccupation,
      `${occurring}.data.supervisor.isSkipPhoneHR`,
      false,
    ),
    streetAddress: get(appOccupation, `${occurring}.data.address.street`, ""),
    city: get(appOccupation, `${occurring}.data.address.city`, ""),
    state: get(appOccupation, `${occurring}.data.address.state`, ""),
    zipcode: get(appOccupation, `${occurring}.data.address.zipCode`, ""),
    region: get(appOccupation, `${occurring}.data.address.region`, "US"),
  },
  additionalIncomes:
    get(appOccupation, "additionalIncomes", []).length > 0
      ? get(appOccupation, "additionalIncomes", [])
      : [{ source: "", amount: "" }], // Pre populate the first income when additional incomes section is open
  isRentSubsidyChecked: !!get(appOccupation, "rentSubsidy"),
  rentSubsidy: get(appOccupation, "rentSubsidy", {
    name: undefined,
    amount: undefined,
    inLieuOfCredit: false,
  }),
})

const generateAddressSchemaShape = ({
  values,
  occurring,
  isUsAddressOnly,
  isRentSpreeApplication,
}) => {
  const isCurrent = occurring === OCCURRING.CURRENT
  const { streetAddress, zipcode, region, state } = values
  const currentAddressSchemaShape = {
    ...addressValidationSchema({
      address: {
        zipCode: zipcode,
        streetAddress,
        region,
      },
      isRequireFullAddress: !isRentSpreeApplication,
      isUsAddressOnly: isUsAddressOnly || region === "US",
    }),
    region: isUsAddressOnly ? mixed() : validateRegion(isCurrent),
  }

  const previousAddressSchemaShape = {
    streetAddress: validateLengthSupportAllowEmpty(
      streetAddress,
      0,
      255,
      isRentSpreeApplication,
      !isRentSpreeApplication,
    ).matches(ADDRESS_REGEX.addressLine1, {
      message: getInvalidAddressLineOneMessage,
      excludeEmptyString: true,
    }),
    city: validateLength(false, 0, 35),
    zipcode: validateZipCodeSupportAllowEmpty(
      zipcode,
      isUsAddressOnly,
      isRentSpreeApplication,
      !isRentSpreeApplication,
    ),
    state: isUsAddressOnly
      ? validateState(false)
      : validateLengthSupportAllowEmpty(
          state,
          2,
          50,
          isRentSpreeApplication,
          !isRentSpreeApplication,
        ),
    region: isUsAddressOnly ? mixed() : validateRegion(false),
  }

  switch (occurring) {
    case OCCURRING.PREVIOUS:
      return isRentSpreeApplication
        ? previousAddressSchemaShape
        : currentAddressSchemaShape // NOTE: address still being required in CAR Application
    case OCCURRING.CURRENT:
      return currentAddressSchemaShape
    default:
      return currentAddressSchemaShape
  }
}

export const generateOccupationFormSchema = props =>
  lazy(values => {
    const { workStatus, employed, isRentSubsidyChecked } = values
    const { isSkipEmailHR = false, isSkipPhoneHR = false } = employed
    const { occurring, isUsAddressOnly, isRentSpreeApplication } = props

    return object().shape({
      workStatus: string().required("Required"),
      ...(workStatus === WORK_STATUS.EMPLOYED && {
        employed: object().shape({
          // Employment information section
          employer: validateLength(true, 0, 255),
          jobTitle: validateLength(true, 0, 35),
          salary: validateLength(true, 0, 10),
          // Duration of Work section
          startDate: validateNoFutureDate(true, "Start date"),
          endDate: lazy(endDate => {
            if (endDate !== "Present") {
              return validateMaxEndDate(true, "startDate", "start date")
            }
            return validateLength()
          }),
          // Supervisor section
          firstName: validateLength(true, 2, 35),
          lastName: validateLength(true, 2, 35),
          phoneNumber: validatePhone(!isSkipPhoneHR, true),
          email: validateEmail(!isSkipEmailHR),
          // Address section
          ...generateAddressSchemaShape({
            values: values.employed,
            occurring,
            isRentSpreeApplication,
            isUsAddressOnly,
          }),
        }),
      }),
      // Additional income section
      additionalIncomes: array(
        object().shape({
          source: validateLength(false, 0, 255),
          amount: validateLength(false, 0, 10),
        }),
      ),
      ...(isRentSubsidyChecked && {
        rentSubsidy: object().shape({
          name: validateLength(true, 1, 100),
          amount: validateIncome(true, 1, 10000000000),
          inLieuOfCredit: boolean(),
        }),
      }),
    })
  })
