import gte from "lodash/gte"
import get from "lodash/get"
import { createSelector } from "reselect"
import { makeSelectProperty } from "containers/property/selectors"
import { isMiamiDadeZipCode } from "utils/helpers/property"
import { APPLICATION_TYPE } from "../rental-submission/constants"
import { initialState } from "./reducer"

export const selectRentalApp = state => state.get("app", initialState)

export const selectScreeningOption = state =>
  state.get("screeningOption", initialState)

export const selectAppPersonal = () =>
  createSelector(selectRentalApp, substate => substate.get("personal").toJS())

export const selectAppResidence = () =>
  createSelector(selectRentalApp, substate => substate.get("residence").toJS())

export const selectAppOccupation = () =>
  createSelector(selectRentalApp, substate => substate.get("occupation").toJS())

export const selectAppReference = () =>
  createSelector(selectRentalApp, substate => substate.get("reference").toJS())

export const selectAppMisc = () =>
  createSelector(selectRentalApp, substate =>
    substate.get("miscellaneous").toJS(),
  )

export const selectAppDraft = () =>
  createSelector(selectRentalApp, substate => substate.get("draft")?.toJS())

export const makeSelectScreeningOption = () =>
  createSelector(selectScreeningOption, substate => substate.toJS())

export const makeSelectRentalApp = () =>
  createSelector(selectRentalApp, substate => substate.toJS())

/**
 * isRentspreeApplicationVersionGreaterOrEqual
 * @param {object} app application object
 * @param {string} app.type application type
 * @param {string} app.version application version
 * @param {string} targetVersion target version to compare
 * @returns Returns true if application type is valid and version is greater than or equal to other, else false.
 */
export const isRentspreeApplicationVersionGreaterOrEqual = (
  { type, version },
  targetVersion,
) =>
  !Number.isNaN(targetVersion) &&
  type === APPLICATION_TYPE.RENTSPREE &&
  gte(version, targetVersion)

export const makeSelectIsUsAddressOnly = () =>
  createSelector(selectRentalApp, state => {
    const app = state.toJS()
    return !isRentspreeApplicationVersionGreaterOrEqual(app, "3.2")
  })

export const makeSelectIsSkipCreditInfo = () =>
  createSelector(selectScreeningOption, state => {
    const screeningOption = state.toJS()
    const haveCreditReport = screeningOption.credit_report
    const appType = screeningOption.applicationType
    return haveCreditReport && appType !== APPLICATION_TYPE.CAR_LRA
  })

export const makeSelectIsRequiredFelonyQuestion = () =>
  createSelector(selectRentalApp, state => {
    const app = state.toJS()
    return !isRentspreeApplicationVersionGreaterOrEqual(app, "3.3")
  })

export const makeSelectIsDisableEvictionQuestion = () =>
  createSelector(
    makeSelectProperty(),
    makeSelectRentalApp(),
    (property, app) => {
      const propertyZipCode = get(property, "zip")
      const applicationType = get(app, "type")

      return (
        applicationType === APPLICATION_TYPE.RENTSPREE &&
        isMiamiDadeZipCode(propertyZipCode)
      )
    },
  )

export const makeSelectIsRentSpreeApplication = () =>
  createSelector(makeSelectRentalApp(), app => {
    const applicationType = get(app, "type")

    return applicationType === APPLICATION_TYPE.RENTSPREE
  })
