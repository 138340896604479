/* eslint-disable no-template-curly-in-string */
/*
 * App Personal Messages
 *
 * This contains all the text for the App Personal component.
 */

import { ERROR_ASK_SUPPORT_TO_UPDATE, TU_EXAM_WRONG_SSN } from "env"
import { defineMessages } from "react-intl"

export default defineMessages({
  required: {
    id: "required",
    defaultMessage: "Required",
  },
  radioRequired: {
    id: "radio-required",
    defaultMessage: "Please select one option",
  },
  minChar: {
    id: "minChar",
    defaultMessage: "Must contains at least {min} characters",
    values: { min: 2 },
  },
  maxChar: {
    id: "maxChar",
    defaultMessage: "Must not exceed {max} characters",
    values: { max: 35 },
  },
  minAge: {
    id: "minAge",
    defaultMessage: "Must be older than {min} years old",
    values: { min: 18 },
  },
  maxAge: {
    id: "maxAge",
    defaultMessage: "Must be under {max} years old",
    values: { max: 125 },
  },
  dobNoFuture: {
    id: "dobNoFuture",
    defaultMessage: "Date of birth cannot be in the future",
  },
  invalidDate: {
    id: "invalidDate",
    defaultMessage: "Please enter a valid date",
  },
  invalidZipCode: {
    id: "invalidZipCode",
    defaultMessage: "Allow only 5 digit number",
  },
  invalidUSZipCode: {
    id: "invalidUSZipCode",
    defaultMessage: "Please enter a valid US Zip Code",
  },
  invalidState: {
    id: "invalidState",
    defaultMessage: "Invalid state code",
  },
  invalidRegion: {
    id: "invalidRegion",
    defaultMessage: "Invalid country / region",
  },
  invalidLengthOfAcquaintance: {
    id: "invalidLoA",
    defaultMessage: "Must not exceed 99 years",
  },
  invalidPhone: {
    id: "invalidPhone",
    defaultMessage: "Invalid phone number",
  },
  invalidEmail: {
    id: "invalidEmail",
    defaultMessage: "Please enter an email address",
  },
  invalidGlobalZipCode: {
    id: "invalidGlobalZipCode",
    defaultMessage: "Invalid zip code",
  },
  mustLetterYear: {
    id: "mustLetterYear",
    defaultMessage: "Must be later than {year} years ago",
    values: { year: 125 },
  },
  noFutureDate: {
    id: "noFutureDate",
    defaultMessage: "{dateName} cannot be in the future",
    values: { dateName: "date" },
  },
  noPastDate: {
    id: "noPastDate",
    defaultMessage: "{dateName} cannot be in the past",
    values: { dateName: "date" },
  },
  endLessThanStart: {
    id: "endLessThanStart",
    defaultMessage: "Must not be prior to {dateName}",
    values: { dateName: "date" },
  },
  networkError: {
    id: "networkError",
    defaultMessage:
      "Sorry, your info could not be saved due to an internal error. Please review the form and try again.",
  },
  saveApplicationError: {
    id: "saveApplicationError",
    defaultMessage:
      "We've encountered an error saving your info. Please check all fields and try again.",
  },
  validationError: {
    id: "validationError",
    defaultMessage:
      "Sorry, we’re having trouble saving your info. Please make sure all the fields are filled out properly.",
  },
  errorSubtitle: {
    id: "errorSubtitle",
    defaultMessage:
      "If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.",
  },
  errorAskSupportToUpdate: {
    id: "errorAskSupportToUpdate",
    defaultMessage:
      ERROR_ASK_SUPPORT_TO_UPDATE ||
      "Please inquire our support to update your information by contacting us via (323) 515-7757 or support@rentspree.com.",
  },
  invalidNameCharacter: {
    id: "invalidNameCharacter",
    defaultMessage: "Use only letters, spaces, and this character (').",
  },
  invalidAlphaNumericCharacter: {
    id: "invalidAlphaNumericCharacter",
    defaultMessage: "Use only letters, numbers, and spaces.",
  },
  invalidTUNameCharacter: {
    id: "invalidTUNameCharacter",
    defaultMessage: "Use only letters, spaces, and these characters (' -).",
  },
  invalidAddressLineOneCharacter: {
    id: "invalidAddressLineOneCharacter",
    defaultMessage:
      "Use only letters, numbers, spaces, and these characters (# ( ) & , . - _ ' ~ / * + ).",
  },
  invalidAddressLineTwoCharacter: {
    id: "invalidAddressLineTwoCharacter",
    defaultMessage:
      "Use only letters, numbers, spaces, and these characters (# ( ) & , . - _ ' ~ / * + ).",
  },
  invalidCityCharacter: {
    id: "invalidCityCharacter",
    defaultMessage: "Use only letters, spaces, and these characters (' -).",
  },
  hasNumber: {
    id: "hasNumber",
    defaultMessage: "Must contain at least 1 number",
  },
  hasLetter: {
    id: "hasLetter",
    defaultMessage: "Must contain at least 1 letter",
  },
  invalidSSN: {
    id: "invalidSSN",
    defaultMessage: "Must contain 9 digits",
  },
  invalidStartingSSN: {
    id: "invalidStartingSSN",
    defaultMessage: "Incorrect format, the number cannot start with 000.",
  },
  ssnMismatch: {
    id: "ssnMismatch",
    defaultMessage: "SSN / ITIN mismatch",
  },
  notAllowedMilitaryAddress: {
    id: "notAllowedMilitaryAddress",
    defaultMessage:
      "Military addresses are not allowed. Please use a physical address.",
  },
  wrongTuExamAnswers: {
    id: "wrongTuExamAnswers",
    defaultMessage:
      "One or more of your answers were incorrect. Please try again",
  },
  paymentPaid: {
    id: "paymentPaid",
    defaultMessage:
      "Your payment has been processed. Please click Submit button below to complete the application/reports.",
  },
  paymentFailed: {
    id: "paymentFailed",
    defaultMessage:
      "Your payment didn't go through. Please check your payment information, use a different payment method, or try again later",
  },
  tuExamWrongSSN: {
    id: "tuExamWrongSSN",
    defaultMessage:
      TU_EXAM_WRONG_SSN || "Please recheck your information or SSN or ITIN.",
  },
  invalidAccountType: {
    id: "invalidAccountType",
    defaultMessage: "Account type is invalid",
  },
  invalidPriceRange: {
    id: "invalidPriceRange",
    defaultMessage: "Price must be between ${min} and ${max}",
    value: { min: 0, max: 1000000 },
  },
  invalidIncomeRange: {
    id: "invalidIncomeRange",
    defaultMessage: "Income must be between ${min} and ${max}",
    value: { min: 0, max: 1000000 },
  },
  invalidWorkStatus: {
    id: "invalidWorkStatus",
    defaultMessage: "Work status is invalid.",
  },
  generalSaveError: {
    id: "generalSaveError",
    defaultMessage:
      "We've encountered an error saving your info. Please check all fields and try again.",
  },
  duplicateEmails: {
    id: "duplicateEmails",
    defaultMessage: "Duplicate emails are not allowed",
  },
  documentUploadFailed: {
    id: "documentUploadFailed",
    defaultMessage:
      "Unable to upload files. Please try again in a few minutes.",
  },
  documentDeleteFailed: {
    id: "documentDeleteFailed",
    defaultMessage:
      "Unable to delete files. Please try again in a few minutes.",
  },
})

export const RENTSPREE_ERROR_CONTACT =
  "Please try again in a few minutes. If the problem persists, contact us at (323) 515-7757 or support@rentspree.com."

export const RENTSPREE_ERROR_CONTACT_NEW_VERSION =
  "Please try again in a few minutes. If the problem persists, contact support@rentspree.com or (323) 515-7757."

export const E_SIGN_ERROR_MESSAGE = {
  LISTING: {
    TITLE: "The leases are temporarily unavailable",
    MESSAGE:
      "Please wait a few minutes and refresh the page (crtl+r/cmd+r).  If you still cannot access the leases, contact us at (323) 515-7757 or support@rentspree.com.",
  },
  CONSENT_ERROR: {
    TITLE: "Something went wrong",
    MESSAGE: RENTSPREE_ERROR_CONTACT,
  },
  RESEND_ERROR: {
    TITLE: "Error sending the request",
    MESSAGE: RENTSPREE_ERROR_CONTACT_NEW_VERSION,
  },
  SIGN_ERROR: {
    TITLE: "Error signing the document",
    MESSAGE: RENTSPREE_ERROR_CONTACT_NEW_VERSION,
  },
}

export const GENERAL_ERROR = {
  UNKNOWN_ERROR: {
    TITLE: "We're sorry.",
    MESSAGE:
      "We’re experiencing some technical issues. Please click the try again button below. If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.",
  },
}
