import { Col as bCol } from "react-bootstrap"
import styled from "styled-components"

export const Col = styled(bCol)`
  padding-left: ${props => (props.isDisablePaddingLeft ? "none" : "10px")};
  padding-right: 10px;
  margin-bottom: ${props => (props.mb ? props.mb : "0px")};
  @media (max-width: 767px) {
    padding-left: ${props => (props.isDisablePaddingLeft ? "none" : "0px")};
    padding-right: 0px;
  }
`
