// TODO: lnr-3458 remove unused step when clean up A/B

import omit from "lodash/omit"

export const stepSelected = data => {
  const residence = {}
  residence[data.occurring] = {
    selected: data.selected,
  }
  return { residence }
}

export const stepDuration = data => {
  const residence = {}
  residence[data.occurring] = {
    dateIn: data.dateIn,
    dateOut: data.dateOut,
  }
  return { residence }
}

export const stepAddress = data => {
  const residence = {}
  residence[data.occurring] = {
    street: data.streetAddress,
    city: data.city,
    zipCode: data.zipcode,
    state: data.state,
    region: data.region,
  }
  return { residence }
}

export const stepRental = data => {
  const residence = {}
  residence[data.occurring] = {
    reason: data.reason,
    landlordEmail: data.landlordEmail,
    landlordName: data.landlordName,
    landlordPhone: data.landlordPhone,
    monthlyRent: data.monthlyRent,
    isSkipEmailLL: data.isSkipEmailLL,
    isSkipPhoneLL: data.isSkipPhoneLL,
  }
  return { residence }
}

export const stepOwner = data => {
  const residence = {}
  const payload = omit(data, "occurring")
  residence[data.occurring] = payload
  return { residence }
}

export const stepResidence = data => {
  const residence = {}
  residence[data.occurring] = {
    selected: data.selected,
    street: data.streetAddress,
    city: data.city,
    zipCode: data.zipcode,
    state: data.state,
    region: data.region,
    dateIn: data.dateIn,
    dateOut: data.dateOut,
    reason: data.reason,
  }
  return { residence }
}
