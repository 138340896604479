import { put, call, select, takeLatest } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import { apiInstance } from "utils/api-interceptor"
import { setSuccess } from "containers/wrapper/actions"
import { trackEvent } from "tracker"
import { LOG_EVENT } from "tracker/tracker-const"
import { selectRentalId } from "containers/rental-submission/selectors"
import {
  attachReportRequest,
  attachReportSuccess,
  attachReportFailed,
  ATTACH_REPORT_CALL,
  START_NEW_SCREENING_API_URL,
} from "../constants"
import { redirectToTuScreeningPages } from "../saga"
import { makeTuRenterSelector } from "../selectors"

export const sendAttachReportAPI = rentalId =>
  apiInstance.post(buildPath(START_NEW_SCREENING_API_URL, { rentalId }), {})

export function* sendAttachReport() {
  yield put(attachReportRequest())
  try {
    const rentalId = yield select(selectRentalId)
    const creditReport = yield call(sendAttachReportAPI, rentalId)
    const tuRenter = yield select(makeTuRenterSelector())
    yield put(attachReportSuccess(creditReport))
    yield put(setSuccess())
    yield call(trackEvent, LOG_EVENT.rental_v3.save_tu_success)
    yield call(redirectToTuScreeningPages, creditReport, tuRenter)
  } catch (e) {
    yield put(attachReportFailed())
  }
}

export function* rootSaga() {
  yield takeLatest(ATTACH_REPORT_CALL, sendAttachReport)
}

export default rootSaga
