import React from "react"
import { B14, Span } from "@rentspree/component-v2"

export const TUScreeningBody = () => (
  <div>
    <B14 color="black" weight="600" margin="9px 0px" mSize="16px">
      {TUScreeningMessage[1]}
    </B14>
    <B14
      color="black"
      size="12px"
      margin="0 0 13px 0"
      mMargin="0 0 20px 0"
      mSize="16px">
      {TUScreeningMessage[2]}
    </B14>
    <B14 color="black" weight="600" margin="9px 0px" mSize="16px">
      {TUScreeningMessage[3]}
    </B14>
    <B14 color="black" size="12px" margin="0 0 9px 0" mSize="16px">
      {TUScreeningMessage[4]}
    </B14>
  </div>
)

const TUScreeningMessage = [
  "TransUnion Screening",
  "No Damage to your Credit Score",
  "RentSpree uses a TransUnion soft credit inquiry, so it will never damage your credit score like traditional credit checks.",
  "Protect your Sensitive Info",
  "Your social security number will never be shared with anyone. It is used securely only to pull your screening reports.",
]

export const TUPersonalInfoSSN = () => (
  <div>
    <B14
      size="12px"
      mSize="16px"
      color="black"
      margin="0 0 10px 0"
      mMargin="0 0 22px 0"
      lineHeight="16px"
      mLineHeight="22px">
      TransUnion requires a&nbsp;
      <Span weight="600">Social Security Number&nbsp;</Span>
      (SSN) or&nbsp;
      <Span weight="600">Individual Taxpayer Identification Number&nbsp;</Span>
      (ITIN) to verify your identity and process screening reports.
    </B14>
    <B14
      size="12px"
      mSize="16px"
      color="black"
      margin="0"
      lineHeight="16px"
      mLineHeight="22px">
      RentSpree does not store your SSN or ITIN.
    </B14>
  </div>
)

export const TUUpdateSSN = () => (
  <div>
    <B14 size="12px" mSize="16px" color="black" lineHeight="16px" margin="3px">
      If you entered your SSN / ITIN incorrectly, please check this box to
      update your SSN with TransUnion.
    </B14>
  </div>
)
