import { B14, Button } from "@rentspree/component-v2"
import styled from "styled-components"

export const BackToDashboardButton = styled(Button)`
  border: 1px solid rgba(170, 170, 170, 1);
  border-radius: 29px;
`
export const ExpiredHeading = styled.h1`
  font-family: Source Sans Pro, sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: rgba(51, 51, 51, 1);
  margin: 20px 0 10px 0;
`
export const ExpiredText = styled(B14)`
  font-family: Source Sans Pro, sans-serif;
  text-align: center;
  padding: 0 20px;
  margin: 0;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
`
