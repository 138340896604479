import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import injectReducer from "utils/inject-reducer"
import injectSaga from "utils/inject-saga"
import { redirect } from "containers/wrapper/actions"
import { clearErrorAlert } from "containers/error/actions"
import { getTUScreeningData } from "./actions"
import { selectCreditReport, makeTuRenterSelector } from "./selectors"
import reducer from "./reducer"
import saga from "./saga"

export const mapStateToProps = createStructuredSelector({
  creditReport: selectCreditReport(),
  tuRenter: makeTuRenterSelector(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getTUScreeningData,
        redirect,
        clearErrorAlert,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export const withReducer = injectReducer({ key: "tuScreening", reducer })

export const withSaga = injectSaga({ key: "tuScreeningSaga", saga })
