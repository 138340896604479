import { fromJS } from "immutable"
import { GET_PAYMENT_SUCCESS, SUBMIT_PAYMENT_SUCCESS } from "./constants"

export const initialState = fromJS({
  paymentId: "",
  price: 0,
  method: "",
  paymentObject: {},
})

function paymentReducer(state = initialState, action) {
  let nextState = state
  const { payload } = action
  switch (action.type) {
    case GET_PAYMENT_SUCCESS:
      nextState = state.mergeDeep(payload)
      return nextState
    case SUBMIT_PAYMENT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default paymentReducer
