import React from "react"
import styled from "styled-components"

import {
  COLOR,
  TOPBAR_HEIGHT,
  BOX_SHADOW_1,
  BOX_SHADOW_TOP_1,
  DIAMOND_LINEAR_GRADIENT,
} from "styles/settings"

import diamondBg from "images/background/gplay.png"

const MENU_HEIGHT = "50px"
const FOOTER_HEIGHT = "60px"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: calc(
    100vh - ${TOPBAR_HEIGHT + MENU_HEIGHT}
  ); /* fallback if calc did not work */
  min-height: calc(var(--vh, 1vh) * 100 - ${TOPBAR_HEIGHT + MENU_HEIGHT});
  position: relative;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  width: 100%;
  background: ${DIAMOND_LINEAR_GRADIENT}, url(${diamondBg});
`

export const SignFlagWrapper = styled.div`
  position: absolute;
  ${props => props.top && `top: ${props.top}`};
  left: calc(((100vw - 800px) / 2) - 100px);
  transition: top 500ms;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${COLOR.newGrey};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  width: 100vw;
  ${props => `
  height: calc(
    100vh - ${MENU_HEIGHT} ${
    props.withoutFooter ? "" : `-   ${FOOTER_HEIGHT}`
  } - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  height: calc(
    var(--vh, 1vh) * 100 - ${MENU_HEIGHT}  ${
    props.withoutFooter ? "" : `-   ${FOOTER_HEIGHT}`
  } - ${TOPBAR_HEIGHT}
  );
  `};
`

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
  padding: 20px;
  background-color: ${COLOR.white};
  overflow: auto;
  &#envelopeToolbar {
    padding: 20px 0;
  }
`

export const SidebarHeader = styled.h5`
  font-weight: 600;
  user-select: none;
  &:hover {
    color: ${COLOR.placeholderGrey};
    cursor: pointer;
  }
`

export const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${props => `
  height: calc(
    100vh - ${MENU_HEIGHT} ${
    props.withoutFooter ? "" : `-   ${MENU_HEIGHT}`
  } - ${TOPBAR_HEIGHT}
  ); /* fallback if calc did not work */
  height: calc(
    var(--vh, 1vh) * 100 - ${MENU_HEIGHT} ${
    props.withoutFooter ? "" : `-   ${MENU_HEIGHT}`
  } - ${TOPBAR_HEIGHT}
  );
  `} padding: 30px 20px;
  overflow: auto;
  ${props => props.center && "align-items : center"};
`

export const DocumentPage = styled.div`
  width: 800px;
  position: relative;
  border: 1px solid ${COLOR.lineGrey};
  box-sizing: content-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  > img {
    user-select: none;
  }
`

export const Divider = styled.div`
  border: 1px solid ${COLOR.newGrey};
  margin: ${props => (props.margin ? props.margin : "15px 0 15px 0")};
`

export const ActionBar = styled.div`
  width: 100%;
  height: ${MENU_HEIGHT};
  padding: 5px 40px;
  box-shadow: ${BOX_SHADOW_1};
  z-index: 1;
  font-weight: 600;
  background-color: ${COLOR.white};
  display: flex;
  justify-content: ${props =>
    (props.flexStart && "flex-start") || "space-between"};
  align-items: center;
  ${props =>
    props.completed
      ? `
  .text-title{
    color: ${COLOR.darkGreen};
  }
  `
      : ""};
`

export const FooterContainer = styled.div`
  width: 100%;
  height: ${FOOTER_HEIGHT};
  padding: 10px 30px;
  box-shadow: ${BOX_SHADOW_TOP_1};
  z-index: 1;
  font-weight: 600;
  background-color: ${COLOR.white};
`

export const IconBeforeText = styled.img`
  margin-right: 10px;
`

export const Icon = styled.i`
  font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
  ${props => props.margin && `margin: ${props.margin}`};
`

export const HeaderButton = styled.button`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  ${props => props.margin && `margin: ${props.margin}`};
  padding: 0px;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const Header = ({ children, ...props }) => (
  <ActionBar {...props}>{children}</ActionBar>
)
