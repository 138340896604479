export const RENTAL_SPECIFICS_AUTOSAVE_CALL = "RENTAL_SPECIFICS_AUTOSAVE_CALL"
export const RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS =
  "RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS"
export const RENTAL_SPECIFICS_AUTOSAVE_ERROR = "RENTAL_SPECIFICS_AUTOSAVE_ERROR"
export const QUEUE_RENTAL_SPECIFICS_AUTOSAVE = "QUEUE_RENTAL_SPECIFICS_AUTOSAVE"
export const CANCEL_RENTAL_SPECIFICS_AUTOSAVE =
  "CANCEL_RENTAL_SPECIFICS_AUTOSAVE_ERROR"

export const RENTAL_APPLICATION_QUEUE_AUTOSAVE_SUCCESS =
  "RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS"
export const RENTAL_APPLICATION_AUTOSAVE_CALL =
  "RENTAL_APPLICATION_AUTOSAVE_CALL"
export const QUEUE_RENTAL_APPLICATION_AUTOSAVE =
  "QUEUE_RENTAL_APPLICATION_AUTOSAVE"
export const CANCEL_RENTAL_APPLICATION_AUTOSAVE =
  "CANCEL_RENTAL_APPLICATION_AUTOSAVE"
export const RENTAL_APPLICATION_AUTOSAVE_ERROR =
  "RENTAL_APPLICATION_AUTOSAVE_ERROR"

export const FORM_TYPE = {
  RENTAL_SPECIFICS: "RENTAL_SPECIFICS",
  RENTAL_APPLICATION: "RENTAL_APPLICATION",
}
