import React from "react"
import styled from "styled-components"

const RentspreeLogo =
  "https://cdn.rentspree.com/static-files/component-2023/rs-full-logo.svg"

const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  padding: 24px 0 24px 20px;
`

const RentSpreeLogo = styled.img`
  height: 24px;
`

export const LogoHeaderContainer = () => (
  <Container>
    <RentSpreeLogo src={RentspreeLogo} alt="RentSpree Logo" />
  </Container>
)
