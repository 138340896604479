/*
 *
 * wrapper constants
 *
 */

export const REDIRECT_RENTAL_APP_CALL = "REDIRECT_RENTAL_APP_CALL"
export const REDIRECT_RENTAL_SUBMISSION_CALL = "REDIRECT_RENTAL_SUBMISSION_CALL"
export const REDIRECT_AUTHWEB_CALL = "REDIRECT_AUTHWEB_CALL"
export const LOCATION_REDIRECT_CALL = "LOCATION_REDIRECT_CALL"
export const SAVE_ALL_FIELD = "SAVE_ALL_FIELD"
export const GET_PROFILE_CALL = "GET_PROFILE_CALL"
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED"
export const CLEAR_PROFILE = "CLEAR_PROFILE"
export const SET_SUCCESS = "SET_SUCCESS"

export const GET_SCREENING_REQUEST_CALL = "GET_SCREENING_REQUEST_CALL"
export const GET_SCREENING_REQUEST_REQUEST = "GET_SCREENING_REQUEST_REQUEST"
export const GET_SCREENING_REQUEST_SUCCESS = "GET_SCREENING_REQUEST_SUCCESS"
export const GET_SCREENING_REQUEST_FAILED = "GET_SCREENING_REQUEST_FAILED"

export const VERIFY_MULTI_SHARE_CALL = "VERIFY_MULTI_SHARE_CALL"
export const VERIFY_MULTI_SHARE_REQUEST = "VERIFY_MULTI_SHARE_REQUEST"
export const VERIFY_MULTI_SHARE_SUCCESS = "VERIFY_MULTI_SHARE_SUCCESS"
export const VERIFY_MULTI_SHARE_FAILED = "VERIFY_MULTI_SHARE_FAILED"

export const VERIFY_MULTI_SHARE_API_URL = "/api/v2/verify-multi-share"

export const SCREENING_REQUEST_API_URL =
  "api/v2/screening-requests/:screeningRequestId/apply"

export const SCREENING_REQUEST_SOURCE = {
  PROPERTY: "property",
  LISTING: "listing",
  INTEGRATION: "integration",
  SYNDICATION: "syndication",
}
export const callSaveAllField = payload => ({ type: SAVE_ALL_FIELD, payload })

export const getProfileCall = payload => ({ type: GET_PROFILE_CALL, payload })
export const getProfileRequest = payload => ({
  type: GET_PROFILE_REQUEST,
  payload,
})
export const getProfileSuccess = payload => ({
  type: GET_PROFILE_SUCCESS,
  payload,
})
export const getProfileFailed = payload => ({
  type: GET_PROFILE_FAILED,
  payload,
})

export const profileClear = () => ({ type: CLEAR_PROFILE })

export const callRedirectRentalApp = payload => ({
  type: REDIRECT_RENTAL_APP_CALL,
  payload,
})

export const callRedirectRentalSubmission = (payload, isReplace, search) => ({
  type: REDIRECT_RENTAL_SUBMISSION_CALL,
  payload,
  isReplace,
  search,
})

export const callRedirectAuthWeb = (payload, query, subdomain) => ({
  type: REDIRECT_AUTHWEB_CALL,
  payload,
  query,
  subdomain,
})

export const callSetSuccess = () => ({ type: SET_SUCCESS })

export const callLocationRedirect = payload => ({
  type: LOCATION_REDIRECT_CALL,
  payload,
})

export const getScreeningRequestCall = payload => ({
  type: GET_SCREENING_REQUEST_CALL,
  payload,
})
export const getScreeningRequestRequest = () => ({
  type: GET_SCREENING_REQUEST_REQUEST,
})
export const getScreeningRequestSuccess = payload => ({
  type: GET_SCREENING_REQUEST_SUCCESS,
  payload,
})
export const getScreeningRequestFailed = payload => ({
  type: GET_SCREENING_REQUEST_FAILED,
  payload,
})
