import { ALERT_PRESET } from "components/sweetalert/constants"
import { OPEN_SWEET_ALERT } from "redux-middleware/constants"
import generateApiCall from "utils/generate-api-call"
// import { ALERT_PRESET } from "redux-middleware/sweet-alert"
// import { OPEN_SWEET_ALERT } from "redux-middleware/sweet-alert/constants"
// import { DASHBOARD } from "constants/route"
// import { GENERAL_ERROR } from "constants/error-messages"

import * as types from "./constants"

export const openSweetAlertTryAgainError = (option, nextAction = {}) => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.TRY_AGAIN,
  option,
  promise: nextAction,
})

export const openSweetAlertError = (option, nextAction = {}) => ({
  type: OPEN_SWEET_ALERT,
  preset: ALERT_PRESET.ERROR,
  option,
  promise: nextAction,
})

export const getEnvelopeByIdCall = (
  envelopeId,
  token,
  voidedPath,
  forSign,
) => ({
  type: types.ENVELOPE_CALL,
  payload: { envelopeId, token, voidedPath, forSign },
})

export const updateEnvelopeSign = payload => ({
  type: types.UPDATE_ENVELOPE_SIGN_CALL,
  payload,
})

export const shareEnvelope = payload => ({
  type: types.SHARE_ENVELOPE_CALL,
  payload,
})

export const updateEnvelopeConsent = (payload, envelopeId, options) => ({
  type: types.UPDATE_ENVELOPE_CONSENT_CALL,
  envelopeId,
  payload,
  options,
})

export const envelopeApi = generateApiCall([
  types.ENVELOPE_REQUEST,
  types.ENVELOPE_SUCCESS,
  types.ENVELOPE_FAILURE,
])

export const updateEnvelopeSignApi = generateApiCall([
  types.UPDATE_ENVELOPE_SIGN_REQUEST,
  types.UPDATE_ENVELOPE_SIGN_SUCCESS,
  types.UPDATE_ENVELOPE_SIGN_FAILED,
])

export const shareEnvelopeApi = generateApiCall([
  types.SHARE_ENVELOPE_REQUEST,
  types.SHARE_ENVELOPE_SUCCESS,
  types.SHARE_ENVELOPE_FAILED,
])

export const uploadSignatureApi = generateApiCall([
  types.UPLOAD_SIGNATURE_REQUEST,
  types.UPLOAD_SIGNATURE_SUCCESS,
  types.UPLOAD_SIGNATURE_FAILED,
])
export const savingFilesApi = generateApiCall([
  types.SAVE_FILES_ENVELOPE_REQUEST,
  types.SAVE_FILES_ENVELOPE_SUCCESS,
  types.SAVE_FILES_ENVELOPE_FAILED,
])
export const generatePdfFileURLApi = generateApiCall([
  types.GENERATE_PDF_FILE_URL_REQUEST,
  types.GENERATE_PDF_FILE_URL_SUCCESS,
  types.GENERATE_PDF_FILE_URL_FAILED,
])

export const updateEnvelopeConsentApi = generateApiCall([
  types.UPDATE_ENVELOPE_CONSENT_REQUEST,
  types.UPDATE_ENVELOPE_CONSENT_SUCCESS,
  types.UPDATE_ENVELOPE_CONSENT_FAILED,
])

export const uploadSaveSignSignature = payload => ({
  type: types.UPLOAD_SIGN_SIGNATURE,
  payload,
  mode: "save",
})
export const uploadEditSignSignature = payload => ({
  type: types.UPLOAD_SIGN_SIGNATURE,
  payload,
  mode: "edit",
})
export const saveSignSignature = payload => ({
  type: types.SIGN_SIGNATURE_SAVE,
  payload,
})
export const editSignSignature = payload => ({
  type: types.SIGN_SIGNATURE_EDIT,
  payload,
})
export const saveFiles = payload => ({
  type: types.SAVE_FILES_ENVELOPE,
  payload,
})
export const generatePdfFileURL = payload => ({
  type: types.GENERATE_PDF_FILE_URL,
  payload,
})
export const dateSignedSave = payload => ({
  type: types.DATE_SIGNED_SAVE,
  payload,
})

export const showConsentModal = payload => ({
  type: types.SHOW_CONSENT_MODAL,
  payload,
})

export const clearLeaseError = () => ({ type: types.CLEAR_LEASE_ERROR })

export const removeActiveBox = () => ({ type: types.REMOVE_ACTIVE_BOX })

export const recipientIdSelected = payload => ({
  type: types.SET_RECIPIENT_ID_SELECTED,
  payload,
})

export const updateEnvelopeByTemplateApi = generateApiCall([
  types.UPDATE_ENVELOPE_TEMPLATE_REQUEST,
  types.UPDATE_ENVELOPE_TEMPLATE_SUCCESS,
  types.UPDATE_ENVELOPE_TEMPLATE_FAILED,
])

export const getUserIntegrationsSuccess = payload => ({
  type: types.GET_USER_INTEGRATION_SUCCESS,
  integration: payload,
})

export const updateConsentLeaseAgreementApi = generateApiCall([
  types.UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  types.UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
  types.UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
])

export const updateLeaseAgreementConsent = (
  envelopeId,
  payload,
  nextPath,
  ignoreUpdateTemplate,
  digitalDocTemplate,
) => ({
  type: types.UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  envelopeId,
  payload,
  nextPath,
  ignoreUpdateTemplate,
  digitalDocTemplate,
})

export const prepareEnvelopeCall = payload => ({
  type: types.PREPARE_ENVELOPE_CALL,
  payload,
})
export const putPrepareEnvelopeRequest = () => ({
  type: types.PREPARE_ENVELOPE_REQUEST,
})
export const putPrepareEnvelopeSuccess = payload => ({
  type: types.PREPARE_ENVELOPE_SUCCESS,
  payload,
})
export const putPrepareEnvelopeFailure = payload => ({
  type: types.PREPARE_ENVELOPE_FAILED,
  payload,
})
export function prepareEnvelope(payload) {
  return prepareEnvelopeCall(payload)
}

export const generateEnvelopePDFCall = ({ token, callback }) => ({
  type: types.GENERATE_ENVELOPE_PDF_CALL,
  token,
  callback,
})

export function generateEnvelopePDF(payload) {
  return generateEnvelopePDFCall(payload)
}
export const generateEnvelopePDFApi = generateApiCall([
  types.GENERATE_ENVELOPE_PDF_REQUEST,
  types.GENERATE_ENVELOPE_PDF_SUCCESS,
  types.GENERATE_ENVELOPE_PDF_FAILED,
])
