const occupation = {}

export const stepEmployedOccupation = (data = {}) => {
  const {
    employed = {},
    workStatus = "",
    additionalIncomes = [],
    isRentSubsidyChecked,
  } = data

  const {
    employer,
    jobTitle,
    salary,
    startDate,
    endDate,
    firstName,
    lastName,
    phoneNumber,
    email,
    isSkipPhoneHR,
    isSkipEmailHR,
  } = employed
  occupation[data.occurring] = {
    workStatus,
    ...(employed && {
      employed: {
        employer,
        jobTitle,
        salary,
        startDate,
        endDate,
        supervisorFirstName: firstName,
        supervisorLastName: lastName,
        supervisorPhoneNumber: phoneNumber,
        supervisorEmail: email,
        isSkipPhoneHR,
        isSkipEmailHR,
      },
    }),
  }
  occupation.rentSubsidy = isRentSubsidyChecked ? data.rentSubsidy : null
  occupation.additionalIncomes = additionalIncomes.filter(
    income => income.source && income.amount, // Filter out incomplete additional incomes
  )

  return { occupation }
}
