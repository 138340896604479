import { displayDateString } from "../../utils/display-date-string"
import { DAILY_APPLY_LIMIT_NUMBER } from "./constants"

export const generateMultiShareMessage = (multiShare, isAppOnly = false) => {
  const {
    multiShareExpiredAt,
    remainingReportPerDay,
    isMultiShareActive,
  } = multiShare
  if (isMultiShareActive) {
    return `Apply for free until ${displayDateString(
      multiShareExpiredAt,
    )}. You have ${remainingReportPerDay} of ${DAILY_APPLY_LIMIT_NUMBER} applications left for today.`
  }

  return isAppOnly
    ? `Your 30-day free application offer expired on ${displayDateString(
        multiShareExpiredAt,
      )}.`
    : `Your 30-day free application offer expired on ${displayDateString(
        multiShareExpiredAt,
      )}. Select “Yes” to reactivate!`
}
