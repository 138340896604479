import { cloneDeep, get, isEqual, omit, set, unset } from "lodash"
import { autoSaveRentalSpecifics, autoSaveRentalApplication } from "./actions"
import { FORM_TYPE } from "./constants"

export const handleOnChangeAutosave = async ({
  dispatch,
  values,
  validator,
  type,
  parser,
  pathname,
  publishedState,
  continuePath,
}) => {
  let cleanedValue = values
  let isDraft = false

  if (validator) {
    const result = await filterOutInvalidFields(
      values,
      validator,
      publishedState,
    )
    cleanedValue = result.cleanValue
    isDraft = result.isError
  }

  // NOTE: do nothing if the cleanedValue is the same as publishedState
  if (isEqual(omit(cleanedValue, "occurring"), publishedState)) {
    return
  }

  switch (type) {
    case FORM_TYPE.RENTAL_SPECIFICS:
      dispatch(
        autoSaveRentalSpecifics({
          originalValue: values,
          value: cleanedValue,
          parser,
          pathname,
          isDraft,
          continuePath,
        }),
      )
      break
    case FORM_TYPE.RENTAL_APPLICATION:
      dispatch(
        autoSaveRentalApplication({
          value: cleanedValue,
          parser,
          pathname,
          isDraft,
          continuePath,
        }),
      )
      break
    default:
  }
}

export const filterOutInvalidFields = async (values, validator, data) => {
  const cleanValue = cloneDeep(values)
  let isError = false
  await validator.validate(values, { abortEarly: false }).catch(error => {
    Object.values(error.inner).forEach(innerValue => {
      const originalValue = get(data, innerValue.path)
      if (originalValue) {
        set(cleanValue, innerValue.path, originalValue)
        return
      }

      unset(cleanValue, innerValue.path)
      isError = true
    })
  })
  return { cleanValue, isError }
}
