import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import makeSelectLoading from "containers/loading/selectors"
import { getCurrentRental } from "containers/rental-submission/actions"
import {
  saveAllField,
  getProfile,
  redirectAuthWeb,
  redirect,
  getScreeningRequest,
  verifyMultiShareCall,
} from "./actions"
import reducer from "./reducer"
import saga from "./saga"
import {
  makeSelectProfile,
  makeSelectSubdomain,
  makeSelectIsAllowNewQuestionUser,
} from "./selectors"
export const withReducer = injectReducer({ key: "wrapper", reducer })
export const withSaga = injectSaga({ key: "wrapper", saga })

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  loading: makeSelectLoading(),
  subdomain: makeSelectSubdomain(),
  isAllowNewQuestionUser: makeSelectIsAllowNewQuestionUser,
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getProfile,
        saveAllField,
        redirectAuthWeb,
        getCurrentRental,
        getScreeningRequest,
        redirect,
        verifyMultiShareCall,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
