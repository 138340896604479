import React from "react"
import { addListener, removeListener } from "../../utils/call-window"

class Beforeunload extends React.Component {
  componentDidMount() {
    addListener("beforeunload", this.handleBeforeunload)
  }

  componentWillUnmount() {
    removeListener("beforeunload", this.handleBeforeunload)
  }

  handleBeforeunload = event => {
    // const { onBeforeunload } = this.props
    // let returnValue

    // if (onBeforeunload) {
    //   returnValue = onBeforeunload(event)
    // }

    // if (typeof returnValue === "string") {
    //   // eslint-disable-next-line no-param-reassign
    //   event.returnValue = returnValue
    //   return returnValue
    // }

    // return returnValue
    let returnValue
    const { isDirty } = this.props
    if (isDirty) {
      returnValue = "alert"
      // eslint-disable-next-line no-param-reassign
      event.returnValue = returnValue
    }
    return returnValue
  }

  render() {
    const { children = null } = this.props
    return children
  }
}

export default Beforeunload
