import React, { Fragment } from "react"
import { compose } from "redux"
import _ from "lodash"
import { getLocalItem } from "@rentspree/cookie"
import { withRouter } from "react-router-dom"

import { STORAGE } from "constants/cookie"
import { FullScreenLoading } from "containers/loading"
import { StyledWrapperDarkGradient } from "components/layout"
import { withReducer, withConnect, withSaga } from "./connect"
import { LogoHeaderContainer } from "./logo-header"

export class WrapperWithoutNavbar extends React.Component {
  componentWillMount() {
    const { actions, match } = this.props
    const screeningRequestId = _.get(match, "params.screeningRequestId", "")
    // TODO Check this
    // actions.saveAllField({ propertyId, screeningOptionId })
    actions.saveAllField({ screeningRequestId })
    if (getLocalItem(STORAGE.USER_TOKEN)) {
      actions.redirect()
    } else {
      actions.getScreeningRequest(screeningRequestId)
    }
  }
  render() {
    const { children, loading } = this.props
    return (
      <Fragment>
        {_.get(loading, "full", false) && <FullScreenLoading noneNavbar />}
        <StyledWrapperDarkGradient>
          <LogoHeaderContainer />
          {children}
        </StyledWrapperDarkGradient>
      </Fragment>
    )
  }
}

export default compose(
  withReducer,
  withConnect,
  withSaga,
  withRouter,
)(WrapperWithoutNavbar)
