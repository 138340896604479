import { createSelector } from "reselect"
import { formatLocalPhone } from "@rentspree/helper"
import { isEmpty } from "lodash"
import { initialState } from "./reducer"

export const selectLandlord = state => state.get("landlord", initialState)

// TODO: Deprecated
export const makeSelectLandlord = () =>
  createSelector(selectLandlord, substate => {
    const substateObj = substate.toJS()
    return substateObj.landlord
  })

export const makeSelectAgentProfile = () =>
  createSelector(selectLandlord, state => {
    const { agentProfile, landlord } = state.toJS()

    if (isEmpty(agentProfile)) {
      return {
        displayName: landlord.displayName || "",
      }
    }

    const agentInformationList = []
    if (agentProfile.phone) {
      agentInformationList.push(formatLocalPhone(agentProfile.phone))
    }
    if (agentProfile.email) {
      agentInformationList.push(agentProfile.email)
    }
    if (agentProfile.brokerageName) {
      agentInformationList.push(agentProfile.brokerageName)
    }
    return {
      displayName: agentProfile.displayName || "",
      information: agentInformationList.join(" | "),
      imageSrc: agentProfile.avatar,
      isVerified: agentProfile.isVerified,
      link: agentProfile.link,
    }
  })

export const makeSelectAgentReview = () =>
  createSelector(selectLandlord, subState => subState.toJS().agentReview)
