import {
  takeLatest,
  put,
  call,
  select,
  cancel,
  all,
  actionChannel,
  race,
  take,
  debounce,
  flush,
} from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import * as Sentry from "@sentry/browser"
import _ from "lodash"
import isEmpty from "lodash/isEmpty"
import { NODE_ENV } from "env"

import { apiInstance } from "utils/api-interceptor"
import { ROUTE } from "containers/router/constants"
import { getError } from "containers/error/selectors"
import {
  selectRentalId,
  selectScreeningOption,
} from "containers/rental-submission/selectors"
import {
  showErrorAlertCall,
  clearErrorAlertCall,
} from "containers/error/constants"
import { tracker } from "tracker"
import { buffers } from "redux-saga"
import {
  RENTAL_GUIDE_EVENT,
  mapEventProperty,
  selectEventNameApplication,
} from "tracker/tracker-const"
import { redirectRentalApp, redirect, setSuccess } from "../wrapper/actions"
import { getParser } from "./map-to-api"
import {
  SAVE_APPLICATION_CALL,
  saveApplicationRequest,
  saveApplicationSuccess,
  saveApplicationFailed,
  validateApplicationErrorExpected,
  getApplicationRequested,
  getApplicationSuccess,
  getApplicationFailed,
  API_APPLICATION,
  APP_STATUS,
  API_APPLICATION_VALIDATION,
  validateApplicationErrorClear,
  validateApplicationCall,
  validateApplicationDone,
  DRAFT_ACTION,
  RENTAL_APPLICATION_AUTOSAVE_FROM_CTA,
} from "./constants"
import {
  RENTAL_APPLICATION_AUTOSAVE_CALL,
  QUEUE_RENTAL_APPLICATION_AUTOSAVE,
} from "../autosave-form/constants"
import {
  autoSaveRentalApplicationError,
  cancelAutoSaveRentalApplication,
  queueAutoSaveRentalApplication,
  rentalApplicationQueueAutosaveSuccess,
} from "../autosave-form/actions"
import { setRentalAppContinuePath } from "../rental-submission/actions"
import { getValidator } from "./get-validator"
import { filterOutInvalidFields } from "../autosave-form/onchange-autosave"
import { selectIsResumeAppEnabled } from "../feature-flags/selectors"
import { getCleanDraftAction } from "../autosave-form/helpers"
import { selectAppDraft } from "./selectors"

export const saveApplicationApi = (data, rentalId, draftAction) => {
  const queryParams = {}
  if (draftAction) {
    queryParams.draftAction = draftAction
  }

  return apiInstance.put(
    buildPath(API_APPLICATION, { rentalId }, queryParams),
    data,
  )
}

export const getApplicationApi = rentalId =>
  apiInstance.get(buildPath(API_APPLICATION, { rentalId }))

export const validateApplicationApi = rentalId =>
  apiInstance.get(buildPath(API_APPLICATION_VALIDATION, { rentalId }))
const caughtError = new Error()

export function* validateApplication() {
  try {
    yield put(validateApplicationCall())
    yield put(validateApplicationErrorClear())
    const rentalId = yield select(selectRentalId)
    yield call(validateApplicationApi, rentalId)
    // return false if task haven't redirect user
    yield put(validateApplicationDone())
    return false
  } catch (err) {
    caughtError.message = _.get(
      err,
      "data.message",
      "Something went wrong when validate the application",
    )
    caughtError.name = _.get(
      err,
      "data.name",
      "Unexpected Validate Application Error",
    )
    const errorDetails = _.get(err, "data.details", err)
    caughtError.details =
      errorDetails && JSON.stringify(errorDetails, null, "  ")

    Sentry.captureException(caughtError) // send error to sentry
    if (NODE_ENV !== "test") {
      // make sure that we can trace error in FullStory
      console.log("Validation Error ======>", _.get(err, "data", err)) // eslint-disable-line
    }

    // identify error to make sure it is expected or not
    if (_.get(err, "data.name") === "ValidationError") {
      yield put(
        validateApplicationErrorExpected({
          error: _.get(err, "data.details"),
          location: ROUTE.APPLICATION.PERSONAL.BASE,
        }),
      )
    } else {
      yield put(
        validateApplicationErrorExpected({
          error: err,
          location: ROUTE.APPLICATION.PERSONAL.BASE,
        }),
      )
    }
    const error = yield select(getError)
    const errorStep = error.location || ROUTE.APPLICATION.PERSONAL.BASE
    yield put(validateApplicationDone())
    yield put(showErrorAlertCall())
    yield put(redirectRentalApp(errorStep))
    // return true if task already redirect user
    return true
  }
}

export function* saveApplication({
  payload,
  route,
  parser,
  isLastStep,
  draftAction,
  continuePath,
}) {
  // Note If click back fast, saga will eject saveApplicationSuccess function
  yield put(saveApplicationRequest())
  // prevent redirect if it is the last page or have no route
  if (!_.isEmpty(route) && !isLastStep) {
    yield put(redirectRentalApp(route.next))
  }
  // get error to check if there are error
  let error = yield select(getError)
  try {
    const rentalId = yield select(selectRentalId)
    const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)
    const parsedPayload = getParser(parser)(payload)
    if (isResumeAppEnabled) {
      parsedPayload.continuePath = continuePath
    }
    const response = yield call(
      saveApplicationApi,
      parsedPayload,
      rentalId,
      draftAction,
    )
    yield put(saveApplicationSuccess(response, isResumeAppEnabled))
    yield put(setRentalAppContinuePath(response.continuePath))

    if (route) {
      // trackEvent when success
      const { eventName, properties } = selectEventNameApplication(route)
      const selectProperties = mapEventProperty(properties, {
        response,
        parsedPayload,
        rental: { rentalId },
      })

      if (!isEmpty(eventName)) {
        yield call([tracker, "trackEvent"], eventName, selectProperties)
      }

      // Clear error when success and passed the error page
      if (route.current === error.location) {
        yield put(clearErrorAlertCall())
      }
    }

    // Only last step
    const appReady =
      _.get(response, "status") === APP_STATUS.READY ||
      _.get(response, "status") === APP_STATUS.SUBMITTED
    if (isLastStep) {
      // call validateApplication when it last step
      const isCancelled = yield* validateApplication()
      // cancel task if validateApplication saga already redirect
      if (isCancelled) {
        yield cancel()
      }

      // Redirect when app is ready and no error
      if (appReady && _.isEmpty(error.location)) {
        yield call(
          [tracker, "trackEvent"],
          RENTAL_GUIDE_EVENT.COMPLETE_RENTAL_APPLICATION,
          {
            rental_submission_id: rentalId,
            template: response.type,
            template_version: response.version,
          },
        )
        yield put(setSuccess())
        yield put(clearErrorAlertCall())
        yield put(redirect(false))
      } else {
        const errorStep = error.location || ROUTE.APPLICATION.PERSONAL.BASE
        yield put(showErrorAlertCall())
        yield put(redirectRentalApp(errorStep))
      }
    }
  } catch (err) {
    caughtError.message = _.get(
      err,
      "data.message",
      "Something went wrong when save the application",
    )
    caughtError.name = _.get(
      err,
      "data.name",
      "Unexpected Save Application Error",
    )
    caughtError.details = _.get(err, "data", err)
    if (NODE_ENV !== "test") {
      // make sure that we can trace error in FullStory
      console.error("Save application error ======> ", err)
    }
    Sentry.captureException(caughtError) // send error to sentry
    if (route) {
      yield put(saveApplicationFailed(route.current))
    }
    // if only last page is error, it should call get error to update error message
    // (get error before try-catch didn't have error message)
    if (isLastStep) {
      error = yield select(getError)
      const errorStep = error.location || ROUTE.APPLICATION.PERSONAL.BASE
      yield put(showErrorAlertCall())
      yield put(redirectRentalApp(errorStep))
    }
  }
}

export function* getApplication() {
  yield put(getApplicationRequested())
  const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)
  try {
    const screeningOption = yield select(selectScreeningOption)
    const rentalId = yield select(selectRentalId)
    if (!_.get(screeningOption, "application", true)) {
      yield put(redirect())
      yield put(getApplicationFailed())
    } else {
      const response = yield call(getApplicationApi, rentalId)
      yield put(getApplicationSuccess(response, isResumeAppEnabled))
    }
  } catch (err) {
    yield put(getApplicationFailed())
  }
}

export function* autoSaveRentalApplicationFromCTA(action) {
  const { payload } = action
  const {
    values,
    validatorName,
    parser,
    props,
    pathname,
    continuePath,
  } = payload
  const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)
  const rentalId = yield select(selectRentalId)
  const draft = yield select(selectAppDraft)
  let toSaveValue = values
  let isSavingDraft = false
  try {
    if (isResumeAppEnabled) {
      if (validatorName) {
        const validator = yield call(getValidator, validatorName)
        const { mapper, schema } = yield call(validator, props)
        const { cleanValue, isError } = yield call(
          filterOutInvalidFields,
          values,
          schema,
          mapper,
        )
        toSaveValue = cleanValue
        isSavingDraft = isError
      }
      const parsedPayload = getParser(parser)(toSaveValue)
      parsedPayload.continuePath = continuePath
      const saveApplicationApiParams = isSavingDraft
        ? [
            {
              data: toSaveValue,
              pathname,
              parser,
              continuePath: payload.continuePath,
            },
            rentalId,
            DRAFT_ACTION.CREATE,
          ]
        : [parsedPayload, rentalId, getCleanDraftAction(draft, pathname)]

      yield put(saveApplicationRequest())
      const response = yield call(
        saveApplicationApi,
        ...saveApplicationApiParams,
      )
      yield put(saveApplicationSuccess(response, isResumeAppEnabled))
      if (response) {
        yield put(setRentalAppContinuePath(response.continuePath))
      }
    }
  } catch (error) {
    yield put(autoSaveRentalApplicationError(error))
  } finally {
    if (payload.redirectToGuide) {
      yield put(redirect())
    }
  }
}

export function* queueApplicationAutosaveSaga({ payload }) {
  yield put(queueAutoSaveRentalApplication(payload))
}

export function* watchAutosaveQueued() {
  const autosaveApplicationQueueBuffer = buffers.expanding()
  const autosaveQueue = yield actionChannel(
    QUEUE_RENTAL_APPLICATION_AUTOSAVE,
    autosaveApplicationQueueBuffer,
  )
  while (true) {
    const { payload } = yield take(autosaveQueue)
    try {
      const rentalId = yield select(selectRentalId)
      const draft = yield select(selectAppDraft)
      const parsedPayload = getParser(payload.parser)(payload.value)
      parsedPayload.continuePath = payload.continuePath
      const saveApplicationApiParams = payload.isDraft
        ? [
            {
              data: payload.value,
              pathname: payload.pathname,
              parser: payload.parser,
              continuePath: payload.continuePath,
            },
            rentalId,
            DRAFT_ACTION.CREATE,
          ]
        : [
            parsedPayload,
            rentalId,
            getCleanDraftAction(draft, payload.pathname),
          ]
      yield put(saveApplicationRequest())
      const { response, cancel: cancelAutosave } = yield race({
        response: call(saveApplicationApi, ...saveApplicationApiParams),
        cancel: take(SAVE_APPLICATION_CALL),
      })

      if (cancelAutosave) {
        yield put(cancelAutoSaveRentalApplication())
        yield flush(autosaveQueue)
      }
      yield put(rentalApplicationQueueAutosaveSuccess())
      if (response) {
        yield put(setRentalAppContinuePath(response.continuePath))
      }
    } catch (error) {
      yield put(autoSaveRentalApplicationError(error))
      yield flush(autosaveQueue)
    }
  }
}

export function* watchSaveApplication() {
  yield takeLatest(SAVE_APPLICATION_CALL, saveApplication)
}

export function* watchAutosaveTriggered() {
  while (true) {
    const queueDebounce = yield debounce(
      3000,
      RENTAL_APPLICATION_AUTOSAVE_CALL,
      queueApplicationAutosaveSaga,
    )
    yield race([
      take(SAVE_APPLICATION_CALL),
      take(RENTAL_APPLICATION_AUTOSAVE_FROM_CTA),
    ])
    yield cancel(queueDebounce)
  }
}
export function* watchAutoSaveRentalApplicationFromCTA() {
  yield takeLatest(
    RENTAL_APPLICATION_AUTOSAVE_FROM_CTA,
    autoSaveRentalApplicationFromCTA,
  )
}

export default function* rootSaga() {
  yield all([
    watchAutosaveTriggered(),
    watchAutosaveQueued(),
    watchSaveApplication(),
    watchAutoSaveRentalApplicationFromCTA(),
  ])
}
