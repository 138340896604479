import { HTML5_FMT } from "moment"

export const TYPES_COMPONENT = {
  TEXT_BOX: "TEXT_BOX",
  CHECK_BOX: "CHECK_BOX",
  INITIAL_SIGNS: "INITIAL_SIGNS",
  SIGNATURE_SIGNS: "SIGNATURE_SIGNS",
  DATE_SIGNS: "DATE_SIGNS",
  ADDRESS: "ADDRESS",
  DATE_BOX: "DATE_BOX",
  AMOUNT_BOX: "AMOUNT_BOX",
  TIME_BOX: "TIME_BOX",
}

export const SIGNABLE_COMPONENT = [
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
]

export const HAS_MOMENT_VALIDATION = [
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const VALIDATION_FORMAT = {
  DATE_BOX: HTML5_FMT.DATE,
  TIME_BOX: "hh:mm",
}

export const CONFIRM_MODAL_TEXT = {
  TITLE: "Recheck your information",
  INFO: "After you finish signing, you won’t be able to edit the application.",
  SUBMIT: "Finish Signing",
  CANCEL: "Recheck",
}
