import _ from "lodash"
import { object, array, lazy } from "yup"
import {
  validateLength,
  validatePhone,
  validateEmail,
  validateAcquaintanceLength,
  validatePersonName,
  validateAlphaNumeric,
  validateAccountType,
  validateOther,
} from "constants/validations"
import { addressValidationSchema } from "../validation-schema"

export const stepCreditorInformation = props => {
  const { creditorInformation } = props
  return {
    mapper: {
      creditors: _.cloneDeep(creditorInformation),
    },
    schema: object().shape({
      creditors: array().of(
        object().shape({
          name: validatePersonName(false, 0, 40),
          accountNumber: validateAlphaNumeric(),
          monthlyPayment: validateLength(false, 0, 18),
          balanceDue: validateLength(false, 0, 18),
        }),
      ),
    }),
  }
}

export const stepBankInformationValidation = props => {
  const { bankInformation } = props
  return {
    mapper: {
      bankInformation: _.cloneDeep(bankInformation),
    },
    schema: object().shape({
      bankInformation: array().of(
        object().shape({
          name: validatePersonName(false, 0, 40),
          accountNumber: validateAlphaNumeric(),
          accountBalance: validateLength(false, 0, 18),
          accountType: validateAccountType(),
          other: validateOther(),
        }),
      ),
    }),
  }
}

export const stepRelativeValidation = props => {
  const { nearestRelatives, isUsAddressOnly, isRequireFullAddress } = props
  return {
    mapper: { relatives: _.cloneDeep(nearestRelatives) },
    schema: object().shape({
      relatives: array(
        lazy(values =>
          object({
            firstName: validateLength(),
            lastName: validateLength(),
            phone: validatePhone(),
            email: validateEmail(false),
            relationship: validateLength(true, 0, 70),
            ...addressValidationSchema({
              address: {
                zipCode: values.zipcode,
                streetAddress: values.streetAddress,
                region: values.region,
              },
              isRequireFullAddress,
              isUsAddressOnly,
            }),
          }),
        ),
      ),
    }),
  }
}

export const stepPersonalRefValidation = props => {
  const { personalReferences, isUsAddressOnly, isRequireFullAddress } = props
  return {
    mapper: { personalRefs: _.cloneDeep(personalReferences) },
    schema: object().shape({
      personalRefs: array(
        lazy(values =>
          object({
            firstName: validateLength(),
            lastName: validateLength(),
            phone: validatePhone(),
            email: validateEmail(false),
            acquaintanceLength: validateAcquaintanceLength(),
            occupation: validateLength(true, 0, 70),
            ...addressValidationSchema({
              address: {
                zipCode: values.zipcode,
                streetAddress: values.streetAddress,
                region: values.region,
              },
              isRequireFullAddress,
              isUsAddressOnly,
            }),
          }),
        ),
      ),
    }),
  }
}
