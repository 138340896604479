import * as AppPersonalValidator from "./personal-information/validation-schema"
import * as AppOccupationValidator from "./occupation/validation-schema"
import * as AppResidenceValidator from "./residence/validation-schema"
import * as AppReferencesValidator from "./reference/validation-schema"
import * as AppMiscellaneousValidator from "./additional-information/validation-schema"

const validatorObject = {
  ...AppPersonalValidator,
  ...AppOccupationValidator,
  ...AppResidenceValidator,
  ...AppReferencesValidator,
  ...AppMiscellaneousValidator,
}
export const getValidator = validatorName => validatorObject[validatorName]
