import {
  getRentalSubmissionCall,
  getRentalWithCoApplicantCall,
  SET_RENTAL_APP_CONTINUE_PATH,
  SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE,
} from "./constants"

export function getCurrentRental(screeningRequestId) {
  return getRentalSubmissionCall({ screeningRequestId })
}

export function getRentalWithCoApplicant() {
  return getRentalWithCoApplicantCall()
}

export const setRentalAppContinuePath = payload => ({
  type: SET_RENTAL_APP_CONTINUE_PATH,
  payload,
})

export const setRentalSpecificsContinuePathFromAutoSave = payload => ({
  type: SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE,
  payload,
})
