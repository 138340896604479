import React from "react"
import _ from "lodash"
import { Clearfix } from "react-bootstrap"
import { withFormik, FieldArray } from "formik"
import { RadioGroupWithConditionalFields, S24 } from "@rentspree/component-v2"

import { Col } from "components/layout/bootstrap"
import { FormFooter } from "components/buttons/form-footer"
import ScrollToError from "components/scroll/scroll-to-error"
import Beforeunload from "components/alerts/beforeunload"

import { examValidation, isSelectedAll } from "./validation-schema"
import { examMapper } from "./map-to-api"

export const InnerForm = props => {
  const {
    actions,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    setFieldValue,
    loading,
  } = props
  return (
    <form onSubmit={handleSubmit} data-hj-suppress className="LoSensitive">
      <ScrollToError containerError {...props} />
      <Beforeunload isDirty={dirty} />
      <FieldArray
        name="questions"
        render={() =>
          values.questions.map((q, index) => (
            <Clearfix key={q.id}>
              <Col sm={12}>
                <S24>
                  {index + 1}. {q.title}
                </S24>
                <RadioGroupWithConditionalFields
                  choices={q.choices}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  name={`questions.${index}.selected`}
                  id={q.id}
                  isChecked={choiceValue =>
                    choiceValue === _.get(q, "selected")
                  }
                  hiddenFieldNames=""
                  setFieldValue={setFieldValue}
                  values={values}
                  error={
                    _.get(touched, `questions.${index}.selected`) &&
                    _.get(errors, `questions.${index}.selected`)
                  }
                  groupClassName="fs-block"
                />
              </Col>
            </Clearfix>
          ))
        }
      />
      <FormFooter
        cancelBtn={{
          id: "verifyExamCancelBtn",
          onClick: () => actions.redirect(),
          disabled: loading.components,
        }}
        continueBtn={{
          id: "verifyExamBtn",
          text: "Continue",
          loading: loading.components,
          loaded: loading.success,
          disabled: !isSelectedAll(values),
        }}
      />
    </form>
  )
}

const VerifyExamForm = withFormik({
  mapPropsToValues: props => ({ questions: props.questions }),
  validationSchema: () => examValidation().schema,
  handleSubmit: (values, { props }) => {
    const tuExam = _.get(props, "tuExam")
    const tuQuestion = _.get(props, "tuExam.Questions")
    props.actions.evaluateExam({
      ...tuExam,
      Questions: examMapper(tuQuestion, values),
    })
  },
  enableReinitialize: true,
  displayName: "verifyExamForm",
})(InnerForm)

export default VerifyExamForm
