import React from "react"
import _ from "lodash"
import { BASE_NAME } from "../env"

// This function takes a component...
export function withTracker(WrappedComponent, tracker, pageName) {
  // ...and returns another component...
  return class extends React.Component {
    componentDidMount() {
      const { match } = this.props
      const urlWithSearch = _.trim(_.get(window, "location.href", ""))
      const pathWithBasename = `${BASE_NAME}${match.path}`
      const processedPageName = _.isEmpty(pageName)
        ? _.get(this, "props.pageName")
        : pageName
      tracker.trackPageView(urlWithSearch, pathWithBasename, {
        pageName: processedPageName,
      })
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
