import React from "react"
import PropTypes from "prop-types"
import { TextInput, ErrorAlert } from "@rentspree/component-v2"
import styled from "styled-components"

import { COLOR } from "../../../styles/settings"
import { COPY_AND_PASTE_ERR_MSG } from "./constants"
import { censorSSN, formatSSN } from "../../../helpers/ssn"

const ShowPwBtn = styled.button`
  color: ${COLOR.lightGray};
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 43px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
    color: ${COLOR.lineGray}
    pointer-events: none
  `};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${COLOR.gray};
  }
`

const SSNInput = ({
  id,
  name,
  value = "",
  label,
  onBlur,
  touched,
  errors,
  isRequired,
  labelChildren,
  setFieldValue,
  disabled,
}) => {
  const [isCopyPasteSSN, setIsCopyPasteSSN] = React.useState(false)
  const [isShowSSN, setIsShowSSN] = React.useState(false)

  const handleCopyAndPaste = event => {
    event.preventDefault()
    setIsCopyPasteSSN(true)
    return false
  }

  const handleChange = e => {
    // Extract the first 9 characters and remove any non number except password character
    const ssnInput = e.target.value.replace(/[^0-9•]/g, "")
    const maxLength = 9

    if (ssnInput.length >= maxLength) {
      setIsCopyPasteSSN(false)
    }

    const isRemoving = value.length > ssnInput.length

    if (ssnInput.length <= maxLength) {
      if (isRemoving) {
        const removedLast = value.slice(0, value.length - 1)
        setFieldValue(name, removedLast)
      } else {
        setFieldValue(name, `${value}${ssnInput.slice(value.length)}`)
      }
    }
  }

  const onToggleShowHide = () => {
    setIsShowSSN(!isShowSSN)
  }

  return (
    <>
      <TextInput
        id={id}
        name={name}
        value={isShowSSN ? formatSSN(value) : formatSSN(censorSSN(value))}
        label={label}
        isRequired={isRequired}
        onChange={handleChange}
        onBlur={onBlur}
        error={touched[name] && errors[name]}
        labelChildren={labelChildren}
        onCopy={handleCopyAndPaste}
        onPaste={handleCopyAndPaste}
        onCut={handleCopyAndPaste}
        disabled={disabled}
        autoComplete="off"
      />
      <ShowPwBtn
        id={`${id}ShowHideButton`}
        type="button"
        onClick={onToggleShowHide}
        tabIndex={-1}
        disabled={disabled}>
        {isShowSSN ? "Hide" : "Show"}
      </ShowPwBtn>
      {isCopyPasteSSN && (
        <ErrorAlert
          errorMessage={COPY_AND_PASTE_ERR_MSG}
          onClose={() => setIsCopyPasteSSN(false)}
          margin="0 0 15px 0"
        />
      )}
    </>
  )
}

SSNInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.func,
  errors: PropTypes.func,
  isRequired: PropTypes.bool,
  labelChildren: PropTypes.node,
  disabled: PropTypes.bool,
}

export default SSNInput
