import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"
import saga from "./saga"
import reducer from "./reducer"
import {
  updateIncomeVerificationStatus,
  createFinicityConnectLink,
  cleanConnectLink,
  submitIncomeVerification,
  handleFinicityPopupDone,
  handleFinicityPopupError,
} from "./action"
import { redirect } from "../../../containers/wrapper/actions"
import { addToast } from "../../../containers/toast/actions"

export const mapStateToProps = createStructuredSelector({})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addToast,
        updateIncomeVerificationStatus,
        createFinicityConnectLink,
        cleanConnectLink,
        submitIncomeVerification,
        redirect,
        handleFinicityPopupDone,
        handleFinicityPopupError,
      },
      dispatch,
    ),
  }
}

export const withReducer = injectReducer({
  key: "income-verification",
  reducer,
})

export const withSaga = injectSaga({
  key: "income-verification",
  saga,
})

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
