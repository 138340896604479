import useTracker from "tracker/use-tracker"
import {
  PAGE_VIEWED_EVENT_PROPERTY,
  PAGE_VIEWED_EVENT_NAME,
} from "tracker/tracker-const"
import { tokenizePathParameter } from "../../helpers/url"

// -- `routeMatch`: should be the property passed to component by <Route> called `match`.
// -- `routeLocation`: should be the property passed to component by <Route> called `location`.
// -- `windowLocation`: should be a `location` object from global `window` object.
// -- `domDocument`: should be global `document` object.
// -- `dependencies`: optional - in case if some page is switched by side-effect instead of main-router.
export default function usePageViewedTracker({
  routeMatch,
  routeLocation,
  windowLocation,
  domDocument,
  dependencies = [],
}) {
  const { params } = routeMatch
  const { pathname, previous } = routeLocation
  const { hostname, href } = windowLocation
  const { referrer, title } = domDocument

  const {
    PAGE_DOMAIN,
    PAGE_LOCATION,
    PAGE_PATH,
    PAGE_TITLE,
    PAGE_URL,
  } = PAGE_VIEWED_EVENT_PROPERTY

  let referrerHostname = ""
  try {
    referrerHostname = new URL(referrer).hostname
  } catch (_) {
    // - do-nothing
  }

  const eventProperty = {
    [PAGE_DOMAIN]: hostname,
    [PAGE_LOCATION]: tokenizePathParameter(href, params),
    [PAGE_PATH]: pathname,
    [PAGE_TITLE]: title,
    [PAGE_URL]: href,
    previous_page: previous,
    referrer,
    referring_domain: referrerHostname,
  }
  useTracker(PAGE_VIEWED_EVENT_NAME, eventProperty, dependencies)
}
