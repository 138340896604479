import get from "lodash/get"
import moment from "moment"

export const stepApplicantInformation = payload => ({
  introduction: get(payload, "introduction"),
  applicant_type: get(payload, "applicantType"),
  moveDate: get(payload, "moveInDate")
    ? moment(get(payload, "moveInDate")).format("YYYY-MM-DD")
    : "",
  haveRenterAgent: get(payload, "haveRenterAgent", "no"),
  renterAgent: get(payload, "renterAgent", {}),
  monthlyRent: get(payload, "monthlyRent"),
})
