import React from "react"
import styled from "styled-components"

import Panel from "@rentspree/component-v2/dist/panel"

import { TOAST_POSITION } from "../../../containers/toast/constants"

const { BOTTOM_LEFT, BOTTOM_CENTER, BOTTOM_RIGHT } = TOAST_POSITION
const FADING_TIME_IN_MS = 300

export const StyledToast = styled(Panel)`
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  font-family: Source Sans Pro, sans-serif;
  ${({ isPersistent, timeOut, position, fadeInPercent, fadeOutPercent }) =>
    !isPersistent &&
    generateFadeTimeOut(timeOut, position, fadeInPercent, fadeOutPercent)};
`

const generateFadeTimeOut = (
  timeOut,
  position,
  fadeInPercent,
  fadeOutPercent,
) =>
  `
    animation: fade ${timeOut / 1000}s linear forwards;
    transition: all ${timeOut / 1000}s ease-out;

    @keyframes face {
      ${toastItemAnimationFromPosition(position, fadeInPercent)}

      ${fadeOutPercent}% {
        opacity: 1;
        transform: translateY(0%);
      }

      100% {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  `

const toastItemAnimationFromPosition = (position, fadeInPercent) => {
  switch (position) {
    case BOTTOM_LEFT:
    case BOTTOM_CENTER:
    case BOTTOM_RIGHT:
      return `
        0% {
          opacity: 0;
          transform: translateY(+100%);
        }
    
        ${fadeInPercent}% {
          opacity: 1;
          transform: translateY(0%);
        }
      `
    // any top position
    default:
      return `
        0% {
          opacity: 0;
          transform: translateY(-100%);
        }
    
        ${fadeInPercent}% {
          opacity: 1;
          transform: translateY(0%);
        }
      `
  }
}

const Toast = ({
  status = "info",
  titleMessage,
  bodyMessage,
  timeOut = 3000,
  onClose,
  isPersistent = false,
  hideIcon = false,
  children,
}) => {
  React.useEffect(() => {
    if (onClose && timeOut && !isPersistent) {
      const timer = setTimeout(onClose, timeOut)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [])

  const fadeInPercent = (FADING_TIME_IN_MS / timeOut) * 100

  return (
    <StyledToast
      timeOut={timeOut}
      status={status}
      titleMessage={titleMessage}
      bodyMessage={bodyMessage}
      onClose={onClose}
      fadeInPercent={fadeInPercent}
      fadeOutPercent={100 - fadeInPercent}
      isPersistent={isPersistent}
      hideIcon={hideIcon}>
      {children && React.cloneElement(children)}
    </StyledToast>
  )
}

export default Toast
