import { call, put } from "redux-saga/effects"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { setOption } from "@rentspree/cookie"
import * as Sentry from "@sentry/browser"
import { Statsig } from "statsig-react"
import { parseUserProfileToInstanceUser } from "../../utils/feature-flag/helpers"
import { userInstance } from "../../utils/api-interceptor"
import {
  getProfileFailed,
  getProfileRequest,
  getProfileSuccess,
} from "./constants"
import { COOKIE_OPTION } from "../../constants/cookie"
import { tracker } from "../../tracker"

export const getProfileApi = () => userInstance.get("/api/v2/user/me")

export const sendUserToSentry = user => {
  Sentry.configureScope(scope => {
    scope.setUser({
      email: get(user, "email"),
      id: get(user, "_id"),
    })
  })
}

export const setUserToStatSig = user => {
  if (!isEmpty(user)) Statsig.updateUser(parseUserProfileToInstanceUser(user))
}
// payload = {access_token}
export function* getProfile() {
  yield put(getProfileRequest())
  try {
    const response = yield call(getProfileApi)
    yield call(setUserToStatSig, response.result)
    yield put(getProfileSuccess(response.result))
    setOption(COOKIE_OPTION)
    yield call([tracker, "identifyUser"], response.result)
    yield call(sendUserToSentry, response.result)
  } catch (err) {
    yield put(getProfileFailed(err))
  }
}
