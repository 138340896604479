import styled from "styled-components"

import { COLOR, DARK_GRADIENT } from "styles/settings"

export const StyledWrapper = styled.div`
  height: calc(100% - (70px));
  ${props => props.hide && `display: none`};
`

export const StyledWrapperDarkGradient = styled.div`
  background: linear-gradient(
    to bottom,
    ${DARK_GRADIENT.color1},
    ${DARK_GRADIENT.color2},
    ${DARK_GRADIENT.color3},
    ${DARK_GRADIENT.color4},
    ${DARK_GRADIENT.color5},
    ${DARK_GRADIENT.color6},
    ${DARK_GRADIENT.color7},
    ${DARK_GRADIENT.color8},
    ${DARK_GRADIENT.color9},
    ${DARK_GRADIENT.color10},
    ${DARK_GRADIENT.color11},
    ${DARK_GRADIENT.color12},
    ${DARK_GRADIENT.color13},
    ${DARK_GRADIENT.color14},
    ${DARK_GRADIENT.color15},
    ${DARK_GRADIENT.color16},
    ${DARK_GRADIENT.color17},
    ${DARK_GRADIENT.color18},
    ${DARK_GRADIENT.color19},
    ${DARK_GRADIENT.color20}
  );
  height: 100%;
  @media (max-width: 767px) {
    background: white;
  }
`

export const Div = styled.div`
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.display && `display: ${props.display}`};
  ${props => props.relative && "position: relative;"};
  ${props => props.minHeight && `min-height: ${props.minHeight}px`};
`

export const InstructionWrapper = styled.div`
  padding-bottom: 30px;
`

/* eslint-disable prettier/prettier */
export const LoadingWrapper = styled.div`
  position: fixed;
  top: ${props => (props.noneNavbar ? "0" : "70px")};
  left: 0;
  z-index: 99;
  background: white;
  width: 100%;
  height: ${props => (props.noneNavbar ? "100vh" : "calc(100vh - 70px)")};
  .gradient-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
`

/* eslint-enable prettier/prettier */
export const TUTermFormWrapper = styled.form`
  #acceptTuTermCheckBox {
    margin-bottom: 10px;
  }
  #receiveCopyReportCheckBox {
    margin-top: 10px;
  }
`

export const RequestedReportInfo = styled.div`
  border-top: 1px solid ${COLOR.lineGray};
  padding: 20px 0px;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
