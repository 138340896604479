/* eslint-disable no-template-curly-in-string */
export const DOCUMENT_SETS_API = `/document-sets`

export const DRAFT_DOCUMENT_API = `${DOCUMENT_SETS_API}/draft`
export const REPLACE_DOCUMENT_API = `${DOCUMENT_SETS_API}/replace`
export const UPLOAD_DOCUMENT_FILE_API = "/rental-submission/documents"
export const SAVE_UPLOADED_DOCUMENT_FILE_API = `${DOCUMENT_SETS_API}/:documentSetId/documents/:documentId/files`
export const DELETE_DOCUMENT_FILE_API = `${DOCUMENT_SETS_API}/:documentSetId/documents/:documentId/files/:fileId`
export const SUBMIT_RENTAL_SUBMISSION_API_URL =
  "/api/v2/rental-submissions/:rentalId/submit"

export const GET_DRAFT_DOCUMENT_CALL = "GET_DRAFT_DOCUMENT_CALL"
export const GET_DRAFT_DOCUMENT_REQUEST = "GET_DRAFT_DOCUMENT_REQUEST"
export const GET_DRAFT_DOCUMENT_SUCCESS = "GET_DRAFT_DOCUMENT_SUCCESS"
export const GET_DRAFT_DOCUMENT_FAILED = "GET_DRAFT_DOCUMENT_FAILED"

export const DOCUMENT_TYPE_MAP = {
  paystubs: "Proof of Income",
  bank_statement: "Bank Statement",
  passport: "Government-issued ID",
  w2s: "W-2s",
  tax_return: "Tax Return",
  other: "Other",
}

export const FILE_TYPE = {
  PDF: "pdf",
  PNG: "png",
  JPEG: "jpeg",
  JPG: "jpg",
}

export const UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL =
  "UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL"

export const UPLOAD_DOCUMENT_FILE_REQUEST = "UPLOAD_DOCUMENT_FILE_REQUEST"
export const UPLOAD_DOCUMENT_FILE_SUCCESS = "UPLOAD_DOCUMENT_FILE_SUCCESS"
export const UPLOAD_DOCUMENT_FILE_FAILED = "UPLOAD_DOCUMENT_FILE_FAILED"

export const SAVE_UPLOADED_DOCUMENT_FILE_REQUEST =
  "SAVE_UPLOADED_DOCUMENT_FILE_REQUEST"
export const SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS =
  "SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS"
export const SAVE_UPLOADED_DOCUMENT_FILE_FAILED =
  "SAVE_UPLOADED_DOCUMENT_FILE_FAILED"

export const SET_UPLOADING_FILE_PROGRESS = "SET_UPLOADING_FILE_PROGRESS"

export const DELETE_DOCUMENT_FILE_CALL = "DELETE_DOCUMENT_FILE_CALL"
export const DELETE_DOCUMENT_FILE_REQUEST = "DELETE_DOCUMENT_FILE_REQUEST"
export const DELETE_DOCUMENT_FILE_SUCCESS = "DELETE_DOCUMENT_FILE_SUCCESS"
export const DELETE_DOCUMENT_FILE_FAILED = "DELETE_DOCUMENT_FILE_FAILED"

export const REJECT_SELECTED_FILES = "REJECT_SELECTED_FILES"

export const CLEAR_FILE_REJECTED_MESSAGES = "CLEAR_FILE_REJECTED_MESSAGES"

export const CLEAR_IN_PROGRESS_FILES = "CLEAR_IN_PROGRESS_FILES"

export const MAXIMUM_FILES_PER_DOCUMENT = 10

export const REJECT_UPLOADED_FILES_ERROR_CODE = {
  FILE_TOO_LARGE: "file-too-large",
  FILE_INVALID_TYPE: "file-invalid-type",
}

export const REJECT_UPLOADED_FILES_ERROR_MESSAGE = {
  FILE_INVALID_TYPE:
    "The file ${fileName} is in incorrect format. Please  note that the file should be in PDF, PNG or JPG.",
  FILE_TOO_LARGE:
    "The file ${fileName} cannot be added due to it's size. Please  note that the file shouldn't be larger than 10MB.",
  TOTAL_FILES_EXCEED_LIMIT: `You can upload a maximum of ${MAXIMUM_FILES_PER_DOCUMENT} files. Please remove or combine some and try again.`,
}

export const GET_DOCUMENT_SET_CALL = "GET_DOCUMENT_SET_CALL"
export const GET_DOCUMENT_SET_REQUEST = "GET_DOCUMENT_SET_REQUEST"
export const GET_DOCUMENT_SET_SUCCESS = "GET_DOCUMENT_SET_SUCCESS"
export const GET_DOCUMENT_SET_FAILED = "GET_DOCUMENT_SET_FAILED"

export const REPLACE_DOCUMENT_CALL = "REPLACE_DOCUMENT_CALL"
export const REPLACE_DOCUMENT_REQUEST = "REPLACE_DOCUMENT_REQUEST"
export const REPLACE_DOCUMENT_SUCCESS = "REPLACE_DOCUMENT_SUCCESS"
export const REPLACE_DOCUMENT_FAILED = "REPLACE_DOCUMENT_FAILED"

export const DOCUMENT_SET_STATUS = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
}

export const SUBMIT_RENTAL_SUBMISSION_CALL = "SUBMIT_RENTAL_SUBMISSION_CALL"
export const SUBMIT_RENTAL_SUBMISSION_REQUEST =
  "SUBMIT_RENTAL_SUBMISSION_REQUEST"
export const SUBMIT_RENTAL_SUBMISSION_SUCCESS =
  "SUBMIT_RENTAL_SUBMISSION_SUCCESS"
export const SUBMIT_RENTAL_SUBMISSION_FAILED = "SUBMIT_RENTAL_SUBMISSION_FAILED"

export const LANDING_DOCUMENT_UPLOAD_CALL = "LANDING_DOCUMENT_UPLOAD_CALL"
export const LANDING_DOCUMENT_UPLOAD_REQUEST = "LANDING_DOCUMENT_UPLOAD_REQUEST"
export const LANDING_DOCUMENT_UPLOAD_SUCCESS = "LANDING_DOCUMENT_UPLOAD_SUCCESS"
export const LANDING_DOCUMENT_UPLOAD_FAILED = "LANDING_DOCUMENT_UPLOAD_FAILED"

export const CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT = 10000

export const DEFAULT_DOCUMENT_OPTION = [
  {
    type: "paystubs",
    note: "",
  },
  {
    type: "bank_statement",
    note: "",
  },
  {
    type: "w2s",
    note: "",
  },
  {
    type: "passport",
    note: "",
  },
]

export const INCOME_VERIFICATION_DOCUMENT_OPTION = [
  {
    type: "passport",
    note: "",
  },
]
