import qs from "qs"
import isEmpty from "lodash/isEmpty"

export const parse = (str = "") =>
  !isEmpty(str) ? qs.parse(str.replace(/^\?/, "")) : {}

export function urlParser(url) {
  const origin = url.replace(/\b\/.*/, "")
  const host = origin.replace(/http[s]?:\/\//, "")
  const port = host.match(/:\d*/) || [""]
  const hostname = host.replace(/:.*/, "")
  const domain = hostname.match(/([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+$/) || [""]
  const subdomain = hostname.replace(/\..*/, "").replace(hostname, "")
  return {
    origin,
    hostname,
    domain: domain[0].replace(`${subdomain}.`, ""),
    subdomain,
    protocol: origin.replace(hostname, "").replace(port[0], ""),
    port: port[0].substring(1),
    paramQuery: url.replace(origin, ""),
  }
}
