import { fromJS } from "immutable"
import { QW_TS_RESUME_APPLICATION } from "utils/feature-flag/constants"

export const initialState = fromJS({
  isResumeAppEnabled: false,
})

function featureFlagsReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case QW_TS_RESUME_APPLICATION:
      nextState = state.merge({ isResumeAppEnabled: action.payload })
      return nextState
    default:
      return state
  }
}

export default featureFlagsReducer
