import React from "react"
import { compose } from "redux"
import { query } from "@rentspree/path"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { Container } from "components/envelope"
import { FullScreenLoading } from "containers/loading"

import EnvelopeDocumentSign from "./sign-page"
import { withReducer, withSaga } from "./connect"
import { withConnect } from "./route-connect"

export const EnvelopePage = ({
  match,
  actions,
  isLoadingPrepareEnvelope,
  isLoadingGetEnvelope,
  envelopeData,
  location,
  ...props
}) => {
  React.useEffect(() => {
    if (isLoadingPrepareEnvelope === false && isEmpty(envelopeData)) {
      const { token: queryToken } = query.parse(location.search)
      const envelopeId = get(match, "params.envelopeId", "")
      actions.getEnvelopeByIdCall(envelopeId, queryToken)
    }
    return () => {
      actions.resetActive()
    }
  }, [])

  if (isLoadingPrepareEnvelope)
    return (
      <FullScreenLoading
        id="prepareEnvelopeLoading"
        text="Preparing document(s) for you... This may take up to 30 seconds"
      />
    )

  if (isLoadingGetEnvelope) return <FullScreenLoading id="getEnvelopeLoading" />

  return (
    <Container>
      <EnvelopeDocumentSign
        match={match}
        location={location}
        actions={actions}
        {...props}
      />
    </Container>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(EnvelopePage)
