import { buildPath } from "@rentspree/path"
import _ from "lodash"
import { apiInstance } from "utils/api-interceptor"
import { takeLatest, call, put, select } from "redux-saga/effects"

import {
  showErrorAlertCall,
  closeErrorAlertCall,
} from "containers/error/constants"
import { tracker } from "tracker"
import { PAYMENT_EVENT } from "tracker/tracker-const"
import { ERROR_CODE } from "constants/error-code"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import message from "constants/error-messages"
import { callLocationRedirect } from "containers/wrapper/constants"
import * as Const from "./constants"
import {
  selectRentalId,
  makeIsPayAndSubmit,
  makeIsSubmitDisable,
  getRental,
} from "../selectors"
import { postSubmitRental } from "../rental-guide/saga"
import getErrorStripeMessage from "./helper/error-code-mapping"
import { selectProfile } from "./selectors"

export const getPaymentApi = (rentalId, provider, multishare) =>
  apiInstance.get(
    buildPath(Const.PAYMENT_API_URL, { rentalId }, { provider, multishare }),
  )

export const submitPaymentApi = (payload, rentalId) =>
  apiInstance.post(buildPath(Const.PAYMENT_API_URL, { rentalId }), payload)

export function* getPaymentSaga({ payload }) {
  const { provider, multishare } = payload
  yield put(closeErrorAlertCall())
  const rentalId = yield select(selectRentalId)
  const shouldShowPayment = yield select(makeIsPayAndSubmit())
  const isAllReportNotReady = yield select(makeIsSubmitDisable())
  if (!shouldShowPayment || isAllReportNotReady) {
    yield put(callLocationRedirect())
  } else {
    yield put(Const.getPaymentRequest())
    try {
      const payment = yield call(getPaymentApi, rentalId, provider, multishare)
      if (payment?.paymentObject?.url) {
        yield call(
          (...args) => window.location.assign(...args),
          payment.paymentObject.url,
        )
      }
      yield put(Const.getPaymentSuccess(payment))
    } catch (err) {
      if (_.get(err, "status") === 409) {
        const errorCode = _.get(err, "data.code", "")
        switch (errorCode) {
          case ERROR_CODE.STATUS_CONFLICT:
            yield put(callLocationRedirect())
            return
          case ERROR_CODE.PAYMENT_GATEWAY_ERROR:
          case ERROR_CODE.PAYMENT_SERVICE_ERROR:
          default:
            yield call(
              SweetAlert,
              ALERT_PRESET.ERROR,
              API_ERRORS[500].option,
              API_ERRORS[500].callback,
            )
            break
        }
      }
      yield put(Const.getPaymentFailed())
    }
  }
}

export function* submitPaymentSaga({ payload }) {
  yield put(closeErrorAlertCall())
  const rentalSubmission = yield select(getRental)
  const rentalId = rentalSubmission?._id
  // eslint-disable-next-line camelcase
  const creditReportId = rentalSubmission?.credit_report?._id
  const userProfile = yield select(selectProfile)
  yield put(Const.submitPaymentRequest())
  try {
    const { stripe, cardElement, payment } = payload
    const { error, paymentMethod } = yield call(stripe.createPaymentMethod, {
      type: "card",
      card: cardElement,
      metadata: {
        rentalId,
        email: userProfile?.email,
        paymentId: payment.paymentId,
        orderId: creditReportId,
      },
    })
    if (error) {
      const errorInDataFormat = { data: error }
      throw errorInDataFormat
    }
    const response = yield call(
      submitPaymentApi,
      {
        ...payment,
        paymentObject: {
          ...payment.paymentObject,
          nonce: paymentMethod.id,
        },
      },
      rentalId,
    )
    yield put(Const.submitPaymentSuccess(response))
    yield call([tracker, "trackEvent"], PAYMENT_EVENT.payAndSubmit)
    // call submit rental api
    yield call(postSubmitRental)
  } catch (err) {
    yield put(Const.submitPaymentFailed())

    let errorMessage = message.paymentFailed
    if (_.get(err, "status") === 409) {
      const errorCode = _.get(err, "data.code", "")
      switch (errorCode) {
        case ERROR_CODE.STATUS_CONFLICT:
          yield put(callLocationRedirect())
          return
        case ERROR_CODE.PAYMENT_GATEWAY_ERROR:
        case ERROR_CODE.PAYMENT_SERVICE_ERROR:
          errorMessage = message.paymentFailed
          break
        default:
          errorMessage = errorCode
            ? getErrorStripeMessage(err?.data) || message.paymentFailed
            : {
                id: "errorFromResponse",
                defaultMessage: _.get(err, "data.message"),
              }
          break
      }
    }
    yield put(
      Const.getPaymentCall({
        provider: "stripe",
      }),
    )
    yield put(
      showErrorAlertCall({
        message: errorMessage,
      }),
    )
  }
}

export default function* rootSaga() {
  yield takeLatest(Const.SUBMIT_PAYMENT_CALL, submitPaymentSaga)
}
