import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export const StyledRow = styled.div`
  display: flex;
  ${props => props.fullWidth && "width:100%"};
  ${props => props.spaceBetween && "justify-content: space-between"};
  ${props => props.flexStart && "justify-content: flex-start"};
  ${props => props.directionRow && "flex-direction: row"};
  ${props => props.spaceAround && "justify-content: space-around"};
  ${props => props.flexEnd && "justify-content: flex-end"};
  ${props => props.alignItems && `align-items:${props.alignItems}`};
  ${props => props.alignContent && `align-content:${props.alignContent}`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    ${props => props.mPadding && `padding: ${props.mPadding}`};
    ${props => props.mMargin && `margin: ${props.mMargin}`};
    ${props => props.mDirectionCol && "flex-direction: column"};
    ${props => props.mWrap && "flex-wrap: wrap"};
  }
`

/** Flex Row component for grid layout */
const Row = ({
  children,
  spaceBetween,
  spaceAround,
  directionRow,
  padding,
  margin,
  mPadding,
  mMargin,
  mDirectionCol,
  fullWidth,
  alignItems,
  mWrap,
  flexEnd,
  className,
  ...rest
}) => (
  <StyledRow
    className={className}
    fullWidth={fullWidth}
    spaceAround={spaceAround}
    spaceBetween={spaceBetween}
    flexEnd={flexEnd}
    directionRow={directionRow}
    padding={padding}
    margin={margin}
    mPadding={mPadding}
    mMargin={mMargin}
    alignItems={alignItems}
    mDirectionCol={mDirectionCol}
    mWrap={mWrap}
    {...rest}>
    {children}
  </StyledRow>
)

Row.propTypes = {
  /** The children node of Row */
  children: PropTypes.node,
  /** Set justify-content to space-between */
  spaceBetween: PropTypes.bool,
  /** Set padding */
  padding: PropTypes.string,
  /** Set margin */
  margin: PropTypes.string,
  /** Set padding for mobile */
  mPadding: PropTypes.string,
  /** Set margin for mobile */
  mMargin: PropTypes.string,
}

export default Row
