import { useState, useCallback } from "react"
import axios from "axios"
import get from "lodash/get"
import * as Sentry from "@sentry/browser"
import { v4 as uuidv4 } from "uuid"
import { GOOGLE_PLACE_API_KEY } from "env"

const AUTOCOMPLETE_API_BASE_URL =
  "https://places.googleapis.com/v1/places:autocomplete"
const PLACE_DETAILS_API_BASE_URL = "https://places.googleapis.com/v1/places"

export const includedPrimaryTypes = [
  "premise",
  "subpremise",
  "street_number",
  "street_address",
]

export const includedRegionCodes = ["us", "pr"]

export const buildAddressDetails = addressComponents => {
  const addressDetails = {
    streetLine: "",
    secondary: "",
    city: "",
    state: "",
    zipcode: "",
  }

  const typeHandlers = {
    street_number: ({ longText }) => {
      addressDetails.streetLine = longText
    },
    route: ({ longText }) => {
      addressDetails.streetLine += ` ${longText}`
    },
    subpremise: ({ longText }) => {
      addressDetails.secondary = longText
    },
    locality: ({ longText }) => {
      addressDetails.city = longText
    },
    sublocality: ({ longText }) => {
      if (!addressDetails.city) addressDetails.city = longText
    },
    administrative_area_level_1: ({ shortText }) => {
      addressDetails.state = shortText
    },
    postal_code: ({ longText }) => {
      addressDetails.zipcode = longText
    },
  }

  addressComponents.forEach(addressComponent => {
    const { types } = addressComponent

    types.forEach(type => {
      if (typeHandlers[type]) {
        typeHandlers[type](addressComponent)
      }
    })
  })

  const { streetLine, secondary, city, state, zipcode } = addressDetails
  const formattedSecondary = secondary && ` ${secondary},`
  addressDetails.displayedAddress = `${streetLine},${formattedSecondary} ${city}, ${state}, ${zipcode}`

  return addressDetails
}

export const getAddressDetails = async (
  placeId,
  sessionToken,
  setSessionToken,
) => {
  try {
    if (!placeId) return {}

    const { addressComponents } = await axios.get(
      `${PLACE_DETAILS_API_BASE_URL}/${placeId}`,
      {
        headers: {
          "X-Goog-FieldMask": "addressComponents",
          "X-Goog-Api-Key": GOOGLE_PLACE_API_KEY,
        },
        params: { sessionToken },
      },
    )
    setSessionToken(uuidv4())

    const addressDetails = buildAddressDetails(addressComponents)

    if (!addressDetails.city) {
      Sentry.captureMessage(
        `[Google Autocomplete] locality and sublocality not exists for placeId: ${placeId}`,
      )
    }

    return addressDetails
  } catch (error) {
    console.error("Call to Google API Place Details failed: ", error)
    return {}
  }
}

export const useGoogleAutoComplete = handleError => {
  const [sessionToken, setSessionToken] = useState(uuidv4())
  const [addresses, setAddresses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchAddresses = useCallback(async inputAddress => {
    setError(null)
    setIsLoading(true)
    try {
      const { suggestions } = await axios.post(
        AUTOCOMPLETE_API_BASE_URL,
        {
          input: inputAddress,
          includedPrimaryTypes,
          includedRegionCodes,
          sessionToken,
        },
        {
          headers: {
            "X-Goog-Api-Key": GOOGLE_PLACE_API_KEY,
            "X-Goog-FieldMask":
              "suggestions.placePrediction.placeId,suggestions.placePrediction.text.text",
          },
        },
      )

      const suggestedAddresses = suggestions || []
      const formattedAddresses = suggestedAddresses.map((address, index) => {
        const formattedAddress = {
          key: index,
          displayedAddress: address.placePrediction.text.text,
          placeId: address.placePrediction.placeId,
        }
        return formattedAddress
      })

      setAddresses(formattedAddresses)
    } catch (e) {
      setAddresses([])
      setError(e)
      if (handleError) handleError(get(e, "data.errors") || e)
    } finally {
      setIsLoading(false)
    }
  })

  return {
    addresses,
    setAddresses: fetchAddresses,
    getAddressDetails: async placeId => {
      const addressDetails = await getAddressDetails(
        placeId,
        sessionToken,
        setSessionToken,
      )
      return addressDetails
    },
    isLoading,
    error,
  }
}
