import isEmpty from "lodash/isEmpty"
import pickBy from "lodash/pickBy"
import isUndefined from "lodash/isUndefined"
import { STATUS } from "components/guide/constant"
import { DOCUMENT_UPLOAD_PROPERTY } from "tracker/tracker-const"
import isArray from "lodash/isArray"
import { APPLICATION_TYPE } from "../constants"
import { SCREENING_FEE_TITLE_WITH_TOOLTIP } from "./constants"

const shouldSignLra = (applicationType, applicationStatus) =>
  applicationType === APPLICATION_TYPE.CAR_LRA &&
  applicationStatus !== STATUS.RENTER_SIGNED

/**
 * Return a string based on applicationType and isPayAndSubmit
 *  @param {isPayAndSubmit} boolean whether or not this should go to the payment page
 *  @param {applicationType} string the application type
 *  @returns {string} a correct text to render on to the button
 */
const createButtonText = (
  isPayAndSubmit,
  applicationType,
  applicationStatus,
  isNextToDocumentStep,
  isProUserSelectOnlyIncome,
  isRenterPay,
) => {
  if (isPayAndSubmit) {
    return "Continue to payment"
  }

  const shouldSignLraApp = shouldSignLra(applicationType, applicationStatus)
  // Have to refactor
  if (shouldSignLraApp) {
    return "Sign & submit application"
  }
  if (isNextToDocumentStep && !isRenterPay) {
    return "Next"
  }
  if (isProUserSelectOnlyIncome) {
    return "Next"
  }
  return "Submit application"
}

/**
 * Return an id based on applicationType and isPayAndSubmit
 *  @param {isPayAndSubmit} boolean whether or not this should go to the payment page
 *  @param {applicationType} string the application type
 *  @returns {string} a correct id of the button prop
 */
const createButtonId = (isPayAndSubmit, applicationType) => {
  if (applicationType === APPLICATION_TYPE.CAR_LRA) {
    return "lraSubmitBtn"
  }
  return isPayAndSubmit ? "rentalPayAndSubmitBtn" : "rentalSubmitBtn"
}

/**
 * Return an onClick function based on applicationType, isPayAndSubmit, and applicationStatus
 *  @param {isPayAndSubmit} boolean whether or not this should go to the payment page
 *  @param {applicationType} string the application type
 *  @param {applicationStatus} string the status of the application
 *  @param {Object} actions Actions that contains a function which will be used, when determining onClick
 *  @returns {Function} a function which called the correct function from actions object
 */
const createButtonClick = ({
  isPayAndSubmit,
  applicationType,
  applicationStatus,
  useFasterProcessing,
  isNextToDocumentStep,
  isProUserSelectOnlyIncome,
  actions: {
    prepareEnvelopeAction = () => {},
    paymentAction = () => {},
    submitAction = () => {},
    nextAction = () => {},
  },
  isRenterPay,
}) => {
  // Have to refactor to be more understandable of what each if stand for.
  // eslint-disable-next-line
  if (shouldSignLra(applicationType, applicationStatus)) {
    return () => prepareEnvelopeAction({ useFasterProcessing })
  }
  if (isPayAndSubmit) {
    return () => paymentAction()
  }
  if (isNextToDocumentStep && !isRenterPay) {
    return () => nextAction()
  }
  if (isProUserSelectOnlyIncome) {
    return () => nextAction()
  }
  return () => submitAction()
}

/**
 * Return id,text, and onClick props based on applicationType, isPayAndSubmit, and applicationStatus
 * @param {Object} actions Actions that contains a function which will be used, when determining onClick
 * @param {applicationType} string the application type
 * @param {isPayAndSubmit} boolean whether or not this should go to the payment page
 * @param {applicationStatus} string the status of the application
 * @returns {Object} that contains the correct id, text, and onClick
 */
export const makeSubmitButtonProps = ({
  actions,
  applicationType,
  isPayAndSubmit,
  applicationStatus,
  useFasterProcessing,
  isNextToDocumentStep,
  isProUserSelectOnlyIncome,
  isRenterPay,
}) => {
  const text = createButtonText(
    isPayAndSubmit,
    applicationType,
    applicationStatus,
    isNextToDocumentStep,
    isProUserSelectOnlyIncome,
    isRenterPay,
  )
  const id = createButtonId(isPayAndSubmit, applicationType)
  const onClick = createButtonClick({
    isPayAndSubmit,
    applicationType,
    applicationStatus,
    actions,
    useFasterProcessing,
    isNextToDocumentStep,
    isProUserSelectOnlyIncome,
    isRenterPay,
  })
  return { text, id, onClick }
}

export const parseRentalSubmissionToContact = rentalSubmission => {
  const { application, email, landlord_id: landlordId } = rentalSubmission

  let applicationParsedValue
  if (application) {
    const {
      dateOfBirth,
      monthlyIncome,
      residence,
      occupation,
      phoneNumber,
      workPhoneNumber,
      homePhoneNumber,
      firstName,
      middleName,
      lastName,
    } = application

    applicationParsedValue = {
      birthDate: dateOfBirth,
      monthlySalary: monthlyIncome,
      workStatus: occupation?.current?.workStatus,
      mobileNumber: phoneNumber,
      officeNumber: workPhoneNumber,
      phoneNumber: homePhoneNumber,
      firstName,
      middleName,
      lastName,
    }

    if (residence?.current) {
      applicationParsedValue.livingAddress = {
        street: residence?.current?.street,
        city: residence?.current?.city,
        state: residence?.current?.state,
        zipcode: residence?.current?.zipCode,
      }
    }

    if (occupation?.current?.workStatus === "employed") {
      applicationParsedValue.companyAddress = {
        name: occupation?.current?.employed?.employer,
        street: occupation?.current?.employed?.street,
        city: occupation?.current?.employed?.city,
        state: occupation?.current?.employed?.state,
        zipcode: occupation?.current?.employed?.zipCode,
      }
    }
  }

  return pickBy(
    {
      owner_id: landlordId,
      automated: true,
      source: "application",
      ...applicationParsedValue,
      email,
    },
    v => !isUndefined(v),
  )
}

export const getApplicationFeeContent = (screeningOption, proOption) => {
  const {
    credit_report: isSelectedCreditReport,
    premium: isSelectedPremium,
  } = screeningOption

  if (isSelectedPremium || !isEmpty(proOption)) {
    return SCREENING_FEE_TITLE_WITH_TOOLTIP.PREMIUM_BUNDLE
  } else if (isSelectedCreditReport) {
    return SCREENING_FEE_TITLE_WITH_TOOLTIP.STANDARD_BUNDLE
  }

  return SCREENING_FEE_TITLE_WITH_TOOLTIP.APPLICATION_ONLY
}

export const getDocumentUploadPurpose = documents => {
  const totalUploadedDocs = documents.filter(doc => !isEmpty(doc.files)).length
  if (totalUploadedDocs === 0) return DOCUMENT_UPLOAD_PROPERTY.PURPOSE.START
  if (totalUploadedDocs < documents.length)
    return DOCUMENT_UPLOAD_PROPERTY.PURPOSE.CONTINUE
  return DOCUMENT_UPLOAD_PROPERTY.PURPOSE.EDIT
}

export const getMultiShareSelectedOptionKey = ({ renterId }) =>
  `${renterId}:isSelectMultiShare`

export const getRedirectConditionParams = (
  screeningOption,
  documents,
  isIncomeVerificationGateEnable,
) => {
  const isSelectedDocumentOptions = isArray(documents) && documents.length > 0
  const isNextToDocumentStep = isIncomeVerificationGateEnable
    ? screeningOption.income
    : isSelectedDocumentOptions
  const isShowDocumentStep = !isNextToDocumentStep && isSelectedDocumentOptions
  const isProUserSelectOnlyIncome =
    screeningOption.income &&
    !screeningOption.premium &&
    !screeningOption.credit_report

  return { isNextToDocumentStep, isShowDocumentStep, isProUserSelectOnlyIncome }
}
