import { css } from "styled-components"

export const SWEET_ALERT_STYLE = css`
  .rentspree-sweetalert {
    .swal-footer {
      text-align: center;
      button.swal-button.swal-button--confirm {
        background-color: rgb(61, 138, 247);
        transition: all ease-in-out 0.3s;
        &:hover {
          background-color: rgb(29, 102, 206);
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .swal-content {
      > div {
        word-wrap: break-word;
      }
    }
    .swal-text {
      padding: 0 20px;
      text-align: center;
    }
  }
`
