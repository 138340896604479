import React from "react"
import { withFormik } from "formik"
import { Clearfix } from "react-bootstrap"

import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import ScrollToError from "components/scroll/scroll-to-error"
import { ApplicationFormFooter } from "components/molecules/application-form-footer"
import Beforeunload from "components/alerts/beforeunload"
import { APPLICANT_PATH_FLOW } from "../constants"
import { stepAdditionalApplicantValidation } from "../validation-schema"
import OtherTenantsForm from "./other-tenants-form"
import GuarantorsForm from "./guarantors-form"

export const Form = props => {
  const { actions, handleSubmit, values, dirty, loading, isGuarantor } = props
  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError {...props} />
      <Beforeunload isDirty={dirty} />
      <OtherTenantsForm {...props} />
      {!isGuarantor && <GuarantorsForm {...props} />}
      <Clearfix>
        <ApplicationFormFooter
          cancelBtn={{
            id: "applicantTenantsCancelBtn",
            onClick: () => actions.redirect(),
            disabled: loading.components,
          }}
          backBtn={{
            id: "applicantTenantsBackBtn",
            onClick: () =>
              actions.redirect(
                APPLICANT_PATH_FLOW.ADDITIONAL_APPLICANT.previous,
              ),
          }}
          continueBtn={{
            id: "applicantTenantsContinueBtn",
            disabled:
              isEmpty(values.haveTenants) ||
              (!isGuarantor && isEmpty(values.haveGuarantors)) ||
              loading.components,
          }}
        />
      </Clearfix>
    </form>
  )
}

const AdditionalApplicantForm = withFormik({
  mapPropsToValues: props => stepAdditionalApplicantValidation(props).mapper,
  validationSchema: props => stepAdditionalApplicantValidation(props).schema,
  handleSubmit: (values, { props }) => {
    const isGuarantor = get(props, "isGuarantor")
    const sentData = isGuarantor
      ? {
          ...values,
          haveGuarantors: "no",
          guarantors: [],
          haveTenants: "yes",
        }
      : { ...values }

    props.actions.saveApplicantInfo({
      payload: sentData,
      route: APPLICANT_PATH_FLOW.ADDITIONAL_APPLICANT,
      parser: "stepAdditionalApplicant",
      draft: props.draft,
      pathname: props.location.pathname,
      isResumeAppEnabled: props.rental.isResumeAppEnabled,
      savedContinuePath: props.rental.continuePath,
      isLastStep: true,
    })
  },
  enableReinitialize: true,
  displayName: "AdditionalApplicantForm",
})(Form)

export default AdditionalApplicantForm
