import { object } from "yup"
import {
  getSSNMismatchErrorMessage,
  validateSSN,
  validateMismatch,
  TU_REGEX,
  getInvalidStartingSSN,
  validateIncome,
  validateWorkStatus,
} from "constants/validations"
import { get } from "lodash"
import {
  INCOME_MAX,
  INCOME_MIN,
  getApplicantIncome,
} from "../info/validation-schema"

export const ssnSchema = props => ({
  mapper: {
    ssn: "",
    ssnConfirm: "",
    income: getApplicantIncome(props?.tuRenter, props?.application),
    workStatus:
      get(props, "tuRenter.workStatus") ||
      get(props, "application.occupation.current.workStatus") ||
      "",
  },
  schema: object({
    ssn: validateSSN(true)
      .matches(TU_REGEX.ssn, getInvalidStartingSSN)
      .test(
        "SSNMismatch",
        getSSNMismatchErrorMessage,
        validateMismatch("ssnConfirm"),
      ),
    ssnConfirm: validateSSN(true)
      .matches(TU_REGEX.ssn, getInvalidStartingSSN)
      .test("SSNMismatch", getSSNMismatchErrorMessage, validateMismatch("ssn")),
    income: validateIncome(true, INCOME_MIN, INCOME_MAX),
    workStatus: validateWorkStatus(),
  }),
})
