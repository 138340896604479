import React, { PureComponent } from "react"
import { compose } from "redux"
import { tracker, withTracker, PAGE_NAME } from "tracker"
import { T36, B16 } from "@rentspree/component-v2"
import track from "react-tracking"
import styled from "styled-components"
import { FormFooter } from "components/buttons/form-footer"
import { LOG_EVENT } from "tracker/tracker-const"
import { withConnect, withSaga } from "./connect"

const Wraper = styled.div`
  .detail {
    margin: 0 0 7px;
  }
`

export class AttachReport extends PureComponent {
  render() {
    const { actions, loading, tracking } = this.props
    return (
      <Wraper>
        <T36 lineHeight="normal" margin="50px 0 35px 0">
          TransUnion Screening
        </T36>
        <B16 weight={600} lineHeight="24px">
          Please click the &quot;Attach Report&quot; button below to continue.
        </B16>
        <p className="detail">
          - RentSpree uses a soft-inquiry that does not damage your credit
          score.
        </p>
        <p className="detail">- We do not store your SSN.</p>
        <p className="detail">
          - If you last applied more than 30 days ago, you will have to enter
          your SSN and reconfirm your identity.
        </p>
        <FormFooter
          cancelBtn={{
            id: "attachReporCancelBtn",
            onClick: () => actions.redirect(),
            disabled: loading.components,
          }}
          continueBtn={{
            id: "attachReportContinueBtn",
            text: "Attach Report",
            loading: loading?.components,
            onClick: () => {
              actions.sendAttachReport()
              tracking.trackEvent(LOG_EVENT.rental_v3.attach_report)
            },
          }}
        />
      </Wraper>
    )
  }
}

export default compose(
  track(),
  withSaga,
  withConnect,
)(withTracker(AttachReport, tracker, PAGE_NAME.TU.ATTACH))
