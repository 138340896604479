import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export const StyledCol = styled.div`
  ${props => props.full && "width: 100%;"}
  ${props => props.half && "width: 50%;"}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.margin && `margin: ${props.margin};`}
  @media (max-width: 991px) {
    ${props => props.hideMobile && "display:none"};
    ${props => props.mFull && "width: 100%;"}
    ${props => props.mHalf && "width: 50%;"}
    ${props => props.mWidth && `width: ${props.mWidth};`}
    ${props => props.mPadding && `padding: ${props.mPadding};`}
    ${props => props.mMargin && `margin: ${props.mMargin};`}
  }
`

/** Column component for grid layout */
const Col = ({
  children,
  full,
  half,
  width,
  padding,
  margin,
  mFull,
  mHalf,
  mPadding,
  mMargin,
  hideMobile,
  mWidth,
  ...rest
}) => (
  <StyledCol
    full={full}
    half={half}
    width={width}
    mWidth={mWidth}
    padding={padding}
    mFull={mFull}
    mHalf={mHalf}
    mPadding={mPadding}
    margin={margin}
    mMargin={mMargin}
    hideMobile={hideMobile}
    {...rest}>
    {children}
  </StyledCol>
)

Col.propTypes = {
  /** The children node of Col */
  children: PropTypes.node,
  /** Set column width to 100% */
  full: PropTypes.bool,
  /** Set column width to 50% */
  half: PropTypes.bool,
  /** Set padding */
  padding: PropTypes.string,
  /** Set column width to 100% on mobile */
  mFull: PropTypes.bool,
  /** Set column width to 50% on mobile */
  mHalf: PropTypes.bool,
  /** Set padding on mobile */
  mPadding: PropTypes.string,
}

export default Col
