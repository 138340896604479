import _ from "lodash"

export const ALERT_PRESET = {
  SUCCESS: {
    icon: "success",
    button: "OK",
    className: "rentspree-sweetalert",
  },
  ERROR: {
    icon: "error",
    button: "Close",
    className: "rentspree-sweetalert",
  },
  TRY_AGAIN: {
    icon: "error",
    button: {
      text: "Try Again",
      className: "error-btn",
    },
    className: "rentspree-sweetalert-error",
  },
}

const GENERAL_ERROR_MESSAGE =
  "We're experiencing some technical issues. Please click the button below to refresh the screen. If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com."
const TU_GENERAL_ERROR_MESSAGE =
  " If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com."

export const API_ERRORS = {
  500: {
    option: {
      title: "We're sorry.",
      text: GENERAL_ERROR_MESSAGE,
      button: "Refresh",
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
    callback: val => {
      if (val) window.location.reload()
    },
  },
  422: {
    option: {
      title: "We're sorry.",
      text: GENERAL_ERROR_MESSAGE,
      button: "Refresh",
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
    callback: val => {
      if (val) window.location.reload()
    },
  },
  TU: error => ({
    option: {
      title: "We're sorry.",
      text: _.get(error, "data.message")
        ? _.get(error, "data.message").concat(TU_GENERAL_ERROR_MESSAGE)
        : GENERAL_ERROR_MESSAGE,
      button: "Refresh",
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
  }),
}
