import React from "react"
import styled from "styled-components"

import { TextWithIcon } from "../molecules/text-with-icon"

export const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileWrapper = styled(TextWithIcon)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`

export const StatusButton = ({
  text,
  icon = "chevron-right",
  onClick,
  renderDesktopButton,
  ...props
}) => (
  <React.Fragment>
    <DesktopWrapper>
      {typeof renderDesktopButton === "function" &&
        renderDesktopButton({
          ...props,
          onClick,
          text,
        })}
    </DesktopWrapper>
    <MobileWrapper text={text} icon={icon} onClick={onClick} />
  </React.Fragment>
)
