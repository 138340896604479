import { ACCEPTED_TYPES, RECIPIENT_STATUS } from "containers/envelope/constants"

export const findSignerByRecipient = (recipients = [], defaultSigner = "") =>
  recipients.find(({ token }) => !!token) || defaultSigner

export const findSignerByOwner = (recipients = [], owner) =>
  recipients.find(({ userId }) => userId === owner)

export const filterFieldsBySigner = (files, cb) =>
  files.map(({ pages, ...restOfFile }) => ({
    ...restOfFile,
    pages: pages.map(({ fields, ...resOfPage }) => ({
      ...resOfPage,
      fields: cb(fields),
    })),
  }))

export const filterPages = (files, cb) =>
  files.map(({ pages, ...restOfFile }) => ({
    ...restOfFile,
    pages: cb(pages),
  }))

export const mapFilesForSign = (files, signer) => {
  const { email, _id, roleId, status } = signer || {}
  const validationList = [email, _id, roleId]

  return filterFieldsBySigner(files, fields =>
    fields
      .map(field => {
        const isAcceptedType = ACCEPTED_TYPES.includes(field.type)
        const isSigner = validationList.includes(field.assignee)

        if (isAcceptedType) {
          if (isSigner) {
            return {
              ...field,
              canEdit: status === RECIPIENT_STATUS.PENDING,
              visible: true,
            }
          }

          if (field.value) {
            return { ...field, canEdit: false, visible: true }
          }

          return { ...field, canEdit: false, visible: false }
        }

        return field
      })
      .filter(field => !!field),
  )
}
