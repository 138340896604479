import _ from "lodash"
import { createSelector } from "reselect"
import { STATUS, RENTAL_STEP } from "components/guide/constant"
import { USER_TYPE } from "constants/user"
import { initialState } from "./reducer"
import {
  CREDIT_REPORT_STATUS,
  APPLICATION_STATUS,
  RENTAL_STATUS,
} from "./constants"
import { makeSelectMultiShare } from "../wrapper/selectors"

export const selectRental = state => state.get("rentalSubmission", initialState)
export const selectRentalId = state => {
  const rental = state.get("rentalSubmission", initialState)
  const rentalJs = rental.toJS()
  return _.get(rentalJs, "_id")
}

export const getRental = state => selectRental(state).toJS()
export const makeSelectCreditReport = () =>
  createSelector(getRental, state => _.get(state, "credit_report"))

export const isActiveStatus = status =>
  status === STATUS.CREATED || status === STATUS.EDIT

// return
// {
// application: "status",
// applicant: "status",
// creditReport: "status"
// }
export const stepStatusMapper = (rentalSubmission = {}) => {
  let hasActiveStep = false
  const status = {}
  // add step applicant info and application when request application
  if (_.get(rentalSubmission, "screeningOption.application")) {
    status.applicant = statusMapper(
      RENTAL_STEP.APPLICANT,
      _.get(rentalSubmission, "status"),
      hasActiveStep,
    )

    if (isActiveStatus(status.applicant)) hasActiveStep = true
    status.application = statusMapper(
      RENTAL_STEP.APPLICATION,
      _.get(rentalSubmission, "application.status", "noApp"),
      hasActiveStep,
    )

    if (
      isActiveStatus(status.applicant) ||
      isActiveStatus(status.application)
    ) {
      hasActiveStep = true
    }
  }
  // add step credit report when request credit report
  if (_.get(rentalSubmission, "screeningOption.credit_report")) {
    status.creditReport = statusMapper(
      RENTAL_STEP.CREDIT_REPORT,
      _.get(rentalSubmission, "credit_report.status"),
      hasActiveStep,
    )
  }
  return status
}

export const statusMapper = (step, status, hasActiveStep) => {
  switch (step) {
    case RENTAL_STEP.APPLICANT:
      switch (status) {
        case RENTAL_STATUS.STARTED:
          return STATUS.CREATED
        case RENTAL_STATUS.EDIT:
        case RENTAL_STATUS.READY:
        case RENTAL_STATUS.REVIEW:
          return status
        default:
          return STATUS.DISABLE
      }
    case RENTAL_STEP.APPLICATION:
      switch (status) {
        case APPLICATION_STATUS.SUBMITTED:
        case APPLICATION_STATUS.READY:
          return STATUS.READY
        case APPLICATION_STATUS.RENTER_SIGNED:
          return STATUS.RENTER_SIGNED
        case APPLICATION_STATUS.CREATED:
        case APPLICATION_STATUS.EDIT:
          return hasActiveStep ? STATUS.DISABLE : status
        default:
          return STATUS.DISABLE
      }
    case RENTAL_STEP.CREDIT_REPORT:
      switch (status) {
        case CREDIT_REPORT_STATUS.CREATED:
          return hasActiveStep ? STATUS.DISABLE : STATUS.CREATED
        case CREDIT_REPORT_STATUS.EXAM:
        case CREDIT_REPORT_STATUS.ERROR:
        case CREDIT_REPORT_STATUS.STARTED:
          return hasActiveStep ? STATUS.DISABLE : STATUS.EDIT
        case CREDIT_REPORT_STATUS.READY:
        case CREDIT_REPORT_STATUS.PAYMENT:
        case CREDIT_REPORT_STATUS.PROCESSING_PAYMENT:
        case CREDIT_REPORT_STATUS.COMPLETED:
        case CREDIT_REPORT_STATUS.SUBMITTED:
        case CREDIT_REPORT_STATUS.REQUESTED:
        case CREDIT_REPORT_STATUS.OPENED:
          return STATUS.READY
        default:
          return STATUS.DISABLE
      }
    default:
      return STATUS.DISABLE
  }
}

export const isPayAndSubmit = rentalSubmission => {
  if (
    _.get(rentalSubmission, "screeningOption.credit_report") &&
    ![CREDIT_REPORT_STATUS.COMPLETED, CREDIT_REPORT_STATUS.OPENED].includes(
      _.get(rentalSubmission, "credit_report.status"),
    ) &&
    _.get(rentalSubmission, "screeningOption.payerType") === USER_TYPE.RENTER
  ) {
    return true
  }
  return false
}

export const isSubmitDisable = status => {
  let isDisable = false
  if (status.application) {
    isDisable = !(
      (status.applicant === STATUS.READY ||
        status.applicant === STATUS.REVIEW ||
        status.applicant === STATUS.RENTER_SIGNED) &&
      (status.application === STATUS.READY ||
        status.application === STATUS.RENTER_SIGNED)
    )
  }
  if (status.creditReport) {
    isDisable = isDisable || !(status.creditReport === STATUS.READY)
  }
  return isDisable
}

export const makeSelectRental = () =>
  createSelector(selectRental, substate => substate.toJS())

export const selectDraft = () =>
  createSelector(getRental, substate => _.get(substate, "draft", {}))

export const makeStatusMapper = () =>
  createSelector(getRental, substate => stepStatusMapper(substate))

const isPayAndSubmitSelector = createSelector(
  [getRental, makeSelectMultiShare()],
  (rentalState, multiShare) => {
    const { isMultiShareActive } = multiShare
    return isPayAndSubmit(rentalState) && !isMultiShareActive
  },
)

export const makeIsPayAndSubmit = () => isPayAndSubmitSelector

const isSubmitDisableSelector = createSelector(getRental, substate =>
  isSubmitDisable(stepStatusMapper(substate)),
)

export const makeIsSubmitDisable = () => isSubmitDisableSelector

export const selectApplication = () =>
  createSelector(getRental, substate => _.get(substate, "application", {}))

export const selectLraEnvelopeId = createSelector(
  selectApplication(),
  substate => _.get(substate, "lraEnvelopeId"),
)
export const selectScreeningOption = state => {
  const rental = state.get("rentalSubmission", initialState)
  const rentalJs = rental.toJS()
  return _.get(rentalJs, "screeningOption", {})
}

export const selectProfile = state => state.get("profile").toJS()
