import { DateInput } from "@rentspree/component-v2"
import React from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import parser from "./ua-parser"
import { GlobalStyle, OverwrittenStyle } from "./style"

const DatePickerInput = ({ onValueChange, ...props }) => {
  const { value, minDate, formatType, handleChange, id } = props
  const handleOnChange = val => {
    if (moment(val).isValid()) {
      onValueChange(moment(val).format("YYYY-MM-DD"))
    } else {
      onValueChange(undefined)
    }
  }
  const selectedDate = value ? moment(value, "YYYY-MM-DD").toDate() : undefined
  const { type } = parser.getDevice()
  const enableNative =
    (type === "mobile" || type === "tablet") && formatType === "date"

  return (
    <>
      <GlobalStyle />
      <OverwrittenStyle />
      {enableNative ? (
        <DateInput {...props} onChange={handleChange} />
      ) : (
        <DatePicker
          id={id}
          forceShowMonthNavigation
          wrapperClassName="rentspree-date-picker"
          calendarClassName="rentspree-date-picker-calendar"
          customInput={<DateInput {...props} onChange={onValueChange} />}
          selected={selectedDate}
          onChange={handleOnChange}
          minDate={minDate}
          peekNextMonth
          dropdownMode="select"
        />
      )}
    </>
  )
}
export default DatePickerInput
