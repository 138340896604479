export function callScroll(x, y) {
  window.scrollTo(x, y)
}

export function addListener(eventName, callback) {
  window.addEventListener(eventName, callback)
}

export function removeListener(eventName, callback) {
  window.removeEventListener(eventName, callback)
}

export function locationAssign(path) {
  try {
    window.location.assign(path)
  } catch (err) {
    // eslint-disable-next-line
    console.error(err)
  }
}

export function getFullUrl() {
  return window.location.host
}

export const locationReload = () => window.location.reload()
