import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import injectSaga from "utils/inject-saga"
import { redirect } from "containers/wrapper/actions"
import selectLoading from "containers/loading/selectors"
import errorSelector from "containers/error/selectors"
import { closeErrorAlert } from "containers/error/actions"
import { makeSelectQuestion, makeSelectExam } from "./selectors"
import saga from "./saga"
import { examRetrieve, evaluateExam } from "./actions"

export const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  questions: makeSelectQuestion(),
  tuExam: makeSelectExam(),
  error: errorSelector(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { redirect, examRetrieve, evaluateExam, closeErrorAlert },
      dispatch,
    ),
  }
}

export const withSaga = injectSaga({ key: "tuExam", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
