import { createInstance } from "@rentspree/feedback-collection-sdk"

import { SURVICATE_SDK_PROVIDER_KEY } from "env"

const provider = "survicate"
const config = {
  providerKey: SURVICATE_SDK_PROVIDER_KEY,
}

const feedbackCollectionInstance = createInstance({ provider, ...config })

export { feedbackCollectionInstance }
