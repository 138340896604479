import omit from "lodash/omit"
import { BANK_ACCOUNT_TYPES } from "../reference/step-bank-information/const"
import { hasAllEmptyValue } from "./helper"

export const stepCreditorInformation = (creditors = []) => {
  const creditorInformation = creditors
    .filter(creditor => !hasAllEmptyValue(creditor))
    .map(creditor => ({
      name: creditor.name,
      accountNumber: creditor.accountNumber,
      monthlyPayment: creditor.monthlyPayment,
      balanceDue: creditor.balanceDue,
    }))
  return {
    reference: {
      creditorInformation,
    },
  }
}

export const stepBankInformation = (bankInfo = []) => {
  const bankInformation = bankInfo
    .filter(
      bank =>
        !hasAllEmptyValue(omit(bank, "accountType")) ||
        bank.accountType !== BANK_ACCOUNT_TYPES.PERSONAL_SAVING,
    )
    .map(
      ({
        name = "",
        accountNumber = "",
        other = "",
        accountBalance = "",
        accountType = BANK_ACCOUNT_TYPES.PERSONAL_SAVING,
      }) => ({
        name: name.trim(),
        accountNumber: accountNumber.trim(),
        other: accountType === BANK_ACCOUNT_TYPES.OTHER ? other.trim() : other,
        accountBalance: parseInt(accountBalance, 10) || "",
        accountType,
      }),
    )
  return {
    reference: {
      bankInformation,
    },
  }
}

export const stepNearestRelative = (nearestRelative = []) => ({
  reference: {
    nearestRelatives: nearestRelative.map(v => ({
      firstName: v.firstName,
      lastName: v.lastName,
      phoneNumber: v.phone,
      email: v.email,
      streetAddress: v.streetAddress,
      city: v.city,
      state: v.state,
      zipCode: v.zipcode,
      relationship: v.relationship,
      region: v.region,
    })),
  },
})

export const stepPersonalRef = (personalRef = []) => ({
  reference: {
    personalReferences: personalRef.map(v => ({
      firstName: v.firstName,
      lastName: v.lastName,
      phoneNumber: v.phone,
      email: v.email,
      streetAddress: v.streetAddress,
      city: v.city,
      state: v.state,
      zipCode: v.zipcode,
      acquaintanceLength: v.acquaintanceLength,
      occupation: v.occupation,
      region: v.region,
    })),
  },
})
