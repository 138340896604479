import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectSaga from "utils/inject-saga"
import injectReducer from "utils/inject-reducer"

import { redirect } from "containers/wrapper/actions"
import selectLoading from "containers/loading/selectors"

import { getTuAgreementContentCall, setIsReConsent } from "./actions"
import {
  selectTuAgreementResponse,
  selectTuAgreementFetching,
  selectTuAgreementErrors,
  selectIsTuAgreementReConsent,
} from "./selectors"
import saga from "./saga"
import reducer from "./reducer"
import { acceptTerms } from "../actions"

export const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  agreement: selectTuAgreementResponse(),
  isFetching: selectTuAgreementFetching(),
  errors: selectTuAgreementErrors(),
  isReConsent: selectIsTuAgreementReConsent(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        redirect,
        acceptTerms,
        getTuAgreementContentCall,
        setIsReConsent,
      },
      dispatch,
    ),
  }
}

export const withReducer = injectReducer({ key: "tuAgreement", reducer })
export const withSaga = injectSaga({ key: "tuAgreement", saga })

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
