import React from "react"

import GuidePage from "containers/rental-submission"
import { RedirectNotFound, ROUTE } from "containers/router/constants"
import * as MainRoute from "containers/router/loadable"
import * as ApplicantSteps from "containers/applicant-info/steps"
import * as TuSteps from "containers/tu-screening/steps"
import EnvelopePage from "containers/envelope"
import { Div } from "components/layout"

export const routes = [
  {
    path: "/",
    key: "guide",
    exact: true,
    component: GuidePage,
  },
  {
    path: ROUTE.APPLICATION.BASE,
    key: "rental-app",
    component: MainRoute.ApplicationLoadable,
  },
  {
    path: ROUTE.APPLICATION_V2.BASE,
    key: "rental-app-v2",
    component: MainRoute.ApplicationV2Loadable,
  },
  {
    path: ROUTE.APPLICANT.BASE,
    key: "applicant",
    component: MainRoute.ApplicantInfoLoadable,
    routes: [
      {
        path: ROUTE.APPLICANT.APPLICANT_INFO,
        key: "applicant-info",
        title: "Applicant Information",
        component: ApplicantSteps.StepApplicantInformation,
      },
      {
        path: ROUTE.APPLICANT.ADDITIONAL_APPLICANT,
        key: "additional-applicant",
        title: "Additional Applicant",
        component: ApplicantSteps.StepAdditionalApplicant,
      },
      {
        key: "not-found",
        render: () => <RedirectNotFound />,
      },
    ],
  },
  {
    path: ROUTE.TU_SCREENING.BASE,
    key: "tu-screening",
    component: MainRoute.TuScreeningLoadable,
    routes: [
      {
        path: ROUTE.TU_SCREENING.TERMS,
        key: "tu-terms",
        title: "Service Agreement",
        component: TuSteps.TuTerms,
      },
      {
        path: ROUTE.TU_SCREENING.INFO,
        key: "tu-info",
        title: "Screening Info",
        component: TuSteps.TuInfo,
      },
      {
        path: ROUTE.TU_SCREENING.EXAM,
        key: "tu-exam",
        title: "Verification",
        component: TuSteps.TuExam,
      },
      {
        path: ROUTE.TU_SCREENING.SSN,
        key: "tu-ssn",
        component: TuSteps.TuSsn,
      },
      {
        path: ROUTE.TU_SCREENING.ATTACH,
        key: "tu-attach",
        component: TuSteps.TuAttach,
      },
      {
        path: ROUTE.TU_SCREENING.ERROR,
        key: "tu-error",
        component: TuSteps.TuError,
      },
      {
        path: "/",
        key: "tu-base",
        component: Div,
      },
      {
        key: "not-found",
        render: () => <RedirectNotFound />,
      },
    ],
  },
  {
    path: ROUTE.ENVELOPE.SIGN,
    key: "envelope",
    component: EnvelopePage,
  },
  {
    path: ROUTE.GUIDE.APPLY_LIMIT,
    key: "apply-limit",
    component: MainRoute.ApplyLimitPageLoadable,
  },
  {
    path: ROUTE.GUIDE.SUBMITTED,
    key: "submitted",
    component: MainRoute.SubmittedPageLoadable,
  },
  {
    path: ROUTE.GUIDE.PAYMENT_PROCESSING,
    key: "processPayment",
    exact: true,
    component: MainRoute.PaymentProcessingPageLoadable,
  },
  {
    path: ROUTE.GUIDE.PAYMENT,
    key: "payment",
    component: MainRoute.RentalPaymentPageLoadable,
  },
  {
    path: ROUTE.GUIDE.FINISH,
    key: "finishSubmission",
    component: MainRoute.FinishPageLoadable,
  },
  {
    path: ROUTE.DOCUMENT_UPLOAD,
    key: "documentUpload",
    exact: true,
    component: MainRoute.DocumentUploadLoadable,
  },
  {
    path: ROUTE.INCOME_VERIFICATION,
    key: "incomeVerification",
    exact: true,
    component: MainRoute.IncomeVerificationLoadable,
  },
  {
    key: "not-found",
    render: () => <RedirectNotFound />,
  },
]

export const routesWithoutNavbar = [
  {
    path: ROUTE.APPLY_NOW_LANDING,
    key: "applyNowLanding",
    component: MainRoute.ApplyNowLandingPageLoadable,
  },
]
