import { createStructuredSelector } from "reselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import injectSaga from "utils/inject-saga"
import { redirect } from "containers/wrapper/actions"
import { closeErrorAlert } from "containers/error/actions"
import selectLoading from "containers/loading/selectors"
import errorSelector from "containers/error/selectors"
import { selectApplication } from "containers/rental-submission/selectors"
import { makeTuRenterSelector } from "../selectors"
import { saveSSN } from "./actions"
import saga from "./saga"
import { updateTuRenterCall } from "../constants"

export const withSaga = injectSaga({ key: "identity", saga })

export const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  tuRenter: makeTuRenterSelector(),
  application: selectApplication(),
  error: errorSelector(),
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { redirect, saveSSN, closeErrorAlert, updateTuRenterCall },
      dispatch,
    ),
  }
}
export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
