import { ROUTE } from "../router/constants"
import { calculateLatestPath } from "../router/helpers/calculate-latest-path"
import {
  RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA,
  saveApplicantInfoCall,
  RENTAL_SPECIFICS_STEP_LOOKUP_TABLE,
} from "./constants"
import { getCleanDraftAction } from "../autosave-form/helpers"

export function saveApplicantInfo({
  payload,
  route,
  parser,
  isLastStep,
  draft,
  pathname,
  savedContinuePath,
}) {
  const updatedContinuePath = calculateLatestPath({
    lookupTable: RENTAL_SPECIFICS_STEP_LOOKUP_TABLE,
    savedContinuePath: savedContinuePath || ROUTE.APPLICANT.BASE,
    currentPath: pathname,
    expectedPrefix: ROUTE.APPLICANT.BASE,
  })

  return saveApplicantInfoCall({
    payload,
    route,
    parser,
    isLastStep,
    draftAction: getCleanDraftAction(draft, pathname),
    continuePath: updatedContinuePath,
  })
}

export const autoSaveRentalSpecificsFromCTA = ({
  values,
  parser,
  validatorName,
  redirectToGuide = false,
  props,
  pathname,
  savedContinuePath,
}) => {
  const updatedContinuePath = calculateLatestPath({
    lookupTable: RENTAL_SPECIFICS_STEP_LOOKUP_TABLE,
    savedContinuePath: savedContinuePath || ROUTE.APPLICANT.BASE,
    currentPath: pathname,
    expectedPrefix: ROUTE.APPLICANT.BASE,
  })
  return {
    type: RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA,
    payload: {
      values,
      parser,
      validatorName,
      redirectToGuide,
      props,
      pathname,
      continuePath: updatedContinuePath,
    },
  }
}
