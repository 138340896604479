import { call, put } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import { apiInstance } from "utils/api-interceptor"
import { ROUTE } from "containers/router/constants"
import { redirect } from "./actions"

import {
  getScreeningRequestRequest,
  getScreeningRequestSuccess,
  getScreeningRequestFailed,
  SCREENING_REQUEST_API_URL,
  SCREENING_REQUEST_SOURCE,
} from "./constants"

export const getScreeningRequestApi = ({ screeningRequestId }) =>
  apiInstance.get(buildPath(SCREENING_REQUEST_API_URL, { screeningRequestId }))

export function* checkInactiveProperty(
  property,
  source = SCREENING_REQUEST_SOURCE.PROPERTY,
) {
  const isDisabledTenantScreeningFeature =
    property?.disabledFeatures?.tenantScreening

  const isDisabledListingApplicationFeature =
    property?.disabledFeatures?.listingApplication &&
    [
      SCREENING_REQUEST_SOURCE.LISTING,
      SCREENING_REQUEST_SOURCE.SYNDICATION,
    ].includes(source)

  if (isDisabledTenantScreeningFeature || isDisabledListingApplicationFeature) {
    yield put(redirect(ROUTE.GUIDE.INACTIVE))
  }
}

export function* checkInactiveScreeningRequest(screeningRequest = {}) {
  const isScreeningRequestDisabled = !!get(
    screeningRequest,
    "disabled.disabledAt",
    null,
  )

  if (isScreeningRequestDisabled) {
    yield put(redirect(ROUTE.GUIDE.INACTIVE))
  }
}

export function* getScreeningRequest(action) {
  yield put(getScreeningRequestRequest()) // TODO find a better description
  try {
    const response = yield call(getScreeningRequestApi, action?.payload)
    const isScreeningWithoutProperty = isEmpty(response.property)
    if (isScreeningWithoutProperty) {
      yield call(checkInactiveScreeningRequest, response)
    } else {
      yield call(checkInactiveProperty, response.property, response.source)
    }
    yield put(getScreeningRequestSuccess(response))
  } catch (err) {
    yield put(getScreeningRequestFailed(err))
  }
}
