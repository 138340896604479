import { RENTALS_DASHBOARD_DOMAIN } from "env"
import rentspreeLogo from "../images/icon/new-rentspree-logos.png"
import aaglaLogo from "../images/icon/partners/aagla-logo.png"
import beverlyHillsLogo from "../images/icon/partners/beverly-hills-logo.png"
import caaLogo from "../images/icon/partners/caa-logo.png"
import carLogo from "../images/icon/partners/car-logo.png"
import crmlsLogo from "../images/icon/partners/crmls-logo.png"
import malibuLogo from "../images/icon/partners/malibu-realtors-logo.png"
import msLogo from "../images/icon/partners/ms-realtors-logo.png"
import ocarLogo from "../images/icon/partners/ocar-logo.png"
import srarLogo from "../images/icon/partners/srar-logo.png"
import utilityhoundLogo from "../images/icon/partners/utilityhound-logo.png"
import wmaLogo from "../images/icon/partners/wma-logo.png"
import zipLogo from "../images/icon/partners/zip-logix-logo.png"

export const PARTNER = {
  ZIPLOGIX: "ziplogix",
  CRMLS: "crmls",
  MSREALTORS: "msrealtors",
  BHGLAAR: "bhglaar",
  SRAR: "srar",
  MALIBUREALTORS: "maliburealtors",
  CAR: "car",
  CAA: "caa",
  UTILITYHOUND: "utilityhound",
  WMA: "wma",
  OCAR: "ocrealtors",
  AAGLA: "aagla",
}

export const LISTING_PARTNER = {
  RENTALS: "rentals",
}

export const RENTALS_URL = {
  DASHBOARD: `${RENTALS_DASHBOARD_DOMAIN}/apply/renter`,
}

export const getSubdomainLogo = subdomain => {
  switch (subdomain) {
    case PARTNER.ZIPLOGIX:
      return zipLogo
    case PARTNER.CRMLS:
      return crmlsLogo
    case PARTNER.MSREALTORS:
      return msLogo
    case PARTNER.BHGLAAR:
      return beverlyHillsLogo
    case PARTNER.SRAR:
      return srarLogo
    case PARTNER.MALIBUREALTORS:
      return malibuLogo
    case PARTNER.CAR:
      return carLogo
    case PARTNER.CAA:
      return caaLogo
    case PARTNER.UTILITYHOUND:
      return utilityhoundLogo
    case PARTNER.WMA:
      return wmaLogo
    case PARTNER.OCAR:
      return ocarLogo
    case PARTNER.AAGLA:
      return aaglaLogo
    default:
      return rentspreeLogo
  }
}
