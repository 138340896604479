export const formatPath = (path, expectedPrefix) => {
  let trimmedPath = path
  if (!path.startsWith(expectedPrefix) && path.includes(expectedPrefix)) {
    trimmedPath = trimmedPath.slice(path.indexOf(expectedPrefix))
  }
  let queryString = ""
  const splitPath = trimmedPath.split("?")
  if (splitPath.length > 1) {
    const [actualContinuePath, queryPath] = splitPath
    trimmedPath = actualContinuePath
    queryString = `?${queryPath}`
  }
  return { path: trimmedPath, queryString }
}

export const calculateLatestPath = ({
  lookupTable,
  savedContinuePath,
  currentPath,
  expectedPrefix,
}) => {
  const formattedSavedContinuePath = formatPath(
    savedContinuePath,
    expectedPrefix,
  )
  const formattedCurrentPath = formatPath(currentPath, expectedPrefix)
  const currentPathOrder = lookupTable[formattedCurrentPath.path]
  const savedContinuePathOrder = lookupTable[formattedSavedContinuePath.path]
  if (currentPathOrder && savedContinuePathOrder >= 0) {
    if (currentPathOrder >= savedContinuePathOrder) {
      return formattedCurrentPath.path.concat(
        "",
        formattedCurrentPath.queryString,
      )
    }
    return savedContinuePath
  }
  return expectedPrefix
}
