import _ from "lodash"
import getHandleAuthorization from "@rentspree/axios-refresh-token"

import { removeAllLocalItem } from "@rentspree/cookie"
import { COOKIE_OPTION } from "constants/cookie"
import {
  TU_ERROR_MAPPING,
  REPEATED_EXAM,
} from "containers/tu-screening/constants"
import { DOMAIN_NAME, USER_API_URL } from "env"

import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import { ROUTE } from "containers/router/constants"

import { redirectAuthWeb, redirect } from "containers/wrapper/actions"
import { store } from "../store"

import { locationAssign } from "./call-window"
import { DAILY_APPLY_LIMIT_ERROR, TRANSUNION_ERROR } from "./constants"

export const handleAuthorization = getHandleAuthorization({
  afterRetry: error => {
    store.dispatch(redirect())
    return Promise.reject(_.get(error, "response", error))
  },
  afterRefreshTokenFailure: error => {
    store.dispatch(redirectAuthWeb(ROUTE.LOGIN))
    removeAllLocalItem("rentspree", COOKIE_OPTION)
    return Promise.reject(_.get(error, "response", error))
  },
  domain: DOMAIN_NAME,
  authAPIBase: USER_API_URL,
})

export async function handleApiErrors(error) {
  const statusCode = _.get(error, "status")
  switch (statusCode) {
    case 401:
      store.dispatch(redirectAuthWeb(ROUTE.LOGIN))
      removeAllLocalItem("rentspree", COOKIE_OPTION)
      break
    case 403:
      if (error?.data?.code === REPEATED_EXAM) {
        break
      }
      SweetAlert(
        ALERT_PRESET.ERROR,
        API_ERRORS[500].option,
        API_ERRORS[500].callback,
      )
      break
    case 404:
      locationAssign("/not-found")
      break
    case 409: {
      const isTuError = _.get(error, "data.name") === TRANSUNION_ERROR
      const responseTuCode = _.get(error, "data.tuCode")
      const showSweetAlert = !TU_ERROR_MAPPING.NOT_SHOWN_SWEET_ALERT_CODES.includes(
        responseTuCode,
      )
      if (isTuError && showSweetAlert) {
        SweetAlert(
          ALERT_PRESET.ERROR,
          API_ERRORS.TU(error).option,
          API_ERRORS[500].callback,
        )
      }
      break
    }
    case 500:
    case 502:
    case 503:
      {
        const isApplyLimitError =
          _.get(error, "data.code") === DAILY_APPLY_LIMIT_ERROR
        if (!isApplyLimitError) {
          SweetAlert(
            ALERT_PRESET.ERROR,
            API_ERRORS[500].option,
            API_ERRORS[500].callback,
          )
        }
      }
      break
    default:
      break
  }
  return Promise.reject(error)
}

export async function handleApiErrorsByPass404(error) {
  const statusCode = _.get(error, "status")
  switch (statusCode) {
    case 401:
      store.dispatch(redirectAuthWeb(ROUTE.LOGIN))
      removeAllLocalItem("rentspree", COOKIE_OPTION)
      break
    case 403:
      if (error?.data?.code === REPEATED_EXAM) {
        break
      }
      SweetAlert(
        ALERT_PRESET.ERROR,
        API_ERRORS[500].option,
        API_ERRORS[500].callback,
      )
      break
    case 404:
      break
    case 409: {
      const isTuError = _.get(error, "data.name") === TRANSUNION_ERROR
      const responseTuCode = _.get(error, "data.tuCode")
      const showSweetAlert = !TU_ERROR_MAPPING.NOT_SHOWN_SWEET_ALERT_CODES.includes(
        responseTuCode,
      )
      if (isTuError && showSweetAlert) {
        SweetAlert(
          ALERT_PRESET.ERROR,
          API_ERRORS.TU(error).option,
          API_ERRORS[500].callback,
        )
      }
      break
    }
    case 500:
    case 502:
    case 503:
      {
        const isApplyLimitError =
          _.get(error, "data.code") === DAILY_APPLY_LIMIT_ERROR
        if (!isApplyLimitError) {
          SweetAlert(
            ALERT_PRESET.ERROR,
            API_ERRORS[500].option,
            API_ERRORS[500].callback,
          )
        }
      }
      break
    default:
      break
  }
  return Promise.reject(error)
}
