import {
  takeLatest,
  call,
  put,
  select,
  all,
  debounce,
  cancel,
  take,
  race,
  actionChannel,
  flush,
} from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import isEmpty from "lodash/isEmpty"
import { apiInstance } from "utils/api-interceptor"
import { getError } from "containers/error/selectors"
import {
  showErrorAlertCall,
  clearErrorAlertCall,
} from "containers/error/constants"
import {
  DRAFT_ACTION,
  validateApplicationErrorExpected,
} from "containers/application/constants"
import {
  getRental,
  selectDraft,
  selectRentalId,
} from "containers/rental-submission/selectors"
import { tracker } from "tracker"
import {
  RENTAL_GUIDE_EVENT,
  selectEventNameApplicant,
  mapEventProperty,
} from "tracker/tracker-const"
import { buffers } from "redux-saga"

import {
  SAVE_APPLICANT_INFO_API_URL,
  SAVE_APPLICANT_INFO_CALL,
  saveApplicantInfoRequested,
  saveApplicantInfoSuccess,
  saveApplicantInfoFailed,
  RENTAL_STATUS,
  RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA,
  APPLICANT_PATH_FLOW,
} from "./constants"
import { getParser } from "./map-to-api"
import { redirect, setSuccess } from "../wrapper/actions"
import {
  RENTAL_SPECIFICS_AUTOSAVE_CALL,
  QUEUE_RENTAL_SPECIFICS_AUTOSAVE,
} from "../autosave-form/constants"
import {
  autoSaveRentalSpecificsError,
  cancelAutoSaveRentalSpecifics,
  queueAutoSaveRentalSpecifics,
  rentalSpecificsQueueAutosaveSuccess,
} from "../autosave-form/actions"
import { filterOutInvalidFields } from "../autosave-form/onchange-autosave"
import { getValidator } from "./get-validator"
import { setRentalSpecificsContinuePathFromAutoSave } from "../rental-submission/actions"
import { selectIsResumeAppEnabled } from "../feature-flags/selectors"
import { getCleanDraftAction } from "../autosave-form/helpers"

export const saveApplicantInfoApi = (payload, rentalId, draftAction) => {
  const queryParams = draftAction ? { draftAction } : null
  return apiInstance.put(
    buildPath(
      SAVE_APPLICANT_INFO_API_URL,
      {
        rentalId,
      },
      queryParams,
    ),
    payload,
  )
}

export function* saveApplicantInfo(action) {
  const {
    isLastStep,
    payload,
    parser,
    route,
    draftAction,
    continuePath,
  } = action
  const parsedPayload = getParser(parser)(payload)
  const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)
  if (isResumeAppEnabled) {
    parsedPayload.continuePath = continuePath
  }
  const rentalId = yield select(selectRentalId)
  yield put(saveApplicantInfoRequested(parsedPayload))
  // prevent redirect if it is the last page OR have no route
  if (!isEmpty(route) && !isLastStep) {
    yield put(redirect(route.next))
  }
  let error = yield select(getError)
  const defaultStep = APPLICANT_PATH_FLOW.APPLICANT_INFO.current

  try {
    const response = yield call(
      saveApplicantInfoApi,
      parsedPayload,
      rentalId,
      draftAction,
    )
    yield put(saveApplicantInfoSuccess(response))

    // trackEvent when success
    if (!isEmpty(route)) {
      const { eventName, properties } = selectEventNameApplicant(route)

      const selectProperties = mapEventProperty(properties, {
        response,
        parsedPayload,
        rental: { rentalId },
      })

      if (!isEmpty(eventName)) {
        yield call([tracker, "trackEvent"], eventName, selectProperties)
      }
    }

    // Clear error when success and passed the error page
    if (response && get(action, "route.current") === error.location) {
      yield put(clearErrorAlertCall())
    }

    // Only last step
    const rentalReady = get(response, "status") === RENTAL_STATUS.READY
    if (isLastStep) {
      // Redirect when app is ready and no error
      if (rentalReady && isEmpty(error.location)) {
        const rentalSubmission = yield select(getRental)
        yield call(
          [tracker, "trackEvent"],
          RENTAL_GUIDE_EVENT.COMPLETE_APPLICANT_INFORMATION,
          {
            rental_submission_id: rentalId,
            template: rentalSubmission.application.type,
            template_version: rentalSubmission.application.version,
          },
        )
        yield put(clearErrorAlertCall())
        yield put(setSuccess())
        yield put(redirect())
      } else {
        const errorStep = error.location || defaultStep
        yield put(showErrorAlertCall())
        yield put(redirect(errorStep))
      }
    }
  } catch (err) {
    if (isEqual(get(err, "status"), 422)) {
      yield put(
        validateApplicationErrorExpected({
          error: err,
          location: defaultStep,
        }),
      )
    } else if (!isEmpty(route)) {
      yield put(saveApplicantInfoFailed(route.current))
    }
    if (isLastStep) {
      error = yield select(getError)
      const errorStep = error.location || defaultStep
      yield put(showErrorAlertCall())
      yield put(redirect(errorStep))
    }
  }
}

export function* queueApplicantInfoAutosaveSaga({ payload }) {
  yield put(queueAutoSaveRentalSpecifics(payload))
}

export function* watchAutosaveQueued() {
  const autosaveQueueBuffer = buffers.expanding()
  const autosaveQueue = yield actionChannel(
    QUEUE_RENTAL_SPECIFICS_AUTOSAVE,
    autosaveQueueBuffer,
  )
  while (true) {
    try {
      const { payload } = yield take(autosaveQueue)
      const rentalId = yield select(selectRentalId)
      const draft = yield select(selectDraft)
      const parsedPayload = getParser(payload.parser)(payload.value)
      parsedPayload.continuePath = payload.continuePath
      const saveApplicantInfoApiParams = payload.isDraft
        ? [
            {
              data: payload.value,
              pathname: payload.pathname,
              parser: payload.parser,
              continuePath: payload.continuePath,
            },
            rentalId,
            DRAFT_ACTION.CREATE,
          ]
        : [
            parsedPayload,
            rentalId,
            getCleanDraftAction(draft, payload.pathname),
          ]
      const { response, cancel: cancelAutosave } = yield race({
        response: call(saveApplicantInfoApi, ...saveApplicantInfoApiParams),
        cancel: take(SAVE_APPLICANT_INFO_CALL),
      })
      if (cancelAutosave) {
        yield put(cancelAutoSaveRentalSpecifics())
        yield flush(autosaveQueue)
      }
      if (response) {
        const parsedOriginalValue = getParser(payload.parser)(
          payload.originalValue,
        )
        yield put(
          rentalSpecificsQueueAutosaveSuccess({
            originalValue: parsedOriginalValue,
            draft: response.draft,
          }),
        )
        yield put(
          setRentalSpecificsContinuePathFromAutoSave(response.continuePath),
        )
      }
    } catch (error) {
      yield put(autoSaveRentalSpecificsError(error))
      yield flush(autosaveQueue)
    }
  }
}

export function* autoSaveRentalSpecificsFromCTA(action) {
  const { payload } = action
  const rentalId = yield select(selectRentalId)
  const draft = yield select(selectDraft)
  const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)

  let toSaveValue = payload.values
  let isSavingDraft = false
  try {
    if (isResumeAppEnabled) {
      if (payload.validatorName) {
        const validator = yield call(getValidator, payload.validatorName)
        const { mapper, schema } = yield call(validator, payload.props)
        const { cleanValue, isError } = yield call(
          filterOutInvalidFields,
          payload.values,
          schema,
          mapper,
        )
        toSaveValue = cleanValue
        isSavingDraft = isError
      }
      const parsedPayload = getParser(payload.parser)(toSaveValue)
      parsedPayload.continuePath = payload.continuePath

      const saveApplicantInfoApiParams = isSavingDraft
        ? [
            {
              data: toSaveValue,
              pathname: payload.pathname,
              parser: payload.parser,
              continuePath: payload.continuePath,
            },
            rentalId,
            DRAFT_ACTION.CREATE,
          ]
        : [
            parsedPayload,
            rentalId,
            getCleanDraftAction(draft, payload.pathname),
          ]
      yield put(saveApplicantInfoRequested(parsedPayload))

      const response = yield call(
        saveApplicantInfoApi,
        ...saveApplicantInfoApiParams,
      )
      yield put(saveApplicantInfoSuccess(response))
    }
  } catch (error) {
    yield put(autoSaveRentalSpecificsError(error))
  } finally {
    if (payload.redirectToGuide) {
      yield put(redirect())
    }
  }
}

export function* watchSaveApplicantInfo() {
  yield takeLatest(SAVE_APPLICANT_INFO_CALL, saveApplicantInfo)
}

export function* watchAutoSaveRentalSpecificsFromCTA() {
  yield takeLatest(
    RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA,
    autoSaveRentalSpecificsFromCTA,
  )
}

export function* watchAutosaveTriggered() {
  try {
    while (true) {
      const queueDebounce = yield debounce(
        3000,
        RENTAL_SPECIFICS_AUTOSAVE_CALL,
        queueApplicantInfoAutosaveSaga,
      )
      yield race({
        saveApplicantInfo: take(SAVE_APPLICANT_INFO_CALL),
        autosaveFromCTA: take(RENTAL_SPECIFICS_AUTOSAVE_FROM_CTA),
      })
      yield cancel(queueDebounce)
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* rootSaga() {
  yield all([
    watchAutosaveTriggered(),
    watchAutosaveQueued(),
    watchSaveApplicantInfo(),
    watchAutoSaveRentalSpecificsFromCTA(),
  ])
}
