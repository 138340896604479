import { OCCURRING, ROUTE } from "../router/constants"

export const SAVE_APPLICATION_CALL = "SAVE_APPLICATION_CALL"
export const SAVE_APPLICATION_REQUEST = "SAVE_APPLICATION_REQUEST"
export const SAVE_APPLICATION_SUCCESS = "SAVE_APPLICATION_SUCCESS"
export const SAVE_APPLICATION_FAILED = "SAVE_APPLICATION_FAILED"
export const VALIDATION_APPLICATION_ERROR_EXPECTED =
  "VALIDATION_APPLICATION_ERROR_EXPECTED"
export const VALIDATION_APPLICATION_ERROR_UNEXPECTED =
  "VALIDATION_APPLICATION_ERROR_UNEXPECTED"
export const VALIDATION_APPLICATION_ERROR_CLEAR =
  "VALIDATION_APPLICATION_ERROR_CLEAR"
export const GET_APPLICATION_CALL = "GET_APPLICATION_CALL"
export const GET_APPLICATION_REQUESTED = "GET_APPLICATION_REQUESTED"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_APPLICATION_FAILED = "GET_APPLICATION_FAILED"
export const API_APPLICATION = "api/v2/rental-submissions/:rentalId/application"
export const API_APPLICATION_VALIDATION = `${API_APPLICATION}/validate`
export const VALIDATION_APPLICATION_CALL = "VALIDATION_APPLICATION_CALL"
export const VALIDATION_APPLICATION_DONE = "VALIDATION_APPLICATION_DONE"
export const RENTAL_APPLICATION_AUTOSAVE_FROM_CTA =
  "RENTAL_APPLICATION_AUTOSAVE_FROM_CTA"
export const saveApplicationRequest = () => ({ type: SAVE_APPLICATION_REQUEST })
export const saveApplicationSuccess = (payload, isMergeDraft) => ({
  type: SAVE_APPLICATION_SUCCESS,
  payload,
  isMergeDraft,
})
export const saveApplicationFailed = payload => ({
  type: SAVE_APPLICATION_FAILED,
  payload,
})

export const validateApplicationCall = () => ({
  type: VALIDATION_APPLICATION_CALL,
})
export const validateApplicationDone = () => ({
  type: VALIDATION_APPLICATION_DONE,
})

export const validateApplicationErrorExpected = payload => ({
  type: VALIDATION_APPLICATION_ERROR_EXPECTED,
  payload,
})

export const validateApplicationErrorUnExpected = payload => ({
  type: VALIDATION_APPLICATION_ERROR_UNEXPECTED,
  payload,
})

export const validateApplicationErrorClear = () => ({
  type: VALIDATION_APPLICATION_ERROR_CLEAR,
})

export const saveApplicationCall = ({
  payload,
  route,
  parser,
  isLastStep = false,
  draftAction,
  continuePath,
}) => ({
  type: SAVE_APPLICATION_CALL,
  payload,
  route,
  parser,
  isLastStep,
  draftAction,
  continuePath,
})

export const getApplicationCall = () => ({
  type: GET_APPLICATION_CALL,
})
export const getApplicationRequested = () => ({
  type: GET_APPLICATION_REQUESTED,
})
export const getApplicationSuccess = (payload, isMergeDraft) => ({
  type: GET_APPLICATION_SUCCESS,
  payload,
  isMergeDraft,
})
export const getApplicationFailed = () => ({ type: GET_APPLICATION_FAILED })

export const APPLICATION_TYPE_MAPPER = {
  car_lra: "Rental Application",
  rentspree: "Rental Application",
}

export const APP_STATUS = {
  CREATED: "created",
  EDIT: "edit",
  READY: "ready",
  SUBMITTED: "submitted",
}

export const APPLICATION_STEP = [
  { title: "Personal Information", key: "personal" },
  { title: "Residence", key: "residence" },
  { title: "Employment & Income", key: "occupation" },
  { title: "References", key: "reference" },
  { title: "Additional Information", key: "additional-info" },
]

export const DRAFT_ACTION = {
  CREATE: "create",
  CLEAN: "clean",
}

const personalBaseRoute = `${ROUTE.GUIDE.RENTAL_APP}${
  ROUTE.APPLICATION.PERSONAL.BASE
}`
const residenceBaseRoute = `${ROUTE.GUIDE.RENTAL_APP}${
  ROUTE.APPLICATION.RESIDENCE.BASE
}`
const occupationBaseRoute = `${ROUTE.GUIDE.RENTAL_APP}${
  ROUTE.APPLICATION.OCCUPATION.BASE
}`
const referenceBaseRoute = `${ROUTE.GUIDE.RENTAL_APP}${
  ROUTE.APPLICATION.REFERENCE.BASE
}`
const additionalInfoBaseRoute = `${ROUTE.GUIDE.RENTAL_APP}${
  ROUTE.APPLICATION.ADDITIONAL_INFORMATION
}`

export const RENTAL_APPLICATION_STEP_LOOKUP_TABLE = {
  [`${ROUTE.GUIDE.RENTAL_APP}/`]: 0,
  // personal info
  [`${personalBaseRoute}${ROUTE.APPLICATION.PERSONAL.PERSONAL_INFO}`]: 1,
  [`${personalBaseRoute}${ROUTE.APPLICATION.PERSONAL.GOV_ID}`]: 2,
  [`${personalBaseRoute}${ROUTE.APPLICATION.PERSONAL.EMERGENCY}`]: 3,
  // residence
  [`${residenceBaseRoute}/${OCCURRING.CURRENT}`]: 4,
  [`${residenceBaseRoute}/${OCCURRING.CURRENT}/rental`]: 5,
  [`${residenceBaseRoute}/${OCCURRING.PREVIOUS}`]: 6,
  [`${residenceBaseRoute}/${OCCURRING.PREVIOUS}/rental`]: 7,
  // occupation
  [`${occupationBaseRoute}/${OCCURRING.CURRENT}`]: 8,
  [`${occupationBaseRoute}/${OCCURRING.PREVIOUS}`]: 9,
  // reference
  [`${referenceBaseRoute}${ROUTE.APPLICATION.REFERENCE.CREDIT}`]: 10,
  [`${referenceBaseRoute}${ROUTE.APPLICATION.REFERENCE.BANK}`]: 11,
  [`${referenceBaseRoute}${ROUTE.APPLICATION.REFERENCE.RELATIVE}`]: 12,
  [`${referenceBaseRoute}${ROUTE.APPLICATION.REFERENCE.PERSONAL}`]: 13,
  // misc
  [additionalInfoBaseRoute]: 14,
}

export const US_STATES = {
  CA: "CA",
}
