import React from "react"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import { Tooltip, B16 } from "@rentspree/component-v2"

export const StyledTooltipContent = styled(Tooltip)`
  span {
    padding: 0;
  }
  i {
    margin-left: 10px;
  }
  @media (max-width: 991px) {
    i {
      ${({ tooltipMobileFontSize }) =>
        tooltipMobileFontSize && `font-size: ${tooltipMobileFontSize}`};
    }
  }
  .tooltiptext {
    ${({ tooltipBackground }) =>
      tooltipBackground &&
      `background: ${tooltipBackground};`} padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    &::before {
      ${({ tooltipBackground }) =>
        tooltipBackground && `background: ${tooltipBackground}`};
    }
    top: 6px;
    left: 24px;
  }
`

export const TooltipDescription = styled(B16)`
  ${({ descriptionFontColor }) =>
    descriptionFontColor && `color: ${descriptionFontColor}`};
  ${({ descriptionFontSize }) =>
    descriptionFontSize && `font-size: ${descriptionFontSize}`};
  @media (max-width: 991px) {
    color: initial;
    ${({ descriptionMobileFontSize }) =>
      descriptionMobileFontSize && `font-size: ${descriptionMobileFontSize}`};
  }
  margin-bottom: 8px;
`
export const HintTooltip = ({
  descriptionFontColor,
  descriptionFontSize,
  descriptionMobileFontSize,
  tooltipBackground,
  tooltipMobileFontSize,
  children,
  ...rest
}) => (
  <StyledTooltipContent
    tooltipBackground={tooltipBackground}
    tooltipMobileFontSize={tooltipMobileFontSize}
    {...rest}>
    <TooltipDescription
      descriptionFontColor={descriptionFontColor}
      descriptionFontSize={descriptionFontSize}
      descriptionMobileFontSize={descriptionMobileFontSize}>
      {children}
    </TooltipDescription>
  </StyledTooltipContent>
)

export default HintTooltip

HintTooltip.defaultProps = {
  descriptionFontColor: COLOR.white,
  descriptionFontSize: "12px",
  descriptionMobileFontSize: "16px",
  tooltipBackground: COLOR.darkGray,
  tooltipMobileFontSize: "18px",
}
