import get from "lodash/get"
import set from "lodash/set"
import pick from "lodash/pick"
import mapKeys from "lodash/mapKeys"
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import { merge } from "lodash"
import { getParser } from "../map-to-api"
const occupationObject = (data, key) => ({
  workStatus: get(data, `occupation.${key}.workStatus`, undefined),
  data: {
    info: {
      ...pick(get(data, `occupation.${key}.employed`, {}), [
        "employer",
        "jobTitle",
        "salary",
      ]),
    },
    duration: {
      ...pick(get(data, `occupation.${key}.employed`, {}), [
        "endDate",
        "startDate",
      ]),
    },
    supervisor: {
      firstName: get(
        data,
        `occupation.${key}.employed.supervisorFirstName`,
        undefined,
      ),
      lastName: get(
        data,
        `occupation.${key}.employed.supervisorLastName`,
        undefined,
      ),
      phoneNumber: get(
        data,
        `occupation.${key}.employed.supervisorPhoneNumber`,
        undefined,
      ),
      email: get(data, `occupation.${key}.employed.supervisorEmail`, undefined),
      isSkipEmailHR: get(
        data,
        `occupation.${key}.employed.isSkipEmailHR`,
        undefined,
      ),
      isSkipPhoneHR: get(
        data,
        `occupation.${key}.employed.isSkipPhoneHR`,
        undefined,
      ),
    },
  },
})

const renameKeys = (obj, newKeys) =>
  mapKeys(obj, (v, k) => (newKeys[k] ? newKeys[k] : k))

const getYesNoFromArray = (dataFromAPI, keyPath) => {
  if (isNil(get(dataFromAPI, keyPath, null))) return ""
  return get(dataFromAPI, keyPath, []).length ? "yes" : "no"
}

export const parseOptionalDataAsEmptyString = dataFromAPI => {
  const clonedData = cloneDeep(dataFromAPI)
  const targets = [
    [
      "personal.emergencyContacts[1]",
      "personal.emergencyContacts[1].phoneNumber",
    ],
    [
      "reference.personalReferences[1]",
      "reference.personalReferences[1].phone",
    ],
    ["reference.nearestRelatives[1]", "reference.nearestRelatives[1].phone"],
  ]

  targets.forEach(target => {
    if (
      !isEmpty(get(clonedData, target[0])) &&
      isEmpty(get(clonedData, target[1]))
    ) {
      set(clonedData, target[1], "")
    }
  })

  return clonedData
}

export const parseFromAPI = (dataFromAPI = {}, isMergeDraft = false) => {
  let localData = dataFromAPI
  if (isMergeDraft && dataFromAPI.draft) {
    localData = merge(
      dataFromAPI,
      getParser(dataFromAPI.draft.parser)(dataFromAPI.draft.data),
    )
  }
  const mapped = {
    personal: {
      ...pick(localData, [
        "firstName",
        "lastName",
        "middleName",
        "governmentId",
      ]),
      emergencyContacts: get(localData, `emergencyContacts`, []).map(
        emergencyContact =>
          renameKeys(emergencyContact, {
            zipCode: "zipcode",
          }),
      ),
      dob: localData.dateOfBirth,
      phoneNumber: localData.phoneNumber,
    },
    occupation: {
      current: occupationObject(localData, "current"),
      previous: occupationObject(localData, "previous"),
      additionalIncomes: get(localData, "occupation.additionalIncomes", []),
      ...(get(localData, "occupation.rentSubsidy") && {
        rentSubsidy: get(localData, "occupation.rentSubsidy"),
      }),
    },
    reference: {
      creditorInformation: get(localData, `reference.creditorInformation`, []),
      bankInformation: get(localData, `reference.bankInformation`, []),
      nearestRelatives:
        get(localData, `reference.nearestRelatives`, []).map(nearestRelative =>
          renameKeys(nearestRelative, {
            phoneNumber: "phone",
            zipCode: "zipcode",
          }),
        ) || [],
      personalReferences:
        (get(localData, `reference.personalReferences`) &&
          get(localData, `reference.personalReferences`).map(personalRef =>
            renameKeys(personalRef, {
              phoneNumber: "phone",
              zipCode: "zipcode",
            }),
          )) ||
        [],
    },
    miscellaneous: {
      pets: {
        havePet: getYesNoFromArray(localData, `miscellaneous.pets`),
        pets: get(localData, `miscellaneous.pets`, []),
      },
      vehicles: {
        haveVehicle: getYesNoFromArray(localData, `miscellaneous.vehicles`),
        vehicles: get(localData, `miscellaneous.vehicles`, []),
      },
      additionalInfo: {
        liquidFurniture: get(
          localData,
          `miscellaneous.additionalInfo.liquidFurniture.choice`,
          "",
        ),
        typeLiquidFurniture: get(
          localData,
          `miscellaneous.additionalInfo.liquidFurniture.type`,
          "",
        ),
        unlawful: get(
          localData,
          `miscellaneous.additionalInfo.unlawful.choice`,
          "",
        ),
        explainUnlawful: get(
          localData,
          `miscellaneous.additionalInfo.unlawful.explain`,
          "",
        ),
        moveOut: get(
          localData,
          `miscellaneous.additionalInfo.moveOut.choice`,
          "",
        ),
        explainMoveOut: get(
          localData,
          `miscellaneous.additionalInfo.moveOut.explain`,
          "",
        ),
        bankruptcy: get(
          localData,
          `miscellaneous.additionalInfo.bankruptcy.choice`,
          "",
        ),
        explainBankruptcy: get(
          localData,
          `miscellaneous.additionalInfo.bankruptcy.explain`,
          "",
        ),
        felony: get(
          localData,
          `miscellaneous.additionalInfo.felony.choice`,
          "",
        ),
        explainFelony: get(
          localData,
          `miscellaneous.additionalInfo.felony.explain`,
          "",
        ),
      },
    },
    ...pick(localData, ["residence"]),
    id: get(localData, "_id"),
    version: get(localData, "version"),
    type: get(localData, "type"),
    draft: get(localData, "draft"),
    continuePath: get(localData, "continuePath"),
  }
  const preprocessed = parseOptionalDataAsEmptyString(mapped)
  return preprocessed
}
