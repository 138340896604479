export const ALLOW_ATTRIBUTES = [
  "registeredUserType",
  "userType",
  "email",
  "subdomain",
  "isCARAccountLinked",
  "userMailingAddressState",
  "createdAt",
]
export const ALLOW_PARTIAL_TRACK = []
export const CAR_PARTNER = "car"

// TODO delete after clean up logic
export const AGENT_PROFILE_AGENT_REVIEW = "agent_profile_agent_review"
export const QW_DATE_PICKER = "qw_date_picker"
export const QW_TS_RESUME_APPLICATION = "qw_ts_resume_application"
export const INCOME_VERIFICATION_REPORT = "income_verification_report"
