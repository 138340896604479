export class UpdateEnvelopeSignError extends Error {
  constructor(payload, thrownError) {
    super("Unable to update the envelope's signature")
    this.name = "UpdateEnvelopeSignError"
    this.payload = payload
    this.thrownError = thrownError
  }
}

export class SubmitRentalSubmissionError extends Error {
  constructor(thrownError) {
    super("Unable to Submit Rental Submission")
    this.name = "RentalSubmissionError"
    this.thrownError = thrownError
  }
}

export class CreatePublicContactError extends Error {
  constructor(thrownError) {
    super("Unable to create public contact")
    this.name = "CreatePublicContactError"
    this.thrownError = thrownError
  }
}
