import {
  attachPaymentCall,
  createPublicContactCall,
  getAgentReviewByRentalCall,
  submitRentalCall,
} from "./constants"

export function submitRental(payload) {
  return submitRentalCall(payload)
}

export function createPublicContact() {
  return createPublicContactCall()
}

export function getAgentReviewByRental(payload) {
  return getAgentReviewByRentalCall(payload)
}

// TODO: INIT-649 - Cleanup
export function attachPayment(payload) {
  return attachPaymentCall(payload)
}
