import * as ApplicantInfo from "./applicant-info"
import * as MoveInDate from "./premise-information"
import { stepAdditionalApplicant } from "./additional-applicant"

export const fn = {
  ...ApplicantInfo,
  ...MoveInDate,
  stepAdditionalApplicant,
}
export const getParser = parserName => fn[parserName]
