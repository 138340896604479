import { fromJS } from "immutable"
import _ from "lodash"
import * as constants from "./constants"
import { SET_SSN, CLEAR_SSN } from "./ssn/constants"
import {
  SET_IS_SHOW_REVIEW_PAGE,
  UNSET_IS_SHOW_REVIEW_PAGE,
} from "./info/constants"

export const initialState = fromJS({
  creditReport: {},
  tuRenter: {},
  ssn: "",
  isShowReviewPage: false,
})

function tuScreeningReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case constants.GET_TU_DATA_SUCCESS:
    case constants.ACCEPT_TERMS_SUCCESS:
      nextState = state.mergeDeep(action.payload)
      return nextState
    case constants.UPDATE_TU_RENTER_CALL:
      nextState = state.mergeDeep({ tuRenter: action.payload })
      return nextState
    case constants.GET_CREDIT_REPORT_SUCCESS:
      nextState = state.mergeDeep({
        creditReport: {
          bundleId: _.get(
            action,
            "payload.transunion_application.bundleId",
            null,
          ),
        },
      })
      return nextState
    case constants.ATTACH_REPORT_SUCCESS:
    case constants.START_NEW_SCREENING_SUCCESS:
    case constants.EXAM_RETRIEVE_SUCCESS:
    case constants.EXAM_EVALUATE_SUCCESS:
      nextState = state.mergeDeep({ creditReport: action.payload })
      return nextState
    case constants.START_NEW_SCREENING_CALL:
    case SET_SSN:
      nextState = state.setIn(["ssn"], _.get(action, "payload.ssn"), "")
      return nextState
    case CLEAR_SSN:
      nextState = state.setIn(["ssn"], "")
      return nextState
    case SET_IS_SHOW_REVIEW_PAGE:
      nextState = state.mergeDeep({
        tuRenter: action.payload,
        isShowReviewPage: true,
      })
      return nextState
    case UNSET_IS_SHOW_REVIEW_PAGE:
      nextState = state.mergeDeep({ isShowReviewPage: false })
      return nextState
    default:
      return state
  }
}

export default tuScreeningReducer
