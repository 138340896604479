import React from "react"
import { compose } from "redux"
import marked from "marked"
import { TermBox, T36, MobileInfoOverlay } from "@rentspree/component-v2"
import Panel from "@rentspree/component-v2/dist/panel"

import { tracker } from "tracker"
import { TU_EVENT } from "tracker/tracker-const"
import { AGREEMENT_TYPE, RE_CONSENT_PANEL } from "./constants"
import Form from "./form"
import { withConnect, withSaga, withReducer } from "./connect"

export class TransUnionAgreement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileOverlay: false,
      checkboxes: [],
    }
    this.openMobileOverlay = this.openMobileOverlay.bind(this)
    this.closeMobileOverlay = this.closeMobileOverlay.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agreement !== this.props.agreement) {
      const { agreement } = nextProps
      const { checkboxes, version } = agreement
      tracker.trackEvent(TU_EVENT.enterAgreementPage, {
        agreement_type: AGREEMENT_TYPE.TU_RENTER,
        version,
      })
      if (checkboxes && checkboxes.length > 0) {
        this.setState({ checkboxes })
      }
    }
  }

  componentDidMount() {
    this.props.actions.getTuAgreementContentCall({
      type: AGREEMENT_TYPE.TU_RENTER,
    })
  }

  openMobileOverlay() {
    this.setState({ showMobileOverlay: true })
  }

  closeMobileOverlay() {
    this.setState({ showMobileOverlay: false })
  }

  renderAgreementContent = content => (
    <div
      dangerouslySetInnerHTML={{
        __html: marked(content),
      }}
    />
  )

  handleCheckboxesAccepted = index =>
    this.setState(prevState => ({
      checkboxes: prevState.checkboxes.map((checkbox, cIndex) => {
        if (cIndex !== index) return checkbox
        return {
          ...checkbox,
          checked: !checkbox.checked,
        }
      }),
    }))

  closePanel = () => this.props.actions.setIsReConsent(false)

  render() {
    const { agreement, isReConsent } = this.props || {}
    const { title, content } = agreement || {}
    const { checkboxes, showMobileOverlay } = this.state
    return (
      <div>
        {isReConsent && (
          <Panel
            status="info"
            titleMessage={RE_CONSENT_PANEL.TITLE}
            bodyMessage={RE_CONSENT_PANEL.BODY}
            onClose={this.closePanel}
          />
        )}
        <MobileInfoOverlay
          showOverlay={showMobileOverlay}
          title={title}
          onClickCloseButton={this.closeMobileOverlay}>
          {content && this.renderAgreementContent(content)}
        </MobileInfoOverlay>

        {title && <T36 margin="35px 0">{title}</T36>}
        <TermBox
          height="360"
          overlayText="Click to continue reading"
          onClickOverlay={this.openMobileOverlay}>
          {content && this.renderAgreementContent(content)}
        </TermBox>
        <Form
          {...this.props}
          checkboxes={checkboxes}
          handleCheckboxesAccepted={this.handleCheckboxesAccepted}
        />
      </div>
    )
  }
}

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(TransUnionAgreement)
