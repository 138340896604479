import React from "react"
import { Popper } from "react-popper"
import styled from "styled-components"
import { COLOR } from "styles/settings"

export const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
`

export const Container = styled.div`
  display: flex;
  top: 6px !important;
  flex-direction: column;
  padding: 5px 0px;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100px;
`
export const Item = styled.div`
  display: flex;
  padding: 2px;
  cursor: pointer;
  padding-left: 15px;
  :hover {
    background-color: rgb(241, 241, 241);
  }
  font-size: 14px;
  font-weight: normal;
  ${props => props.redText && `color: ${COLOR.negativeRed};`};
`
const PopperDropDown = ({ popper, dropdownItems = [] }) => {
  if (popper !== "") {
    try {
      const refNode = document.querySelector(`[id='${popper}']`)
      return (
        <Wrapper>
          <Popper referenceElement={refNode} placement="bottom-end">
            {({ ref, placement, style }) => (
              <Container ref={ref} style={style} data-placement={placement}>
                {dropdownItems.map(({ label, onClick, ...rest }) => (
                  <Item
                    key={`dropdown-${label}-item`}
                    {...rest}
                    onClick={onClick}>
                    {label}
                  </Item>
                ))}
              </Container>
            )}
          </Popper>
        </Wrapper>
      )
    } catch (e) {
      return null
    }
  }
  return null
}

export default PopperDropDown
