import * as Personal from "./personal"
import * as Occupation from "./occupation"
import * as Reference from "./reference"
import * as Residence from "./residence"
import { stepAdditionalInformation } from "./additional-information"

export const fn = {
  ...Personal,
  ...Occupation,
  ...Reference,
  ...Residence,
  stepAdditionalInformation,
}
export const getParser = parserName => fn[parserName]
