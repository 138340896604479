import React from "react"
import styled from "styled-components"
import Alert from "@rentspree/component-v2/dist/alert"
import { Icon } from "@rentspree/component-v2/dist/icon"

import ErrorPanel from "components/molecules/error-panel"
import { COLOR } from "styles/settings"

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.alignItem ? props.alignItem : "flex-start")};
  i {
    line-height: 20px;
  }
  border-radius: 8px;
  ${props => props.fullWidth && "width: 100%"};
  ${props =>
    props.error &&
    `background: ${COLOR.lightWhiteRed}; color: ${COLOR.softRed}`};
  ${props =>
    props.info &&
    `background: ${COLOR.deepBluePale}; color: ${COLOR.brightBlue}`};
`

const Button = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0px;
  margin: 0 0 0 5px;
  height: 20px;
`

export const ERROR_SAVING =
  "We’ve encountered an error saving your info. Please try again."

export const PLEASE_CONTACT =
  "If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com."

export const AlertPanel = ({
  alertDetail,
  handleClose,
  error,
  description,
  ...rest
}) => (
  <StyledAlert {...rest} error={error}>
    {error ? (
      <ErrorPanel
        title={alertDetail || ERROR_SAVING}
        description={description || PLEASE_CONTACT}
      />
    ) : (
      alertDetail
    )}
    {handleClose && (
      <Button onClick={handleClose}>
        <Icon icon="close" />
      </Button>
    )}
  </StyledAlert>
)

export default AlertPanel
