import Tracker, {
  GTMTracker,
  AmplitudeTracker,
  HubspotTracker,
  UserRecorderTracker,
} from "@rentspree/tracker"
import Amplitude from "amplitude-js"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import merge from "lodash/merge"
import trim from "lodash/trim"
import omitBy from "lodash/omitBy"

import {
  GOOGLE_TAG_MANAGER_CONTAINER_ID,
  BASE_NAME,
  AMPLITUDE_API_KEY,
  AMPLITUDE_COOKIE_DOMAIN,
} from "../env"
import { generateAmplitudeProfileItem } from "./tracker-const"

const userRecorderTracker = new UserRecorderTracker()

const gtmTracker = new GTMTracker({
  trackingId: GOOGLE_TAG_MANAGER_CONTAINER_ID,
  mapUserProfile: profile => ({
    id: get(profile, "_id"),
    email: get(profile, "email"),
  }),
  mapUserIdentity: profile => get(profile, "_id"),
})

const amplitudeTracker = new AmplitudeTracker({
  apiKey: AMPLITUDE_API_KEY,
  amplitudeSDK: Amplitude,
  amplitudeConfig: {
    domain: AMPLITUDE_COOKIE_DOMAIN,
    saveParamsReferrerOncePerSession: false,
    includeUtm: true,
  },
  mapUserIdentity: profile => get(profile, "_id"),
  mapUserProfile: profile => {
    const initProfile = {
      email: generateAmplitudeProfileItem(profile.email),
      phone: generateAmplitudeProfileItem(profile.phone),
      createdAt: generateAmplitudeProfileItem(profile.createdAt, true),
      userType: generateAmplitudeProfileItem(profile.userType),
      registeredUserType: generateAmplitudeProfileItem(
        profile.registeredUserType,
      ),
    }
    if (!isEmpty(profile.firstName)) {
      initProfile.firstName = generateAmplitudeProfileItem(profile.firstName)
    }
    if (!isEmpty(profile.lastName)) {
      initProfile.lastName = generateAmplitudeProfileItem(profile.lastName)
    }
    return omitBy(initProfile, item => isEmpty(item.value))
  },
})

const hubspotTracker = new HubspotTracker({
  mapUserIdentity: profile => ({
    email: get(profile, "email"),
    instantlyCreateContact: true,
  }),
})

const tracker = new Tracker()

tracker.registerTracker(gtmTracker)
tracker.registerTracker(amplitudeTracker)
tracker.registerTracker(hubspotTracker)
tracker.registerTracker(userRecorderTracker)

const trackEvent = obj => {
  const action = get(obj, "action")
  const properties = get(obj, "properties")
  const customProperties = get(obj, "customProperties")
  if (!isEmpty(customProperties)) {
    merge(properties, customProperties)
  }
  return tracker.trackEvent(action, properties)
}

const trackPageView = (pageName, path) => {
  const urlWithSearch = trim(get(window, "location.href", ""))
  const pathWithBasename = `${BASE_NAME}${path}`
  tracker.trackPageView(urlWithSearch, pathWithBasename, {
    pageName,
  })
}

export {
  tracker,
  gtmTracker,
  trackEvent,
  trackPageView,
  amplitudeTracker,
  hubspotTracker,
}
