import { removeAllLocalItem } from "@rentspree/cookie"
import { DOMAIN_NAME } from "../env"

export const STORAGE = {
  USER_TOKEN: "rentspreeUserToken",
}

// DOMAIN NAME HAS TO BE OMITTED FOR SHARE COOKIE TO WORK ON LOCALHOST
export const COOKIE_OPTION =
  DOMAIN_NAME === "localhost"
    ? { path: "/" }
    : { path: "/", domain: DOMAIN_NAME }

export const removeAccessToken = () => {
  removeAllLocalItem("rentspree", COOKIE_OPTION)
}
