import get from "lodash/get"
import moment from "moment"

export const stepPremiseInformation = payload => {
  const moveInDate = get(payload, "moveInDate")
  return {
    moveDate: moveInDate ? moment(moveInDate).format("YYYY-MM-DD") : "",
    monthlyRent: parseFloat(get(payload, "monthlyRent")) || "",
  }
}
