import styled from "styled-components"

export const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  padding: ${props => (props.padding ? props.padding : "55px 0")};
  position: relative;

  @media (max-width: 991px) {
    padding: ${props => (props.tPadding ? props.tPadding : "55px 0")};
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`
