import React, { useEffect } from "react"
import styled from "styled-components"
import RadioImage from "components/atoms/radio-image"
import { COLOR } from "styles/settings"
import BooleanCard from "../molecules/boolean-card"
import AlertPanel from "../templates/alert-panel-template"

const SpecialOfferContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const GreyWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  background-color: ${COLOR.bgGrey};
  border: solid 1px ${COLOR.borderGrey};
  color: ${COLOR.newBlack};
  overflow-y: auto;
`

const SpecialOfferRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const SpecialOfferStep = ({
  handleAction = () => {},
  isSelectSpecialOffer,
  testId,
  isShowError,
  setIsShowError = () => {},
  acceptOfferLabel,
  notAcceptOfferLabel,
  children,
}) => {
  useEffect(
    () => {
      if (isShowError === true) {
        setIsShowError(false)
      }
    },
    [isSelectSpecialOffer],
  )
  return (
    <SpecialOfferContainer
      data-testid={`${testId}-special-offer-radio-group-container`}>
      <GreyWrapper>
        {isShowError && (
          <AlertPanel
            data-testid={`${testId}-alert-panel-special-offer-radio-group`}
            error
            margin="0"
            alertDetail="Selection required"
            description=" "
            padding="10px"
          />
        )}
        {children}
        <SpecialOfferRadioGroup>
          <BooleanCard
            id="AcceptedSpecialOfferBooleanCard"
            testId={`${testId}-accept-special-offer`}
            checked={isSelectSpecialOffer}
            isHidden={false}
            title={acceptOfferLabel}
            icon={RadioImage}
            ascent=""
            onClick={() => {
              handleAction(true)
            }}
          />
          <BooleanCard
            id="NotAcceptedSpecialOfferBooleanCard"
            testId={`${testId}-not-accept-special-offer`}
            checked={isSelectSpecialOffer === false}
            isHidden={false}
            title={notAcceptOfferLabel}
            icon={RadioImage}
            ascent=""
            onClick={() => {
              handleAction(false)
            }}
          />
        </SpecialOfferRadioGroup>
      </GreyWrapper>
    </SpecialOfferContainer>
  )
}
