import { take, actionChannel, put, fork } from "redux-saga/effects"
import { buffers } from "redux-saga"

import { hideLoading } from "containers/loading/actions"
import { GET_APPLICATION_CALL } from "containers/application/constants"
import {
  GET_PROFILE_CALL,
  GET_SCREENING_REQUEST_CALL,
} from "containers/wrapper/constants"
import {
  GET_RENTAL_SUBMISSION_CALL,
  GET_RENTAL_WITH_CO_APPLICANT_CALL,
} from "containers/rental-submission/constants"
import {
  GET_TU_DATA_CALL,
  EXAM_RETRIEVE_CALL,
  GET_CREDIT_REPORT_CALL,
} from "containers/tu-screening/constants"

import { getApplication } from "containers/application/saga"
import { getProfile } from "containers/wrapper/saga-profile"
import { getScreeningRequest } from "containers/wrapper/saga-screening-request"
import {
  getRentalWithCoApplicant,
  getRentalSubmission,
} from "containers/rental-submission/saga"
import {
  getCreditReportSaga,
  getTUScreeningData,
} from "containers/tu-screening/saga"

import { getExam } from "containers/tu-screening/exam/saga"
import { GET_PAYMENT_CALL } from "containers/rental-submission/rental-payment/constants"
import { getPaymentSaga } from "containers/rental-submission/rental-payment/saga"
import { GET_DRAFT_DOCUMENT_CALL } from "../document-upload/constants"
import { getDraftDocument } from "../document-upload/saga"

export function* fetchApiQueue() {
  // create buffer size 5 which will expanding when overflow
  const buffer = buffers.expanding(5)
  // create queue for following action type
  const apiChannel = yield actionChannel(
    [
      GET_APPLICATION_CALL,
      GET_RENTAL_SUBMISSION_CALL,
      GET_RENTAL_WITH_CO_APPLICANT_CALL,
      GET_PROFILE_CALL,
      GET_SCREENING_REQUEST_CALL,
      GET_TU_DATA_CALL,
      EXAM_RETRIEVE_CALL,
      GET_PAYMENT_CALL,
      GET_DRAFT_DOCUMENT_CALL,
      GET_CREDIT_REPORT_CALL,
    ],
    buffer,
  )
  while (true) {
    // take actions from queue
    const actions = yield take(apiChannel)
    let worker = () => {}
    // assign worker for each action type
    switch (actions.type) {
      case GET_APPLICATION_CALL:
        worker = getApplication
        break
      case GET_RENTAL_SUBMISSION_CALL:
        worker = getRentalSubmission
        break
      case GET_RENTAL_WITH_CO_APPLICANT_CALL:
        worker = getRentalWithCoApplicant
        break
      case GET_PROFILE_CALL:
        worker = getProfile
        break
      case GET_SCREENING_REQUEST_CALL:
        worker = getScreeningRequest
        break
      case GET_TU_DATA_CALL:
        worker = getTUScreeningData
        break
      case EXAM_RETRIEVE_CALL:
        worker = getExam
        break
      case GET_PAYMENT_CALL:
        worker = getPaymentSaga
        break
      case GET_DRAFT_DOCUMENT_CALL:
        worker = getDraftDocument
        break
      case GET_CREDIT_REPORT_CALL:
        worker = getCreditReportSaga
        break
      default:
        break
    }
    // call worker with actions as argument
    yield* worker(actions)
    // call HIDE_LOADING action when buffer is empty to hide full screen loading component
    if (buffer.isEmpty()) {
      yield put(hideLoading())
    }
  }
}

export default function* root() {
  yield fork(fetchApiQueue)
}
