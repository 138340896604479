import React from "react"

import ERROR_ICON from "images/error.svg"
import { locationAssign } from "utils/call-window"
import { ROUTE } from "containers/router/constants"
import {
  ContentWrapper,
  ExpiredHeading,
  ExpiredText,
  BackToDashboardButton,
} from "./styles"

export const ApplyLinkExpired = () => (
  <ContentWrapper>
    <img src={ERROR_ICON} alt="Expired error" height="90px" width="90px" />
    <ExpiredHeading>Sorry, that link has expired</ExpiredHeading>
    <ExpiredText>
      Please contact the property representative to ask for a new link.
    </ExpiredText>
    <BackToDashboardButton
      text="Back to dashboard"
      margin="30px 0 0 0"
      padding="9px 25px 9px 25px"
      size="16px"
      id="BackToDashBoardBtn"
      semiBold
      google
      onClick={() => locationAssign(ROUTE.DASHBOARD_RENTER)}
    />
  </ContentWrapper>
)
