import { API_URL, FILES_URL, USER_API_URL } from "../../env"

export const USER_ME_API = `${USER_API_URL}/me`
export const USER_TERMS_API = `${USER_ME_API}/terms`
export const PREPARE_ENVELOPE_API_URL =
  "/api/v2/rental-submissions/:rentalId/application/envelope/prepare"
// === eSign document APIs Zone ===
export const UPLOAD_ENVELOPE_FILE = `${FILES_URL}/digidocs/files/upload`
export const BASE_DIGIDOC_URL = `${FILES_URL}/digidocs/`
export const DIGIDOC_GET_FILE = `${FILES_URL}/digidocs/envelope/:envelopeId/files`
export const CREATE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope`
export const UPDATE_ENVELOPE_SIGN = `${API_URL}/api/v2/rental-submissions/:rentalId/application/envelope/sign`
export const VOID_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/void`
export const RESEND_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/share/resend`
export const GET_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId`
export const SHARE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/share`
export const UPLOAD_SIGNATURE = `${FILES_URL}/digidocs/envelope/:envelopeId/signature`
export const UPDATE_ENVELOPE_CONSENT = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/consent`
export const UPDATE_TEMPLATE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/template`
export const GENERATE_ENVELOPE_PDF = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/pdf/generate`
export const GET_ENVELOPE_PDF = `${API_URL}/api/v2/rental-submissions/:rentalId/envelope/:envelopeId/pdf`
// === End of eSign document APIs ===
