import React from "react"
import B14 from "@rentspree/component-v2/dist/typography/b14"
import panelError from "images/icon/panel-error.svg"
import Row from "components/atoms/row"

const ErrorPanel = ({ title, description }) => (
  <div>
    <Row flexStart>
      <img src={panelError} alt="panel-error" />
      <B14 weight="600" color="inherit" margin="0 0 0 10px" lineHeight="20px">
        {title}
      </B14>
    </Row>
    {description && (
      <B14 color="inherit" margin="2px 0 0 30px" lineHeight="20px">
        {description}
      </B14>
    )}
  </div>
)

export default ErrorPanel
