import _ from "lodash"

export const examMapper = (tuQuestion, values) =>
  tuQuestion.map(question => {
    const questionId = question.Id
    const currentQuestion = _.find(values.questions, { id: questionId })
    if (currentQuestion) {
      return {
        ...question,
        SelectedAnswerId: _.get(currentQuestion, "selected"),
      }
    }
    return question
  })
