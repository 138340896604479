import React from "react"
import { Clearfix } from "react-bootstrap"
import { FormattedInput, SelectDropdown } from "@rentspree/component-v2"
import { Col } from "components/layout/bootstrap"
import ToolTipContent from "components/atom/tooltip-content"
import HintTooltip from "components/atom/hint-tooltip"
import { COLOR } from "styles/settings"
import { INCOME_TOOLTIP_TEXT, TU_WORK_STATUS } from "./constants"

export const EmploymentInfo = ({
  values,
  onIncomeChange,
  onWorkStatusChange,
  handleBlur,
  errors,
  touched,
  disabled,
}) => (
  <Clearfix>
    <Col md={4} sm={4}>
      <SelectDropdown
        id="tuEmployedInfoWorkStatus"
        isRequired
        type="text"
        name="workStatus"
        onChange={onWorkStatusChange}
        onBlur={handleBlur}
        value={values.workStatus}
        error={touched.workStatus && errors.workStatus}
        label="Work Status"
        options={TU_WORK_STATUS}
        disabled={disabled}
      />
    </Col>
    <Col md={4} sm={4}>
      <FormattedInput
        formatType="currency"
        isRequired
        value={values.income}
        id="tuEmployedInfoIncome"
        name="income"
        label="Gross Monthly Income"
        labelChildren={
          <HintTooltip
            style={{ display: "inline" }}
            position="TOP"
            iconMargin="0px"
            iconName="fa-question-circle"
            title=""
            fontTitle="14px"
            sizeTitle="13px"
            color={COLOR.lightGray}
            weightTitle="600"
            bubbleWidth="267px">
            <ToolTipContent
              title="Gross Monthly Income"
              body={INCOME_TOOLTIP_TEXT}
            />
          </HintTooltip>
        }
        onValueChange={onIncomeChange}
        onBlur={handleBlur}
        error={touched.income && errors.income}
        disabled={disabled}
        allowNegative={false}
      />
    </Col>
  </Clearfix>
)
